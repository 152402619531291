// ##############################
// // // Dashboard styles
// #############################

import { successColor,dangerColor,reverseColor } from "assets/jss/material-dashboard-react.jsx";

const calendarStyle = {
  successText: {
    color: successColor
  },
  dangerText: {
    color: dangerColor
  },
  reverseText:{
  	color:reverseColor
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  oilCardCategory: {
    width: 14,
    height: 14
  },
  backgroundCalendar: {
    backgroundColor: '#fff'
  }
};

export default calendarStyle;
