import React from "react";
import { withStyles, Card, CardContent, CardHeader, CardActions, Typography } from "@material-ui/core";
import { IconButton } from "components";
import PropTypes from "prop-types";
import cx from "classnames";
import Tooltip from '@material-ui/core/Tooltip';
import { AddCircle, Reply } from "@material-ui/icons";
import specialCardStyle from "assets/jss/material-dashboard-react/specialCardStyle";

function SpecialCard({ ...props }) {
  const { classes, plainCard, cardTitle, cardSubtitle, content, footer, add, edit,
    back, excel, iconColor 
  } = props;
  const plainCardClasses = cx({
    [" " + classes.cardPlain]: plainCard
  });
  return (
    <Card className={classes.card + plainCardClasses}>
      <CardHeader
        classes={{
          root: classes.cardHeader + " " + classes[iconColor + "CardHeader"],
          avatar: classes.cardAvatar
        }}
        avatar={<props.icon className={classes.cardIcon} />}
        />
      <div>
        <Typography variant="title" className={classes.title}>
          {cardTitle}
        </Typography>
        <Typography component="p" className={classes.cardCategory}>
          {cardSubtitle}
        </Typography>
        <div>
        <div className={classes.iconos}>
          {add !== undefined ? (
            
            <Tooltip
                  title={edit?'Agregar':'Regresar'}
                  placement={'bottom-start'}
                  enterDelay={100}
                >
              <IconButton
                  color="primary"
                  aria-label="Agregar"
                  aria-haspopup="true"
                  onClick={(edit)?add:back}
                  className={classes.buttonLink}>
                {edit ?<AddCircle className={classes.links} />:<Reply className={classes.links} />}
              </IconButton>
            </Tooltip>
          ) : null}
            {(excel !== undefined ) ? (
              excel
            ) : <div></div>}
          </div>
        </div>
      </div>
      <CardContent>{content}</CardContent>
      {footer !== undefined ? (
        <CardActions className={classes.cardActions}>{footer}</CardActions>
      ) : null}
    </Card>
  );
}

SpecialCard.defaultProps = {
  headerColor: "orange"
};

SpecialCard.propTypes = {
  plainCard: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple"]),
  cardTitle: PropTypes.node,
  cardSubtitle: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node
};

export default withStyles(specialCardStyle)(SpecialCard);
