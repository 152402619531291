import React from "react";
import classNames from "classnames";
import invoke from "services/invoke.jsx";
import { Manager, Target, Popper } from "react-popper";
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Portal from '@material-ui/core/Portal';
import TextField from '@material-ui/core/TextField';
import { withStyles, IconButton, MenuItem, MenuList, Grow, Paper, ClickAwayListener, Hidden, InputAdornment, Grid,
  ListItem, ListItemText, ListItemIcon, Checkbox } from "@material-ui/core";
import { Person, Dashboard, LocalGasStation, Lock, LockOpen, VpnKey, Assessment, Help, Contacts, Info, AssignmentReturned, Visibility, VisibilityOff, Description } from "@material-ui/icons";
import { FormDialog, ExpansionPanelCustomized, ItemGrid } from "components";
import headerLinksStyle from "assets/jss/material-dashboard-react/headerLinksStyle";
import dashRouter from "routes/dashboard.jsx";

var sesion = invoke.getSesion();
class HeaderLinks extends React.Component {
  state = {
    open: false,
    open2: false,
    estacion: '',
    abrirEstacion: false,
    abrirClientes: false,
    id_Estacion: '',
    id_Cliente: '',
    id_EstacionCE: '',
    id_ClienteCE: '',
    nb_ClienteCE: '',
    required: false,
    estaciones:   [],
    clientes:   [],
    message: '',
    tr: false,
    openPass: false,
    nb_PassAnterior: '',
    nb_PassNueva: '',
    nb_PassConfirm:'',
    requiredPassNueva: false,
    requiredPassAnterior: false,
    requiredPassConfirm: false,
    sn_Estacion: true,
    sn_Cliente: false,
    openDialogAyudaView: false,
    nb_Modulo: null,
    tableDataInfo: null,
    infografias: [],
    ayudas: [],
    nb_Manual: null,
    openDialogDocumentoView: false,
    urlDocumento: '',
    urlDocumentoDownload: '',
    de_AvisoPassword: 'Estimado cliente, por tu seguridad y de tu información se agregó la medida de seguridad para que cambies tu contraseña asignada por default. Si la pierdes, solicita al personal de petroil que te atiende para que te restablecer la contraseña.',
    showPassword: false,
    showPassWordNew: false,
    showPassWordConfirm: false,
    nu_PermisoCRE: '',
    // Información Fiscal
    regimenFiscal: [],
    regimenSocietario: [],
    direccionesFiscales: [],
    de_Rfc: '',
    requiredRfc: false,
    de_RazonSocial: '',
    requirednbRazonSocial: false,
    id_RegimenFiscal: '',
    requiredRegimenFiscal: false,
    id_RegimenSocietario:'',
    requiredRegimenSocietario: false,
    id_ClienteDireccionFiscal: '',
    requiredDireccionFiscal: false,
    openModalInfoFiscal: false,
    id_TipoPersona: '',
    de_AvisoInformacionFiscal: 'Estimado cliente, favor de excluir el régimen societario de su razón social',
    sn_InformacionFiscalCorrecta: 'FALSE',
    requiredConfirmacion: false,
  };

  async componentWillMount(){//es parecido a la funcion init

    const {location} = this.props
    // escucha cuando cambian las rutas
    if (this.props.history) {
      this.props.history.listen(async (location, action) => {
        let currentRoute = null;
        dashRouter.forEach( (route)=>{
          if (route.path === location.pathname) {
            currentRoute = route;
            this.setState({nb_Modulo: currentRoute.navbarName})
          }
        });
        this.setState({infografias: []})
        this.setState({ urlAyuda: null});
        this.setState({ nb_Manual: null });
        const responseInfografias = await invoke.post('opciones', 'infografias', { id_OpcionPortal: (currentRoute) ? currentRoute.id_OpcionPortal : null, id_TipoUsuario : sesion ? sesion.usuario.id_TipoUsuario : null })
        if (responseInfografias.data.data) {
          this.setState({infografias: responseInfografias.data.data || []})
        }
      });

      // la primera vez que entra
      let currentRoute = null;
      dashRouter.forEach( (route)=>{
        if (route.path === location.pathname) {
          currentRoute = route;
          this.setState({nb_Modulo: currentRoute.navbarName})
        }
      });
      const responseInfografias = await invoke.post('opciones', 'infografias', { id_OpcionPortal: (currentRoute) ? currentRoute.id_OpcionPortal : null, id_TipoUsuario : sesion ? sesion.usuario.id_TipoUsuario : null })
      if (responseInfografias.data.data) {
        this.setState({infografias: responseInfografias.data.data || []})
      }
    }

    if(localStorage.getItem("sesion")){
      if(sesion.usuario.id_TipoUsuario===5){
        if(!sesion.estacion){
          this.setState({ abrirEstacion: true });
        }else if(sesion.estacionObj){
          this.setState({ estacion: sesion.estacionObj[0].nb_Estacion });
          this.setState({ nu_PermisoCRE: sesion.estacionObj[0].nu_PermisoCRE });
        }
      }
      if(sesion.usuario.sn_ContraDefault){
        this.setState({ openPass: true });
        this.setState({ abrirEstacion: false });
      }
    }else{
      localStorage.removeItem("sesion");
      window.location.reload();
    }

    // Cerrar sesión después de 15 minutos de inactividad
    this.interval = setInterval(() => {
      var deadSesion = localStorage.getItem('dead')
      localStorage.setItem('dead', (deadSesion-5000))
      if(localStorage.getItem('dead') <= 0){
        invoke.logout();
        window.location.reload();
      }
    }, 5000);

     // Caducar la sesión si han transcurrido 4 horas desde que se loggeo
    this.interval = setInterval(() => {
      const loginDate = new Date(localStorage.getItem('logInDate')); // Fecha de inicio de sesión
      const currentDate = new Date(); // Fecha actual
      // const testDate = new Date('May 16, 2022 9:00:00');
      if(loginDate){
        // Obtener los milisegundos de diferencia entre la fecha actual y la fecha de inicio de sesion
        var hours = (Math.abs(currentDate - loginDate) / 36e5);
        if(hours >= 4){
          invoke.logout();
          window.location.reload();
        }
      }
    }, 10000);

    // Preguntar al usuario si sigue en el sistema después de 14 minutos
    if(sesion.usuario.id_TipoUsuario===5){
      invoke.post('estacion','combo',{id_Usuario: sesion.usuario.id_Usuario, id_Estacion: null}).then(res => {
        if(res.status===200 && res.data.data){
          var VAR = [];
          for(var i in res.data.data){
            var x = res.data.data[i];
            VAR.push({
              value: x.id_Estacion,
              label: x.nb_Estacion,
              obj: x
            });
          }
          this.setState({ estaciones: VAR });
          if(sesion.estacion){
            this.setState({ id_Estacion: sesion.estacion });
            this.setState({ id_EstacionCE: sesion.usuario.id_Cliente+''+sesion.estacion });
            this.setState({ nb_ClienteCE: sesion.usuario.de_RazonSocial || '' });
            this.setState({ id_ClienteCE: sesion.usuario.id_Cliente || '' });
          }
        }else{
          this.setState({ required: true });
          this.showNotification("tr", res.message)
        }
      });

      this.setState({ sn_Estacion: true });
      this.setState({ sn_Cliente: false });
    }else{
      if (sesion.usuario.nu_Clientes > 1) {
        invoke.post('clientes','combo',{id_Usuario: sesion.usuario.id_Usuario}).then(res => {
          if(res.status===200 && res.data.data){
            var VAR = [];
            for(var i in res.data.data){
              var x = res.data.data[i];
              VAR.push({
                value: x.id_Cliente,
                label: x.de_RazonSocial,
                obj: x
              });
            }
            this.setState({ clientes: VAR });
            if(sesion.usuario.id_Cliente){
              this.setState({ id_Cliente: sesion.usuario.id_Cliente });
            }
          }else{
            this.setState({ required: true });
            this.showNotification("tr", res.message)
          }
        });
        this.setState({ sn_Cliente: true });
      }else{
        this.setState({ sn_Cliente: false });
      }
      this.setState({ sn_Estacion: false });
    }

    this.combosInformacionFiscal();
  }

  showNotification(place,message,time=5000) {
    this.setState({ message: message });
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000){
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  handleClickAddEstacion = () => {
    if (this.state.id_Estacion === ''){
      this.mensajeLog('required','Estacion Requerida.');
      return;
    } else {
      var sesion = invoke.getSesion();
      sesion.estacion = this.state.id_Estacion;
      sesion.usuario.id_Cliente = this.state.id_ClienteCE;
      sesion.usuario.de_RazonSocial = this.state.nb_ClienteCE;
      sesion.usuario.nb_Cliente = this.state.nb_ClienteCE;
      invoke.post('estacion','combo',{id_Usuario: sesion.usuario.id_Usuario, id_Estacion: this.state.id_Estacion, id_Cliente: sesion.usuario.id_Cliente}).then(res => {

        if (res.status===200 && res.data.data) {
          sesion.estacionObj = res.data.data;
          invoke.setSesion(sesion);
          this.setState({ abrirEstacion: false });
          this.setState({ estacion: sesion.estacionObj[0].nb_Estacion });
          this.setState({ nu_PermisoCRE: sesion.estacionObj[0].nu_PermisoCRE });
          this.showNotification("tc", 'Se guardo correctamente');
          window.location.reload();
        } else {
          this.setState({ required: true });
          this.showNotification("tr", res.message)
        }
      });
    }
  };

  handleClickEstacion = () => {
    this.setState({ abrirEstacion: true });
  };

  // Controlador para "contraseña anterior"
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Controlador para "contraseña nueva"
  handleClickShowPasswordNew = () => {
    this.setState({ showPasswordNew: !this.state.showPasswordNew });
  };

  handleMouseDownPasswordNew = (event) => {
    event.preventDefault();
  };

  // Controlador para "confirmar contraseña"
  handleClickShowPasswordConfirm = () => {
    this.setState({ showPasswordConfirm: !this.state.showPasswordConfirm });
  };

  handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  handleClickEstacionClose = () => {
    var sesion = invoke.getSesion();
    if(sesion.estacion > 0){
      this.setState({ abrirEstacion: false });
    }else{
      this.mensajeLog('required','Estacion Requerida.');
      return;
    }
    this.setState({ open: false, open2: false });
  };

  handleClickAddClientes = () => {
    if(this.state.id_Cliente === ''){
      this.mensajeLog('required','Cliente Requerido.');
      return;
    }else{
      var sesion = invoke.getSesion();
      sesion.usuario.id_Cliente = this.state.id_Cliente;
      sesion.usuario.de_RazonSocial = this.state.clientes.find(x => x.value === this.state.id_Cliente).label;
      invoke.setSesion(sesion);
      this.setState({ abrirClientes: false });
      this.setState({ clientes: sesion.clientesObj });
      this.showNotification("tc", 'Se guardo correctamente');
      window.location.reload();
    }
  };

  handleClickClientes = () => {
    this.setState({ abrirClientes: true });
  };

  handleClickClientesClose = () => {
    var sesion = invoke.getSesion();
    if(sesion.usuario.id_Cliente > 0){
      this.setState({ abrirClientes: false });
    }else{
      this.mensajeLog('required','Cliente Requerido.');
      return;
    }
    this.setState({ open: false, open2: false });
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClickLogin = () => {
    this.setState({ open2: !this.state.open2 });
  };

  handleClose = () => {
    //this.setState({ open: false, open2: false });
  };

  handleCloseSesion = () => {
    invoke.logout();
    this.setState({ open: false, open2: false });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeEstacion = name => event => {
    let option
    let i
    for(i in this.state.estaciones) {
      option = this.state.estaciones[i]
      if(option.obj.id_CE === event.target.value){
        this.setState({ id_Estacion: option.obj.id_Estacion })
        this.setState({ [name]: event.target.value })
        this.setState({ id_ClienteCE: option.obj.id_Cliente })
        this.setState({ nb_ClienteCE: option.obj.de_RazonSocial })
      }
    }
  };

  handleClickChangePass = () => {
    var sesion = invoke.getSesion();
    if(this.state.nb_PassAnterior === ''){
      this.mensajeLog('requiredPassAnterior','Contraseña anterior es requerida.');
      return;
    }else if(this.state.nb_PassNueva === ''){
      this.mensajeLog('requiredPassNueva','Contraseña nueva es requerida.');
      return;
    }else if(this.state.nb_PassNueva === sesion.usuario.nb_Usuario){
      this.mensajeLog('requiredPassNueva','La contraseña no puede ser igual al usuario.');
      return;
    }else if(this.state.nb_PassConfirm === ''){
      this.mensajeLog('requiredPassConfirm','Es requerido confirmar la contraseña.');
      return;
    }else if(this.state.nb_PassConfirm !== this.state.nb_PassNueva){
      this.mensajeLog('requiredPassConfirm','La contraseña nueva debe coincidir con la confirmación.');
      return;
    }else{
      invoke.post('login','verificarContra',{id_Usuario: sesion.usuario.id_Usuario, nb_PassAnterior: this.state.nb_PassAnterior}).then(res => {
        if(res.status===200 && res.data.result){
          if(res.data.result.length){
            invoke.post('login','cambiarContra',{id_Usuario: sesion.usuario.id_Usuario, nb_PassNueva: this.state.nb_PassNueva}).then(res => {
              if(res.status===200 && res.data.message==='OK'){
                this.showNotification("tc", 'Contraseña guardada correctamente.')
                if(sesion.usuario.sn_ContraDefault){
                  sesion.usuario.sn_ContraDefault = 0;
                  invoke.setSesion(sesion);
                  window.location.reload();
                } else {
                  this.handleClickPassClose();
                }
                // Limpiar controles
                this.setState({ nb_PassAnterior: '' });
                this.setState({ nb_PassNueva: '' });
                this.setState({ nb_PassConfirm: '' });
              }else{
                this.setState({ requiredPassAnterior: true });
                this.showNotification("tr", res.data.message);
              }
            });
          }else{
            this.mensajeLog('requiredPassAnterior','Contraseña anterior Incorrecta.');
          }
        }else{
          this.setState({ required: true });
          this.showNotification("tr", res.message)
        }
      });
    }
  };

  handleClickOpenPass = () => {
    this.setState({ openPass: true });
    this.setState({ open: false, open2: false });
  };

  handleClickPassClose = () => {
    if (sesion.usuario.sn_ContraDefault){
      this.mensajeLog('requiredPassAnterior','Necesitas cambiar la contraseña por seguridad.');
    } else {
      this.setState({ openPass: false });
    }
  };

  // INFORMACION FISCAL DEL CLIENTE
  handleClickOpenInfoFiscal = () => {
    this.getInformacionFiscal();
    this.setState({ openModalInfoFiscal: true });
    this.setState({ open: false, open2: false });
  };

  handleClickInfoFiscalClose = () => {
    this.setState({ openModalInfoFiscal: false });
  };

  getInformacionFiscal = () => {
    invoke.post('Clientes','getInformacionFiscal',{ id_Cliente: sesion.usuario.id_Cliente }).then(res => {
      if(res.status===200 && res.data.data){
        let informacionFiscal = res.data.data[0];
        this.setState({ de_RazonSocial: informacionFiscal.de_RazonSocial });
        this.setState({ de_Rfc: informacionFiscal.de_Rfc });
        this.setState({ id_TipoPersona: informacionFiscal.id_TipoPersona });
        this.setState({ id_RegimenSocietario: informacionFiscal.id_RegimenSocietario });
        this.setState({ id_RegimenFiscal: informacionFiscal.id_RegimenFiscal });
        this.setState({ id_ClienteDireccionFiscal: informacionFiscal.id_ClienteDireccionFiscal });
        this.setState({ sn_InformacionFiscalCorrecta: informacionFiscal.sn_InformacionFiscalCorrecta ? 'TRUE' : 'FALSE' });

      }else{
        this.setState({ required: true });
        this.showNotification("tr", res.message)
      }
    });
  }

  combosInformacionFiscal = () => {
    // Combo Regimen fiscal
    invoke.post('Clientes','comboRegimenFiscal',{}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_RegimenFiscal,
            label: x.nb_RegimenFiscal,
            obj: x
          });
        }
        this.setState({ regimenFiscal: VAR });
      }else{
        this.setState({ required: true });
        this.showNotification("tr", res.message)
      }
    });

    // Combo Regimen Societario
    invoke.post('Clientes','comboRegimenSocietario',{}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_RegimenSocietario,
            label: x.nb_RegimenSocietario,
            obj: x
          });
        }
        this.setState({ regimenSocietario: VAR });
      }else{
        this.setState({ required: true });
        this.showNotification("tr", res.message)
      }
    });
    // Combo Direcciones Fiscales
    invoke.post('Clientes','comboDireccionesFiscales',{ id_Cliente: sesion.usuario.id_Cliente }).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_ClienteDireccionFiscal,
            label: x.de_Direccion,
            obj: x
          });
        }
        this.setState({ direccionesFiscales: VAR });
      }else{
        this.setState({ required: true });
        this.showNotification("tr", res.message)
      }
    });
  }

  guardarInformacionFiscal = () =>{
    if(this.state.de_RazonSocial === '' || !this.state.de_RazonSocial){
      this.mensajeLog('requierednbRazonSocial','Es requerido capturar la Razón Social.');
      return;
    }
    if(this.state.de_Rfc === '' || !this.state.de_Rfc){
      this.mensajeLog('requieredRfc','Es requerido capturar el RFC.');
      return;
    }

    // eslint-disable-next-line no-useless-escape
    let rfc = '^(([A-Z]|[a-z]|\s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
    if(this.state.de_Rfc.length === 12){
      rfc = '^(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
    }

    var valida = new RegExp(rfc);
    if(!valida.test(this.state.de_Rfc)){
        this.mensajeLog('requieredRfc','La estructura del RFC no es válida, favor de revisar.');
        return false;
    }

    if(this.state.id_RegimenFiscal === '' || !this.state.id_RegimenFiscal){
      this.mensajeLog('requiredRegimenFiscal','Es requerido capturar el Régimen Fiscal.');
      return;
    }

    // régimen societario solo es requerido para las personas morales
    if (this.state.id_TipoPersona === 2) {
      if(this.state.id_RegimenSocietario === '' || !this.state.id_RegimenSocietario){
        this.mensajeLog('requiredRegimenSocietario','Es requerido capturar el Régimen Societario.');
        return;
      }
    }

    if(this.state.id_ClienteDireccionFiscal === '' || !this.state.id_ClienteDireccionFiscal){
      this.mensajeLog('requiredDireccionFiscal','Es requerido capturar la Dirección Fiscal.');
      return;
    }

    if(this.state.sn_InformacionFiscalCorrecta === 'FALSE' || !this.state.sn_InformacionFiscalCorrecta){
      this.mensajeLog('requiredConfirmacion','Es requerido marcar la casilla de confirmación información fiscal.');
      return;
    }

    const datos = {
      id_Cliente: sesion.usuario.id_Cliente,
      de_RazonSocial: this.state.de_RazonSocial,
      de_Rfc: this.state.de_Rfc,
      id_RegimenFiscal: this.state.id_RegimenFiscal,
      id_RegimenSocietario: this.state.id_RegimenSocietario,
      id_ClienteDireccionFiscal: this.state.id_ClienteDireccionFiscal,
      sn_InformacionFiscalCorrecta: this.state.sn_InformacionFiscalCorrecta === 'TRUE' ? 1 : 0,
    }

    invoke.post('clientes','guardarInformacionFiscal',datos).then(res => {
      if(res.status===200 && res.data.message==='OK'){
        this.showNotification("tc", 'Información editada correctamente.')
        sesion.usuario.de_RazonSocial = this.state.de_RazonSocial;
        invoke.setSesion(sesion);
        window.location.reload();
      }else{
        this.setState({ requiredRfc: true });
        this.showNotification("tr", res.data.message);
      }
    });

  }

  handleChangeRegimenFiscal = name => event => {
    let option
    let i
    for(i in this.state.regimenFiscal) {
      option = this.state.regimenFiscal[i]
      if(option.obj.id_RegimenFiscal === event.target.value){
        this.setState({ id_RegimenFiscal: option.obj.id_RegimenFiscal })
        this.setState({ [name]: event.target.value })
      }
    }
  };

  handleChangeRegimenSocietario = name => event => {
    let option
    let i
    for(i in this.state.regimenSocietario) {
      option = this.state.regimenSocietario[i]
      if(option.obj.id_RegimenSocietario === event.target.value){
        this.setState({ id_RegimenSocietario: option.obj.id_RegimenSocietario })
        this.setState({ [name]: event.target.value })
      }
    }
  };

  handleChangeDireccionFiscal = name => event => {
    let option
    let i
    for(i in this.state.direccionesFiscales) {
      option = this.state.direccionesFiscales[i]
      if(option.obj.id_ClienteDireccionFiscal === event.target.value){
        this.setState({ id_ClienteDireccionFiscal: option.obj.id_ClienteDireccionFiscal })
        this.setState({ [name]: event.target.value })
      }
    }
  };

  handleChangeConfirmacion = name => event => {
    this.setState({
      [name]: (event.target.value==='FALSE')?'TRUE':'FALSE',
    });
  };

  handleChangeValidate = (name,validate) => event => {
    invoke.resetTime();
    if(validate.maxLength && (event.target.value).length <= validate.maxLength){
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  setInfografria = (infografia) => {
    this.setState({ urlAyuda: `${infografia.de_RutaInfografia}` } );
    this.setState({ nb_Manual: infografia.nb_Manual || null } );
  };


  handleClickAyuda = () => {
    //const { classes } = this.props;
    this.setState({ openDialogAyudaView: true });
    if (this.state.infografias && this.state.infografias.length) {
      this.setState({nb_Manual: this.state.infografias[0].nb_Manual || null})
      this.setState({ urlAyuda: `${this.state.infografias[0].de_RutaInfografia}` || null});
    }else {
      this.setState({ openDialogAyudaView: true });
      this.showNotification("tr", 'no existen infografias')
      return
    }
    let tableDataVAR = []
    let ayudasVAR = [];
    let documento
    if (this.state.infografias) {

      this.state.infografias.forEach((infografia,key)=>{
        tableDataVAR.push([(
          <ListItem
            button
            selected={this.state.nb_Manual === infografia.nb_Manual}
            onClick={() => this.setInfografria(infografia)}
            key={key+1}
          >
            <ListItemIcon>
              <Info style={{color: 'rgb(255 152 0 / 80%)', marginRight: '0px'}}/>
            </ListItemIcon>
            <ListItemText primary={infografia.nb_Manual} />
          </ListItem>
        )]);

        documento = (<IconButton style={{color:'#C62828'}} aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (infografia.de_RutaInfografia ? infografia.de_RutaInfografia:''),(infografia.de_RutaInfografia ? infografia.de_RutaInfografia:''))}>
                      <AssignmentReturned />
                    </IconButton>)

        ayudasVAR.push({
          title: (<div><Info style={{color: 'rgb(255 152 0 / 80%)', marginRight: '0px', verticalAlign: 'middle' }}/> {infografia.nb_Manual}</div>),
          description: (infografia.sn_Infografias) ? 'Infografia - '+infografia.nb_Manual : infografia.de_Manual,
          urlDocumento: (<div style={{ position: 'absolute', right: '5px', top: '5px'}}>{infografia.de_RutaInfografia ? documento : null}</div>)
        });
      })



      this.setState({ ayudas : ayudasVAR })
      this.setState({ buttons : tableDataVAR })
    }
  };

  handleClickCloseDialogAyuda = () => {
    this.setState({ openDialogAyudaView: false });
  };

  downloadPdf = (url,urlDescarga) => event => {
    this.setState({ openDialogDocumentoView: true });
    this.setState({ urlDocumento: url });
    this.setState({ urlDocumentoDownload: urlDescarga });
  };

  handleClickDocumento = () => {
    window.open(this.state.urlDocumentoDownload, "_blank")
    this.setState({ openDialogDocumentoView: false });
  };

  handleClickCloseDocumentoView = () => {
    this.setState({ openDialogDocumentoView: false });
  };

  /*
  redirecLink(path){
    this.setState({ open: false, open2: false });
    this.props.history.push(path);
  };
  */
  render() {
    const { classes } = this.props;
    const { open2 } = this.state;
      return (
        <div>
          <Hidden smDown>
            {(this.state.clientes && sesion.usuario.nu_Clientes > 1)?(<Button onClick={this.handleClickClientes}><Contacts style={{ color:'#0d4377',verticalAlign: 'sub', marginRight: '5px'}}/> Clientes</Button>):(<label></label>)}
          </Hidden>
          <Hidden mdUp>
            {(this.state.clientes && sesion.usuario.nu_Clientes > 1)?(<Button style={{margin: '10px auto', display: 'block', color: '#fff',background:'rgba(255,255,255,0.3)'}} onClick={this.handleClickClientes}>Cambiar Cliente</Button>):(<label></label>)}
          </Hidden>
          {/* Visualizar Estación y Permiso en Movil */}
          <Hidden mdUp>
            {(this.state.estacion)?(<label style={{margin: '10px auto', display: 'block', textAlign:'center', color:'#fff', fontWeight:'bold'}}>{this.state.estacion}</label>):(<label></label>)}
          </Hidden>
          <Hidden mdUp>
            {(this.state.nu_PermisoCRE)?(<label style={{margin: '10px auto', display: 'block', textAlign:'center', color:'#fff', fontWeight:'bold'}}>{this.state.nu_PermisoCRE}</label>):(<label></label>)}
          </Hidden>
          <Hidden smDown>
            {(this.state.estacion)?(<Button onClick={this.handleClickEstacion}>{this.state.estacion}</Button>):(<label></label>)}
          </Hidden>
          <Hidden mdUp>
            {(this.state.estacion)?(<Button style={{margin: '10px auto', display: 'block', color: '#fff',background:'rgba(255,255,255,0.3)'}} onClick={this.handleClickEstacion}>Cambiar Estación</Button>):(<label></label>)}
          </Hidden>
          {(this.state.infografias.length)?(<IconButton
              color="inherit"
              title="Ayuda"
              className={classes.helpButton}
              style={{height:'35px',width:'35px'}}
              onClick={this.handleClickAyuda}
            >
              <Help className={classes.links} />
            </IconButton>)
            : (<label></label>)
          }
          <Hidden smDown>
            <NavLink
              to={'/inicio'}
              className={classes.item}
              activeClassName="active"
            >
              <IconButton
                color="inherit"
                aria-label="Inicio"
                title="Inicio"
                className={classes.buttonLink}
                style={(this.state.sn_Estacion===false)?{height:'35px',width:'35px'}:{}}
              >
                <Dashboard className={classes.links} />
              </IconButton>
            </NavLink>
          </Hidden>
          {(this.state.sn_Estacion===false)?(<div style={{display:'inline-block'}}>
          <Hidden smDown><NavLink
            to={'/Reportes'}
            className={classes.item}
            activeClassName="active"
          >
            <IconButton
              color="inherit"
              aria-label="Reportes"
              title="Reportes"
              className={classes.buttonLink}
              style={{color:'#1cbfd3',height:'35px',width:'35px'}}
            >
              <Assessment className={classes.links} />
            </IconButton>
          </NavLink></Hidden></div>):(<label></label>)}

          <Manager style={{ display: 'inline-block'}}>
            <Target>
              <IconButton
                color="inherit"
                aria-label="Sesion"
                aria-owns={open2 ? "menu-list" : null}
                aria-haspopup="true"
                onClick={this.handleClickLogin}
                className={classes.buttonLink}
              >
                <Person className={classes.links} />
                {/*<span className={classes.notifications}></span>*/}
                <Hidden mdUp>
                  <p onClick={this.handleClick} className={classes.linkText}>
                    Usuario
                  </p>
                </Hidden>
              </IconButton>
            </Target>
            <Popper
              placement="bottom-start"
              eventsEnabled={open2}
              className={
                classNames({ [classes.popperClose]: !open2 }) +
                " " +
                classes.pooperResponsive
              }
            >
              <ClickAwayListener onClickAway={this.handleClose}>
                <Grow
                  in={open2}
                  id="menu-list"
                  style={{ transformOrigin: "0 0 0" }}
                >
                  <Paper className={classes.dropdown}>
                    <MenuList role="menu">
                      {(this.state.estacion)?(<MenuItem
                        onClick={this.handleClickEstacion}
                        className={classes.dropdownItem}
                      >
                        Cambiar Estación
                      </MenuItem>):(<div></div>)}
                      <MenuItem
                        onClick={this.handleClickOpenPass}
                        className={classes.dropdownItem}
                      >
                        Cambiar Contraseña
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClickOpenInfoFiscal}
                        className={classes.dropdownItem}
                      >
                        Información Fiscal
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleCloseSesion}
                        className={classes.dropdownItem}
                      >
                        Cerrar Sesion
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Grow>
              </ClickAwayListener>
            </Popper>
          </Manager>

          <FormDialog
            title={(<div><InputAdornment position="start"><LocalGasStation /><span style={{paddingLeft:'15px'}}>Cambiar Estación</span></InputAdornment></div>)}
            description= "Puedes cambiar de estación cuando quieras"
            content= {
              <div>
                <TextField
                    id="id_Estacion"
                    select
                    label="Estación"
                    className={classes.textField}
                    error={this.state.required}
                    value={this.state.id_EstacionCE}
                    onChange={this.handleChangeEstacion('id_EstacionCE')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                      value: this.state.id_EstacionCE
                    }}
                    margin="normal"
                    fullWidth
                    autoFocus
                  >
                    {this.state.estaciones.map(option => (
                      <MenuItem key={option.obj.id_CE} value={option.obj.id_CE}>
                        {option.obj.nb_EstacionLabel}
                      </MenuItem>
                    ))}
                  </TextField>
              </div>
            }
            btn= 'Guardar'
            action= {this.handleClickAddEstacion}
            secondBtn= {{action: this.handleCloseSesion, text: 'Cerrar Sesión'}}
            close={this.handleClickEstacionClose}
            open= {this.state.abrirEstacion}
          />
          <FormDialog
            title={(<div><InputAdornment position="start"><Contacts /><span style={{paddingLeft:'15px'}}>Cambiar Razón Social</span></InputAdornment></div>)}
            description= "Puedes cambiar de Razon Social cuando quieras"
            content= {
              <div>
                <TextField
                    id="id_Cliente"
                    select
                    label="Cliente"
                    className={classes.textField}
                    error={this.state.required}
                    value={this.state.id_Cliente}
                    onChange={this.handleChange('id_Cliente')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                      value: this.state.id_Cliente
                    }}
                    margin="normal"
                    fullWidth
                    autoFocus
                  >
                    {this.state.clientes.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
              </div>
            }
            btn= 'Guardar'
            action= {this.handleClickAddClientes}
            secondBtn= {{action: this.handleCloseSesion, text: 'Cerrar Sesión'}}
            close={this.handleClickClientesClose}
            open= {this.state.abrirClientes}
          />
          <FormDialog
            title={(<div><InputAdornment position="start"><VpnKey /><span style={{paddingLeft:'15px'}}>Cambiar Contraseña</span></InputAdornment></div>)}
            description= "Puedes cambiar tu contraseña las veces que quieras."
            content= {
              <div>
                <Grid container spacing={8} alignItems="flex-end">
                  <Grid item xs={1}>
                    <LockOpen />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      id="nb_PassAnterior"
                      label="Contraseña Anterior"
                      className={classes.textField}
                      error={this.state.requiredPassAnterior}
                      value={this.state.nb_PassAnterior}
                      onChange={this.handleChange('nb_PassAnterior')}
                      margin="normal"
                      type={this.state.showPassword ? 'text' : 'password'}
                      autoFocus
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="Ver Contraseña"
                      onClick={this.handleClickShowPassword}>
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={8} alignItems="flex-end">
                  <Grid item xs={1}>
                    <Lock />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      id="nb_PassNueva"
                      label="Contraseña Nueva"
                      className={classes.textField}
                      error={this.state.requiredPassNueva}
                      value={this.state.nb_PassNueva}
                      onChange={this.handleChange('nb_PassNueva')}
                      margin="normal"
                      type={this.state.showPasswordNew ? 'text' : 'password'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                        aria-label="Ver Contraseña"
                        onClick={this.handleClickShowPasswordNew}>
                        {this.state.showPasswordNew ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={8} alignItems="flex-end">
                  <Grid item xs={1}>
                    <Lock />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      id="nb_PassConfirm"
                      label="Confirmar Contraseña"
                      className={classes.textField}
                      error={this.state.requiredPassConfirm}
                      value={this.state.nb_PassConfirm}
                      onChange={this.handleChange('nb_PassConfirm')}
                      margin="normal"
                      type={this.state.showPasswordConfirm ? 'text' : 'password'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                        aria-label="Ver Contraseña"
                        onClick={this.handleClickShowPasswordConfirm}>
                        {this.state.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </Grid>
                </Grid>
                <p style={{color:'#0d4377', display: 'block', width: '400px', textAlign:'justify', fontSize:'0.9em'}}><Info style={{fontSize:'16px'}}/> {this.state.de_AvisoPassword}</p>
              </div>
            }
            btn= 'Guardar'
            action= {this.handleClickChangePass}
            secondBtn= {{action: this.handleCloseSesion, text: 'Cerrar Sesión'}}
            close={this.handleClickPassClose}
            open= {this.state.openPass}
          />

          {/*Información Fiscal del Cliente*/}
          <FormDialog
            title={(<div><InputAdornment position="start"><Description /><span style={{paddingLeft:'15px', minWidth:'800px'}}>Información Fiscal</span></InputAdornment></div>)}
            description= ""
            content= {
              <div>
                {(this.state.id_TipoPersona === 2 )?(
                  <p style={{color:'#C62828', display: 'block', textAlign:'justify', fontSize:'1.0em', fontWeight:'bold'}}><Info style={{fontSize:'14px'}}/> {this.state.de_AvisoInformacionFiscal}</p>
                )
                  : (<label></label>)
                }
                <Grid container>
                  <ItemGrid item xs={9}>
                    <TextField
                      id="de_RazonSocial"
                      label="Razón Social"
                      className={classes.textField}
                      error={this.state.requirednbRazonSocial}
                      value={this.state.de_RazonSocial}
                      onChange={this.handleChangeValidate('de_RazonSocial', {'maxLength':250})}
                      margin="normal"
                      type="text"
                      fullWidth
                    />
                  </ItemGrid>
                  <ItemGrid item xs={3}>
                    <TextField
                      id="de_Rfc"
                      label="RFC"
                      className={classes.textField}
                      error={this.state.requieredRfc}
                      value={this.state.de_Rfc}
                      onChange={this.handleChangeValidate('de_Rfc', {'maxLength':13})}
                      margin="normal"
                      type="text"
                      fullWidth
                    />
                  </ItemGrid>
                </Grid>
                <div>
                  <TextField
                      id="id_RegimenFiscal"
                      select
                      label="Régimen Fiscal"
                      className={classes.textField}
                      error={this.state.requiredRegimenFiscal}
                      value={this.state.id_RegimenFiscal}
                      onChange={this.handleChangeRegimenFiscal('id_RegimenFiscal')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps:{
                            style:{ maxHeight: 350, maxWidth: 1000 }
                          },
                        },
                        value: this.state.id_RegimenFiscal
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.regimenFiscal.map(option => (
                        <MenuItem key={option.obj.id_RegimenFiscal} value={option.obj.id_RegimenFiscal}>
                          {option.obj.nb_RegimenFiscal}
                        </MenuItem>
                      ))}
                    </TextField>
                </div>
                {(this.state.id_TipoPersona === 2 )?(
                  <TextField
                      id="id_RegimenSocietario"
                      select
                      label="Régimen Societario"
                      className={classes.textField}
                      error={this.state.requiredRegimenSocietario}
                      value={this.state.id_RegimenSocietario}
                      onChange={this.handleChangeRegimenSocietario('id_RegimenSocietario')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps:{
                            style:{ maxHeight: 350, }
                          }
                        },
                        value: this.state.id_RegimenSocietario
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.regimenSocietario.map(option => (
                        <MenuItem key={option.obj.id_RegimenSocietario} value={option.obj.id_RegimenSocietario}>
                          {option.obj.nb_RegimenSocietario}
                        </MenuItem>
                      ))}
                  </TextField>
                )
                  : (<label></label>)
                }
                <div>
                  <TextField
                      id="id_ClienteDireccionFiscal"
                      select
                      label="Domicilio Fiscal"
                      className={classes.textField}
                      error={this.state.requiredDireccionFiscal}
                      value={this.state.id_ClienteDireccionFiscal}
                      onChange={this.handleChangeDireccionFiscal('id_ClienteDireccionFiscal')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps:{
                            style:{ maxHeight: 350 }
                          },
                        },
                        value: this.state.id_ClienteDireccionFiscal
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.direccionesFiscales.map(option => (
                        <MenuItem key={option.obj.id_ClienteDireccionFiscal} value={option.obj.id_ClienteDireccionFiscal}>
                          {option.obj.de_Direccion}
                        </MenuItem>
                      ))}
                    </TextField>
                </div>
                <ItemGrid xs={6} sm={6} md={6} style={{marginLeft: '-20px'}}>
                  <Checkbox
                    checked={(this.state.sn_InformacionFiscalCorrecta==='TRUE')?true:false}
                    onChange={this.handleChangeConfirmacion('sn_InformacionFiscalCorrecta')}
                    value={this.state.sn_InformacionFiscalCorrecta}
                    error={this.state.requiredConfirmacion}
                    label="Confirmo que mi información fiscal es correcta"
                    style={{color:'#C62828'}}
                  />
                  <label style={{color:'#C62828', fontWeight:'bold'}}>Confirmo que mi información fiscal es correcta</label>
                </ItemGrid>
              </div>
            }
            btn= 'Guardar'
            action= {this.guardarInformacionFiscal}
            close={this.handleClickInfoFiscalClose}
            open= {this.state.openModalInfoFiscal}
          />
          <FormDialog
            title = {this.state.nb_Modulo}
            description = {(<div style={{paddingBottom: '5px', fontWeight: '700'}}>Preguntas Frecuentes e Infografias</div>)}
            content = {
              <div style={{ minWidth: '850px'}}>
                <ExpansionPanelCustomized
                  panels={ this.state.ayudas }
                />
              </div>
            }
            close={this.handleClickCloseDialogAyuda}
            open= {this.state.openDialogAyudaView}
          />
          <FormDialog
            title = {(<div><InputAdornment position="start"><AssignmentReturned /><span style={{paddingLeft:'15px'}}>Visualizar Documento</span></InputAdornment></div>)}
            content = {
              <div>
                <iframe title="Documento" style={{minHeight: '63VH', minWidth: '1100px'}} src={this.state.urlDocumento}></iframe>
              </div>
            }
            btn = 'Descargar'
            action = {this.handleClickDocumento}
            close = {this.handleClickCloseDocumentoView}
            open = {this.state.openDialogDocumentoView}
          />
          <Portal>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.tr}
              onClose={() => this.setState({ tr: false })}
              ContentProps={{ 'className': classes.red }}
              message={this.state.message}
            />
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={this.state.tc}
              onClose={() => this.setState({ tc: false })}
              ContentProps={{ 'className': classes.green }}
              message={this.state.message}
            />
          </Portal>
          {/*Permiso CRE */}
          {(this.state.nu_PermisoCRE) ? (
          <Hidden smDown>
            {(this.state.nu_PermisoCRE)?(<label style={{marginBottom: '-11px', marginRight:'10px', display: 'block', color:'rgba(0, 0, 0, 0.87)',marginLeft: '15px', marginTop: '-8px'}}>{this.state.nu_PermisoCRE}</label>):(<label></label>)}
          </Hidden>
          ) : (<label></label>)}
        </div>

      );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
