import {cajaLoginStyle, domains, hostname} from "assets/jss/material-dashboard-react/cajaLoginStyle";
import React from "react";
import PropTypes from "prop-types";
import invoke from "services/invoke.jsx";
import { Lock, AccountCircle } from "@material-ui/icons";
import injectSheet from 'react-jss'
import { Grid, InputAdornment, Snackbar, TextField } from '@material-ui/core';
import { RegularCard, Button, ItemGrid, Linear } from "components";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tl: false,
      tc: false,
      tr: false,
      bl: false,
      bc: false,
      br: false,
      user: '',
      pass: '',
      message: '',
      requiredPass: false,
      requiredUser: false,
      classMask:  'maskProgress',
      image: null
    };
  }

  showNotification(place,message,time=5000) {
    this.setState({message:message});
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClick = (event, value) => {
    if(this.state.user === ''){
      this.mensajeLog('requiredUser','Usuario requerida.');
      return;
    }else if(this.state.pass === ''){
      this.mensajeLog('requiredPass','Contraseña requerida.');
      return;
    }
    this.setState({classMask:'maskProgress active'});
    invoke.login({nb_usuario: this.state.user, nb_contra: this.state.pass})
      .then(res => {
        if(res.id_Usuario > 0){
          if(res.sn_ActivoUsuario){
            const sn_TipoCombustibleSurtir = res.sn_TipoCombustibleSurtir;
            invoke.post('opciones','listado',{id_Usuario: res.id_Usuario})
              .then(res => {
                if(res.status === 200 && res.data.data){
                  invoke.setSesion({usuario:invoke.getCurrentUser(), opciones:'', estaciones:'', estacionObj:'', estacion:''});
                  var sesion = invoke.getSesion();
                  sesion.opciones = res.data.data.opciones;
                  sesion.sn_TipoCombustibleSurtir = sn_TipoCombustibleSurtir;
                  invoke.setSesion(sesion);
                  invoke.resetTime();
                  const logInDate = new Date();
                  invoke.setDateSession(logInDate);
                  localStorage.setItem('showDialogNotificacion', true) // seteamos a falso para solo mostrar el modal
                  window.location.reload();
                }else{
                  this.showNotification("tr",'Usuario o contraseña incorrecta.')
                }
            });
          } else {
            this.showNotification("tr",'Usuario Sin Acceso.')
          }            
        }else{
          this.showNotification("tr",'Usuario o contraseña incorrecta.')
        }
        setTimeout(function() {
          this.setState({classMask:'maskProgress desactive'});
          setTimeout(function() {
              this.setState({classMask:'maskProgress'});
          }.bind(this), 300);
        }.bind(this), 300);
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.containerLogin}>
          <div className={classes.cajaLogin}>
            <RegularCard
                cardTitle={
                  (domains[hostname])?domains[hostname].logo1:''
                }
                content={
                  <div>
                    <Grid container>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <TextField
                          label="Usuario"
                          className={classes.textField}
                          id="user"
                          value={this.state.user}
                          onChange={this.handleChange('user')}
                          error={this.state.requiredUser}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          margin="normal"
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <TextField
                          label="Contraseña"
                          className={classes.textField}
                          id="pass"
                          value={this.state.pass}
                          onChange={this.handleChange('pass')}
                          error={this.state.requiredPass}
                          onKeyPress={(ev) => {if (ev.key === 'Enter') {this.handleClick(); ev.preventDefault();}}}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            ),
                          }}
                          margin="normal"
                          type="password"
                          fullWidth
                        />
                      </ItemGrid>
                    </Grid>
                  </div>
                }
                footer={
                    <Button onClick={this.handleClick} color="primary" className={classes.simple}>Iniciar Sesion</Button>
                }
            />
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.tr}
          onClose={() => this.setState({ tr: false })}
          ContentProps={{ 'className': classes.red }}
          message={this.state.message}
        />
        <div className={classes.backLogin}></div>
        <div className={classes.backLoginMascara}></div>
        <div className={this.state.classMask}>
          <Linear  />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectSheet(cajaLoginStyle)(Dashboard);
