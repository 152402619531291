// ##############################
// // // App styles
// #############################

import { drawerWidth, transition, container } from "assets/jss/material-dashboard-react.jsx";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanelLogin: {
    width: "100%",
    overflow: "auto",
    float: "right",
    ...transition,
    position: "relative",
    maxHeight: "100%",
    overflowScrolling: 'touch',
    height: '100%'
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: 'touch'
  },
  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100% - 123px)",
    '@media (max-width: 768px)' : {
      padding: "30px 5px",
    }
  },
  contentLogin: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: 'calc(100% - 221px)'
  },
  container,
  map: {
    marginTop: "70px"
  },
  footerLogin: {
    color: '#fff',
    position: 'absolute',
    width: '90%',
    height: '78px',
    padding: '0 5%',
    bottom: '0',
    left: '0'
  },
  headerLogin: {
    color: '#fff',
    position: 'absolute',
    width: '90%',
    height: '70px',
    padding: '0 5%',
    top: '0',
    left: '0'
  }
});

export default appStyle;
