// ##############################
// // // ProfileCard styles
// #############################

//let imgss = "https://dev-asociados-petroil.firebaseapp.com/static/media/"+(window.applicationCache.hostname.split('.')[0])+"logoNegociosBlanco.a77744a6.png"
import {domains, logo, logoBlanco, background, hostname } from 'variables/domains/domains.jsx'

const cajaLoginStyle = {
  containerLogin: {
    width: "100%",
    'flex-wrap': "wrap",
    'box-sizing': "border-box"
  },
  cajaLogin: {
    'max-width' : "320px",
    'text-align':"center",
    margin: "0 auto",
    position: 'absolute',
    top: 'calc(50% - 230px)',
    left: 'calc(50% - 160px)'
  },
  titleImg: {
    width: '95%',
    height: '100px',
    backgroundSize: 'contain',
    backgroundImage: 'url('+logo+')',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    margin: '0 auto'
  },
  backLogin: {
    'background-image' : "url("+background+")",
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'block',
    'background-color': '#00315c',
    'background-repeat': 'no-repeat',
    position: 'absolute',
    'background-size': 'cover',
    'background-position': 'center center',
    'z-index': '-2'
  },
  backLoginMascara: {
    //'background' : "linear-gradient(60deg, rgba(0, 49, 92, 0.2), rgba(0, 49, 92, 0.7))",
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    top: '0',
    left: '0',
    width: '100%',
    height: '100VH',
    display: 'block',
    'background-repeat': 'no-repeat',
    position: 'absolute',
    'background-size': 'contain',
    'background-position': 'center center',
    'z-index': '-1'
  },
  red: {
    backgroundColor: '#f44336'
  },
  textLogo: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#345186',
    fontSize: '35px',
    lineHeight: '35px',
    padding: '20px 0'
  },
  logoArco:{
    width: '100px'
  },
  bottonArco: {
    background: 'rgba(255,255,255,0.8)',
    "&,&:focus,&:hover": {
      background: 'rgba(255,255,255,0.9)',
    }
  }
};

export {
  //variables
  cajaLoginStyle,
  logo,
  logoBlanco,
  background,
  domains,
  hostname
}
