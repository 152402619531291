import React from "react";
import logoArco from 'assets/img/ARCO_Transparente.png'
import logoBlancoAsociados from 'assets/img/logoNegociosBlanco.png'
import logoIcon from "assets/img/logo.png";
import { Button } from "@material-ui/core";

const hostname = (window.location.hostname.split('.')[0]==='arcoasociados')?'arcoasociados':'clientes';
let logo = process.env.REACT_APP_BUCKET+`${hostname}_logo.png`;
let logoBlanco = process.env.REACT_APP_BUCKET+`${hostname}_logo_blanco.png`;
let background = process.env.REACT_APP_BUCKET+`${hostname}_background.jpg`;
let backgroundFiscales = process.env.REACT_APP_BUCKET+`${hostname}_fiscales_background.jpg`;

const logo1 = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  color: '#345186',
  fontSize: '35px',
  lineHeight: '35px',
  padding: '20px 0'
}
const logo2 = {
  fontSize: '20px',
  textAlign: 'center',
  display: 'inline-block',
  fontWeight: 'bold',
  color: '#fff',
  width: '100%',
  verticalAlign: 'text-top'
}

const titleImg = {
  width: '95%',
    height: '100px',
    backgroundSize: 'contain',
    backgroundImage: 'url('+logo+')',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    margin: '0 auto'
}
const img = {
  width:' 90%',
  border: '0',
  margin: '0 auto',
  display: 'block'
}
const titleLoginIcon = {
  lineHeight: "25px",
  fontSize: "18px",
  borderRadius: "3px",
  backgroundImage: 'url('+logoIcon+')',
  backgroundPosition: '0 center',
  backgroundSize: '20px',
  backgroundRepeat: 'no-repeat',
  paddingLeft: '30px',
  textTransform: "none",
  color: "inherit",
  display: 'inline-block'
}

const domains = {
  clientes: {
    logo1: (<div style={titleImg}></div>),
    logo2: (<img src={logoBlanco} alt="logo" style={img} />),
    logo3: (<div><span style={titleLoginIcon}>Distribución de Combustible</span></div>),
    logo4: (<Button style={{background:'rgba(255,255,255,0.8)',color: '#ff8b00', marginLeft: '10px'}}><img src={logo} style={{width:'95px'}} alt="Petroil"></img></Button>),
    background: background,
    backgroundFiscales: backgroundFiscales,
  },
  arcoasociados: {
    logo1: (<div style={logo1}>Portal Asociados</div>),//logo login
    logo2: (<label style={logo2}>Portal Asociados</label>),//logo blanco dentro
    logo3: (<div><img src={logoBlancoAsociados} style={{width:'140px'}} alt="Petroil"></img></div>),//logo izquierod
    logo4: (<Button style={{background:'rgba(255,255,255,0.8)'}}><img src={logoArco} style={{width:'100px'}} alt="Petroil"></img></Button>),//logo Derecho
    background: background,//background login/sidebar
  },
};

export {
  domains,
  logo,
  logoBlanco,
  background,
  hostname,
  backgroundFiscales,
};
