import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from "react-router-dom";
import "assets/css/material-dashboard-react.css?v=1.2.0";
import "assets/css/fullcalendar.min.css?v=1.2.0";
import indexRoutes from "routes/index.jsx";

export default createBrowserHistory();
var history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Switch>
      {
      	indexRoutes.map((prop, key) => {
        	return <Route path={prop.path} component={prop.component} key={key} history={history} />;
      	})
  	}
    </Switch>
  </Router>,
  document.getElementById("root")
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
   .then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}

