import React from "react";
import PropTypes from "prop-types";
import ReactExport from 'react-data-export';
import invoke from "services/invoke.jsx";
//import ChartistGraph from "react-chartist";
import { Today, TrendingUp, Assignment, Opacity, Search, AssignmentReturned,
  /*LocalGasStation,*/ Contacts, Assessment, Business, RecentActors } from "@material-ui/icons";
import { withStyles, Grid, TextField, Tooltip, IconButton, Snackbar, InputAdornment, MenuItem, 
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { ItemGrid, Table, TableCustom, FormDialog, Linear, /*SpecialCard,*/ ExpansionCard } from "components";

import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//import { dailySalesChart } from "variables/charts";
import dashboardStyle from "assets/jss/material-dashboard-react/dashboardStyle";
//import * as moment from 'moment';

import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

//const queryString = require('query-string');
//var crypto = require('crypto');

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: green
  },
});

var sesion = invoke.getSesion();
var id_EmpresaVenta = (sesion && sesion.estacionObj && sesion.estacionObj[0].id_EmpresaVenta)?sesion.estacionObj[0].id_EmpresaVenta:1;

class Dashboard extends React.Component {

  state = {
    usuario :  (sesion.usuario)? sesion.usuario: {},
    estacion :  (sesion.estacionObj)? sesion.estacionObj[0]: {},
    info: {},
    precios:[],
    multiDataSet: [],
    estaciones: [],
    sucursales: [],
    plazas: [],
    documentos: [],
    productos: [],
    descriptionFactura: '',
    tableDataCliente: [],
    tableDataInfoCliente: [],
    tableDataDeuda: [],
    tableDataInfoDeuda: [],
    tableData: [],
    estatuscobranza: [],
    multiDataSetExcel: [],
    openDialogFactura: false,
    openDialogFacturaView: false,
    urlFactura: '',
    urlFacturaDownload: '',
    classMask:  'maskProgress',
    id_Estacion: sesion.estacion,
    id_EstatusCobranza: '',
    nb_EstatusCobranza: 'Todos',
    id_tipoReporte: '1',
    id_Sucursal: '',
    id_Plaza: '',
    cl_Documento: '',
    nb_Estacion: '',
    nb_Sucursal: 'Todas',
    arr_DeudaProductos: [],
    id_Pedido: '',
    fl_Documento: '',
    fh_Inicio: '',
    fh_Fin: '',
		nb_Plaza_Rpt: '',
		de_Cl_Documento: '',
    domiciliosEntrega: [],
    id_Domicilio: '',
    de_Domicilio_Rpt:'Todos',
    // Reporte de Referencias
    comboEstatus:[],
    tableDataReferencias: [],
    multiDataSetExcelReferencias: [],
    fh_Inicio_Ref: '',
    fh_Fin_Ref:'',
    de_Referencia: '',
    fh_Registro_Ref:'',
    id_Estatus_Ref: '',
    fl_Pago:'',
    fh_Pago: '',
    de_Estatus_Ref: 'Todos',
  };

  componentWillMount(){ //es parecido a la funcion init

    invoke.resetTime();
    this.handleClickSearch();

    invoke.post('facturas','estatusId',{id_Inicial: 1511,id_Final: 1513}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value:0,label:'Todas'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Estatus,
            label: x.de_Estatus,
            obj: x
          });
        }
        this.setState({ estatuscobranza: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Estaciones
    invoke.post('estacion','sucursales',{id_Empresa: id_EmpresaVenta}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value:0,label:'Todas'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Sucursal,
            label: x.nb_Sucursal,
            obj: x
          });
        }
        this.setState({ sucursales: VAR });
        this.setState({ id_Sucursal: 0 });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    if(sesion.usuario){
      invoke.post('reportes','plazasClientes',{id_Cliente: sesion.usuario.id_Cliente}).then(res => {
        if(res.status===200 && res.data.data){
          var VAR = [{value:0,label:'Todas'}];
          for(var i in res.data.data){
            var x = res.data.data[i];
            VAR.push({
              value: x.id_Plaza,
              label: x.nb_Plaza,
              obj: x
            });
          }
          this.setState({ plazas: VAR });
          this.setState({ id_Plaza: 0 });
        }else{
          this.showNotification("tr", res.message)
        }
      });

      invoke.post('reportes','cldocumentosClientes',{id_Cliente: sesion.usuario.id_Cliente}).then(res => {
        if(res.status===200 && res.data.data){
          var VAR = [{value:0,label:'Todos'}];
          for(var i in res.data.data){
            var x = res.data.data[i];
            VAR.push({
              value: x.cl_TipoDocumento,
              label: x.nb_TipoDocumento,
              obj: x
            });
          }
          this.setState({ documentos: VAR });
          this.setState({ cl_documento: 0 });
        }else{
          this.showNotification("tr", res.message)
        }
      });

      invoke.post('precios','productos',{id_Cliente: sesion.usuario.id_Cliente, id_Tar: null}).then(res => {
        if(res.status===200 && res.data.data){
          var productosVAR = [];
          for(var i in res.data.data){
            var x = res.data.data[i];
            productosVAR.push({
              value: x.id_TipoProducto,
              label: x.nb_TipoProducto,
              color: x.nb_Color,
              id_TipoProducto: x.id_TipoProducto,
            });
          }
          this.setState({ productos: productosVAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });

      invoke.post('reportes','domiciliosClientes',{id_Cliente: sesion.usuario.id_Cliente}).then(res => {
        if(res.status===200 && res.data.data){
          var VAR = [{value:0,label:'Todos'}];
          for(var i in res.data.data){
            var x = res.data.data[i];
            VAR.push({
              value: x.id_Domicilio,
              label: x.de_Domicilio,
              obj: x
            });
          }
          this.setState({ domiciliosEntrega: VAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });

      // Cargar combo estatus para referencias
    invoke.post('facturas','estatusId',{id_Inicial: 1531,id_Final: 1534}).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [{value:0,label:'Todas'}];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.id_Estatus,
            label: x.de_Estatus,
            obj: x
          });
        }
        console.log(VAR);
        this.setState({ comboEstatus: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });
    }

    var fh_inicio = this.getFirstDayOfMonth();
    var fh_fin = this.getLastDayOfMonth();
    fh_inicio = fh_inicio.toISOString().slice(0,10);
    fh_fin = fh_fin.toISOString().slice(0,10);

    this.setState({ fh_Inicio: fh_inicio });
    this.setState({ fh_Fin: fh_fin });

    this.setState({ fh_Inicio_Ref: fh_inicio });
    this.setState({ fh_Fin_Ref: fh_fin });

  }

  downloadPdf = (url,urlDescarga) => event => {
    this.setState({ openDialogFacturaView: true });
    this.setState({ urlFactura: url });
    this.setState({ urlFacturaDownload: urlDescarga });
  };

  handleClickFactura = () => {
    window.location.href = this.state.urlFacturaDownload;
    this.setState({ openDialogFacturaView: false });
  };

  handleClickCloseFacturaView = () => {
    this.setState({ openDialogFacturaView: false });
  };

  handleClickSearch = () => {
    this.getInfoCliente();
  };

  handleClickSearchFacturas = () => {
    this.buscarFactura();
  };

  handleClickOpenFactura = () => {
    this.setState({ openDialogFactura: true });
    this.buscarFactura();
  };

  handleClickCloseFactura = () => {
    this.setState({ openDialogFactura: false });
  };

  handleChange = name => event => {
    invoke.resetTime();
    this.setState({
      [name]: event.target.value,
    }, () => {
      if(name==='id_tipoReporte'){
        this.buscarFactura();
      }
    });
  };

  handleChangeValidate = (name,validate) => event => {
    invoke.resetTime();
    if(validate.maxLength && (event.target.value).length <= validate.maxLength){
      this.setState({
        [name]: event.target.value,
      });
    }
    else if(validate.date){
      var VAR = new Date((validate.min).toISOString().slice(0,10));
      var VAL = new Date(event.target.value);
      if(VAR <= VAL){
        this.setState({
          [name]: event.target.value,
        });
      }
    }
  };

  noOfDaysInMonth(){
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  getFirstDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      1,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  getLastDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      this.noOfDaysInMonth(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  showNotification(place,message,time=5000) {
    this.setState({message : message})
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  async getInfoCliente(){
    let filtros = {
      id_Usuario : this.state.usuario.id_Usuario,
      id_Estacion : this.state.id_Estacion,
      id_Sucursal : this.state.id_Sucursal,
      id_Cliente : sesion.usuario.id_Cliente,
    }

    for(let i in this.state.estaciones){
      if(this.state.estaciones[i].value===this.state.id_Estacion){
        this.setState({nb_Estacion : this.state.estaciones[i].label})
      }
    }

    for(let i in this.state.sucursales){
      if(this.state.sucursales[i].value===this.state.id_Sucursal){
        this.setState({nb_Sucursal : this.state.sucursales[i].label})
      }
    }

    this.setState({classMask:'maskProgress active'});
    await invoke.post('usuario','getinfoCliente',filtros)
      .then(res => {
        if(res.status===200 && res.data.data){
          this.setState({info : res.data.data[0]})
          this.setState({arr_DeudaProductos : res.data.data.filter((item) => item.id_TipoProductoDC)})
          var tableDataClienteVAR = [];
          var tableDataDeudaVAR = [];
          tableDataClienteVAR.push([
            (<Contacts />),
            'Cliente',
            (<span style={{textAlign:'right',display:'block'}}>
              <span style={{textTransform: 'uppercase'}}>{sesion.usuario.de_RazonSocial}</span>
            </span>)
          ]);
          tableDataClienteVAR.push([
            (<RecentActors />),
            'RFC',
            (<span style={{textAlign:'right',display:'block'}}>
              {((this.state.info && this.state.info.de_RFC) ? this.state.info.de_RFC : '')}
            </span>)
          ]);
          tableDataClienteVAR.push([
            (<Business />),
            'Sucursal(es)',
            (<span style={{textAlign:'right',display:'block'}}>
              {((this.state.info && this.state.nb_Sucursal) ? this.state.nb_Sucursal : '')}
            </span>)
          ]);
          tableDataClienteVAR.push([
            (<TrendingUp />),
            'Linea de Credito',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$ "+ ((this.state.info && this.state.info.im_LineaDeCredito)? parseFloat(this.state.info.im_LineaDeCredito).toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2})  : '0')}
            </span>)
          ]);
          tableDataClienteVAR.push([
            (<Business />),
            'Empresa del Credito',
            (<span style={{textAlign:'right',display:'block'}}>
              {((this.state.info && this.state.info.nb_Empresa) ? this.state.info.nb_Empresa : '')}
            </span>)
          ]);
          tableDataClienteVAR.push([
            (<Today />),
            'Dias de Credito',
            (<span style={{textAlign:'right',display:'block'}}>
              {((this.state.info && this.state.info.nu_DiasCredito)? parseFloat(this.state.info.nu_DiasCredito).toLocaleString("en-US")  : '0') + ' Dias'}
            </span>)
          ]);
          this.setState({tableDataInfoCliente : tableDataClienteVAR})

          //---------------------------------------------------------------------------------------------------------------------------
          tableDataDeudaVAR.push([
            (<span></span>),
            (<b>Total Deuda</b>),
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoTotal_Estacion).toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2}):parseFloat(this.state.info.im_SaldoTotal).toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2}))  : '0')}
            </span>)
          ]);
          res.data.data.map((option,index) => (
            tableDataDeudaVAR.push([
              (<span></span>),
              (<b><span style={{color: option.nb_Color,verticalAlign: 'middle'}}><Opacity /></span> Total Deuda {option.nb_TipoProducto}</b>),
              (<span style={{textAlign:'right',display:'block', color:option.nb_Color}}><b>
                {"$"+ ((this.state.info)? ((option.im_totalDeudaDC)?parseFloat(option.im_totalDeudaDC).toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2}): '0' ) : '0')}
              </b></span>)
            ])
          ));
          tableDataDeudaVAR.push([
            (<span></span>),
            (<b>Total Vencido</b>),
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVencido_Estacion).toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2}):parseFloat(this.state.info.im_SaldoVencido).toLocaleString("en-US"))  : '0')}
            </span>)
          ]);
          tableDataDeudaVAR.push([
            (<span></span>),
            (<b>Total Vigente</b>),
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVigente_Estacion).toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2}):parseFloat(this.state.info.im_SaldoVigente).toLocaleString("en-US"))  : '0')}
            </span>)
          ]);
          tableDataDeudaVAR.push([
            (<span></span>),
            (<b>1 - 30 Dias</b>),
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVencido30_Estacion).toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2}):parseFloat(this.state.info.im_SaldoVencido30).toLocaleString("en-US"))  : '0')}
            </span>)
          ]);
          tableDataDeudaVAR.push([
            (<span></span>),
            (<b>31 - 60 Dias</b>),
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVencido60_Estacion).toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2}):parseFloat(this.state.info.im_SaldoVencido60).toLocaleString("en-US"))  : '0')}
            </span>)
          ]);
          tableDataDeudaVAR.push([
            (<span></span>),
            (<b>61 - 90 Dias</b>),
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVencido90_Estacion).toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2}):parseFloat(this.state.info.im_SaldoVencido90).toLocaleString("en-US"))  : '0')}
            </span>)
          ]);
          tableDataDeudaVAR.push([
            (<span></span>),
            (<b>Mas de 90 Dias</b>),
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVencido99_Estacion).toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2}):parseFloat(this.state.info.im_SaldoVencido99).toLocaleString("en-US"))  : '0')}
            </span>)
          ]);
          this.setState({tableDataInfoDeuda : tableDataDeudaVAR})

          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 300);
          }.bind(this), 300);
        }
    })


    var obj = {
        id_Empresa:   id_EmpresaVenta,
        id_Cliente:   sesion.usuario.id_Cliente,
        id_Estacion:  null,
        id_Sucursal:  (this.state.id_Sucursal)?this.state.id_Sucursal:null,
        id_Usuario:   null,
        sn_Asociados: 0
    }
    //Combo de Estaciones
    await invoke.post('reportes','estadoDeCuenta',obj).then(res => {
      if(res.status===200 && res.data.data){
        let empresaVAR = -1; //NUME
        let sucursalVAR = -1; //NUMS
        let estacionVAR = -1; //NUMES
        let clienteVAR = -1; //NUMC
        let newTableVAR = [];
        let newTableClienteVAR = [];
        let newTableEmpresaVAR = [];
        let newTableEstacionVAR = [];
        let newTableSucursalVAR = [];
        let countVAR = 0;

        let DataVAR = [];
        for(let i in res.data.data){
          let DC = res.data.data[i];

          if(DC.NUMES===1){
            estacionVAR = estacionVAR + 1; countVAR = 0;
          }
          if(DC.NUMS===1){
            sucursalVAR = sucursalVAR + 1; estacionVAR=0; countVAR = 0; newTableEstacionVAR=[];
          }
          if(DC.NUME===1){
            empresaVAR = empresaVAR + 1; countVAR = 0; sucursalVAR=0; estacionVAR=0; newTableSucursalVAR=[]; newTableEstacionVAR=[];
          }
          if(DC.NUMC===1){
            clienteVAR = clienteVAR + 1; countVAR = 0; empresaVAR=0; sucursalVAR=0; estacionVAR=0; newTableEmpresaVAR = []; newTableSucursalVAR=[]; newTableEstacionVAR=[];
          }

          if(DC.NUMC===1 || DC.NUME===1 || DC.NUMS===1 || DC.NUMES===1){
            newTableVAR=[];
            newTableVAR[countVAR] = DC;
            newTableEstacionVAR[estacionVAR] = newTableVAR;
            newTableSucursalVAR[sucursalVAR] = newTableEstacionVAR;
            newTableEmpresaVAR[empresaVAR] = newTableSucursalVAR;
            newTableClienteVAR[clienteVAR] = newTableEmpresaVAR;
          }
          else{
            newTableVAR[countVAR] = DC;
            newTableEstacionVAR[estacionVAR] = newTableVAR;
            newTableSucursalVAR[sucursalVAR] = newTableEstacionVAR;
            newTableEmpresaVAR[empresaVAR] = newTableSucursalVAR;
            newTableClienteVAR[clienteVAR] = newTableEmpresaVAR;
          }
          countVAR++;
        }

        for(var i1 in newTableClienteVAR){
          var item1 = newTableClienteVAR[i1];
          var cantidadC  = 0;
          var totalC     = 0;
          var cantidadCE = 0;
          var totalCE    = 0;
          var abonoC     = 0;
          var saldoC     = 0;
          var moratorioC = 0;
          for(var i2 in item1){
            var item2 = item1[i2];
            var cantidadE  = 0;
            var totalE     = 0;
            var cantidadEE = 0;
            var totalEE    = 0;
            var abonoE     = 0;
            var saldoE     = 0;
            var moratorioE = 0;
            for(var i3 in item2){
              var item3 = item2[i3];
              var cantidadS  = 0;
              var totalS     = 0;
              var cantidadSE = 0;
              var totalSE    = 0;
              var abonoS     = 0;
              var saldoS     = 0;
              var moratorioS = 0;
              for(var i4 in item3){
                var item4 = item3[i4];
                var cantidadET  = 0;
                var totalET     = 0;
                var cantidadETE = 0;
                var totalETE    = 0;
                var abonoET     = 0;
                var saldoET     = 0;
                var moratorioET = 0;

                for(var i in item4){
                  var item = item4[i];
                  var sn_Anticipadas = item.sn_Anticipadas;
                  var sn_Cuenta = item.sn_cuenta;
                  DataVAR.push([
                    {value: (item.nb_RazonSocial)?item.nb_RazonSocial:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    {value: (item.nb_Sucursal)?item.nb_Sucursal:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    // {value: (item.nb_Estacion)?item.nb_Estacion:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    {value: (item.fl_FolioDocumento)?item.fl_FolioDocumento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    {value: (item.fl_FolioFiscal)?item.fl_FolioFiscal:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    {value: (item.fh_Documento)?item.fh_Documento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    {value: (item.fh_Vencimiento)?item.fh_Vencimiento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    {value: (item.fh_VentaRemision)?item.fh_VentaRemision:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    {value: (item.nb_FormaPago)?item.nb_FormaPago:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    {value: (item.c_MonedaSAT)?item.c_MonedaSAT:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},

                    {value: (item.nu_CantidadEntregada)?item.nu_CantidadEntregada:0, style: {type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                    {value: (item.im_ImporteEntregado)?item.im_ImporteEntregado:0, style: {type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                    {value: (item.nu_Cantidad)?item.nu_Cantidad:0, style: {type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                    {value: (item.im_Total)?item.im_Total:0, style: {type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                    {value: (item.im_Abono)?item.im_Abono:0, style: {type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                    {value: (item.fh_Aplicacion)?item.fh_Aplicacion:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    {value: (item.im_SaldoFactura)?item.im_SaldoFactura:0, style: {type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                    {value: (item.Dvencidos)?item.Dvencidos:0, style: {type:'number', numFmt: "0",alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    {value: (item.DTRANSCURRIDOS)?item.DTRANSCURRIDOS:0, style: {type:'number', numFmt: "0",alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                    {value: (item.Moratorios)?item.Moratorios:0, style: {type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}}
                  ]);

                  var cantidad    = item.nu_Cantidad;
                  var total       = item.im_Total;
                  var cantidadEN  = item.nu_CantidadEntregada;
                  var totalEN     = item.im_ImporteEntregado;
                  var abono       = item.im_Abono;
                  var saldo       = item.im_SaldoFactura;
                  var moratorio   = item.Moratorios;

                  if (sn_Anticipadas === 1 && sn_Cuenta === 1){
                    saldoS     = saldoS + saldo;
                    saldoE     = saldoE + saldo;
                    saldoC     = saldoC + saldo;
                    saldoET    = saldoET + saldo;
                  }
                  if (sn_Anticipadas === 1 && sn_Cuenta === 0){
                    cantidadS  = cantidadS + cantidad;
                    totalS     = totalS + total;
                    cantidadSE = cantidadSE + cantidadEN;
                    totalSE    = totalSE + totalEN;
                    abonoS     = abonoS + abono;
                    moratorioS = moratorioS + moratorio;

                    cantidadE  = cantidadE + cantidad;
                    totalE     = totalE + total;
                    cantidadEE = cantidadEE + cantidadEN;
                    totalEE    = totalEE + totalEN;
                    abonoE     = abonoE + abono;
                    moratorioE = moratorioE + moratorio;

                    cantidadC  = cantidadC + cantidad;
                    totalC     = totalC + total;
                    cantidadCE = cantidadCE + cantidadEN;
                    totalCE    = totalCE + totalEN;
                    abonoC     = abonoC + abono;
                    moratorioC = moratorioC + moratorio;

                    cantidadET  = cantidadET + cantidad;
                    totalET     = totalET + total;
                    cantidadETE = cantidadETE + cantidadEN;
                    totalETE    = totalETE + totalEN;
                    abonoET     = abonoET + abono;
                    moratorioET = moratorioET + moratorio;
                  }
                  if (sn_Anticipadas === 0 && sn_Cuenta === 1){
                    cantidadS  = cantidadS + cantidad;
                    totalS     = totalS + total;
                    cantidadSE = cantidadSE + cantidadEN;
                    totalSE    = totalSE + totalEN;
                    abonoS     = abonoS + abono;
                    saldoS     = saldoS + saldo;
                    moratorioS = moratorioS + moratorio;

                    cantidadE  = cantidadE + cantidad;
                    totalE     = totalE + total;
                    cantidadEE = cantidadEE + cantidadEN;
                    totalEE    = totalEE + totalEN;
                    abonoE     = abonoE + abono;
                    saldoE     = saldoE + saldo;
                    moratorioE = moratorioE + moratorio;

                    cantidadC  = cantidadC + cantidad;
                    totalC     = totalC + total;
                    cantidadCE = cantidadCE + cantidadEN;
                    totalCE    = totalCE + totalEN;
                    abonoC     = abonoC + abono;
                    saldoC     = saldoC + saldo;
                    moratorioC = moratorioC + moratorio;

                    cantidadET  = cantidadET + cantidad;
                    totalET     = totalET + total;
                    cantidadETE = cantidadETE + cantidadEN;
                    totalETE    = totalETE + totalEN;
                    abonoET     = abonoET + abono;
                    saldoET     = saldoET + saldo;
                    moratorioET = moratorioET + moratorio;
                  }
                }
                DataVAR.push([
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  // {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  {value:'Total por Estacion', style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true }}},
                  {value: (cantidadETE)?cantidadETE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value: (totalETE)?totalETE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value: (cantidadET)?cantidadET:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value: (totalET)?totalET:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value: (abonoET)?abonoET:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  {value: (saldoET)?saldoET:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                  {value: (moratorioET)?moratorioET:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}}
                ]);
              }
              DataVAR.push([
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                // {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                {value:'Total por Sucural', style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true }}},
                {value: (cantidadSE)?cantidadSE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value: (totalSE)?totalSE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value: (cantidadS)?cantidadS:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value: (totalS)?totalS:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value: (abonoS)?abonoS:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                {value: (saldoS)?saldoS:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                {value: (moratorioS)?moratorioS:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}}
              ]);
            }
            DataVAR.push([
              {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              // {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              {value:'Total por Empresa', style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true }}},
              {value: (cantidadEE)?cantidadEE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
              {value: (totalEE)?totalEE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
              {value: (cantidadE)?cantidadE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
              {value: (totalE)?totalE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
              {value: (abonoE)?abonoE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
              {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              {value: (saldoE)?saldoE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
              {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
              {value: (moratorioE)?moratorioE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}}
            ]);
          }
          DataVAR.push([
            {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            // {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            {value:'Total por Cliente', style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true }}},
            {value: (cantidadCE)?cantidadCE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
            {value: (totalCE)?totalCE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
            {value: (cantidadC)?cantidadC:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
            {value: (totalC)?totalC:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
            {value: (abonoC)?abonoC:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
            {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            {value: (saldoC)?saldoC:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
            {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
            {value: (moratorioC)?moratorioC:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}}
          ]);
        }
        var columnsHeader = [{value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},{value:''},{value:''},{value:''}];
        var dataHeader = [
                            {value: 'Cliente:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                            {value: sesion.usuario.de_RazonSocial, style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                            {},{},{},{},{},
                            {value: 'Total Deuda:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                            {value: "$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoTotal_Estacion).toLocaleString("en-US"):parseFloat(this.state.info.im_SaldoTotal).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},{},
                          ];
        // Agregar los encabezados productos
        this.state.arr_DeudaProductos.map((option,index) => (
          dataHeader.push({value: option.nb_TipoProducto, style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'12', bold: true, color:{rgb: option.nb_Color.replace('#','') } } }})
        ));

        var dataHeader_2 = [
          {value: 'RFC:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
          {value: ((this.state.info && this.state.info.de_RFC) ? this.state.info.de_RFC : ''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
          {},{},{},{},{},
          {value: 'Total Vencido:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
          {value: "$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVencido_Estacion).toLocaleString("en-US"):parseFloat(this.state.info.im_SaldoVencido).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},{},
        ];
        // Agregar los importes productos
        this.state.arr_DeudaProductos.map((option,index) => (
          dataHeader_2.push({value: (option.im_totalDeudaDC) ? option.im_totalDeudaDC : 0, style: {type:'number', numFmt: "$#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}})
        ));

        var multiDataSet = [
            {
              xSteps: 5,
              ySteps: 0,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: 'Estado de cuenta', style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'18', bold: true } }},

                ],
                [
                  {value: sesion.usuario.de_RazonSocial, style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'14', bold: false } }},
                ],
              ]
            },
            {
              xSteps: 10,
              ySteps: -3,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: ((new Date()).toISOString().slice(0,10))+'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'14', bold: true } }},
                ],
              ]
            },
            {
              xSteps: 2,
              ySteps: 0,
              columns: columnsHeader,
              data: [
                dataHeader,
                dataHeader_2,
                [
                  {value: 'Sucursales:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                  {value: ((this.state.info && this.state.nb_Sucursal) ? this.state.nb_Sucursal : ''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                  {},{},{},{},{},
                  {value: 'Total Vigente:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                  {value: "$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVigente_Estacion).toLocaleString("en-US"):parseFloat(this.state.info.im_SaldoVigente).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                  {},{},{},{},
                ],
                [
                  {value: 'Linea de Credito:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                  {value: "$ "+ ((this.state.info && this.state.info.im_LineaDeCredito)? parseFloat(this.state.info.im_LineaDeCredito).toLocaleString("en-US")  : '0'), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                  {},{},{},{},{},
                  {value: '1-30 Dias:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                  {value: "$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVencido30_Estacion).toLocaleString("en-US"):parseFloat(this.state.info.im_SaldoVencido30).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                  {},{},{},{},
                ],
                [
                  {value: 'Empresa del Credito:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                  {value: ((this.state.info && this.state.info.nb_Empresa) ? this.state.info.nb_Empresa : ''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                  {},{},{},{},{},
                  {value: '31-60 Dias:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                  {value: "$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVencido60_Estacion).toLocaleString("en-US"):parseFloat(this.state.info.im_SaldoVencido60).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                  {},{},{},{}
                ],
                [
                  {value: 'Dias de Credito:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                  {value: ((this.state.info && this.state.info.nu_DiasCredito)? parseFloat(this.state.info.nu_DiasCredito).toLocaleString("en-US")  : '0') + ' Dias', style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                  {},{},{},{},{},
                  {value: '61-90 Dias:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                  {value: "$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVencido90_Estacion).toLocaleString("en-US"):parseFloat(this.state.info.im_SaldoVencido90).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                  {},{},{},{},
                ],
                [
                  {},{},{},{},{},{},{},
                  {value: 'Mas de 90 Dias:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                  {value: "$"+ ((this.state.info)? ((this.state.id_Estacion)?parseFloat(this.state.info.im_SaldoVencido99_Estacion).toLocaleString("en-US"):parseFloat(this.state.info.im_SaldoVencido99).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                  {},{},{},{},
                ],
              ]
            },
            {
              xSteps: 0,
              ySteps: 1,
              columns: [
                {value:'Empresa',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Sucursal',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                //{value:'Estacion',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Folio Doc.',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Folio Fiscal',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'FDocumento',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Vencimiento',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'FHVenta',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'FPago',style: { wpx: '65', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Moneda',style: { wpx: '65', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Ltr Entregados',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Imp Entregado',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Ltr Facturados',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Imp Total Facturado',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Abonos',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Aplicacion',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Saldo Pendiente',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'DVenc',style: { wpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'DTrans',style: { wpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Moratorios',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}}
              ],
              data: DataVAR
            }
          ];

          this.setState({ multiDataSet: multiDataSet });

      }else{
        this.showNotification("tr", res.message)
      }
    });

  }

  buscarFactura(){
    const { classes } = this.props;
    var obj = {
      fh_Inicio: this.state.fh_Inicio,
      fh_Fin: this.state.fh_Fin,
      id_Estatus: this.state.id_EstatusCobranza,
      id_Cliente : sesion.usuario.id_Cliente,
      cl_Documento: this.state.cl_Documento ? this.state.cl_Documento : null,
      id_Plaza: this.state.id_Plaza,
      // eslint-disable-next-line
			id_Pedido: this.state.id_Pedido ? this.state.id_Pedido.replace(/[^0-9\.]+/g, ""): null, 
      fl_Documento: this.state.fl_Documento,
      id_Domicilio: (this.state.id_Domicilio)?this.state.id_Domicilio:null,
    }

    if(obj.id_Pedido === ''){
      obj.id_Pedido = null;
    }

    if(obj.fh_Inicio === ''){
      obj.fh_Inicio = null;
    }

    if(obj.fh_Fin === ''){
      obj.fh_Fin = null;
    }
    
    this.setState({ tableData: [] });

    let tipoReporte = ''
    if (this.state.id_tipoReporte === '1') {
      tipoReporte = 'facturasListadoPorDocumento'
    } else if (this.state.id_tipoReporte === '2') {
      tipoReporte = 'facturasListadoDetallado'
    } else {
      tipoReporte = 'facturasListadoFacturaNota'
    }

    for(var i in this.state.estatuscobranza){
      if(this.state.estatuscobranza[i].value===this.state.id_EstatusCobranza){
        this.setState({nb_EstatusCobranza : this.state.estatuscobranza[i].label})
      }
    }

		for(var x in this.state.documentos){
      if(this.state.documentos[x].value===this.state.cl_Documento){
        this.setState({de_Cl_Documento : this.state.documentos[x].label})
      }
    }

    for(var y in this.state.plazas){
      if(this.state.plazas[y].value===this.state.id_Plaza){
        this.setState({nb_Plaza_Rpt : this.state.plazas[y].label})
      }
    }

    for(var z in this.state.domiciliosEntrega){
      if(this.state.domiciliosEntrega[z].value===this.state.id_Domicilio){
        this.setState({de_Domicilio_Rpt : this.state.domiciliosEntrega[z].label})
      }
    }

    this.setState({classMask:'maskProgress active'});
    invoke.post('reportes', tipoReporte, obj).then(res => {
      if (res.status===200 && res.data.data) {
          var tableDataVAR = [];
          for (var i in res.data.data) {
            var PD = res.data.data[i];

            if (tipoReporte === 'facturasListadoPorDocumento') {
              tableDataVAR.push([
                PD.nb_EmpresaPlaza,
                PD.fl_Pedido,
                PD.nb_TipoDocumento,
                PD.fl_FolioDocumento,
                PD.fh_Documento,
                PD.fh_Vencimiento,
                PD.im_Total,
                PD.im_SaldoFactura,
                PD.nb_Moneda,
                PD.Dvencidos,
                PD.DPorVencer,
                (
                  <Tooltip
                    title={PD.de_Estatus}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                  </Tooltip>
                ),
                (
                  <Tooltip
                    title={PD.de_EstatusCobranza}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_ColorCobranza}}>{PD.de_EstatusCobranza}</span>
                  </Tooltip>
                ),
                (
                  <div>
                    <Tooltip
                        title='Visualizar Factura PDF'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <IconButton style={{color:'#C62828'}} aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Descargar Factura XML'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <a href={PD.urlFacturaDownloadXML} ><IconButton style={{color:'#388E3C'}} aria-label="DescargarXML" aria-haspopup="true" className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton></a>
                    </Tooltip>
                  </div>
                )
              ]);
            } else if (tipoReporte === 'facturasListadoDetallado') {
              tableDataVAR.push([
                PD.nb_EmpresaPlaza,
                PD.fl_Pedido,
                PD.nb_TipoDocumento,
                PD.fl_FolioDocumento,
                PD.fh_Documento,
                PD.fh_Vencimiento,
                PD.nb_Producto,
                PD.nb_UnidadDeMedida,
                PD.nu_Cantidad,
                PD.im_TotalDetalleNeto,
                PD.nb_Moneda,
                (
                  <Tooltip
                    title={PD.de_Estatus}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                  </Tooltip>
                ),
                (
                  <Tooltip
                    title={PD.de_EstatusCobranza}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_ColorCobranza}}>{PD.de_EstatusCobranza}</span>
                  </Tooltip>
                ),
                (
                  <div>
                    <Tooltip
                        title='Visualizar Factura PDF'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <IconButton style={{color:'#C62828'}} aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Descargar Factura XML'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <a href={PD.urlFacturaDownloadXML} ><IconButton style={{color:'#388E3C'}} aria-label="DescargarXML" aria-haspopup="true" className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton></a>
                    </Tooltip>
                  </div>
                )
              ]);
            } else if (tipoReporte === 'facturasListadoFacturaNota') {
              tableDataVAR.push([
                PD.nb_EmpresaPlaza,
                PD.fl_Pedido,
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.nb_TipoDocumento}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.fl_FolioDocumento}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.fh_Documento}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.fh_Vencimiento}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.im_Total}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.im_SaldoFactura}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.nb_Moneda}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.Dvencidos}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.DPorVencer}</label>),
                (
                  <Tooltip
                    title={PD.de_Estatus}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                  </Tooltip>
                ),
                (
                  <Tooltip
                    title={PD.de_EstatusCobranza}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_ColorCobranza}}>{PD.de_EstatusCobranza}</span>
                  </Tooltip>
                ),
                (
                  <div>
                    <Tooltip
                        title='Visualizar Factura PDF'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <IconButton style={{color:'#C62828'}} aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Descargar Factura XML'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <a href={PD.urlFacturaDownloadXML} ><IconButton style={{color:'#388E3C'}} aria-label="DescargarXML" aria-haspopup="true" className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton></a>
                    </Tooltip>
                  </div>
                )
              ]);
            }
          }
          let DataVAR = [];
          let columnsVAR = [];
          if (tipoReporte === 'facturasListadoPorDocumento') {
            for(i in res.data.data){
              PD = res.data.data[i];
              DataVAR.push([
                {value: (PD.nb_Empresa)?PD.nb_Empresa.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Sucursal)?PD.nb_Sucursal.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_Pedido)?PD.fl_Pedido.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_TipoDocumento)?PD.nb_TipoDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_FolioDocumento)?PD.fl_FolioDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Documento)?PD.fh_Documento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Vencimiento)?PD.fh_Vencimiento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.im_TotalSinFormato)?PD.im_TotalSinFormato.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.im_SaldoFacturaSinFormato)?PD.im_SaldoFacturaSinFormato.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.nb_Moneda)?PD.nb_Moneda.toString():'0', style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.DPorVencer)?PD.DPorVencer.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.Dvencidos)?PD.Dvencidos.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Estatus)?PD.de_Estatus.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_EstatusCobranza)?PD.de_EstatusCobranza.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_DocumentosRelacionados)?PD.nb_DocumentosRelacionados.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Domicilio)?PD.de_Domicilio.toString():'', style: {alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' }}},
              ]);
            }
            columnsVAR = [
              {value:'Empresa Petroil',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Plaza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Pedido',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Tipo Documentos',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Documento',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Documento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Vencimiento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Importe',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Saldo',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Moneda',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Dias Por Vencer',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Dias Vencidos',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Cobranza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Documentos Relacionados',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Domicilio de Entrega',style: { wpx: '500', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
            ]
          } else if (tipoReporte === 'facturasListadoDetallado'){
            for(i in res.data.data){
              PD = res.data.data[i];
              DataVAR.push([
                {value: (PD.nb_Empresa)?PD.nb_Empresa.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Sucursal)?PD.nb_Sucursal.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_Pedido)?PD.fl_Pedido.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_TipoDocumento)?PD.nb_TipoDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_FolioDocumento)?PD.fl_FolioDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Documento)?PD.fh_Documento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Vencimiento)?PD.fh_Vencimiento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Documento)?PD.fh_Documento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Producto)?PD.nb_Producto.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_UnidadDeMedida)?PD.nb_UnidadDeMedida.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nu_Cantidad)?PD.nu_Cantidad.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.im_TotalDetalleNeto)?PD.im_TotalDetalleNeto.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.nb_Moneda)?PD.nb_Moneda.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Estatus)?PD.de_Estatus.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_EstatusCobranza)?PD.de_EstatusCobranza.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_DocumentosRelacionados)?PD.nb_DocumentosRelacionados.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Domicilio)?PD.de_Domicilio.toString():'', style: {alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' }}},
              ]);
            }
            columnsVAR = [
              {value:'Empresa Petroil',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Plaza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Pedido',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Tipo Documentos',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Documento',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Documento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Vencimiento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Producto',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Unidad Medida',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Cantidad',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Importe',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Moneda',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Cobranza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Documentos Relacionados',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Domicilio de Entrega',style: { wpx: '500', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              
            ]
          } else if (tipoReporte === 'facturasListadoFacturaNota'){
            for(i in res.data.data){
              PD = res.data.data[i];
							if(PD.de_ColorNota === '#000000de'){
								PD.de_ColorNota = '#000000';
							}
              DataVAR.push([
                {value: (PD.nb_Empresa)?PD.nb_Empresa.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Sucursal)?PD.nb_Sucursal.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_Pedido)?PD.fl_Pedido.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_TipoDocumento)?PD.nb_TipoDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.fl_FolioDocumento)?PD.fl_FolioDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.fh_Documento)?PD.fh_Documento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.fh_Vencimiento)?PD.fh_Vencimiento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.im_TotalSinFormato)?PD.im_TotalSinFormato.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.im_SaldoFacturaSinFormato)?PD.im_SaldoFacturaSinFormato.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.nb_Moneda)?PD.nb_Moneda.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.DPorVencer)?PD.DPorVencer.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.Dvencidos)?PD.Dvencidos.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.de_Estatus)?PD.de_Estatus.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.de_EstatusCobranza)?PD.de_EstatusCobranza.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.nb_DocumentosRelacionados)?PD.nb_DocumentosRelacionados.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Domicilio)?PD.de_Domicilio.toString():'', style: {alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' }}},
              ]);
            }
            columnsVAR = [
              {value:'Empresa Petroil',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Plaza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Pedido',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Tipo Documentos',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Documento',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Documento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Vencimiento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Importe',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Saldo',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Moneda',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Dias Por Vencer',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Dias Vencidos',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Cobranza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Documentos Relacionados',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Domicilio de Entrega',style: { wpx: '500', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
            ]
          }

          var multiDataSetFacturas = [
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: ('Listado de Facturas - ' + ((tipoReporte === 'facturasListadoPorDocumento') ? 'Por Documentos' : (tipoReporte === 'facturasListadoDetallado') ? 'Detallado por Documento' : 'Por Facturas vs Notas')) },
                ],
              ]
            },
            {
              xSteps: 8,
              ySteps: -2,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: ((new Date()).toISOString().slice(0,10))+'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                ],
              ]
            },
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
              ],
              data: [
                [
                  {value:'Estatus Cobranza: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.nb_EstatusCobranza.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Tipo de Documento', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value:(this.state.de_Cl_Documento.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Inicio: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Inicio.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''},
									{value: 'Fecha Fin: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Fin.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value:'Plaza:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value:(this.state.nb_Plaza_Rpt.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Folio de Pedido: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.id_Pedido.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Folio de Documento:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fl_Documento.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Domicilio de Entrega: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.de_Domicilio_Rpt.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                ]
              ]
            },
            {
              xSteps: 0,
              ySteps: 1,
              columns: columnsVAR,
              data: DataVAR
            }
          ];
          this.setState({ multiDataSetExcel: multiDataSetFacturas });

          if(!tableDataVAR.length){
            this.showNotification("tr", 'No se encontraron Facturas')
          }
          this.setState({ tableData: tableDataVAR });
      }else{
        this.showNotification("tr", res.data.message)
      }

      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  buscarReferencias(){
    const { classes } = this.props;
    var obj = {
      id_Cliente : sesion.usuario.id_Cliente,
      fh_Inicio: this.state.fh_Inicio_Ref || null,
      fh_Fin: this.state.fh_Fin_Ref || null,
       // eslint-disable-next-line
      de_Referencia: this.state.de_Referencia,
      fh_Registro: this.state.fh_Registro_Ref || null,
      id_Estatus: this.state.id_Estatus_Ref,
      // eslint-disable-next-line
      fl_Pago: this.state.fl_Pago ? this.state.fl_Pago.replace(/[^0-9\.]+/g, ""): null,
      fh_Pago: this.state.fh_Pago || null,
    }

    if(obj.fh_Inicio === ''){
      obj.fh_Inicio = null;
    }

    if(obj.fh_Fin === ''){
      obj.fh_Fin = null;
    }

    if(obj.fh_Registro === ''){
      obj.fh_Registro = null;
    }

    if(obj.fh_Pago === ''){
      obj.fh_Pago = null;
    }
    
    this.setState({ tableDataReferencias: [] });

    for(var y in this.state.comboEstatus){
      if(this.state.comboEstatus[y].value===this.state.id_Estatus_Ref){
        this.setState({de_Estatus_Ref : this.state.comboEstatus[y].label})
      }
    }

    this.setState({classMask:'maskProgress active'});
    invoke.post('reportes', 'detalladoReferencias', obj).then(res => {
      if (res.status===200 && res.data.data) {
          var tableDataVAR = [];
          for (var i in res.data.data) {
            var PD = res.data.data[i];

              tableDataVAR.push([
                PD.nb_Empresa,
                PD.de_Referencia,
                PD.fh_Vencimiento,
                (
                  <Tooltip
                    title={PD.de_Estatus}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                  </Tooltip>
                ),
                PD.fl_Pago,
                PD.fh_Pago,
                PD.fh_Aplicacion,
                PD.fl_FolioDocumento,
                PD.im_TotalFactura,
                PD.c_FormaPago,
                PD.nu_ConvenioH2H,
                (
                  <div>
                    <Tooltip
                      title='Descargar Factura PDF'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.ar_PDF ? PD.ar_PDF:''),(PD.ar_PDF ? PD.ar_PDF:''))} style={{width:'23px',height:'23px',color:'#C62828'}} className={classes.buttonTable}>
                        <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descargar Factura XML'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <a href={PD.ar_XML} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="DescargarXML" aria-haspopup="true" style={{width:'23px',height:'23px',color:'#388E3C'}} className={classes.buttonTable}>
                          <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                        </IconButton>
                      </a>
                    </Tooltip>
                  </div>
                )
              ]);
          }
          let DataVAR = [];
          let columnsVAR = [];
            for(i in res.data.data){
              PD = res.data.data[i];
              DataVAR.push([
                {value: (PD.nb_Empresa)?PD.nb_Empresa.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Referencia)?PD.de_Referencia.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Vencimiento)?PD.fh_Vencimiento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Estatus)?PD.de_Estatus.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_Pago)?PD.fl_Pago.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Pago)?PD.fh_Pago.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Aplicacion)?PD.fh_Aplicacion.toString():'', style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_FolioDocumento)?PD.fl_FolioDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.im_TotalFactura)?PD.im_TotalFactura.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.c_FormaPago)?PD.c_FormaPago.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nu_ConvenioH2H)?PD.nu_ConvenioH2H.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              ]);
            }
            columnsVAR = [
              {value:'Empresa Petroil/Plaza',style: { wpx: '150', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Referencia',style: { wpx: '120', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Vencimiento Referencia',style: { wpx: '110', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Referencia',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Pago',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Depósito',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Aplicación	',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Factura',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Total Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Tipo Pago',style: { wpx: '120', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Cuenta',style: { wpx: '120', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
            ]

          var multiDataSetReferncias = [
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: 'Reporte Detallado de Referencias', style: {font: { sz:'14', bold: true } } },
                ],
              ]
            },
            {
              xSteps: 8,
              ySteps: -2,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: ((new Date()).toISOString().slice(0,10))+'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                ],
              ]
            },
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
              ],
              data: [

                [
                  {value: 'Fecha Inicio Factura: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Inicio_Ref.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Fecha Fin Factura:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value:(this.state.fh_Fin_Ref.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Folio Referencia: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.de_Referencia.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Fecha Registro Referencia:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value:(this.state.fh_Registro_Ref.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Estatus Referencia: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.de_Estatus_Ref.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Folio de Pago:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fl_Pago.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Pago: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Pago.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                ]
              ]
            },
            {
              xSteps: 0,
              ySteps: 1,
              columns: columnsVAR,
              data: DataVAR
            }
          ];
          this.setState({ multiDataSetExcelReferencias: multiDataSetReferncias });

          if(!tableDataVAR.length){
            this.showNotification("tr", 'No se encontraron referencias')
          }
          this.setState({ tableDataReferencias: tableDataVAR });
      }else{
        this.showNotification("tr", res.data.message)
      }

      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  handleClickSearchReferencias = () => {
    this.buscarReferencias();
  };


  handleChangeIndex = index => {
    invoke.resetTime();
    this.setState({ value: index });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <ExpansionCard
                  icon={Assessment}
                  iconColor="green"
                  defaultExpanded={true}
                  cardTitle="Reporte Estado de Cuenta"
                  cardSubtitle='Informacion de adeudo del Cliente'
                  content={(<div>
                <div className={classes.backForm}>
                <MuiThemeProvider theme={theme}>
                  <Grid container>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="id_Sucursal"
                        select
                        label="Sucursal"
                        className={classes.textField}
                        value={this.state.id_Sucursal}
                        onChange={this.handleChange('id_Sucursal')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.sucursales.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                    <ItemGrid xs={1} sm={1} md={1}>
                      <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                        <IconButton
                            color="primary"
                            aria-label="Agregar"
                            aria-haspopup="true"
                            style={{marginTop:'25px'}}
                            onClick={this.handleClickSearch}>
                          <Search className={classes.links} />
                        </IconButton>
                      </Tooltip>
                    </ItemGrid>
                  </Grid>
                  </MuiThemeProvider>
                </div>
                <br/>
                <Grid container>
                  <ItemGrid xs={12} sm={6} md={6}>
                    <TableCustom xs={12} sm={12} md={12}
                        tableHead={[]}
                        tableData={this.state.tableDataInfoCliente}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} md={6}>
                    <TableCustom xs={12} sm={12} md={12}
                        tableHead={[]}
                        tableData={this.state.tableDataInfoDeuda}
                    />
                  </ItemGrid>
                </Grid>
              </div>)}
              action = {<div><Divider />
                <ExpansionPanelActions>
                  <ExcelFile element={<MuiThemeProvider theme={theme}><Button variant="contained" size="small" color="secondary" style={{color:'#fff'}}>
                    Descargar Excel
                  </Button></MuiThemeProvider>}>
                    <ExcelSheet dataSet={this.state.multiDataSet} name="Estado de Cuenta"/>
                  </ExcelFile>
                </ExpansionPanelActions></div>}
              expansionAction = {this.handleClickSearch}
            />
          </ItemGrid>


          <ItemGrid xs={12} sm={12} md={12}>
            <ExpansionCard
              icon={Assignment}
              iconColor="blue"
              cardTitle="Facturas del cliente"
              cardSubtitle='Informacion de facturas del Cliente'
              content={(<div>
                <div className={classes.backForm}>
                  <MuiThemeProvider theme={theme}>
                    <Grid container>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Inicio"
                          label="Fecha Factura Inicio"
                          type="date"
                          value={this.state.fh_Inicio}
                          onChange={this.handleChange('fh_Inicio')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Fin"
                          label="Fin"
                          type="date"
                          value={this.state.fh_Fin}
                          onChange={this.handleChange('fh_Fin')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="cl_Documento"
                          select
                          label="Tipo de Documento"
                          className={classes.textField}
                          value={this.state.cl_Documento}
                          onChange={this.handleChange('cl_Documento')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.documentos.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="id_EstatusCobranza"
                          select
                          label="Estatus de Cobranza"
                          className={classes.textField}
                          value={this.state.id_EstatusCobranza}
                          onChange={this.handleChange('id_EstatusCobranza')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.estatuscobranza.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                    </Grid>
                    <Grid container>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="id_Plaza"
                          select
                          label="Plaza"
                          className={classes.textField}
                          value={this.state.id_Plaza}
                          onChange={this.handleChange('id_Plaza')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.plazas.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={3}>
                        <TextField
                          id="name"
                          label="Folio de Pedido"
                          className={classes.textField}
                          value={this.state.id_Pedido}
                          onChange={this.handleChangeValidate('id_Pedido', { 'maxLength': 10 })}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={3}>
                        <TextField
                          id="name"
                          label="Folio de Documento"
                          className={classes.textField}
                          value={this.state.fl_Documento}
                          onChange={this.handleChangeValidate('fl_Documento', { 'maxLength': 10 })}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>

                    </Grid>
                    <Grid container>
                      <ItemGrid xs={12} sm={12} md={6}>
                        <TextField
                          id="id_Domicilio"
                          select
                          label="Domicilio de Entrega"
                          className={classes.textField}
                          value={this.state.id_Domicilio}
                          onChange={this.handleChange('id_Domicilio')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.domiciliosEntrega.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                    </Grid>
                    <br />
                    <Grid container>
                      <ItemGrid xs={11} sm={7} md={7}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Tipo de Reporte</FormLabel>
                          <RadioGroup
                            aria-label="Tipo de Reporte"
                            name="gender1"
                            className={classes.group}
                            value={this.state.id_tipoReporte}
                            onChange={this.handleChange('id_tipoReporte')}
                            row
                          >
                            <FormControlLabel value="1" control={<Radio color="primary" />} label="Por Documento" />
                            <FormControlLabel value="2" control={<Radio color="primary" />} label="Detallado por Documento" />
                            <FormControlLabel value="3" control={<Radio color="primary" />} label="Por Factura vs Nota" />
                          </RadioGroup>
                        </FormControl>
                      </ItemGrid>
                      <ItemGrid xs={1} sm={1} md={1}>
                        <Tooltip title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                          <IconButton
                            color="primary"
                            style={{ marginTop: '30px' }}
                            aria-label="Agregar"
                            aria-haspopup="true"
                            onClick={this.handleClickSearchFacturas}>
                            <Search />
                          </IconButton>
                        </Tooltip>
                      </ItemGrid>
                    </Grid>
                  </MuiThemeProvider>
                </div>
                {(this.state.id_tipoReporte === '1') ? (
                  <Grid container>
                    <Table xs={12} sm={12} md={12}
                      tableHead={[
                        { id: 'nb_EmpresaPlaza', numeric: false, disablePadding: false, label: 'Empresa Petroil/Plaza' },
                        { id: 'fl_Pedido', numeric: false, disablePadding: false, label: 'Folio Pedido' },
                        { id: 'cl_TipoDocumento', numeric: false, disablePadding: false, label: 'Tipo Documento' },
                        { id: 'fl_Documento', numeric: false, disablePadding: false, label: 'Folio Documento' },
                        { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Fecha Documento' },
                        { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Fecha Vencimiento' },
                        { id: 'im_Importe', numeric: false, disablePadding: false, label: 'Importe' },
                        { id: 'im_Saldo', numeric: false, disablePadding: false, label: 'Saldo' },
                        { id: 'nb_Moneda', numeric: false, disablePadding: false, label: 'Moneda' },
                        { id: 'DPorVencer', numeric: false, disablePadding: false, label: 'Dias por Vencer' },
                        { id: 'Dvencidos', numeric: false, disablePadding: false, label: 'Dias Vencidos' },
                        { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Factura' },
                        { id: 'de_EstatusCobranza', numeric: false, disablePadding: false, label: 'Estatus Cobranza' },
                        { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                      ]}
                      tableData={this.state.tableData}
                    />
                  </Grid>) : (this.state.id_tipoReporte === '2') ? (
                    <Grid container>
                      <Table xs={12} sm={12} md={12}
                        tableHead={[
                          { id: 'nb_EmpresaPlaza', numeric: false, disablePadding: false, label: 'Empresa Petroil/Plaza' },
                          { id: 'fl_Pedido', numeric: false, disablePadding: false, label: 'Folio Pedido' },
                          { id: 'cl_TipoDocumento', numeric: false, disablePadding: false, label: 'Tipo Documento' },
                          { id: 'fl_Documento', numeric: false, disablePadding: false, label: 'Folio Documento' },
                          { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Fecha Documento' },
                          { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Fecha Vencimiento' },
                          { id: 'nb_Producto', numeric: false, disablePadding: false, label: 'Producto' },
                          { id: 'nb_UnidadMedida', numeric: false, disablePadding: false, label: 'Unidad Medida' },
                          { id: 'nu_Cantidad', numeric: false, disablePadding: false, label: 'Cantidad' },
                          { id: 'im_Importe', numeric: false, disablePadding: false, label: 'Importe' },
                          { id: 'nb_Moneda', numeric: false, disablePadding: false, label: 'Moneda' },
                          { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Factura' },
                          { id: 'de_EstatusCobranza', numeric: false, disablePadding: false, label: 'Estatus Cobranza' },
                          { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                        ]}
                        tableData={this.state.tableData}
                      />
                    </Grid>) : (this.state.id_tipoReporte === '3') ? (
                      <Grid container>
                        <Table xs={12} sm={12} md={12}
                          tableHead={[
                            { id: 'nb_EmpresaPlaza', numeric: false, disablePadding: false, label: 'Empresa Petroil/Plaza' },
                            { id: 'fl_Pedido', numeric: false, disablePadding: false, label: 'Folio Pedido' },
                            { id: 'cl_TipoDocumento', numeric: false, disablePadding: false, label: 'Tipo Documento' },
                            { id: 'fl_Documento', numeric: false, disablePadding: false, label: 'Folio Documento' },
                            { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Fecha Documento' },
                            { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Fecha Vencimiento' },
                            { id: 'im_Importe', numeric: false, disablePadding: false, label: 'Importe' },
                            { id: 'im_Saldo', numeric: false, disablePadding: false, label: 'Saldo' },
                            { id: 'nb_Moneda', numeric: false, disablePadding: false, label: 'Moneda' },
                            { id: 'DPorVencer', numeric: false, disablePadding: false, label: 'Dias por Vencer' },
                            { id: 'Dvencidos', numeric: false, disablePadding: false, label: 'Dias Vencidos' },
                            { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Factura' },
                            { id: 'de_EstatusCobranza', numeric: false, disablePadding: false, label: 'Estatus Cobranza' },
                            { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                          ]}
                          tableData={this.state.tableData}
                        />
                      </Grid>) : null}
              </div>)}
              action={<div><Divider />
                <ExpansionPanelActions>
                  <ExcelFile element={<MuiThemeProvider theme={theme}><Button variant="contained" size="small" color="secondary" style={{ color: '#fff' }}>
                    Descargar Excel
                  </Button></MuiThemeProvider>}>
                    <ExcelSheet dataSet={this.state.multiDataSetExcel} name="Listado de Facturas" />
                  </ExcelFile>
                </ExpansionPanelActions></div>}
              expansionAction={this.handleClickSearchFacturas}
            />
          </ItemGrid>

          {/*Reporte Detallado de referencias */}
          <ItemGrid xs={12} sm={12} md={12}>
            <ExpansionCard
              icon={Assignment}
              iconColor="orange"
              cardTitle="Referencias del cliente"
              cardSubtitle='Informacion de referencias del Cliente'
              content={(<div>
                <div className={classes.backForm}>
                  <MuiThemeProvider theme={theme}>
                    <Grid container>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Inicio_Ref"
                          label="Fecha Inicio Factura"
                          type="date"
                          value={this.state.fh_Inicio_Ref}
                          onChange={this.handleChange('fh_Inicio_Ref')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Fin_Ref"
                          label="Fecha Fin Factura"
                          type="date"
                          value={this.state.fh_Fin_Ref}
                          onChange={this.handleChange('fh_Fin_Ref')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={3}>
                        <TextField
                          id="name"
                          label="Folio Referencia"
                          className={classes.textField}
                          value={this.state.de_Referencia}
                          onChange={this.handleChangeValidate('de_Referencia',{'maxLength':10})}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Registro_Ref"
                          label="Fecha Registro Referencia"
                          type="date"
                          value={this.state.fh_Registro_Ref}
                          onChange={this.handleChange('fh_Registro_Ref')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      
                    </Grid>

                    <Grid container>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="id_Estatus_Ref"
                          select
                          label="Estatus Referencias"
                          className={classes.textField}
                          value={this.state.id_Estatus_Ref}
                          onChange={this.handleChange('id_Estatus_Ref')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.comboEstatus.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={3}>
                        <TextField
                          id="name"
                          label="Folio de Pago"
                          className={classes.textField}
                          value={this.state.fl_Pago}
                          onChange={this.handleChangeValidate('fl_Pago',{'maxLength':10})}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Pago"
                          label="Fecha Pago"
                          type="date"
                          value={this.state.fh_Pago}
                          onChange={this.handleChange('fh_Pago')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      
                      <ItemGrid xs={1} sm={1} md={1}>
                        <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                          <IconButton
                              color="primary"
                              style={{marginTop: '30px'}}
                              aria-label="Agregar"
                              aria-haspopup="true"
                              onClick={this.handleClickSearchReferencias}>
                            <Search />
                          </IconButton>
                        </Tooltip>
                      </ItemGrid>
                    </Grid>
                  </MuiThemeProvider>
                </div>
                <br />
                <Grid container>
                  <Table xs={12} sm={12} md={12}
                    tableHead={[
                      { id: 'nb_EmpresaPlaza', numeric: false, disablePadding: false, label: 'Empresa Petroil/Plaza' },
                      { id: 'cl_TipoDocumento', numeric: false, disablePadding: false, label: 'Referencia' },
                      { id: 'fl_Documento', numeric: false, disablePadding: false, label: 'Vencimiento Referencia' },
                      { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Estatus Referencia' },
                      { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Folio Pago' },
                      { id: 'im_Importe', numeric: false, disablePadding: false, label: 'Fecha Depósito' },
                      { id: 'im_Saldo', numeric: false, disablePadding: false, label: 'Fecha Aplicación' },
                      { id: 'nb_Moneda', numeric: false, disablePadding: false, label: 'Folio Factura' },
                      { id: 'Dvencidos', numeric: false, disablePadding: false, label: 'Total Factura' },
                      { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Tipo Pago' },
                      { id: 'de_EstatusCobranza', numeric: false, disablePadding: false, label: 'Cuenta' },
                      { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                    ]}
                    tableData={this.state.tableDataReferencias}
                  />
                </Grid>
              </div>)}
              action={<div><Divider />
                <ExpansionPanelActions>
                  <ExcelFile element={<MuiThemeProvider theme={theme}><Button variant="contained" size="small" color="secondary" style={{ color: '#fff' }}>
                    Descargar Excel
                  </Button></MuiThemeProvider>}>
                    <ExcelSheet dataSet={this.state.multiDataSetExcelReferencias} name="Listado de Referencias" />
                  </ExcelFile>
                </ExpansionPanelActions></div>}
              expansionAction={this.handleClickSearchReferencias}
            />
          </ItemGrid>
        </Grid>

        <FormDialog
          title={(<div><InputAdornment position="start"><Assignment /><span style={{paddingLeft:'15px'}}>Facturas</span></InputAdornment></div>)}
          description= {this.state.descriptionFactura}
          content= {<div>
            <Grid container>
              <ItemGrid xs={12} sm={12} md={3}>
                <TextField
                  id="fh_Inicio"
                  label="Fecha Factura Inicio"
                  type="date"
                  value={this.state.fh_Inicio}
                  onChange={this.handleChange('fh_Inicio')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  fullWidth
                />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={3}>
                <TextField
                  id="fh_Fin"
                  label="Fin"
                  type="date"
                  value={this.state.fh_Fin}
                  onChange={this.handleChange('fh_Fin')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  fullWidth
                />
              </ItemGrid>
              <ItemGrid xs={1} sm={1} md={1}>
                <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                  <IconButton
                      color="primary"
                      aria-label="Agregar"
                      aria-haspopup="true"
                      onClick={this.handleClickSearch}>
                    <Search />
                  </IconButton>
                </Tooltip>
              </ItemGrid>
            </Grid>
            <Grid container>
              <Table xs={12} sm={12} md={12}
                tableHead={[
                  { id: 'fl_Pedido', numeric: true, disablePadding: false, label: 'Folio Pedido' },
                  { id: 'fl_FolioDocumento', numeric: false, disablePadding: false, label: 'Folio Factura' },
                  { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Fecha Factura' },
                  { id: 'nb_Producto', numeric: false, disablePadding: false, label: 'Producto' },
                  { id: 'nu_Cantidad', numeric: false, disablePadding: false, label: 'Litros' },
                  { id: 'im_Total', numeric: false, disablePadding: false, label: 'Importe' },
                  { id: 'im_SaldoFactura', numeric: true, disablePadding: false, label: 'Saldo' },
                  { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Factura' },
                  { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                ]}
                tableData={this.state.tableData}
              />
            </Grid>
          </div>}
          close={this.handleClickCloseFactura}
          open= {this.state.openDialogFactura}
        />
        <FormDialog
            title = {(<div><InputAdornment position="start"><AssignmentReturned /><span style={{paddingLeft:'15px'}}>Descargar Factura</span></InputAdornment></div>)}
            description= {this.state.descriptionFactura}
            content = {
              <div>
                <iframe title="Factura" style={{minHeight: '63VH', minWidth: '1100px'}} src={this.state.urlFactura}></iframe>
              </div>
            }
            btn= 'Descargar'
            action= {this.handleClickFactura}
            close={this.handleClickCloseFacturaView}
            open= {this.state.openDialogFacturaView}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.tr}
            onClose={() => this.setState({ tr: false })}
            ContentProps={{ 'className': classes.red }}
            message={this.state.message}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.tc}
            onClose={() => this.setState({ tc: false })}
            ContentProps={{ 'className': classes.green }}
            message={this.state.message}
          />
        <div className={this.state.classMask}>
          <Linear  />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
