import React from "react";
import PropTypes from "prop-types";
import ReactExport from 'react-data-export';
import invoke from "services/invoke.jsx";
import { AccessTime, Today, TrendingUp, AttachMoney, MoneyOff, Assignment, MonetizationOn, Search, AssignmentReturned,
  Contacts, Opacity, Payment, DateRange, Warning } from "@material-ui/icons";
import { withStyles, Grid, TextField, Tooltip, IconButton, Snackbar, InputAdornment, MenuItem  } from "@material-ui/core";
import { StatsCard, ItemGrid, Table, FormDialog, Linear, TableCustom, ExpansionCard } from "components";
import dashboardStyle from "assets/jss/material-dashboard-react/dashboardStyle";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

var sesion = invoke.getSesion();
class Dashboard extends React.Component {

  state = {
    usuario :  (sesion.usuario)? sesion.usuario: {},
    estacion :  (sesion.estacionObj)? sesion.estacionObj[0]: {},
    info: {},
    precios:[],
    multiDataSet: [],
    multiDataSetExcel: [],
    tableDataPendientes: [],
    estaciones: [],
    descriptionFactura: '',
    tableData: [],
    tableDataInfo: [],
    estatuscobranza: [],
    descuentos: [],
    openDialogFactura: false,
    openDialogDescuentos: false,
    openDialogFacturaView: false,
    urlFactura: '',
    urlFacturaDownload: '',
    classMask:  'maskProgress',
    id_EstatusCobranza: '',
    nb_EstatusCobranza: 'Todos',
    id_Estacion: '',
    nb_Estacion: '',

    fl_Pedido: '',
    fh_Inicio: '',
    fh_Fin: '',
    // Modal para aviso de posible fraude
    openDialogNotificacion: false,
    de_DescripcionAvisoNotificacion: 'Estimado cliente, GRUPO PETROIL no envía correos notificando cambio de cuentas para pagos.'
  };

  componentWillMount(){ //es parecido a la funcion init

    this.getInfoCliente();
    this.buscarFacturasPendientes();
    this.openModalAviso();
    invoke.resetTime();

    //Combo de Estatus Factura
    invoke.post('facturas','estatusId',{id_Inicial: 1511,id_Final: 1513}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value:0,label:'Todas'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Estatus,
            label: x.de_Estatus,
            obj: x
          });
        }
        this.setState({ estatuscobranza: VAR });
      }else{
        // this.showNotification("tr", res.message)
      }
    });

    var fh_inicio = this.getFirstDayOfMonth();
    var fh_fin = this.getLastDayOfMonth();
    fh_inicio = fh_inicio.toISOString().slice(0,10);
    fh_fin = fh_fin.toISOString().slice(0,10);

    this.setState({ fh_Inicio: fh_inicio });
    this.setState({ fh_Fin: fh_fin });
  }

  downloadPdf = (url,urlDescarga) => event => {
    this.setState({ openDialogFacturaView: true });
    this.setState({ urlFactura: url });
    this.setState({ urlFacturaDownload: urlDescarga });
  };

  handleClickFactura = () => {
    window.open(this.state.urlFacturaDownload, "_blank")
    //window.location.href = this.state.urlFacturaDownload;
    this.setState({ openDialogFacturaView: false });
  };

  handleClickCloseFacturaView = () => {
    this.setState({ openDialogFacturaView: false });
  };

  handleClickSearch = () => {
    this.buscar();
  };

  buscarFacturasPendientes(){
    const { classes } = this.props;

    var obj = {
      id_Usuario: sesion.usuario.id_Usuario,
      fh_Inicio: null,
      fh_Fin: null,
      id_Estatus: null,
      id_Empresa: null,
      id_Cliente : sesion.usuario.id_Cliente,
    }
    invoke.post('facturas','listadoPendientes',obj).then(res => {
      if(res.status===200 && res.data.data){
          var tableDataVAR = [];
          for(var i in res.data.data){
            var PD = res.data.data[i];

            tableDataVAR.push([
              (<span style={{textAlign:'center',display:'block'}}>{PD.fl_FolioDocumento}</span>),
              (<span style={{textAlign:'center',display:'block'}}>{PD.fh_Vencimiento}</span>),
              (<span style={{textAlign:'center',display:'block'}}>{PD.im_SaldoFactura}</span>),
              (
                <div style={{textAlign:'center',display:'block'}}>
                  <Tooltip
                      title='Visualizar Factura PDF'
                      placement={'bottom-start'}
                      enterDelay={300}>
                    <IconButton style={{color:'#C62828'}} aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} className={classes.buttonTable}>
                      <AssignmentReturned className={classes.links} />
                    </IconButton>
                  </Tooltip>
                </div>
              )
            ]);
          }

          // if(!tableDataVAR.length){
          //   this.showNotification("tr", 'No se encontraron Facturas pendientes')
          // }
          this.setState({ tableDataPendientes: tableDataVAR });
      }else{
        this.showNotification("tr", res.data.message)
      }
    });
  }

  buscarDescuentos(){
    // Descuentos
    this.setState({classMask:'maskProgress active'});
    invoke.post('descuentos','listado',{id_Cliente: sesion.usuario.id_Cliente}).then(res => {
      if(res.status===200 && res.data.data){
        this.setState({ descuentos: [] });
        let VAR = [];
        for(let i in res.data.data){
          const x = res.data.data[i];
          VAR.push([
            (<div> {x.nb_Empresa} - {x.nb_Sucursal}</div>),
            (<div><Opacity style={{color: (x.nb_Color)?x.nb_Color:'#000000', verticalAlign: 'sub'}} /> {x.nb_Producto}</div>),
            (<div style={{textAlign: 'center'}}>{x.im_Descuento}</div>),
            (<div style={{textAlign: 'center'}}>{x.im_Descuento2}</div>)
          ]);
        }
        this.setState({ descuentos: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  buscar(){
    const { classes } = this.props;

    var obj = {
      id_Usuario: sesion.usuario.id_Usuario,
      fh_Inicio: this.state.fh_Inicio,
      fh_Fin: this.state.fh_Fin,
      id_Estatus: this.state.id_EstatusCobranza,
      id_Cliente : sesion.usuario.id_Cliente,
    }

    for(var i in this.state.estatuscobranza){
      if(this.state.estatuscobranza[i].value===this.state.id_EstatusCobranza){
        this.setState({nb_EstatusCobranza : this.state.estatuscobranza[i].label})
      }
    }
    for(i in this.state.estaciones){
      if(this.state.estaciones[i].value===this.state.id_Estacion){
        this.setState({nb_Estacion : this.state.estaciones[i].label})
      }
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('facturas','listado',obj).then(res => {
      if(res.status===200 && res.data.data){
          var tableDataVAR = [];
          for(var i in res.data.data){
            var PD = res.data.data[i];

            tableDataVAR.push([
              PD.fl_FolioDocumento,
              PD.fh_Documento,
              PD.fh_Vencimiento,
              PD.nb_Producto,
              PD.nu_Cantidad,
              PD.im_Total,
              PD.im_SaldoFactura,
              PD.nb_Moneda,
              PD.DPorVencer,
              PD.Dvencidos,
              (
                <Tooltip
                  title={PD.de_Estatus}
                  placement={'bottom-start'}
                  enterDelay={300}>
                  <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                </Tooltip>
              ),
              (
                <Tooltip
                  title={PD.de_EstatusCobranza}
                  placement={'bottom-start'}
                  enterDelay={300}>
                  <span className={classes.estatus} style={{background: PD.de_ColorCobranza}}>{PD.de_EstatusCobranza}</span>
                </Tooltip>
              ),
              (
                <div>
                  <Tooltip
                      title='Visualizar Factura PDF'
                      placement={'bottom-start'}
                      enterDelay={300}>
                    <IconButton style={{color:'#C62828'}} aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} className={classes.buttonTable}>
                      <AssignmentReturned className={classes.links} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                      title='Descargar Factura XML'
                      placement={'bottom-start'}
                      enterDelay={300}>
                    <a href={PD.urlFacturaDownloadXML} ><IconButton style={{color:'#388E3C'}} aria-label="DescargarXML" aria-haspopup="true" className={classes.buttonTable}>
                      <AssignmentReturned className={classes.links} />
                    </IconButton></a>
                  </Tooltip>
                </div>
              )
            ]);
          }

          var DataVAR = [];
          for(i in res.data.data){
            PD = res.data.data[i];
            DataVAR.push([
              {value: (PD.fl_FolioDocumento)?PD.fl_FolioDocumento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.fh_Documento)?PD.fh_Documento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.fh_Vencimiento)?PD.fh_Vencimiento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.nb_Producto)?PD.nb_Producto:'', style: {alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' }}},
              {value: (PD.nu_CantidadSinFormato)?PD.nu_CantidadSinFormato:0, style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
              {value: (PD.im_TotalSinFormato)?PD.im_TotalSinFormato:0, style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
              {value: (PD.im_SaldoFacturaSinFormato)?PD.im_SaldoFacturaSinFormato:0, style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
              {value: (PD.nb_Moneda)?PD.nb_Moneda:'', style: {alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' }}},
              {value: (PD.DPorVencer)?PD.DPorVencer:0, style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.Dvencidos)?PD.Dvencidos:0, style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.de_Estatus)?PD.de_Estatus:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.de_EstatusCobranza)?PD.de_EstatusCobranza:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}}
            ]);
          }

          var multiDataSet = [
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: 'Listado de Facturas', style: { font: { sz:'14', bold: true } }},
                ],
              ]
            },
            {
              xSteps: 8,
              ySteps: -2,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: ((new Date()).toISOString().slice(0,10))+'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'14', bold: true } }},
                ],
              ]
            },
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
              ],
              data: [
                [
                  {value: 'Estatus Cobranza: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.nb_EstatusCobranza+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Estacion(es): ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.nb_Estacion+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Inicio: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Inicio+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Fin: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Fin+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ]
              ]
            },
            {
              xSteps: 0,
              ySteps: 1,
              columns: [
                {value:'Folio Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Fecha Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Fecha Vencimiento',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Producto',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Litros',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Importe',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Saldo',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Moneda',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Dias Por Vencer',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Dias Vencidos',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Estatus Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Estatus Cobranza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              ],
              data: DataVAR
            }
          ];
          this.setState({ multiDataSetExcel: multiDataSet });

          if(!tableDataVAR.length){
            this.showNotification("tr", 'No se encontraron Facturas')
          }
          this.setState({ tableData: tableDataVAR });
      }else{
        this.showNotification("tr", res.data.message)
      }

      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  handleClickOpenFactura = () => {
    this.setState({ openDialogFactura: true });
    this.buscar();
  };

  handleClickCloseFactura = () => {
    this.setState({ openDialogFactura: false });
  };

  handleClickOpenDescuentos = () => {
    this.setState({ openDialogDescuentos: true });
    this.buscarDescuentos();
  };

  handleClickCloseDescuentos = () => {
    this.setState({ openDialogDescuentos: false });
  };

  handleChange = name => event => {
    invoke.resetTime();
    this.setState({
      [name]: event.target.value,
    }, () => {
      if( name === 'id_Producto' || name === 'fl_Pedido' || name === 'id_Estatus' || name === 'fh_Inicio' || name === 'fh_Fin'){
        //this.buscar();
      }
    });
  };

  handleChangeValidate = (name,validate) => event => {
    invoke.resetTime();
    if(validate.maxLength && (event.target.value).length <= validate.maxLength){
      this.setState({
        [name]: event.target.value,
      });
    }
    else if(validate.date){
      var VAR = new Date((validate.min).toISOString().slice(0,10));
      var VAL = new Date(event.target.value);
      if(VAR <= VAL){
        this.setState({
          [name]: event.target.value,
        });
      }
    }
  };

  noOfDaysInMonth(){
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  getFirstDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      1,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  getLastDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      this.noOfDaysInMonth(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  showNotification(place,message,time=5000) {
    this.setState({message : message})
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  getInfoCliente(){
    let filtros = {
      id_Usuario : this.state.usuario.id_Usuario,
      id_Cliente : sesion.usuario.id_Cliente,
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('usuario','getinfoCliente',filtros)
      .then(res => {
        if(res.status===200 && res.data.data){
          this.setState({info : res.data.data[0]})
          var tableDataVAR = [];
          tableDataVAR.push([
            (<TrendingUp />),
            'Linea de Credito',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_LineaDeCredito)? parseFloat(this.state.info.im_LineaDeCredito).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          tableDataVAR.push([
            (<Today />),
            'Plazo Autorizado',
            (<span style={{textAlign:'right',display:'block'}}>
              {((this.state.info && this.state.info.nu_DiasCredito)? parseFloat(this.state.info.nu_DiasCredito).toLocaleString("en-US")  : '0') + ' dias'}
            </span>)
          ]);
          tableDataVAR.push([
            (<MonetizationOn />),
            'Credito Disponible',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_CreditoDisponible)? parseFloat(this.state.info.im_CreditoDisponible).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          tableDataVAR.push([
            (<Assignment />),
            'Importe Facturado/Entregado',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_SaldoFacturadoEntregado)? parseFloat(this.state.info.im_SaldoFacturadoEntregado).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          tableDataVAR.push([
            (<MoneyOff />),
            'Saldo Vencido',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_SaldoVencido)? parseFloat(this.state.info.im_SaldoVencido).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          tableDataVAR.push([
            (<AttachMoney />),
            'Saldo Pedidos No Entregados',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_SaldoPedidosNoEntregados)? parseFloat(this.state.info.im_SaldoPedidosNoEntregados).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          tableDataVAR.push([
            (<AttachMoney />),
            'Saldo Pendiente Total',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_SaldoTotal)? parseFloat(this.state.info.im_SaldoTotal).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          this.setState({tableDataInfo : tableDataVAR})
          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 100);
          }.bind(this), 1);

        }
    })
  }

  // Aviso para notificación de prevención de fraude
  openModalAviso = () => {
    if(localStorage.getItem("showDialogNotificacion") === 'true'){
      this.setState({ openDialogNotificacion: true });
    }
  };
  
  handleClickCloseAviso = () => {
    this.setState({ openDialogNotificacion: false });
    localStorage.setItem('showDialogNotificacion', false) // seteamos a falso para solo mostrar el modal
  };

  routeChange = (path) => event => {
    this.props.history.push(path);
  }

  handleChangeIndex = index => {
    invoke.resetTime();
    this.setState({ value: index });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container>
          <ItemGrid xs={12} sm={3} md={4}>
            <StatsCard
              icon={DateRange}
              iconColor="blue"
              title="Historial"
              description={"Calendario"}
              statIcon={AccessTime}
              statText="Actualizado hoy"
              actionAll={this.routeChange('/calendario')}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={3} md={4}>
            <StatsCard
              icon={Assignment}
              iconColor="orange"
              title="Pedidos"
              description={"Pedidos"}
              statIcon={AccessTime}
              statText="Actualizado hoy"
              actionAll={this.routeChange('/Pedidos')}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={3} md={4}>
            <StatsCard
              icon={Assignment}
              iconColor="blue"
              title="Reportes"
              description={"Facturas"}
              statIcon={AccessTime}
              statText="Actualizado hoy"
              actionAll={this.handleClickOpenFactura}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={3} md={4}>
            <StatsCard
              icon={Payment}
              iconColor="blueSip"
              title="Pagos"
              description={"Referencias"}
              statIcon={AccessTime}
              statText="Actualizado hoy"
              actionAll={this.routeChange('/ReferenciaBancaria')}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={3} md={4}>
            <StatsCard
              icon={TrendingUp}
              iconColor="orange"
              title="Reportes"
              description={"Estado de cuenta"}
              statIcon={AccessTime}
              statText="Actualizado hoy"
              actionAll={this.routeChange('/Reportes')}
            />
          </ItemGrid>
          {/*<ItemGrid xs={12} sm={3} md={4}>
            <StatsCard
              icon={Receipt}
              iconColor="green"
              title="Agregar Comprobantes"
              description={"Comprobantes"}
              statIcon={AccessTime}
              statText="Actualizado hoy"
              actionAll={this.routeChange('/ComprobantesBancarios')}
            />
          </ItemGrid>*/}
          {/* {(1===2)?(<ItemGrid xs={12} sm={3} md={3}>
            <StatsCard
              icon={MonetizationOn}
              iconColor="orange"
              title="Listado"
              description={"Condición Comercial"}
              statIcon={AccessTime}
              statText="Actualizado hoy"
              actionAll={this.handleClickOpenDescuentos}
            />
          </ItemGrid>):null} */}
          <ItemGrid xs={12} sm={6} md={6}>
            <ItemGrid xs={12} sm={12} md={12}>
            <ExpansionCard
              icon={Contacts}
              iconColor="blueSip"
              defaultExpanded={false}
              cardTitle={(<div><span style={{textTransform: 'uppercase'}}>{sesion.usuario.de_RazonSocial}</span></div>)}
              cardSubtitle='Información general del Cliente'
              content={(<div>
                <TableCustom xs={12} sm={12} md={12}
                  tableHead={[]}
                  tableData={this.state.tableDataInfo}
                />
              </div>)}
            />
            </ItemGrid>
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={6}>
            <ExpansionCard
              icon={Assignment}
              iconColor="orange"
              defaultExpanded={false}
              cardTitle={(<div><span style={{textTransform: 'uppercase'}}>Facturas proximas a vencer</span></div>)}
              cardSubtitle='Facturas que vencen de ahora a 7 dias'
              content={(<div>
                <TableCustom xs={12} sm={12} md={12}
                    tableHead={[
                      { id: 'Folio', numeric: true, disablePadding: false, label: 'Folio' },
                      { id: 'Vence', numeric: false, disablePadding: false, label: 'Vence' },
                      { id: 'Saldo', numeric: true, disablePadding: false, label: 'Saldo' },
                      { id: 'Acciones', numeric: true, disablePadding: false, label: 'Acciones' },
                    ]}
                    tableData={this.state.tableDataPendientes}
                  />
              </div>)}
            />
          </ItemGrid>
        </Grid>

        <FormDialog
          title={(<div><InputAdornment position="start"><Assignment /><span style={{paddingLeft:'15px',minWidth:'1100px'}}>Facturas</span></InputAdornment></div>)}
          description= {this.state.descriptionFactura}
          content= {<div>
            <Grid container>
              <ItemGrid xs={12} sm={4} md={2}>
                <TextField
                  id="fh_Inicio"
                  label="Fecha Factura Inicio"
                  type="date"
                  value={this.state.fh_Inicio}
                  onChange={this.handleChange('fh_Inicio')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  fullWidth
                />
              </ItemGrid>
              <ItemGrid xs={12} sm={4} md={2}>
                <TextField
                  id="fh_Fin"
                  label="Fin"
                  type="date"
                  value={this.state.fh_Fin}
                  onChange={this.handleChange('fh_Fin')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  fullWidth
                />
              </ItemGrid>
              <ItemGrid xs={12} sm={4} md={2}>
                <TextField
                    id="id_EstatusCobranza"
                    select
                    label="Estatus de Cobranza"
                    className={classes.textField}
                    value={this.state.id_EstatusCobranza}
                    onChange={this.handleChange('id_EstatusCobranza')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    fullWidth
                  >
                    {this.state.estatuscobranza.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
              </ItemGrid>
              <ItemGrid xs={1} sm={1} md={1}>
                <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                  <IconButton
                      color="primary"
                      style={{marginTop: '20px'}}
                      aria-label="Agregar"
                      aria-haspopup="true"
                      onClick={this.handleClickSearch}>
                    <Search />
                  </IconButton>
                </Tooltip>
              </ItemGrid>
            </Grid>
            <Grid container>
              <Table xs={12} sm={12} md={12}
                tableHead={[
                  { id: 'fl_FolioDocumento', numeric: false, disablePadding: false, label: 'Folio Factura' },
                  { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Fecha Factura' },
                  { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Fecha Vencimiento' },
                  { id: 'nb_Producto', numeric: false, disablePadding: false, label: 'Producto' },
                  { id: 'nu_Cantidad', numeric: false, disablePadding: false, label: 'Litros' },
                  { id: 'im_Total', numeric: false, disablePadding: false, label: 'Importe' },
                  { id: 'im_SaldoFactura', numeric: true, disablePadding: false, label: 'Saldo' },
                  { id: 'nb_Moneda', numeric: true, disablePadding: false, label: 'Moneda' },
                  { id: 'DPorVencer', numeric: false, disablePadding: false, label: 'Dias por Vencer' },
                  { id: 'Dvencidos', numeric: false, disablePadding: false, label: 'Dias Vencido' },
                  { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Factura' },
                  { id: 'de_EstatusCobranza', numeric: false, disablePadding: false, label: 'Estatus Cobranza' },
                  { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                ]}
                tableData={this.state.tableData}
              />
            </Grid>
          </div>}
          close={this.handleClickCloseFactura}
          open= {this.state.openDialogFactura}
          excel = {<div><ExcelFile element={
              <span>Descargar Excel</span>
            }>
            <ExcelSheet dataSet={this.state.multiDataSetExcel} name="Listado de Facturas"/>
            </ExcelFile></div>}
        />

        <FormDialog
          title={(<div><InputAdornment position="start"><Warning style={{color: '#ff3f3f'}}/><span style={{paddingLeft:'15px',maxWidth:'600px', color: '#ff3f3f', fontWeight: 'bold'}}>¡Aviso Importante!</span></InputAdornment></div>)}
          content= {
            <div style={{maxWidth: '600px'}}>
              <p align="justify">
                Estimado cliente, Grupo Petroil no está haciendo cambios en sus cuentas interbancarias, 
                por lo cual te pedimos denunciar cualquier irregularidad.
              </p>
              <p align="justify">
              Gracias por su amable atención.
                Ante la ola de fraudes digitales suscitados a nivel nacional, te invitamos de la manera más atenta, a verificar 
                la información oficial de la empresa con tu asesor comercial.
              </p>
              <p align="justify">
              Adicionalmente ponemos a tu disposición una línea segura para validar tu información. <b>800 EN ORDEN (800 366 7336)</b>
              </p>
            </div>
          }
          close={this.handleClickCloseAviso}
          open= {this.state.openDialogNotificacion}
        />

        <FormDialog
          title={(<div><InputAdornment position="start"><MonetizationOn /><span style={{paddingLeft:'15px',minWidth:'1100px'}}>Condición Comercial</span></InputAdornment></div>)}
          content= {<div>
            <Grid container>
            {(this.state.descuentos.length)?(<ItemGrid xs={12} sm={12} md={12}>
              <TableCustom xs={12} sm={12} md={12}
                  tableHead={[
                    { id: 'id_Producto', numeric: true, disablePadding: false, label: 'Empresa - Sucursal' },
                    { id: 'id_Producto', numeric: true, disablePadding: false, label: 'Producto' },
                    { id: 'id_Producto', numeric: true, disablePadding: false, label: 'Descuento Por Litro (CREDITO)' },
                    { id: 'id_Producto', numeric: true, disablePadding: false, label: 'Descuento Por Litro (CONTADO)' },
                  ]}
                  tableData={this.state.descuentos}
              />
            </ItemGrid>):null}
            </Grid>
          </div>}
          close={this.handleClickCloseDescuentos}
          open= {this.state.openDialogDescuentos}
        />
        <FormDialog
            title={(<div><InputAdornment position="start"><AssignmentReturned /><span style={{paddingLeft:'15px'}}>Descargar Factura</span></InputAdornment></div>)}
            description= {this.state.descriptionFactura}
            content= {
              <div>
                <iframe title="Facturas" style={{minHeight: '63VH', minWidth: '1100px'}} src={this.state.urlFactura}></iframe>
              </div>
            }
            btn= 'Descargar'
            action= {this.handleClickFactura}
            close={this.handleClickCloseFacturaView}
            open= {this.state.openDialogFacturaView}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.tr}
            onClose={() => this.setState({ tr: false })}
            ContentProps={{ 'className': classes.red }}
            message={this.state.message}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.tc}
            onClose={() => this.setState({ tc: false })}
            ContentProps={{ 'className': classes.green }}
            message={this.state.message}
          />
        <div className={this.state.classMask}>
          <Linear  />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
