import React from "react";
import PropTypes from "prop-types";
import invoke from "services/invoke.jsx";
import { Grid, Tooltip, Hidden, Divider } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { ItemGrid, Snackbar, Linear } from "components";
import { Opacity, FiberManualRecord } from "@material-ui/icons";
import calendarStyle from "assets/jss/material-dashboard-react/calendarStyle";
import BigCalendar from 'react-big-calendar';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import 'moment/locale/es';

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
var sesion = invoke.getSesion();

class Calendar extends React.Component {
  //Inicializar estados
  state={
    usuario :  sesion.usuario,
    classMask:  'maskProgress',
    events:[],
    message:'',
    month: '',
    times:0,
    open: true,
    productos: [],
  }

  componentWillMount(){//es parecido a la funcion init
    var fh_inicio = this.getFirstDayOfMonth();
    var fh_fin = this.getLastDayOfMonth();
    fh_inicio = fh_inicio.toISOString().slice(0,10);
    fh_fin = fh_fin.toISOString().slice(0,10);
    this.buscar(fh_inicio,fh_fin);
    invoke.resetTime();
  }

  CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.date.setMonth( toolbar.date.getMonth() - 1);

    var fh_inicio = this.getFirstDayOfMonth(toolbar.date.getMonth()-1,toolbar.date);
    var fh_fin = this.getLastDayOfMonth(toolbar.date.getMonth()+1,toolbar.date);
    fh_inicio = fh_inicio.toISOString().slice(0,10);
    fh_fin = fh_fin.toISOString().slice(0,10);
    this.buscar(fh_inicio,fh_fin);

    toolbar.onNavigate('prev');
    invoke.resetTime();
  };

  const goToNext = () => {
    toolbar.date.setMonth( toolbar.date.getMonth() + 1);

    var fh_inicio = this.getFirstDayOfMonth(toolbar.date.getMonth()-1,toolbar.date);
    var fh_fin = this.getLastDayOfMonth(toolbar.date.getMonth()+1,toolbar.date);
    fh_inicio = fh_inicio.toISOString().slice(0,10);
    fh_fin = fh_fin.toISOString().slice(0,10);
    this.buscar(fh_inicio,fh_fin);

    toolbar.onNavigate('next');
    invoke.resetTime();
  };

  const goToCurrent = () => {
    let now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());

    var fh_inicio = this.getFirstDayOfMonth(toolbar.date.getMonth()-1,toolbar.date);
    var fh_fin = this.getLastDayOfMonth(toolbar.date.getMonth()+1,toolbar.date);
    fh_inicio = fh_inicio.toISOString().slice(0,10);
    fh_fin = fh_fin.toISOString().slice(0,10);
    this.buscar(fh_inicio,fh_fin);

    toolbar.onNavigate('current');
    invoke.resetTime();
  };

  const goToMonth = () => {
    toolbar.onViewChange('month');
    invoke.resetTime();
  };

  const goToWeek = () => {
    toolbar.onViewChange('week');
    invoke.resetTime();
  };

  const goToDay = () => {
    toolbar.onViewChange('day');
    invoke.resetTime();
  };

  const label = () => {
    let date = moment(toolbar.date);
    return (
      <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
    );
  };

  return (
    <div className='rbc-toolbar'>
      <div className='rbc-btn-group'>
        <button onClick={goToCurrent}>Hoy</button>
        <button onClick={goToBack}>Atras</button>
        <button onClick={goToNext}>Siguiente</button>
      </div>
      <span className='rbc-toolbar-label'>{label()}</span>
      <Hidden smDown>
        <span className="rbc-btn-group">
          <button type="button" onClick={goToMonth}>Mes</button>
          <button type="button" onClick={goToWeek}>Semana</button>
          <button type="button" onClick={goToDay}>Dia</button>
        </span>
      </Hidden>
    </div>
  );
};

  noOfDaysInMonth(){
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  getFirstDayOfMonth(month,daten=new Date()){
    var date = new Date(daten);
    return new Date(date.getFullYear(),
      (month)?month:date.getMonth(),
      1,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  getLastDayOfMonth(month,daten=new Date()){
    var date = new Date(daten);
    return new Date(date.getFullYear(),
      (month)?month:date.getMonth(),
      this.noOfDaysInMonth(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  event ( event ) {
    return (
      <Tooltip
          title={event.title?event.title:''}
          placement={'top-start'}
          enterDelay={100}>
        <span>{event.title}</span>
      </Tooltip>
    )
  }

  eventStyleGetter (event, start, end, isSelected) {
    var style = {
        backgroundColor: event.color,
        borderRadius: '3px',
        color: '#fff',
        border: '0px',
        display: 'block'
    };
    return {
        style: style,
        event: event,
        tooltipAccessor: undefined
    };
  }



  handleClick(e) {
    invoke.resetTime();
    this.setState({open: !this.state.open});
  }

  handleClose(e) {
    invoke.resetTime();
    this.setState({open: false});
  }

  buscar(fh_Inicio,fh_Fin){
    invoke.resetTime();
    if(sesion.usuario){
      invoke.post('precios','productos',{id_Cliente: sesion.usuario.id_Cliente, id_Tar: null}).then(res => {
        if(res.status===200 && res.data.data){
          var productosVAR = [];
          for(var i in res.data.data){
            var x = res.data.data[i];
            productosVAR.push({
              value: x.id_TipoProducto,
              label: x.nb_TipoProducto,
              color: x.nb_Color,
              id_TipoProducto: x.id_TipoProducto,
            });
          }
          this.setState({ productos: productosVAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });
    }
    let filtros = {
      fh_Inicio : fh_Inicio,
      fh_Fin : fh_Fin,
      id_Cliente : sesion.usuario.id_Cliente,
      id_Usuario : sesion.usuario.id_Usuario
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('ordenesDeSuministro','listado',filtros)
          .then(res => {
            if(res.status===200 && res.data.data){
              this.setState({times:1});
              let events = []
              res.data.data.forEach((item,index) => {
                events.push(
                  {
                    id: index,
                    tooltip: 'hola',
                    allDay: true,
                    title: (
                        <div>
                          <FiberManualRecord style={{color: (item.de_Color==='#4caf50')?'#ffb300':(item.de_Color==='#f44336')?'#b71c1c':item.de_Color, border: '1px solid #fff', borderRadius: '50%', verticalAlign: 'sub', fontSize: '15px', marginRight: '7px'}}/>
                          Folio: {item.fl_PedidoNG} Producto: {item.nb_Producto} Cantidad: {item.nu_Cantidad} | Pedido por el portal: {(item.id_Usuario !== 1)?'SI':'NO'}
                    </div>),
                    start: item.fh_Pedido.slice(0,10)+' 12:00',
                    end: item.fh_Pedido.slice(0,10)+' 16:00',
                    color: item.de_ColorTipoProducto
                  },
                )
              })
              this.setState({events:events});
              setTimeout(() => {
                this.setState({times:0});
              }, 100)
            }else{
              setTimeout(() => {
                this.setState({times:0});
              }, 100)
            }

            setTimeout(function() {
              this.setState({classMask:'maskProgress desactive'});
              setTimeout(function() {
                  this.setState({classMask:'maskProgress'});
              }.bind(this), 100);
            }.bind(this), 1);
        });

  }


  showNotification(place,message,time=5000) {
    invoke.resetTime();
    this.setState({message:message});
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);

    }.bind(this), time );
  }

  dateSelected(slotInfo){
    invoke.resetTime();
    this.setState({datepicked:slotInfo.start,open: true});
  }

  render() {
    return (
        <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <div>
              <Paper elevation={4} style={{padding: '20px', maxWidth:'900px',margin: '0 auto', marginBottom: '20px'}}>
                <Grid container>
                    {this.state.productos.map((option,index) => (
                      <ItemGrid xs={12} sm={4} md={4} style={{textAlign: 'center'}}>
                        <Opacity style={{color:option.color, verticalAlign: 'sub'}}/> {option.label}
                      </ItemGrid>
                    ))}
                </Grid>

                <Divider style={{margin:'20px 0'}}/>

                <Grid container style={{textAlign: 'left'}}>
                  <ItemGrid xs={12} sm={4} md={4}>
                    <FiberManualRecord style={{color:'#FFB300', verticalAlign: 'bottom'}}/> Programado
                  </ItemGrid>
                </Grid>
                <Grid container style={{textAlign: 'left'}}>
                  <ItemGrid xs={12} sm={4} md={4}>
                    <FiberManualRecord style={{color:'#1565c0', verticalAlign: 'bottom'}}/> Facturado/Finalizado
                  </ItemGrid>
                </Grid>
                <Grid container style={{textAlign: 'left'}}>
                  <ItemGrid xs={12} sm={4} md={4}>
                    <FiberManualRecord style={{color:'#B71C1C', verticalAlign: 'bottom'}}/> Cancelado
                  </ItemGrid>
                </Grid>
              </Paper>
              <BigCalendar
                popup
                events={this.state.events}
                defaultDate={new Date()}
                style={{height:'500px', maxWidth:'900px',margin: '0 auto'}}
                components={{
                  event: this.event,
                  toolbar : this.CustomToolbar
                }}
                eventPropGetter={(this.eventStyleGetter)}
              />
            </div>
            <Snackbar place="tr" color="danger" message={this.state.message} open={this.state.tr} closeNotification={() => this.setState({ tr: false })} close />
          </ItemGrid>
          <div className={this.state.classMask}>
            <Linear  />
          </div>
        </Grid>
    );
  }
}

Calendar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(calendarStyle)(Calendar);
