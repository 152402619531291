/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, withStyles, Hidden } from "@material-ui/core";
import footerStyle from "assets/jss/material-dashboard-react/footerStyle";
function Footer({ ...props }) {
  const { classes, mobile, simpleDialog } = props;

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <Hidden smDown>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a href="http://petroil.com.mx/" target="_blank" rel="noopener noreferrer" className={classes.block}>
                  Web Site Petroil {mobile}
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="http://petroil.com.mx/productos" target="_blank" rel="noopener noreferrer" className={classes.block}>
                  Productos
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="http://petroil.com.mx/servicios" target="_blank" rel="noopener noreferrer" className={classes.block}>
                  Servicios
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="http://petroil.com.mx/nosotros" target="_blank" rel="noopener noreferrer" className={classes.block}>
                  Nosotros
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a onClick={simpleDialog} className={classes.block} style={{cursor: 'pointer'}}>
                  Aviso de privacidad
                </a>
              </ListItem>
            </List>
          </div>
        </Hidden>
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()}{" "}
            <a href="http://petroil.com.mx/" target="_blank" rel="noopener noreferrer" className={classes.a}>
              Petroil
            </a>, todos los derechos reservados.
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired
};

export default withStyles(footerStyle)(Footer);
