import React from "react";
import PropTypes from "prop-types";
import ReactExport from 'react-data-export';
import invoke from "services/invoke.jsx";
//import ChartistGraph from "react-chartist";
import { Today, TrendingUp, Assignment, Opacity, Search, AssignmentReturned,
  LocalGasStation, Contacts, Assessment, Business, RecentActors, Print } from "@material-ui/icons";
import { withStyles, Grid, TextField, Tooltip, IconButton, Snackbar, InputAdornment, MenuItem,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Table as TableBasic, TableBody, TableHead, TableCell, TableRow } from "@material-ui/core";
import { ItemGrid, Table, TableCustom, FormDialog, Linear, ExpansionCard } from "components";

import orange from '@material-ui/core/colors/orange';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//import { dailySalesChart } from "variables/charts";
import dashboardStyle from "assets/jss/material-dashboard-react/dashboardStyle";
import html2canvas from "html2canvas";
import { Line } from "react-chartjs-2";
import logoBlancoAsociados from 'assets/img/logoNegocios.png'
const pdfConverter = require("jspdf");
//import * as moment from 'moment';

//const queryString = require('query-string');
//var crypto = require('crypto');

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// Create styles PDF 
const styles = {
  img: {
    width:' 100%',
    height: '100%',
    color: '#345186',
    border: '0',
    margin: '0 auto',
    display: 'block',
  },
  title: {
    fontSize: 14, 
    fontWeight: 'bold', 
    textDecoration: 'underline', 
    color: 'Black'
  },
  subtitle: {
    fontSize: 12, 
    fontWeight: 'bold',
    color: 'Black'
  },
  text: {
    fontSize: 12, 
    color: 'Black'
  },
  cell: {
    color: '#fff', 
    padding: '2px 2px 2px 15px'
  },
  cellText: {
    padding: '2px 2px 2px 15px'
  },
  rowHead: {
    background: '#0d4377', 
    height: '10px'
  },
};

const Meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

var sesion = invoke.getSesion();
var id_EmpresaVenta = (sesion && sesion.estacionObj && sesion.estacionObj[0].id_EmpresaVenta)?sesion.estacionObj[0].id_EmpresaVenta:1;

class Dashboard extends React.Component {

  state = {
    usuario :  (sesion.usuario)? sesion.usuario: {},
    estacion :  (sesion.estacionObj)? sesion.estacionObj[0]: {},
    info: {},
    precios:[],
    multiDataSet: [],
    estaciones: [],
    plazas: [],
    descriptionFactura: '',
    tableDataCliente: [],
    tableDataInfoCliente: [],
    tableDataDeuda: [],
    tableDataInfoDeuda: [],
    openDialogFactura: false,
    openDialogFacturaView: false,
    urlFactura: '',
    urlFacturaDownload: '',
    classMask:  'maskProgress',
    id_Estacion: sesion.estacion,
    id_Plaza: '',
    nb_Estacion: '',
    nb_Plaza: '',
    fl_Pedido: '',
    fh_Inicio: '',
    fh_Fin: '',
    // Para el reporte de facturas
    id_Plaza_RP:'',
    id_Estacion_RP: sesion.estacion,
    id_Pedido: '',
    fl_Documento: '',
    cl_Documento: '',
    documentos: [],
    estatuscobranza: [],
    id_EstatusCobranza: '',
    nb_EstatusCobranza: 'Todos',
    id_tipoReporte: '1',
    tableData: [],
    multiDataSetExcel: [],
    // filtros excel
    de_Cl_Documento: 'Todos',
    nb_Estacion_Rpt: 'Todas',
    nb_Plaza_Rpt: 'Todas',
    domiciliosEntrega: [],
    id_Domicilio: '',
    de_Domicilio_Rpt:'Todos',
    // Reporte de Referencias
    comboEstatus:[],
    tableDataReferencias: [],
    multiDataSetExcelReferencias: [],
    fh_Inicio_Ref: '',
    fh_Fin_Ref:'',
    de_Referencia: '',
    fh_Registro_Ref:'',
    id_Estatus_Ref: '',
    fl_Pago:'',
    fh_Pago: '',
    de_Estatus_Ref: 'Todos',
    id_Estacion_Ref: '',
    nb_Estacion_Ref: 'Todos',
    // Justificacion
    fh_inicio_Month: '',
    fh_fin_Month:'',
    id_Estacion_Jus: sesion.estacion, 
    ventas: [],
    required_id_Meses: false,
    required_id_Ventas: false,
    sn_ImprimirReporte: false, 
    fh_inicio_MonthFormat: '', // Date to pdf
    fh_fin_MonthFormat:'', // Date to pdf
    fh_Impresion:'', // Date to pdf
    cData: { labels: [], datasets: [] }, // Data to pdf
    totalVentaM3 : [{mes: '', nu_Cantidad: 0}], // Table pdf
    // Reporte Restituciones
    fh_inicio_Restitucion: '',
    fh_fin_Restitucion: '',
    fh_ImpresionREST:'', 
    tableDataRestituciones: [],
    required_idMesesRestituciones: false,
    fh_inicio_MonthFormatREST: '',
    fh_fin_MonthFormatREST: '',
    // Variables para expandir los reportes
    expandedRestituciones: false,
    expandedEstadoCuenta: true,
    expandedFacturasCliente: false,
    expandedReferenciasCliente: false,
    expandedJustificacion : false,
  };

  componentWillMount(){ //es parecido a la funcion init
    if (this.state.estacion.id_Estacion) {
      //this.getInfoCliente();
      //this.getHistorialPrecios();
      invoke.resetTime();
    }

    //Combo de Estaciones
    invoke.post('estacion','combo',{id_Usuario: sesion.usuario.id_Usuario, id_Estacion: null, id_Cliente: sesion.usuario.id_Cliente}).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [{value:0,label:'Todas'}];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.id_Estacion,
            label: x.nb_Estacion,
            obj: x
          });
        }
        this.setState({ estaciones: VAR });
        //Combo de Estaciones
        invoke.post('estacion','plazas').then(res => {
          if(res.status===200 && res.data.data){
            let VAR = [{value:0,label:'Todas'}];
            for(let i in res.data.data){
              let x = res.data.data[i];
              VAR.push({
                value: x.id_Plaza,
                label: x.nb_Plaza,
                obj: x
              });
            }
            this.setState({ plazas: VAR }, () => {
              this.setState({ id_Plaza: 0 });
          
              if(this.state.plazas[0].value===0){
                this.setState({nb_Plaza : this.state.plazas[0].label})
              }
              this.getInfoCliente();
              this.getCombosFacturas();
            });
          }else{
            this.showNotification("tr", res.message)
          }
        });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    // Cargar combo estatus para referencias
    invoke.post('facturas','estatusId',{id_Inicial: 1531,id_Final: 1534}).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [{value:0,label:'Todas'}];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.id_Estatus,
            label: x.de_Estatus,
            obj: x
          });
        }
        this.setState({ comboEstatus: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    var fh_inicio = this.getFirstDayOfMonth();
    var fh_fin = this.getLastDayOfMonth();
    fh_inicio = fh_inicio.toISOString().slice(0,10);
    fh_fin = fh_fin.toISOString().slice(0,10);

    this.setState({ fh_Inicio: fh_inicio });
    this.setState({ fh_Fin: fh_fin });

    this.setState({ fh_Inicio_Ref: fh_inicio });
    this.setState({ fh_Fin_Ref: fh_fin });
    // Seteamos fechas del mes actual
    let date = new Date();
    let monthActual = ( ( ( parseInt( date.getMonth() ) + 1 ) < 10 )?'0':'') + (parseInt( date.getMonth() ) + 1);
    let fh_fin_Month = date.getFullYear() +'-'+ monthActual;
    this.setState({ fh_inicio_Month: fh_fin_Month });
    this.setState({ fh_fin_Month: fh_fin_Month });

    // Seteamos fechas del mes actual para restituciones
    this.setState({ fh_inicio_Restitucion: fh_fin_Month });
    this.setState({ fh_fin_Restitucion: fh_fin_Month });
  }

  downloadPdf = (url,urlDescarga) => event => {
    this.setState({ openDialogFacturaView: true });
    this.setState({ urlFactura: url });
    this.setState({ urlFacturaDownload: urlDescarga });
  };

  //Funcion para Imprimir la grafica a pdf
  div2PDF = e => {
    // Validamos que haya informacion para imprimir
    if(this.state.ventas.length <= 0){
      this.mensajeLog("required_id_Ventas", 'No es posible descargar el reporte sin ventas existentes')
      return false;
    }
    this.setState({classMask:'maskProgress active'});
    setTimeout(function() {
      this.setState({sn_ImprimirReporte: true});
      this.setState({sn_ImprimirReporte: false});
    }.bind(this), 10);
    setTimeout(function() {
      this.setState({sn_ImprimirReporte: true});
        // Se toma el html
        let input = window.document.getElementById("chartDiv"); 
        // Se convierte a imagen
        html2canvas(input).then(canvas => {
          const img = canvas.toDataURL("image/png");
          const pdf = new pdfConverter("l", "pt"); 
          pdf.addImage(
            img,
            "png", // Format
            10, // X
            10, // Y
            830, // Width
            550, // Height
            'none', // Alias
            'NONE' // Compresion
          );
          pdf.save("JustificacionFZDA_" + this.state.fh_inicio_Month + "_" + this.state.fh_fin_Month + ".pdf");
        });
        this.setState({sn_ImprimirReporte: false});
        this.hideMaskProgress();
      }.bind(this), 1000);
  };
  
  handleClickFactura = () => {
    window.location.href = this.state.urlFacturaDownload;
    this.setState({ openDialogFacturaView: false });
  };

  handleClickCloseFacturaView = () => {
    this.setState({ openDialogFacturaView: false });
  };

  handleClickSearch = () => {
    this.getInfoCliente();
  };

  handleClickSearchFacturas = () => {
    this.buscarFactura();
  };

  buscar(){
    const { classes } = this.props;
    var obj = {
      id_Usuario: sesion.usuario.id_Usuario,
      id_Estacion: sesion.estacion,
      fl_Pedido: this.state.fl_Pedido,
      fh_Inicio: this.state.fh_Inicio,
      fh_Fin: this.state.fh_Fin
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('facturas','listado',obj).then(res => {
      if(res.status===200 && res.data.data){
          var tableDataVAR = [];
          for(var i in res.data.data){
            var PD = res.data.data[i];
            tableDataVAR.push([
              PD.fl_Pedido,
              PD.fl_FolioDocumento,
              PD.fh_Documento,
              PD.nb_Producto,
              PD.nu_Cantidad,
              PD.im_Total,
              PD.im_SaldoFactura,
              PD.de_Estatus,
              (
                <div>
                  <Tooltip
                      title='Visualizar Factura PDF'
                      placement={'bottom-start'}
                      enterDelay={300}>
                    <IconButton style={{color:'#C62828'}} aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} className={classes.buttonTable}>
                      <AssignmentReturned className={classes.links} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                      title='Descargar Factura XML'
                      placement={'bottom-start'}
                      enterDelay={300}>
                    <a href={PD.urlFacturaDownloadXML} ><IconButton style={{color:'#388E3C'}} aria-label="DescargarXML" aria-haspopup="true" className={classes.buttonTable}>
                      <AssignmentReturned className={classes.links} />
                    </IconButton></a>
                  </Tooltip>
                </div>
              )
            ]);
          }
          if(!tableDataVAR.length){
            this.showNotification("tr", 'No se encontraron Facturas')
          }
          this.setState({ tableData: tableDataVAR });
      }else{
        this.showNotification("tr", res.data.message)
      }

      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 300);
      }.bind(this), 300);
    });
  }

  buscarFactura(){
    const { classes } = this.props;
    var obj = {
      fh_Inicio: this.state.fh_Inicio || null,
      fh_Fin: this.state.fh_Fin || null,
      id_Estatus: this.state.id_EstatusCobranza,
      id_Cliente : sesion.usuario.id_Cliente,
      cl_Documento: this.state.cl_Documento ? this.state.cl_Documento : null,
      id_Plaza: (this.state.id_Plaza_RP)?this.state.id_Plaza_RP:null,
      // eslint-disable-next-line
      id_Pedido: this.state.id_Pedido ? this.state.id_Pedido.replace(/[^0-9\.]+/g, ""): null,
      fl_Documento: this.state.fl_Documento,
      id_Estacion: (this.state.id_Estacion_RP)?this.state.id_Estacion_RP:null,
      id_Domicilio: (this.state.id_Domicilio)?this.state.id_Domicilio:null,
    }

    if(obj.fh_Inicio === ''){
      obj.fh_Inicio = null;
    }

    if(obj.fh_Fin === ''){
      obj.fh_Fin = null;
    }
    if(obj.id_Pedido === ''){
      obj.id_Pedido = null;
    }
    this.setState({ tableData: [] });

    let tipoReporte = ''
    if (this.state.id_tipoReporte === '1') {
      tipoReporte = 'facturasListadoPorDocumento'
    } else if (this.state.id_tipoReporte === '2') {
      tipoReporte = 'facturasListadoDetallado'
    } else {
      tipoReporte = 'facturasListadoFacturaNota'
    }

    for(var i in this.state.estatuscobranza){
      if(this.state.estatuscobranza[i].value===this.state.id_EstatusCobranza){
        this.setState({nb_EstatusCobranza : this.state.estatuscobranza[i].label})
      }
    }

    for(var x in this.state.documentos){
      if(this.state.documentos[x].value===this.state.cl_Documento){
        this.setState({de_Cl_Documento : this.state.documentos[x].label})
      }
    }

    for(var y in this.state.plazas){
      if(this.state.plazas[y].value===this.state.id_Plaza_RP){
        this.setState({nb_Plaza_Rpt : this.state.plazas[y].label})
      }
    }

    for(var z in this.state.estaciones){
      if(this.state.estaciones[z].value===this.state.id_Estacion_RP){
        this.setState({nb_Estacion_Rpt : this.state.estaciones[z].label})
      }
    }

    for(var t in this.state.domiciliosEntrega){
      if(this.state.domiciliosEntrega[t].value===this.state.id_Domicilio){
        this.setState({de_Domicilio_Rpt : this.state.domiciliosEntrega[t].label})
      }
    }

    this.setState({classMask:'maskProgress active'});
    invoke.post('reportes', tipoReporte, obj).then(res => {
      if (res.status===200 && res.data.data) {
          var tableDataVAR = [];
          for (var i in res.data.data) {
            var PD = res.data.data[i];

            if (tipoReporte === 'facturasListadoPorDocumento') {
              tableDataVAR.push([
                PD.nb_EmpresaPlaza,
                PD.fl_Pedido,
                PD.nb_Estacion,
                PD.nb_TipoDocumento,
                PD.fl_FolioDocumento,
                PD.fh_Documento,
                PD.fh_Vencimiento,
                PD.im_Total,
                PD.im_SaldoFactura,
                PD.nb_Moneda,
                PD.Dvencidos,
                PD.DPorVencer,
                (
                  <Tooltip
                    title={PD.de_Estatus}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                  </Tooltip>
                ),
                (
                  <Tooltip
                    title={PD.de_EstatusCobranza}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_ColorCobranza}}>{PD.de_EstatusCobranza}</span>
                  </Tooltip>
                ),
                (
                  <div>
                    <Tooltip
                        title='Visualizar Factura PDF'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <IconButton style={{color:'#C62828'}} aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Descargar Factura XML'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <a href={PD.urlFacturaDownloadXML} ><IconButton style={{color:'#388E3C'}} aria-label="DescargarXML" aria-haspopup="true" className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton></a>
                    </Tooltip>
                  </div>
                )
              ]);
            } else if (tipoReporte === 'facturasListadoDetallado') {
              tableDataVAR.push([
                PD.nb_EmpresaPlaza,
                PD.fl_Pedido,
                PD.nb_Estacion,
                PD.nb_TipoDocumento,
                PD.fl_FolioDocumento,
                PD.fh_Documento,
                PD.fh_Vencimiento,
                PD.nb_Producto,
                PD.nb_UnidadDeMedida,
                PD.nu_Cantidad,
                PD.im_TotalDetalleNeto,
                PD.nb_Moneda,
                (
                  <Tooltip
                    title={PD.de_Estatus}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                  </Tooltip>
                ),
                (
                  <Tooltip
                    title={PD.de_EstatusCobranza}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_ColorCobranza}}>{PD.de_EstatusCobranza}</span>
                  </Tooltip>
                ),
                (
                  <div>
                    <Tooltip
                        title='Visualizar Factura PDF'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <IconButton style={{color:'#C62828'}} aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Descargar Factura XML'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <a href={PD.urlFacturaDownloadXML} ><IconButton style={{color:'#388E3C'}} aria-label="DescargarXML" aria-haspopup="true" className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton></a>
                    </Tooltip>
                  </div>
                )
              ]);
            } else if (tipoReporte === 'facturasListadoFacturaNota') {
              tableDataVAR.push([
                PD.nb_EmpresaPlaza,
                PD.fl_Pedido,
                PD.nb_Estacion,
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.nb_TipoDocumento}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.fl_FolioDocumento}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.fh_Documento}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.fh_Vencimiento}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.im_Total}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.im_SaldoFactura}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.nb_Moneda}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.Dvencidos}</label>),
                (<label style={{color: PD.de_ColorNota, fontSize: 12}}>{PD.DPorVencer}</label>),
                (
                  <Tooltip
                    title={PD.de_Estatus}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                  </Tooltip>
                ),
                (
                  <Tooltip
                    title={PD.de_EstatusCobranza}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_ColorCobranza}}>{PD.de_EstatusCobranza}</span>
                  </Tooltip>
                ),
                (
                  <div>
                    <Tooltip
                        title='Visualizar Factura PDF'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <IconButton style={{color:'#C62828'}} aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Descargar Factura XML'
                        placement={'bottom-start'}
                        enterDelay={300}>
                      <a href={PD.urlFacturaDownloadXML} ><IconButton style={{color:'#388E3C'}} aria-label="DescargarXML" aria-haspopup="true" className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton></a>
                    </Tooltip>
                  </div>
                )
              ]);
            }
          }
          let DataVAR = [];
          let columnsVAR = [];
          if (tipoReporte === 'facturasListadoPorDocumento') {
            for(i in res.data.data){
              PD = res.data.data[i];
              DataVAR.push([
                {value: (PD.nb_Empresa)?PD.nb_Empresa.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Sucursal)?PD.nb_Sucursal.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Estacion)?PD.nb_Estacion.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_Pedido)?PD.fl_Pedido.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_TipoDocumento)?PD.nb_TipoDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_FolioDocumento)?PD.fl_FolioDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Documento)?PD.fh_Documento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Vencimiento)?PD.fh_Vencimiento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.im_TotalSinFormato)?PD.im_TotalSinFormato.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.im_SaldoFacturaSinFormato)?PD.im_SaldoFacturaSinFormato.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.nb_Moneda)?PD.nb_Moneda.toString():'0', style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.DPorVencer)?PD.DPorVencer.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.Dvencidos)?PD.Dvencidos.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Estatus)?PD.de_Estatus.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_EstatusCobranza)?PD.de_EstatusCobranza.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_DocumentosRelacionados)?PD.nb_DocumentosRelacionados.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Domicilio)?PD.de_Domicilio.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              ]);
            }
            columnsVAR = [
              {value:'Empresa Petroil',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Plaza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estación',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Pedido',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Tipo Documentos',style: { wpx: '110', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Documento',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Documento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Vencimiento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Importe',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Saldo',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Moneda',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Dias Por Vencer',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Dias Vencidos',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Cobranza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Documentos Relacionados',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Domicilio de Entrega',style: { wpx: '500', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
            ]
          } else if (tipoReporte === 'facturasListadoDetallado'){
            for(i in res.data.data){
              PD = res.data.data[i];
              DataVAR.push([
                {value: (PD.nb_Empresa)?PD.nb_Empresa.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Sucursal)?PD.nb_Sucursal.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Estacion)?PD.nb_Estacion.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_Pedido)?PD.fl_Pedido.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_TipoDocumento)?PD.nb_TipoDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_FolioDocumento)?PD.fl_FolioDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Documento)?PD.fh_Documento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Vencimiento)?PD.fh_Vencimiento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Documento)?PD.fh_Documento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Producto)?PD.nb_Producto.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_UnidadDeMedida)?PD.nb_UnidadDeMedida.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nu_Cantidad)?PD.nu_Cantidad.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.im_TotalDetalleNeto)?PD.im_TotalDetalleNeto.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.nb_Moneda)?PD.nb_Moneda.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Estatus)?PD.de_Estatus.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_EstatusCobranza)?PD.de_EstatusCobranza.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_DocumentosRelacionados)?PD.nb_DocumentosRelacionados.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Domicilio)?PD.de_Domicilio.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              ]);
            }
            columnsVAR = [
              {value:'Empresa Petroil',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Plaza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estación',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Pedido',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Tipo Documentos',style: { wpx: '110', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Documento',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Documento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Vencimiento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Producto',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Unidad Medida',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Cantidad',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Importe',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Moneda',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Cobranza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Documentos Relacionados',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Domicilio de Entrega',style: { wpx: '500', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
            ]
          } else if (tipoReporte === 'facturasListadoFacturaNota'){
            for(i in res.data.data){
              PD = res.data.data[i];
              if(PD.de_ColorNota === '#000000de'){
								PD.de_ColorNota = '#000000';
							}
              DataVAR.push([
                {value: (PD.nb_Empresa)?PD.nb_Empresa.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Sucursal)?PD.nb_Sucursal.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Estacion)?PD.nb_Estacion.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_Pedido)?PD.fl_Pedido.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_TipoDocumento)?PD.nb_TipoDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.fl_FolioDocumento)?PD.fl_FolioDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.fh_Documento)?PD.fh_Documento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.fh_Vencimiento)?PD.fh_Vencimiento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.im_TotalSinFormato)?PD.im_TotalSinFormato.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.im_SaldoFacturaSinFormato)?PD.im_SaldoFacturaSinFormato.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.nb_Moneda)?PD.nb_Moneda.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.DPorVencer)?PD.DPorVencer.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.Dvencidos)?PD.Dvencidos.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.de_Estatus)?PD.de_Estatus.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.de_EstatusCobranza)?PD.de_EstatusCobranza.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10', color:{rgb: PD.de_ColorNota.replace('#','')} }}},
                {value: (PD.nb_DocumentosRelacionados)?PD.nb_DocumentosRelacionados.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Domicilio)?PD.de_Domicilio.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              ]);
            }
            columnsVAR = [
              {value:'Empresa Petroil',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Plaza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estación',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Pedido',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Tipo Documentos',style: { wpx: '110', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Documento',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Documento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Vencimiento',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Importe',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Saldo',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Moneda',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Dias Por Vencer',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Dias Vencidos',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Cobranza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Documentos Relacionados',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Domicilio de Entrega',style: { wpx: '500', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
            ]
          }

          var multiDataSetFacturas = [
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: ('Listado de Facturas - ' + ((tipoReporte === 'facturasListadoPorDocumento') ? 'Por Documentos' : (tipoReporte === 'facturasListadoDetallado') ? 'Detallado por Documento' : 'Por Facturas vs Notas')) },
                ],
              ]
            },
            {
              xSteps: 8,
              ySteps: -2,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: ((new Date()).toISOString().slice(0,10))+'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                ],
              ]
            },
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
              ],
              data: [
                [
                  {value:'Estatus Cobranza: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.nb_EstatusCobranza.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Tipo de Documento', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value:(this.state.de_Cl_Documento.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Inicio: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Inicio.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Estación:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value:(this.state.nb_Estacion_Rpt.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Fin: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Fin.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Plaza:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value:(this.state.nb_Plaza_Rpt.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Folio de Pedido: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.id_Pedido.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Folio de Documento:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fl_Documento.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Domicilio de Entrega: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.de_Domicilio_Rpt.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                ]
              ]
            },
            {
              xSteps: 0,
              ySteps: 1,
              columns: columnsVAR,
              data: DataVAR
            }
          ];
          this.setState({ multiDataSetExcel: multiDataSetFacturas });

          if(!tableDataVAR.length){
            this.showNotification("tr", 'No se encontraron Facturas')
          }
          this.setState({ tableData: tableDataVAR });
      }else{
        this.showNotification("tr", res.data.message)
      }

      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  handleClickOpenFactura = () => {
    this.setState({ openDialogFactura: true });
    this.buscar();
  };

  handleClickCloseFactura = () => {
    this.setState({ openDialogFactura: false });
  };

  buscarReferencias(){
    const { classes } = this.props;
    var obj = {
      id_Cliente : sesion.usuario.id_Cliente,
      fh_Inicio: this.state.fh_Inicio_Ref || null,
      fh_Fin: this.state.fh_Fin_Ref || null,
       // eslint-disable-next-line
      de_Referencia: this.state.de_Referencia || null,
      fh_Registro: this.state.fh_Registro_Ref || null,
      id_Estatus: this.state.id_Estatus_Ref ? this.state.id_Estatus_Ref:null,
      // eslint-disable-next-line
      fl_Pago: this.state.fl_Pago ? this.state.fl_Pago.replace(/[^0-9\.]+/g, ""): null,
      fh_Pago: this.state.fh_Pago || null,
      id_Estacion: this.state.id_Estacion_Ref ? this.state.id_Estacion_Ref:null,
      id_Usuario: sesion.usuario.id_Usuario,
    }

    if(obj.fh_Inicio === ''){
      obj.fh_Inicio = null;
    }

    if(obj.fh_Fin === ''){
      obj.fh_Fin = null;
    }

    if(obj.fh_Registro === ''){
      obj.fh_Registro = null;
    }

    if(obj.fh_Pago === ''){
      obj.fh_Pago = null;
    }
    this.setState({ tableDataReferencias: [] });

    for(var y in this.state.comboEstatus){
      if(this.state.comboEstatus[y].value===this.state.id_Estatus_Ref){
        this.setState({de_Estatus_Ref : this.state.comboEstatus[y].label})
      }
    }

    for(var x in this.state.estaciones){
      if(this.state.estaciones[x].value===this.state.id_Estacion_Ref){
        this.setState({de_Estatus_Ref : this.state.estaciones[x].label})
      }
    }

    this.setState({classMask:'maskProgress active'});
    invoke.post('reportes', 'detalladoReferenciasAsociados', obj).then(res => {
      if (res.status===200 && res.data.data) {
          var tableDataVAR = [];
          for (var i in res.data.data) {
            var PD = res.data.data[i];

              tableDataVAR.push([
                PD.nb_Empresa,
                PD.nb_Estacion || '',
                PD.de_Referencia,
                PD.fh_Vencimiento,
                (
                  <Tooltip
                    title={PD.de_Estatus}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                  </Tooltip>
                ),
                PD.fl_Pago,
                PD.fh_Pago,
                PD.fh_Aplicacion,
                PD.fl_FolioDocumento,
                PD.im_TotalFactura,
                PD.c_FormaPago,
                PD.nu_ConvenioH2H,
                (
                  <div>
                    <Tooltip
                      title='Descargar Factura PDF'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.ar_PDF ? PD.ar_PDF:''),(PD.ar_PDF ? PD.ar_PDF:''))} style={{width:'23px',height:'23px',color:'#C62828'}} className={classes.buttonTable}>
                        <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descargar Factura XML'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <a href={PD.ar_XML} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="DescargarXML" aria-haspopup="true" style={{width:'23px',height:'23px',color:'#388E3C'}} className={classes.buttonTable}>
                          <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                        </IconButton>
                      </a>
                    </Tooltip>
                  </div>
                )
              ]);
          }
          let DataVAR = [];
          let columnsVAR = [];
            for(i in res.data.data){
              PD = res.data.data[i];
              DataVAR.push([
                {value: (PD.nb_Empresa)?PD.nb_Empresa.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Estacion)?PD.nb_Estacion.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Referencia)?PD.de_Referencia.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Vencimiento)?PD.fh_Vencimiento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Estatus)?PD.de_Estatus.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_Pago)?PD.fl_Pago.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Pago)?PD.fh_Pago.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Aplicacion)?PD.fh_Aplicacion.toString():'', style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_FolioDocumento)?PD.fl_FolioDocumento.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.im_TotalFactura)?PD.im_TotalFactura.toString():'0', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.c_FormaPago)?PD.c_FormaPago.toString():'0', style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nu_ConvenioH2H)?PD.nu_ConvenioH2H.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              ]);
            }
            columnsVAR = [
              {value:'Empresa Petroil/Plaza',style: { wpx: '150', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estación',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Referencia',style: { wpx: '120', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Vencimiento Referencia',style: { wpx: '110', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Estatus Referencia',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Pago',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Depósito',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Fecha Aplicación	',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Folio Factura',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Total Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Tipo Pago',style: { wpx: '120', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Cuenta',style: { wpx: '120', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
            ]

          var multiDataSetReferncias = [
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: 'Reporte Detallado de Referencias', style: {font: { sz:'14', bold: true } } },
                ],
              ]
            },
            {
              xSteps: 8,
              ySteps: -2,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: ((new Date()).toISOString().slice(0,10))+'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                ],
              ]
            },
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
              ],
              data: [

                [
                  {value: 'Fecha Inicio Factura: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Inicio_Ref.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Fecha Fin Factura:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value:(this.state.fh_Fin_Ref.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Folio Referencia: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.de_Referencia.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Fecha Registro Referencia:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value:(this.state.fh_Registro_Ref.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Estatus Referencia: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.de_Estatus_Ref.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value:'Folio de Pago:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fl_Pago.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Pago: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Pago.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, 
                  {value: 'Estación: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.nb_Estacion_Ref.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                ]
              ]
            },
            {
              xSteps: 0,
              ySteps: 1,
              columns: columnsVAR,
              data: DataVAR
            }
          ];
          this.setState({ multiDataSetExcelReferencias: multiDataSetReferncias });

          if(!tableDataVAR.length){
            this.showNotification("tr", 'No se encontraron referencias')
          }
          this.setState({ tableDataReferencias: tableDataVAR });
      }else{
        this.showNotification("tr", res.data.message)
      }

      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  handleClickSearchReferencias = () => {
    this.buscarReferencias();
  };
  // Funcion para traducir los meses a español
  getNameMonth = (mes) => {
    for (let i = 0; i < Meses.length; i++) {
      if(i === mes){
        return Meses[i];
      }
    }
  };

  buscarJustificacion = () => {
    this.setState({ cData: {labels: [], datasets: []}});
    this.setState({ totalVentaM3: [] });
    let date = new Date();
    let inicio = new Date(this.state.fh_inicio_Month.slice(0,4), parseInt(this.state.fh_inicio_Month.slice(5,7)) - 1, 1);
    let fin = new Date(this.state.fh_fin_Month.slice(0,4), parseInt(this.state.fh_fin_Month.slice(5,7)), 0);

    this.setState({fh_inicio_MonthFormat : (' '+inicio.getDate()+' '+this.getNameMonth(parseInt(this.state.fh_inicio_Month.slice(5,7)) - 1)+' '+inicio.getFullYear())})
    this.setState({fh_fin_MonthFormat : (fin.getDate()+' '+this.getNameMonth(parseInt(this.state.fh_fin_Month.slice(5,7)) - 1)+' '+fin.getFullYear())})
    this.setState({fh_Impresion : (date.getDate()+' de '+ this.getNameMonth(parseInt(date.getMonth(), 0))+' de '+date.getFullYear())})

    this.setState({classMask:'maskProgress active'});
    let obj = {
      id_Cliente: sesion.estacionObj[0].id_Cliente, 
      id_Estacion: this.state.id_Estacion_Jus || null, 
      fh_Inicio: inicio || null,
      fh_Fin: fin || null,
    }

    invoke.post('reportes','getGraficoJustificacionFZDA', obj).then(res => {
      if(res.status===200 && res.data.data){
        let {labels, datasets} = this.state.cData;
        let Diesel = [];
        let Magna = [];
        let Premium = [];
        let meses = [];
        // Sacar datos para grafica 
        for(let i in res.data.data){
          let x = res.data.data[i];
          if(!labels.find(function(element) { return element === x.fh_Documento;})){
            labels.push(x.fh_Documento);
          }
          // Conocer meses filtrados 
          if(!meses.find(function(element) { return element === x.nu_Mes;})){
            meses.push(x.nu_Mes);
          } 
          Diesel.push(x.Diesel);
          Magna.push(x.Gasolina_Magna);
          Premium.push(x.Gasolina_Premium);
        }
        datasets.push({label: 'Diesel',  data: Diesel,  borderColor: 'Black', backgroundColor: 'Black', fill: false });
        datasets.push({label: 'Magna',   data: Magna,   borderColor: 'Green', backgroundColor: 'Green', fill: false });
        datasets.push({label: 'Premium', data: Premium, borderColor: 'Red',   backgroundColor: 'Red',   fill: false });

        // Calcular datos de tabla del pdf 
        meses.forEach(element => {
          let arrayMes = res.data.data.filter((item)=> item.nu_Mes === element); 
          let suma = 0;
          arrayMes.forEach(e => { 
              suma += e.Diesel + e.Gasolina_Magna + e.Gasolina_Premium
          });
          this.state.totalVentaM3.push({mes: this.getNameMonth(element - 1), nu_Cantidad: (suma/1000)});
        });
        // Validamos que haya venta
        let venta = this.state.totalVentaM3.reduce((acumulator, current) => acumulator + current.nu_Cantidad, 0); 
        if( venta <= 0 ){
          datasets = [];
          this.mensajeLog("required_id_Ventas", 'No existen ventas que mostrar en el rango seleccionado');
          this.hideMaskProgress();
        }
        this.setState({ ventas: datasets });
        this.setState({ cData: {labels, datasets}});
      }else{
        this.showNotification("tr", res.message)
      }
      this.hideMaskProgress();
    });
  };

  buscarRestituciones = () => {
    this.setState({ cData: {labels: [], datasets: []}});

    let date = new Date();
    let inicio = new Date(this.state.fh_inicio_Restitucion.slice(0,4), parseInt(this.state.fh_inicio_Restitucion.slice(5,7)) - 1, 1);
    let fin = new Date(this.state.fh_fin_Restitucion.slice(0,4), parseInt(this.state.fh_fin_Restitucion.slice(5,7)), 0);

    this.setState({fh_inicio_MonthFormatREST : (this.getNameMonth(parseInt(this.state.fh_inicio_Restitucion.slice(5,7)) - 1)+' '+inicio.getFullYear())})
    this.setState({fh_fin_MonthFormatREST : (this.getNameMonth(parseInt(this.state.fh_fin_Restitucion.slice(5,7)) - 1)+' '+fin.getFullYear())})
    this.setState({fh_ImpresionREST : (date.getDate()+' de '+ this.getNameMonth(parseInt(date.getMonth(), 0))+' de '+date.getFullYear())})
    this.setState({classMask:'maskProgress active'});

    let obj = {
      id_Cliente: sesion.estacionObj[0].id_Cliente, 
      id_Estacion: sesion.estacionObj[0].id_Estacion,
      fh_Inicio: inicio,
      fh_Fin: fin,
    }

    invoke.post('reportes','getListadoRestituciones', obj).then(res => {
      if(res.status===200 && res.data.data){
        var DataRestituciones = [];
        for (var i in res.data.data) {
          var PD = res.data.data[i];
              DataRestituciones.push([
                PD.fh_OperacionPemex,
                PD.nu_PermisoCRE,
                PD.nu_ComprobanteCargaPemex,
                PD.fl_RemisionPemex,
                PD.nb_ProductoPemex,
                PD.nu_VolumenComprobante,
                PD.nu_VolumenTotalFactura,
                PD.nu_VolumenRestituir,
              ]);
          }

          let DataREST = [];
          let columnsREST = [];
            for(i in res.data.data){
              PD = res.data.data[i];
              DataREST.push([
                {value: (PD.fh_OperacionPemex)?PD.fh_OperacionPemex.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nu_PermisoCRE)?PD.nu_PermisoCRE.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nu_ComprobanteCargaPemex)?PD.nu_ComprobanteCargaPemex.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_RemisionPemex)?PD.fl_RemisionPemex.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_ProductoPemex)?PD.nb_ProductoPemex.toString():'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nu_VolumenComprobante)?PD.nu_VolumenComprobante.toString():'', style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.nu_VolumenTotalFactura)?PD.nu_VolumenTotalFactura.toString():'', style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.nu_VolumenRestituir)?PD.nu_VolumenRestituir.toString():'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
              ]);
            }
            if(PD){
              DataREST.push([
                {value:''},
                {value:''},
                {value:''},
                {value:''},
                {value:'TOTALES: ', style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true }}},
                {value: (PD.nu_TotalVolComprobante)?PD.nu_TotalVolComprobante.toString():'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.nu_TotalVolFacturas)?PD.nu_TotalVolFacturas.toString():'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.nu_TotalVolRestituir)?PD.nu_TotalVolRestituir.toString():'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
              ]);
            }
            columnsREST = [
              {value:'Fecha operación',style: { wpx: '80', hpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Permiso CRE',style: { wpx: '150', hpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Referencia',style: { wpx: '150', hpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Remisión',style: { wpx: '70', hpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Producto',style: { wpx: '120', hpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Vol. Comprobante de carga',style: { wpx: '150', hpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Vol. Total de factura',style: { wpx: '120', hpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              {value:'Vol. A restituir',style: { wpx: '120', hpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}}
            ]

          var DataSetExcelRestituciones = [
            {
              xSteps: 3,
              ySteps: 0,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: 'Volumen Restituido', style: {font: { sz:'18', bold: true}, alignment:{vertical:'center', horizontal:'center'},}},
                ],
              ]
            },
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
              ],
              data: [
                [
                  {value: 'Mes Inicio: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_inicio_MonthFormatREST.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''},
                  {value: 'Fecha de Impresión: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: this.state.fh_ImpresionREST +'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                ],
                [
                  {value:'Mes fin:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value:(this.state.fh_fin_MonthFormatREST.toString()), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''},
                ],
              ]
            },
            {
              xSteps: 0,
              ySteps: 1,
              columns: columnsREST,
              data: DataREST
            },
          ];
          this.setState({ multiDataSetExcelRestituciones: DataSetExcelRestituciones });
          if(!DataRestituciones.length){
            this.showNotification("tr", 'No se encontraron restituciones en el rango seleccionado')
          }
          this.setState({ tableDataRestituciones: DataRestituciones });
      }else{
        this.showNotification("tr", res.message)
      }
      this.hideMaskProgress();
    });
  };

  hideMaskProgress(){
    setTimeout(function() {
      this.setState({classMask:'maskProgress desactive'});
      setTimeout(function() {
          this.setState({classMask:'maskProgress'});
      }.bind(this), 1);
    }.bind(this), 1);
  }

  handleClickSearchJustificacion = () => {
    let fh_fin = this.state.fh_fin_Month;
    let fh_ini = this.state.fh_inicio_Month;
    let dif_m = Math.abs(parseInt(fh_fin.slice(5,7)) - parseInt(fh_ini.slice(5,7)));
    let dif_a = Math.abs(parseInt(fh_fin.slice(0,4)) - parseInt(fh_ini.slice(0,4)));
    
    // Validamos que solo se pueda elegir un rango de dos meses
    if((dif_a === 0 && dif_m > 1)   || //Mismo año 
      (dif_a === 1 && dif_m !== 11) || // Un año de diferencia, se valida con 11 cuando se selecciona Dic-Enero de años continuos
      (dif_a > 1 && dif_m < 1)      || // Meses continuos con años no continuos
      (dif_a > 1)                      // Años no continuos     
    ) {
      this.mensajeLog("required_id_Meses", 'No es posible seleccionar un rango mayor a dos meses')
      return false;
    }
    this.buscarJustificacion();
  };

  handleClickSearchRestituciones = () => {
    this.setState({ tableDataRestituciones: [] });
    let fh_fin = this.state.fh_fin_Restitucion;
    let fh_ini = this.state.fh_inicio_Restitucion;
    let dif_m = Math.abs(parseInt(fh_fin.slice(5,7)) - parseInt(fh_ini.slice(5,7)));
    let dif_a = Math.abs(parseInt(fh_fin.slice(0,4)) - parseInt(fh_ini.slice(0,4)));
    
    if( parseInt(fh_ini.slice(5,7)) > parseInt(fh_fin.slice(5,7) )  ){
      this.mensajeLog("required_idMesesRestituciones", 'La fecha de inicio es mayor a la fecha fin')
      return false;
    }
    // Validamos que solo se pueda elegir un rango de dos meses
    if((dif_a === 0 && dif_m > 1)   || //Mismo año 
      (dif_a === 1 && dif_m !== 11) || // Un año de diferencia, se valida con 11 cuando se selecciona Dic-Enero de años continuos
      (dif_a > 1 && dif_m < 1)      || // Meses continuos con años no continuos
      (dif_a > 1)                      // Años no continuos     
    ) {
      this.mensajeLog("required_idMesesRestituciones", 'No es posible seleccionar un rango mayor a dos meses')
      return false;
    }
    this.buscarRestituciones();
  };
  
  handleChange = name => event => {
    invoke.resetTime();
    this.setState({
      [name]: event.target.value,
    }, () => {
      if(name==='id_tipoReporte'){
        this.buscarFactura();
      }
    });
  };

  handleChangeValidate = (name,validate) => event => {
    invoke.resetTime();
    if(validate.maxLength && (event.target.value).length <= validate.maxLength){
      this.setState({
        [name]: event.target.value,
      });
    }
    else if(validate.date){
      var VAR = new Date((validate.min).toISOString().slice(0,10));
      var VAL = new Date(event.target.value);
      if(VAR <= VAL){
        this.setState({
          [name]: event.target.value,
        });
      }
    }
  };

  noOfDaysInMonth(){
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  getFirstDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      1,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  getLastDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      this.noOfDaysInMonth(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  showNotification(place,message,time=5000) {
    this.setState({message : message})
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  getInfoCliente(){
    let filtros = {
      id_Usuario : this.state.usuario.id_Usuario,
      id_Estacion : this.state.id_Estacion,
      id_Plaza : this.state.id_Plaza,
      id_Cliente : sesion.usuario.id_Cliente,
    }

    // console.log(filtros);

    var dataInfo = [];

    for(let i in this.state.estaciones){
      if(this.state.estaciones[i].value===this.state.id_Estacion){
        this.setState({nb_Estacion : this.state.estaciones[i].label})
      }
    }

    for(let i in this.state.plazas){
      if(this.state.plazas[i].value===this.state.id_Plaza){
        this.setState({nb_Plaza : this.state.plazas[i].label})
      }
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('usuario','getinfoCliente',filtros)
      .then(res => {
        if(res.status===200 && res.data.data){
          dataInfo = res.data.data[0];
          this.setState({info : res.data.data[0]}, () => {
            var tableDataClienteVAR = [];
            var tableDataDeudaVAR = [];
            tableDataClienteVAR.push([
              (<Contacts />),
              'Cliente',
              (<span style={{textAlign:'right',display:'block'}}>
              <span style={{textTransform: 'uppercase'}}>{sesion.usuario.de_RazonSocial}</span>
              </span>)
            ]);
            tableDataClienteVAR.push([
              (<RecentActors />),
              'RFC',
              (<span style={{textAlign:'right',display:'block'}}>
                {((dataInfo && dataInfo.de_RFC) ? dataInfo.de_RFC : '')}
              </span>)
            ]);
            tableDataClienteVAR.push([
              (<Business />),
              'Plazas(es)',
              (<span style={{textAlign:'right',display:'block'}}>
                {((dataInfo && this.state.nb_Plaza) ? this.state.nb_Plaza : '')}
              </span>)
            ]);
            tableDataClienteVAR.push([
              (<LocalGasStation />),
              'Estacion(es)',
              (<span style={{textAlign:'right',display:'block'}}>
                {((dataInfo && this.state.nb_Estacion) ? this.state.nb_Estacion : '')}
              </span>)
            ]);
            tableDataClienteVAR.push([
              (<TrendingUp />),
              'Linea de Credito',
              (<span style={{textAlign:'right',display:'block'}}>
                {"$ "+ ((dataInfo && dataInfo.im_LineaDeCredito)? parseFloat(dataInfo.im_LineaDeCredito).toLocaleString("en-US")  : '0')}
              </span>)
            ]);
            tableDataClienteVAR.push([
              (<Business />),
              'Empresa del Credito',
              (<span style={{textAlign:'right',display:'block'}}>
                {((dataInfo && dataInfo.nb_Empresa) ? dataInfo.nb_Empresa : '')}
              </span>)
            ]);
            tableDataClienteVAR.push([
              (<Today />),
              'Dias de Credito',
              (<span style={{textAlign:'right',display:'block'}}>
                {((dataInfo && dataInfo.nu_DiasCredito)? parseFloat(dataInfo.nu_DiasCredito).toLocaleString("en-US")  : '0') + ' Dias'}
              </span>)
            ]);
            this.setState({tableDataInfoCliente : tableDataClienteVAR})

            //---------------------------------------------------------------------------------------------------------------------------

            tableDataDeudaVAR.push([
              (<span></span>),
              (<b>Total Deuda</b>),
              (<span style={{textAlign:'right',display:'block'}}>
                {"$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoTotal_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoTotal).toLocaleString("en-US"))  : '0')}
              </span>)
            ]);
            tableDataDeudaVAR.push([
              (<span></span>),
              (<b><span style={{color:'rgb(0, 0, 0)',verticalAlign: 'text-top'}}><Opacity /></span> Total Deuda Diesel</b>),
              (<span style={{textAlign:'right',display:'block'}}><b>
                {"$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoTotalDiesel_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoTotalDiesel).toLocaleString("en-US"))  : '0')}
              </b></span>)
            ]);
            tableDataDeudaVAR.push([
              (<span></span>),
              (<b><span style={{color:'rgb(76, 175, 80)',verticalAlign: 'text-top'}}><Opacity /></span> Total Deuda 87 Octanos</b>),
              (<span style={{textAlign:'right',display:'block', color:'rgb(76, 175, 80)'}}><b>
                {"$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoTotalMagna_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoTotalMagna).toLocaleString("en-US"))  : '0')}
              </b></span>)
            ]);
            tableDataDeudaVAR.push([
              (<span></span>),
              (<b><span style={{color:'rgb(244, 67, 54)',verticalAlign: 'text-top'}}><Opacity /></span> Total Deuda 91 Octanos</b>),
              (<span style={{textAlign:'right',display:'block', color:'rgb(244, 67, 54)'}}><b>
                {"$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoTotalPremium_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoTotalPremium).toLocaleString("en-US"))  : '0')}
              </b></span>)
            ]);
            tableDataDeudaVAR.push([
              (<span></span>),
              (<b>Total Vencido</b>),
              (<span style={{textAlign:'right',display:'block'}}>
                {"$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVencido_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVencido).toLocaleString("en-US"))  : '0')}
              </span>)
            ]);
            tableDataDeudaVAR.push([
              (<span></span>),
              (<b>Total Vigente</b>),
              (<span style={{textAlign:'right',display:'block'}}>
                {"$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVigente_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVigente).toLocaleString("en-US"))  : '0')}
              </span>)
            ]);
            tableDataDeudaVAR.push([
              (<span></span>),
              (<b>1 - 30 Dias</b>),
              (<span style={{textAlign:'right',display:'block'}}>
                {"$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVencido30_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVencido30).toLocaleString("en-US"))  : '0')}
              </span>)
            ]);
            tableDataDeudaVAR.push([
              (<span></span>),
              (<b>31 - 60 Dias</b>),
              (<span style={{textAlign:'right',display:'block'}}>
                {"$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVencido60_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVencido60).toLocaleString("en-US"))  : '0')}
              </span>)
            ]);
            tableDataDeudaVAR.push([
              (<span></span>),
              (<b>61 - 90 Dias</b>),
              (<span style={{textAlign:'right',display:'block'}}>
                {"$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVencido90_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVencido90).toLocaleString("en-US"))  : '0')}
              </span>)
            ]);
            tableDataDeudaVAR.push([
              (<span></span>),
              (<b>Mas de 90 Dias</b>),
              (<span style={{textAlign:'right',display:'block'}}>
                {"$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVencido99_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVencido99).toLocaleString("en-US"))  : '0')}
              </span>)
            ]);
            this.setState({tableDataInfoDeuda : tableDataDeudaVAR});          
          });
        }

        let obj = {
          id_Empresa:   id_EmpresaVenta,
          id_Cliente:   sesion.usuario.id_Cliente,
          id_Estacion:  (this.state.id_Estacion)?this.state.id_Estacion:null,
          id_Plaza:  (this.state.id_Plaza)?this.state.id_Plaza:null,
          id_Usuario:   sesion.usuario.id_Usuario
        }
        //Combo de Estaciones
        invoke.post('reportes','estadoDeCuenta',obj).then(res => {
          if(res.status===200 && res.data.data){
    
            let empresaVAR = -1; //NUME
            let sucursalVAR = -1; //NUMS
            let estacionVAR = -1; //NUMES
            let clienteVAR = -1; //NUMC
            let newTableVAR = [];
            let newTableClienteVAR = [];
            let newTableEmpresaVAR = [];
            let newTableEstacionVAR = [];
            let newTableSucursalVAR = [];
            let countVAR = 0;
    
            let DataVAR = [];
            for(let i in res.data.data){
              let DC = res.data.data[i];
    
              if(DC.NUMES===1){
                estacionVAR = estacionVAR + 1; countVAR = 0;
              }
              if(DC.NUMS===1){
                sucursalVAR = sucursalVAR + 1; estacionVAR=0; countVAR = 0; newTableEstacionVAR=[];
              }
              if(DC.NUME===1){
                empresaVAR = empresaVAR + 1; countVAR = 0; sucursalVAR=0; estacionVAR=0; newTableSucursalVAR=[]; newTableEstacionVAR=[];
              }
              if(DC.NUMC===1){
                clienteVAR = clienteVAR + 1; countVAR = 0; empresaVAR=0; sucursalVAR=0; estacionVAR=0; newTableEmpresaVAR = []; newTableSucursalVAR=[]; newTableEstacionVAR=[];
              }
    
              if(DC.NUMC===1 || DC.NUME===1 || DC.NUMS===1 || DC.NUMES===1){
                newTableVAR=[];
                newTableVAR[countVAR] = DC;
                newTableEstacionVAR[estacionVAR] = newTableVAR;
                newTableSucursalVAR[sucursalVAR] = newTableEstacionVAR;
                newTableEmpresaVAR[empresaVAR] = newTableSucursalVAR;
                newTableClienteVAR[clienteVAR] = newTableEmpresaVAR;
              }
              else{
                newTableVAR[countVAR] = DC;
                newTableEstacionVAR[estacionVAR] = newTableVAR;
                newTableSucursalVAR[sucursalVAR] = newTableEstacionVAR;
                newTableEmpresaVAR[empresaVAR] = newTableSucursalVAR;
                newTableClienteVAR[clienteVAR] = newTableEmpresaVAR;
              }
              countVAR++;
            }
    
            for(var i1 in newTableClienteVAR){
              var item1 = newTableClienteVAR[i1];
              var cantidadC  = 0;
              var totalC     = 0;
              var cantidadCE = 0;
              var totalCE    = 0;
              var abonoC     = 0;
              var saldoC     = 0;
              var moratorioC = 0;
              for(var i2 in item1){
                var item2 = item1[i2];
                var cantidadE  = 0;
                var totalE     = 0;
                var cantidadEE = 0;
                var totalEE    = 0;
                var abonoE     = 0;
                var saldoE     = 0;
                var moratorioE = 0;
                for(var i3 in item2){
                  var item3 = item2[i3];
                  var cantidadS  = 0;
                  var totalS     = 0;
                  var cantidadSE = 0;
                  var totalSE    = 0;
                  var abonoS     = 0;
                  var saldoS     = 0;
                  var moratorioS = 0;
                  for(var i4 in item3){
                    var item4 = item3[i4];
                    var cantidadET  = 0;
                    var totalET     = 0;
                    var cantidadETE = 0;
                    var totalETE    = 0;
                    var abonoET     = 0;
                    var saldoET     = 0;
                    var moratorioET = 0;
    
                    for(var i in item4){
                      var item = item4[i];
                      var sn_Anticipadas = item.sn_Anticipadas;
                      var sn_Cuenta = item.sn_cuenta;
                      DataVAR.push([
                        {value: (item.nb_RazonSocial)?item.nb_RazonSocial:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.nb_Sucursal)?item.nb_Sucursal:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.nb_Estacion)?item.nb_Estacion:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.fl_FolioDocumento)?item.fl_FolioDocumento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.fl_FolioFiscal)?item.fl_FolioFiscal:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.fh_Documento)?item.fh_Documento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.fh_Vencimiento)?item.fh_Vencimiento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.fh_VentaRemision)?item.fh_VentaRemision:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.nb_FormaPago)?item.nb_FormaPago:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.c_MonedaSAT)?item.c_MonedaSAT:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
    
                        {value: (item.nu_CantidadEntregada)?item.nu_CantidadEntregada:0, style: {type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                        {value: (item.im_ImporteEntregado)?item.im_ImporteEntregado:0, style: {type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                        {value: (item.nu_Cantidad)?item.nu_Cantidad:0, style: {type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                        {value: (item.im_Total)?item.im_Total:0, style: {type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                        {value: (item.im_Abono)?item.im_Abono:0, style: {type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                        {value: (item.fh_Aplicacion)?item.fh_Aplicacion:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.im_SaldoFactura)?item.im_SaldoFactura:0, style: {type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                        {value: (item.Dvencidos)?item.Dvencidos:0, style: {type:'number', numFmt: "0",alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.DTRANSCURRIDOS)?item.DTRANSCURRIDOS:0, style: {type:'number', numFmt: "0",alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                        {value: (item.Moratorios)?item.Moratorios:0, style: {type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}}
                      ]);
    
                      var cantidad    = item.nu_Cantidad;
                      var total       = item.im_Total;
                      var cantidadEN  = item.nu_CantidadEntregada;
                      var totalEN     = item.im_ImporteEntregado;
                      var abono       = item.im_Abono;
                      var saldo       = item.im_SaldoFactura;
                      var moratorio   = item.Moratorios;
    
                      if (sn_Anticipadas === 1 && sn_Cuenta === 1){
                        saldoS     = saldoS + saldo;
                        saldoE     = saldoE + saldo;
                        saldoC     = saldoC + saldo;
                        saldoET    = saldoET + saldo;
                      }
                      if (sn_Anticipadas === 1 && sn_Cuenta === 0){
                        cantidadS  = cantidadS + cantidad;
                        totalS     = totalS + total;
                        cantidadSE = cantidadSE + cantidadEN;
                        totalSE    = totalSE + totalEN;
                        abonoS     = abonoS + abono;
                        moratorioS = moratorioS + moratorio;
    
                        cantidadE  = cantidadE + cantidad;
                        totalE     = totalE + total;
                        cantidadEE = cantidadEE + cantidadEN;
                        totalEE    = totalEE + totalEN;
                        abonoE     = abonoE + abono;
                        moratorioE = moratorioE + moratorio;
    
                        cantidadC  = cantidadC + cantidad;
                        totalC     = totalC + total;
                        cantidadCE = cantidadCE + cantidadEN;
                        totalCE    = totalCE + totalEN;
                        abonoC     = abonoC + abono;
                        moratorioC = moratorioC + moratorio;
    
                        cantidadET  = cantidadET + cantidad;
                        totalET     = totalET + total;
                        cantidadETE = cantidadETE + cantidadEN;
                        totalETE    = totalETE + totalEN;
                        abonoET     = abonoET + abono;
                        moratorioET = moratorioET + moratorio;
                      }
                      if (sn_Anticipadas === 0 && sn_Cuenta === 1){
                        cantidadS  = cantidadS + cantidad;
                        totalS     = totalS + total;
                        cantidadSE = cantidadSE + cantidadEN;
                        totalSE    = totalSE + totalEN;
                        abonoS     = abonoS + abono;
                        saldoS     = saldoS + saldo;
                        moratorioS = moratorioS + moratorio;
    
                        cantidadE  = cantidadE + cantidad;
                        totalE     = totalE + total;
                        cantidadEE = cantidadEE + cantidadEN;
                        totalEE    = totalEE + totalEN;
                        abonoE     = abonoE + abono;
                        saldoE     = saldoE + saldo;
                        moratorioE = moratorioE + moratorio;
    
                        cantidadC  = cantidadC + cantidad;
                        totalC     = totalC + total;
                        cantidadCE = cantidadCE + cantidadEN;
                        totalCE    = totalCE + totalEN;
                        abonoC     = abonoC + abono;
                        saldoC     = saldoC + saldo;
                        moratorioC = moratorioC + moratorio;
    
                        cantidadET  = cantidadET + cantidad;
                        totalET     = totalET + total;
                        cantidadETE = cantidadETE + cantidadEN;
                        totalETE    = totalETE + totalEN;
                        abonoET     = abonoET + abono;
                        saldoET     = saldoET + saldo;
                        moratorioET = moratorioET + moratorio;
                      }
                    }
                    DataVAR.push([
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value:'Total por Estacion', style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true }}},
                      {value: (cantidadETE)?cantidadETE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                      {value: (totalETE)?totalETE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                      {value: (cantidadET)?cantidadET:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                      {value: (totalET)?totalET:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                      {value: (abonoET)?abonoET:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value: (saldoET)?saldoET:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}}},
                      {value: (moratorioET)?moratorioET:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFffb74d"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}}
                    ]);
                  }
                  DataVAR.push([
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value:'Total por Sucural', style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true }}},
                    {value: (cantidadSE)?cantidadSE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                    {value: (totalSE)?totalSE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                    {value: (cantidadS)?cantidadS:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                    {value: (totalS)?totalS:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                    {value: (abonoS)?abonoS:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value: (saldoS)?saldoS:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}}},
                    {value: (moratorioS)?moratorioS:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF81D4FA"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}}
                  ]);
                }
                DataVAR.push([
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value:'Total por Empresa', style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true }}},
                  {value: (cantidadEE)?cantidadEE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value: (totalEE)?totalEE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value: (cantidadE)?cantidadE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value: (totalE)?totalE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value: (abonoE)?abonoE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value: (saldoE)?saldoE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}}},
                  {value: (moratorioE)?moratorioE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFEE58"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}}
                ]);
              }
              DataVAR.push([
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value:'Total por Cliente', style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true }}},
                {value: (cantidadCE)?cantidadCE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value: (totalCE)?totalCE:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value: (cantidadC)?cantidadC:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value: (totalC)?totalC:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value: (abonoC)?abonoC:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value: (saldoC)?saldoC:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value:'',style:{fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}}},
                {value: (moratorioC)?moratorioC:0, style: {fill: {patternType: "solid", fgColor: {rgb: "FF4caf50"}}, type:'number', numFmt: "#,##0.00", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true }}}
              ]);
            }
            var multiDataSet = [
                {
                  xSteps: 5,
                  ySteps: 0,
                  columns: [
                    {value:''},
                  ],
                  data: [
                    [
                      {value: 'Estado de cuenta', style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'18', bold: true } }},
    
                    ],
                    [
                      {value: sesion.usuario.de_RazonSocial, style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'14', bold: false } }},
                    ],
                  ]
                },
                {
                  xSteps: 10,
                  ySteps: -3,
                  columns: [
                    {value:''},
                  ],
                  data: [
                    [
                      {value: ((new Date()).toISOString().slice(0,10))+'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'14', bold: true } }},
                    ],
                  ]
                },
                {
                  xSteps: 2,
                  ySteps: 0,
                  columns: [
                    {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                    {value:'Diésel', style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'12', color:{rgb: "FF000000"}, bold: true } }},
                    {value:'87 Octanos', style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'12', color:{rgb: "FF4fa953"}, bold: true } }},
                    {value:'91 Octanos', style: { alignment:{vertical:'center', horizontal:'center'}, font: { sz:'12', color:{rgb: "FFf44336"}, bold: true } }},
                  ],
                  data: [
                    [
                      {value: 'Cliente:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: sesion.usuario.de_RazonSocial, style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                      {},{},{},{},{},
                      {value: 'Total Deuda:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: "$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoTotal_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoTotal).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                      {value: "$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoTotalDiesel_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoTotalDiesel).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                      {value: "$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoTotalMagna_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoTotalMagna).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                      {value: "$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoTotalPremium_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoTotalPremium).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                    ],
                    [
                      {value: 'RFC:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: ((dataInfo && dataInfo.de_RFC) ? dataInfo.de_RFC : ''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                      {},{},{},{},{},
                      {value: 'Total Vencido:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: "$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVencido_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVencido).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                      {},{},{},
                    ],
                    [
                      {value: 'Plazas:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: ((dataInfo && this.state.nb_Plaza) ? this.state.nb_Plaza : ''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                      {},{},{},{},{},
                      {value: 'Total Vigente:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: "$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVigente_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVigente).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                      {},{},{},
                    ],
                    [
                      {value: 'Estaciones:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: ((dataInfo && this.state.nb_Estacion) ? this.state.nb_Estacion : ''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                      {},{},{},{},{},
                      {value: '1-30 Dias:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: "$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVencido30_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVencido30).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                      {},{},{},
                    ],
                    [
                      {value: 'Linea de Credito:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: "$ "+ ((dataInfo && dataInfo.im_LineaDeCredito)? parseFloat(dataInfo.im_LineaDeCredito).toLocaleString("en-US")  : '0'), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                      {},{},{},{},{},
                      {value: '31-60 Dias:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: "$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVencido60_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVencido60).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                      {},{},{},
                    ],
                    [
                      {value: 'Empresa del Credito:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: ((dataInfo && dataInfo.nb_Empresa) ? dataInfo.nb_Empresa : ''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                      {},{},{},{},{},
                      {value: '61-90 Dias:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: "$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVencido90_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVencido90).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                      {},{},{},
                    ],
                    [
                      {value: 'Dias de Credito:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: ((dataInfo && dataInfo.nu_DiasCredito)? parseFloat(dataInfo.nu_DiasCredito).toLocaleString("en-US")  : '0') + ' Dias', style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'12', bold: false } }},
                      {},{},{},{},{},
                      {value: 'Mas de 90 Dias:', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: true } }},
                      {value: "$"+ ((dataInfo)? ((this.state.id_Estacion)?parseFloat(dataInfo.im_SaldoVencido99_Estacion).toLocaleString("en-US"):parseFloat(dataInfo.im_SaldoVencido99).toLocaleString("en-US"))  : '0'), style: {alignment:{vertical:'center', horizontal:'right'}, font: { sz:'12', bold: false } }},
                      {},{},{},
                    ],
                  ]
                },
                {
                  xSteps: 0,
                  ySteps: 1,
                  columns: [
                    {value:'Empresa',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Sucursal',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Estacion',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Folio Doc.',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Folio Fiscal',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'FDocumento',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Vencimiento',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'FHVenta',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'FPago',style: { wpx: '65', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Moneda',style: { wpx: '65', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Ltr Entregados',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Imp Entregado',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Ltr Facturados',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Imp Total Facturado',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Abonos',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Aplicacion',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Saldo Pendiente',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'DVenc',style: { wpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'DTrans',style: { wpx: '50', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                    {value:'Moratorios',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}}
                  ],
                  data: DataVAR
                }
              ];
              this.setState({ multiDataSet: multiDataSet }, () => {
                setTimeout(function() {
                  this.setState({classMask:'maskProgress desactive'});
                  setTimeout(function() {
                      this.setState({classMask:'maskProgress'});
                  }.bind(this), 300);
                }.bind(this), 300);
              });
          }else{
            this.showNotification("tr", res.message)
          }
        });

    })

  }

  getCombosFacturas(){
    invoke.post('reportes','cldocumentosClientes',{id_Cliente: sesion.usuario.id_Cliente}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value:0,label:'Todos'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.cl_TipoDocumento,
            label: x.nb_TipoDocumento,
            obj: x
          });
        }
        this.setState({ documentos: VAR });
        this.setState({ cl_documento: 0 });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    invoke.post('facturas','estatusId',{id_Inicial: 1511,id_Final: 1513}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value:0,label:'Todas'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Estatus,
            label: x.de_Estatus,
            obj: x
          });
        }
        this.setState({ estatuscobranza: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    invoke.post('reportes','domiciliosClientes',{id_Cliente: sesion.usuario.id_Cliente}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value:0,label:'Todos'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Domicilio,
            label: x.de_Domicilio,
            obj: x
          });
        }
        this.setState({ domiciliosEntrega: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });
  }
  handleChangeIndex = index => {
    invoke.resetTime();
    this.setState({ value: index });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            {/* Icono de descarga */}
            <ItemGrid xs={12} sm={12} md={12}>
              <Grid container>
                  <ItemGrid xs={11} sm={11} md={11}></ItemGrid>
                  <ItemGrid xs={1} sm={1} md={1}>
                    {(this.state.expandedEstadoCuenta)?(
                      <ExcelFile element={
                        <div>
                          <Tooltip
                            title={'Descargar Estado de Cuenta'}
                            placement={'bottom-start'}
                            enterDelay={100}
                          >
                            <IconButton
                                aria-label="Excel"
                                aria-haspopup="true"
                                style={{top:'50px', zIndex: 1}}
                                className={classes.buttonLink}>
                              <Assessment className={classes.links} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      }
                      filename="EstadoDeCuenta">
                      <ExcelSheet dataSet={this.state.multiDataSet} name="Estado de Cuenta"/>
                      </ExcelFile>
                    ):null}
                  </ItemGrid>
              </Grid>
            </ItemGrid>
            <ExpansionCard 
              defaultExpanded={true}
              icon={Assessment}
              iconColor="green"
              cardTitle="Reporte Estado de Cuenta"
              cardSubtitle='Información de adeudo del Cliente'
              expanded={this.state.expandedEstadoCuenta}
              content={(<div>
                <div className={classes.backForm}>
                <MuiThemeProvider theme={theme}>
                  <Grid container>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="id_Estacion"
                        select
                        label="Estación"
                        className={classes.textField}
                        value={this.state.id_Estacion}
                        onChange={this.handleChange('id_Estacion')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.estaciones.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="id_Plaza"
                        select
                        label="Plazas"
                        className={classes.textField}
                        value={this.state.id_Plaza}
                        onChange={this.handleChange('id_Plaza')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.plazas.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                    <ItemGrid xs={1} sm={1} md={1}>
                      <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                        <IconButton
                            color="primary"
                            aria-label="Agregar"
                            aria-haspopup="true"
                            style={{marginTop:'25px'}}
                            onClick={this.handleClickSearch}>
                          <Search className={classes.links} />
                        </IconButton>
                      </Tooltip>
                    </ItemGrid>
                  </Grid>
                  </MuiThemeProvider>
                </div>
                <br/>
                <Grid container>
                  <ItemGrid xs={12} sm={6} md={6}>
                    <TableCustom xs={12} sm={12} md={12}
                        tableHead={[]}
                        tableData={this.state.tableDataInfoCliente}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} md={6}>
                    <TableCustom xs={12} sm={12} md={12}
                        tableHead={[]}
                        tableData={this.state.tableDataInfoDeuda}
                    />
                  </ItemGrid>
                </Grid>
              </div>)}
              expansionAction= { (e) => { this.setState({ expandedEstadoCuenta : true })} }
              expationClose= { (e) => { this.setState({ expandedEstadoCuenta : false })} }
            />
          </ItemGrid>
          {/* REPORTE DE FACTURAS */}
          <ItemGrid xs={12} sm={12} md={12}>
            {/* Icono de descarga */}
            <ItemGrid xs={12} sm={12} md={12}>
              <Grid container>
                <ItemGrid xs={11} sm={11} md={11}></ItemGrid>
                <ItemGrid xs={1} sm={1} md={1}>
                  <div>
                    {(this.state.tableData.length > 0 && this.state.expandedFacturasCliente)?(
                      <ExcelFile element={
                      <div>
                        <Tooltip
                          title={'Descargar Listado Facturas'}
                          placement={'bottom-start'}
                          enterDelay={100}
                        >
                          <IconButton
                            aria-label="Excel"
                            aria-haspopup="true"
                            style={{top:'50px', zIndex: 1}}
                            className={classes.buttonLink}>
                            <Assessment className={classes.links} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    }
                    filename="Facturas">
                    <ExcelSheet dataSet={this.state.multiDataSetExcel} name="Facturas" />
                    </ExcelFile>
                    ):null}
                  </div>
                </ItemGrid>
              </Grid>
            </ItemGrid>
            <ExpansionCard defaultExpanded={true}
              icon={Assignment}
              iconColor="blue"
              cardTitle="Facturas del cliente"
              cardSubtitle='Información de facturas del Cliente'
              expanded={this.state.expandedFacturasCliente}
              content={(<div>
                <div className={classes.backForm}>
                  <MuiThemeProvider theme={theme}>
                    <Grid container>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Inicio"
                          label="Fecha Factura Inicio"
                          type="date"
                          value={this.state.fh_Inicio}
                          onChange={this.handleChange('fh_Inicio')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Fin"
                          label="Fin"
                          type="date"
                          value={this.state.fh_Fin}
                          onChange={this.handleChange('fh_Fin')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="cl_Documento"
                          select
                          label="Tipo de Documento"
                          className={classes.textField}
                          value={this.state.cl_Documento}
                          onChange={this.handleChange('cl_Documento')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.documentos.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="id_EstatusCobranza"
                          select
                          label="Estatus de Cobranza"
                          className={classes.textField}
                          value={this.state.id_EstatusCobranza}
                          onChange={this.handleChange('id_EstatusCobranza')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.estatuscobranza.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                    </Grid>

                    <Grid container>
                      <ItemGrid xs={12} sm={12} md={3}>
                        <TextField
                          id="id_Estacion"
                          select
                          label="Estación"
                          className={classes.textField}
                          value={this.state.id_Estacion_RP}
                          onChange={this.handleChange('id_Estacion_RP')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.estaciones.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="id_Plaza"
                          select
                          label="Plaza"
                          className={classes.textField}
                          value={this.state.id_Plaza_RP}
                          onChange={this.handleChange('id_Plaza_RP')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.plazas.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={3}>
                        <TextField
                          id="name"
                          label="Folio de Pedido"
                          className={classes.textField}
                          value={this.state.id_Pedido}
                          onChange={this.handleChangeValidate('id_Pedido',{'maxLength':10})}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={3}>
                        <TextField
                          id="name"
                          label="Folio de Documento"
                          className={classes.textField}
                          value={this.state.fl_Documento}
                          onChange={this.handleChangeValidate('fl_Documento',{'maxLength':10})}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                    </Grid>
                    <Grid container>
                      <ItemGrid xs={12} sm={12} md={6}>
                        <TextField
                          id="id_Domicilio"
                          select
                          label="Domicilio de Entrega"
                          className={classes.textField}
                          value={this.state.id_Domicilio}
                          onChange={this.handleChange('id_Domicilio')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.domiciliosEntrega.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                    </Grid>
                    <br/>
                    <Grid container>
                      <ItemGrid xs={11} sm={7} md={7}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Tipo de Reporte</FormLabel>
                          <RadioGroup
                            aria-label="Tipo de Reporte"
                            name="gender1"
                            className={classes.group}
                            value={this.state.id_tipoReporte}
                            onChange={this.handleChange('id_tipoReporte')}
                            row
                          >
                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Por Documento" />
                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Detallado por Documento" />
                            <FormControlLabel value="3" control={<Radio color="primary"/>} label="Por Factura vs Nota" />
                          </RadioGroup>
                        </FormControl>
                      </ItemGrid>
                      <ItemGrid xs={1} sm={1} md={1}>
                        <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                          <IconButton
                              color="primary"
                              style={{marginTop: '30px'}}
                              aria-label="Agregar"
                              aria-haspopup="true"
                              onClick={this.handleClickSearchFacturas}>
                            <Search />
                          </IconButton>
                        </Tooltip>
                      </ItemGrid>
                    </Grid>
                  </MuiThemeProvider>
                </div>

                <br />

                {(this.state.id_tipoReporte==='1')?(
                  <Grid container>
                    <Table xs={12} sm={12} md={12}
                      tableHead={[
                        { id: 'nb_EmpresaPlaza', numeric: false, disablePadding: false, label: 'Empresa Petroil/Plaza' },
                        { id: 'fl_Pedido', numeric: false, disablePadding: false, label: 'Folio Pedido' },
                        { id: 'nb_Estación', numeric: false, disablePadding: false, label: 'Estación' },
                        { id: 'cl_TipoDocumento', numeric: false, disablePadding: false, label: 'Tipo Documento' },
                        { id: 'fl_Documento', numeric: false, disablePadding: false, label: 'Folio Documento' },
                        { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Fecha Documento' },
                        { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Fecha Vencimiento' },
                        { id: 'im_Importe', numeric: false, disablePadding: false, label: 'Importe' },
                        { id: 'im_Saldo', numeric: false, disablePadding: false, label: 'Saldo' },
                        { id: 'nb_Moneda', numeric: false, disablePadding: false, label: 'Moneda' },
                        { id: 'DPorVencer', numeric: false, disablePadding: false, label: 'Dias por Vencer' },
                        { id: 'Dvencidos', numeric: false, disablePadding: false, label: 'Dias Vencidos' },
                        { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Factura' },
                        { id: 'de_EstatusCobranza', numeric: false, disablePadding: false, label: 'Estatus Cobranza' },
                        { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                      ]}
                      tableData={this.state.tableData}
                    />
                  </Grid>):(this.state.id_tipoReporte==='2')?(
                  <Grid container>
                    <Table xs={12} sm={12} md={12}
                      tableHead={[
                        { id: 'nb_EmpresaPlaza', numeric: false, disablePadding: false, label: 'Empresa Petroil/Plaza' },
                        { id: 'fl_Pedido', numeric: false, disablePadding: false, label: 'Folio Pedido' },
                        { id: 'nb_Estación', numeric: false, disablePadding: false, label: 'Estación' },
                        { id: 'cl_TipoDocumento', numeric: false, disablePadding: false, label: 'Tipo Documento' },
                        { id: 'fl_Documento', numeric: false, disablePadding: false, label: 'Folio Documento' },
                        { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Fecha Documento' },
                        { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Fecha Vencimiento' },
                        { id: 'nb_Producto', numeric: false, disablePadding: false, label: 'Producto' },
                        { id: 'nb_UnidadMedida', numeric: false, disablePadding: false, label: 'Unidad Medida' },
                        { id: 'nu_Cantidad', numeric: false, disablePadding: false, label: 'Cantidad' },
                        { id: 'im_Importe', numeric: false, disablePadding: false, label: 'Importe' },
                        { id: 'nb_Moneda', numeric: false, disablePadding: false, label: 'Moneda' },
                        { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Factura' },
                        { id: 'de_EstatusCobranza', numeric: false, disablePadding: false, label: 'Estatus Cobranza' },
                        { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                      ]}
                      tableData={this.state.tableData}
                    />
                  </Grid>):(this.state.id_tipoReporte==='3')?(
                  <Grid container>
                    <Table xs={12} sm={12} md={12}
                      tableHead={[
                        { id: 'nb_EmpresaPlaza', numeric: false, disablePadding: false, label: 'Empresa Petroil/Plaza' },
                        { id: 'fl_Pedido', numeric: false, disablePadding: false, label: 'Folio Pedido' },
                        { id: 'nb_Estación', numeric: false, disablePadding: false, label: 'Estación' },
                        { id: 'cl_TipoDocumento', numeric: false, disablePadding: false, label: 'Tipo Documento' },
                        { id: 'fl_Documento', numeric: false, disablePadding: false, label: 'Folio Documento' },
                        { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Fecha Documento' },
                        { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Fecha Vencimiento' },
                        { id: 'im_Importe', numeric: false, disablePadding: false, label: 'Importe' },
                        { id: 'im_Saldo', numeric: false, disablePadding: false, label: 'Saldo' },
                        { id: 'nb_Moneda', numeric: false, disablePadding: false, label: 'Moneda' },
                        { id: 'DPorVencer', numeric: false, disablePadding: false, label: 'Dias por Vencer' },
                        { id: 'Dvencidos', numeric: false, disablePadding: false, label: 'Dias Vencidos' },
                        { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Factura' },
                        { id: 'de_EstatusCobranza', numeric: false, disablePadding: false, label: 'Estatus Cobranza' },
                        { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                      ]}
                      tableData={this.state.tableData}
                    />
                  </Grid>):null}
              </div>)}
              expansionAction={(e) => { this.setState({ expandedFacturasCliente : true })} }
              expationClose={(e) => { this.setState({ expandedFacturasCliente : false })} }
            />
          </ItemGrid>
          
          {/* REPORTE DE REFERENCIAS BANCARIAS DETALLADO*/}
          <ItemGrid xs={12} sm={12} md={12}>
            {/* Icono de descarga */}
            <ItemGrid xs={12} sm={12} md={12}>
              <Grid container>
                <ItemGrid xs={11} sm={11} md={11}></ItemGrid>
                <ItemGrid xs={1} sm={1} md={1}>
                  <div>
                    {(this.state.tableDataReferencias.length > 0 && this.state.expandedReferenciasCliente)?(
                      <ExcelFile element={
                      <div>
                        <Tooltip
                          title={'Descargar Listado Referencias'}
                          placement={'bottom-start'}
                          enterDelay={100}
                        >
                          <IconButton
                            aria-label="Excel"
                            aria-haspopup="true"
                            style={{top:'50px', zIndex: 1}}
                            className={classes.buttonLink}>
                            <Assessment className={classes.links} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    }
                    filename="DetalladoReferencias">
                    <ExcelSheet dataSet={this.state.multiDataSetExcelReferencias} name="Detallado Referencias" />
                    </ExcelFile>
                    ):null}
                  </div>
                </ItemGrid>
              </Grid>
            </ItemGrid>
            <ExpansionCard defaultExpanded={true}
              icon={Assignment}
              iconColor="orange"
              cardTitle="Referencias del cliente"
              cardSubtitle='Información de referencias del Cliente'
              expanded={this.state.expandedReferenciasCliente}
              content={(<div>
                <div className={classes.backForm}>
                  <MuiThemeProvider theme={theme}>
                    <Grid container>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Inicio_Ref"
                          label="Fecha Inicio Factura"
                          type="date"
                          value={this.state.fh_Inicio_Ref}
                          onChange={this.handleChange('fh_Inicio_Ref')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Fin_Ref"
                          label="Fecha Fin Factura"
                          type="date"
                          value={this.state.fh_Fin_Ref}
                          onChange={this.handleChange('fh_Fin_Ref')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={3}>
                        <TextField
                          id="name"
                          label="Folio Referencia"
                          className={classes.textField}
                          value={this.state.de_Referencia}
                          onChange={this.handleChangeValidate('de_Referencia',{'maxLength':10})}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Registro_Ref"
                          label="Fecha Registro Referencia"
                          type="date"
                          value={this.state.fh_Registro_Ref}
                          onChange={this.handleChange('fh_Registro_Ref')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      
                    </Grid>

                    <Grid container>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="id_Estatus_Ref"
                          select
                          label="Estatus Referencias"
                          className={classes.textField}
                          value={this.state.id_Estatus_Ref}
                          onChange={this.handleChange('id_Estatus_Ref')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.comboEstatus.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={3}>
                        <TextField
                          id="name"
                          label="Folio de Pago"
                          className={classes.textField}
                          value={this.state.fl_Pago}
                          onChange={this.handleChangeValidate('fl_Pago',{'maxLength':10})}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4} md={3}>
                        <TextField
                          id="fh_Pago"
                          label="Fecha Pago"
                          type="date"
                          value={this.state.fh_Pago}
                          onChange={this.handleChange('fh_Pago')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={2}>
                      <TextField
                        id="id_Estacion"
                        select
                        label="Estación"
                        className={classes.textField}
                        value={this.state.id_Estacion_Ref}
                        onChange={this.handleChange('id_Estacion_Ref')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.estaciones.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                      <ItemGrid xs={1} sm={1} md={1}>
                        <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                          <IconButton
                              color="primary"
                              style={{marginTop: '30px'}}
                              aria-label="Agregar"
                              aria-haspopup="true"
                              onClick={this.handleClickSearchReferencias}>
                            <Search />
                          </IconButton>
                        </Tooltip>
                      </ItemGrid>
                    </Grid>
                  </MuiThemeProvider>
                </div>
                <br />
                <Grid container>
                  <Table xs={12} sm={12} md={12}
                    tableHead={[
                      { id: 'nb_EmpresaPlaza', numeric: false, disablePadding: false, label: 'Empresa Petroil/Plaza' },
                      { id: 'nb_Estación', numeric: false, disablePadding: false, label: 'Estación' },
                      { id: 'cl_TipoDocumento', numeric: false, disablePadding: false, label: 'Referencia' },
                      { id: 'fl_Documento', numeric: false, disablePadding: false, label: 'Vencimiento Referencia' },
                      { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Estatus Referencia' },
                      { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Folio Pago' },
                      { id: 'im_Importe', numeric: false, disablePadding: false, label: 'Fecha Depósito' },
                      { id: 'im_Saldo', numeric: false, disablePadding: false, label: 'Fecha Aplicación' },
                      { id: 'nb_Moneda', numeric: false, disablePadding: false, label: 'Folio Factura' },
                      { id: 'Dvencidos', numeric: false, disablePadding: false, label: 'Total Factura' },
                      { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Tipo Pago' },
                      { id: 'de_EstatusCobranza', numeric: false, disablePadding: false, label: 'Cuenta' },
                      { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                    ]}
                    tableData={this.state.tableDataReferencias}
                  />
                </Grid>
              </div>)}
              expansionAction={(e) => { this.setState({ expandedReferenciasCliente : true })} }
              expationClose={(e) => { this.setState({ expandedReferenciasCliente : false })} }
            />
          </ItemGrid>

          {/* REPORTE JUSTIFICACION FZ DA*/}
          <ItemGrid xs={12} sm={12} md={12}>
            {/* Icono de descarga */}
            <ItemGrid xs={12} sm={12} md={12}>
              <Grid container>
                <ItemGrid xs={11} sm={11} md={11}></ItemGrid>
                <ItemGrid xs={1} sm={1} md={1}>
                  <div>
                  {(this.state.expandedJustificacion)?(
                    <Tooltip  
                      title={'Descargar Justificiación FZ DA'} 
                      placement={'bottom-start'} 
                      enterDelay={100} >
                        <IconButton 
                            aria-label="Detalle" 
                            style={{top:'50px', zIndex: 1}}
                            error={this.state.required_id_Ventas}
                            aria-haspopup="true" 
                            className={classes.buttonLink}
                            onClick={e => this.div2PDF(e)}>
                          <Assessment className={classes.links} />
                        </IconButton>
                    </Tooltip>
                  ):null}
                  </div>
                </ItemGrid>
              </Grid>
            </ItemGrid>
            <ExpansionCard defaultExpanded={true}
              icon={Assignment}
              iconColor="green"
              cardTitle="Justificación FZ DA"
              cardSubtitle='Gráfica de volumen de ventas'
              expanded={this.state.expandedJustificacion}
              content={(<div>
                <div className={classes.backForm}>
                  <MuiThemeProvider theme={theme}>
                    <Grid container>
                      <ItemGrid xs={12} sm={3} md={3}>
                        <TextField
                          id="id_Estacion_Jus"
                          select
                          label="Estación"
                          className={classes.textField}
                          value={this.state.id_Estacion_Jus}
                          onChange={this.handleChange('id_Estacion_Jus')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.estaciones.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={3} md={3}>
                        <TextField
                          id="fh_inicio_Month"
                          label="Fecha Inicio"
                          error={this.state.requiredMonth}
                          type="month"
                          value={this.state.fh_inicio_Month ||""}
                          onChange={this.handleChange('fh_inicio_Month')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={3} md={3}>
                        <TextField
                          id="fh_fin_Month"
                          label="Fecha Fin"
                          error={this.state.requiredMonth}
                          type="month"
                          value={this.state.fh_fin_Month || ""}
                          onChange={this.handleChange('fh_fin_Month')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                      {/* Btn  */}
                      <ItemGrid xs={1} sm={1} md={1}>
                        <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                          <IconButton
                              color="primary"
                              error={this.state.required_id_Meses}
                              aria-label="Buscar"
                              aria-haspopup="true"
                              style={{marginTop:'25px'}}
                              onClick={this.handleClickSearchJustificacion}>
                            <Search className={classes.links} />
                          </IconButton>
                        </Tooltip>
                      </ItemGrid>
                    </Grid>
                    <br /><br />
                      {/* Grafica  */}
                    <Grid container>
                      {(this.state.ventas.length)?(
                        <ItemGrid xs={12} sm={12} md={12}>
                          {/* Informacion que se mostrará en el pdf*/}
                         <div id="chartDiv" >
                            {(this.state.sn_ImprimirReporte)?(
                              <Grid container>
                                <ItemGrid  xs={10} sm={10} md={10}></ItemGrid>
                                <ItemGrid xs={2} sm={2} md={2} > 
                                  <img src={logoBlancoAsociados} alt="logo" style={styles.img} />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={12} style={{textAlign:'center'}}>
                                  <label style={styles.title}>
                                    Justificación FZ DA Abastecedora de Combustibles del Pacífico
                                  </label>
                                  <br></br>
                                </ItemGrid> 
                                <ItemGrid xs={12} sm={12} md={7}>
                                    <label style={styles.subtitle}>
                                      {sesion.usuario.de_RazonSocial}
                                    </label>
                                    <br></br>
                                    <label style={styles.subtitle}> 
                                      Estación: {this.state.estaciones.filter((item)=> item.value === this.state.id_Estacion_Jus)[0].label}
                                    </label>
                                    <br></br>
                                    <label style={styles.text}> 
                                      Se adjunta gráfica con el volumen de venta de la fecha 
                                        {this.state.fh_inicio_MonthFormat} al {this.state.fh_fin_MonthFormat}
                                    </label>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={0} md={3}></ItemGrid>
                                <ItemGrid xs={4} sm={4} md={2}>
                                  <TableBasic size="small">
                                    <TableHead>
                                      <TableRow style={styles.rowHead}>
                                        <TableCell style={styles.cell}>Mes</TableCell>
                                        <TableCell style={styles.cell}>Litros M3</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.totalVentaM3.map((row) => (
                                        <TableRow key={row.mes} style={{height: '5px'}}>
                                          <TableCell style={styles.cellText}>{row.mes}</TableCell>
                                          <TableCell style={styles.cellText}>{row.nu_Cantidad}</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </TableBasic>
                                </ItemGrid>
                              </Grid>
                              ): <div></div> 
                            }
                            <Line
                              data={this.state.cData}
                              height={80}
                              options={{
                                title: {
                                  display: true,
                                  text: 'Historial de Venta',
                                  fontSize: 18
                                },
                                legend: {
                                  display: true,
                                  position: "top"
                                }
                              }}
                            />
                            {(this.state.sn_ImprimirReporte)?(
                                <label style={styles.subtitle}> 
                                  Fecha Impresión: {this.state.fh_Impresion}
                                </label>
                            ):<div></div>}
                          </div>
                        </ItemGrid>
                      ):null} 
                    </Grid>
                  </MuiThemeProvider>
                </div>
              </div>)}
              expansionAction ={(e) => { this.setState({ expandedJustificacion : true })} }
              expationClose={(e) => { this.setState({ expandedJustificacion : false })} }
           />
          </ItemGrid>
     
          {/* REPORTE RESTITUCIONES*/}
          {(this.state.estacion.sn_ProgramaBienestar) ? (
            <ItemGrid xs={12} sm={12} md={12}>
              {/* Icono de descarga */}
              <ItemGrid xs={12} sm={12} md={12}>
                <Grid container>
                  <ItemGrid xs={11} sm={11} md={11}></ItemGrid>
                  <ItemGrid xs={1} sm={1} md={1}>
                    {(this.state.tableDataRestituciones.length > 0 && this.state.expandedRestituciones)?( 
                      <ExcelFile element={
                        <div>
                          <Tooltip
                            title={'Descargar Listado Restituciones'}
                            placement={'bottom-start'}
                            enterDelay={100}
                          >
                            <IconButton
                              aria-label="Excel"
                              style={{top:'50px', zIndex: 1}}
                              aria-haspopup="true"
                              className={classes.buttonLink}>
                              <Print className={classes.links}/>
                            </IconButton>
                          </Tooltip>
                        </div>
                      }
                      filename="DetalladosRestituciones">
                      <ExcelSheet dataSet={this.state.multiDataSetExcelRestituciones} name="Detallado Restituciones" />
                      </ExcelFile>
                    ):null}
                  </ItemGrid>
                </Grid>
              </ItemGrid>
              <ExpansionCard defaultExpanded={false}
                icon={Assignment}
                iconColor="blue"
                cardTitle="Volumen Restituido"
                cardSubtitle=''
                expanded={this.state.expandedRestituciones}
                content={(
                  <div>
                    <div className={classes.backForm}>
                      <MuiThemeProvider theme={theme}>
                        <Grid container>
                          <ItemGrid xs={12} sm={3} md={3}>
                            <TextField
                              id="fh_inicio_Restitucion"
                              label="Fecha Inicio"
                              error={this.state.requiredMonthRestitucion}
                              type="month"
                              value={this.state.fh_inicio_Restitucion ||""}
                              onChange={this.handleChange('fh_inicio_Restitucion')}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              fullWidth
                            />
                          </ItemGrid>
                          <ItemGrid xs={12} sm={3} md={3}>
                            <TextField
                              id="fh_fin_Restitucion"
                              label="Fecha Fin"
                              error={this.state.requiredMonthRestitucion}
                              type="month"
                              value={this.state.fh_fin_Restitucion || ""}
                              onChange={this.handleChange('fh_fin_Restitucion')}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              fullWidth
                            />
                          </ItemGrid>
                          {/* Btn  */}
                          <ItemGrid xs={1} sm={1} md={1}>
                            <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                              <IconButton
                                  color="primary"
                                  error={this.state.required_idMesesRestituciones}
                                  aria-label="Buscar"
                                  aria-haspopup="true"
                                  style={{marginTop:'25px'}}
                                  onClick={this.handleClickSearchRestituciones}>
                                <Search className={classes.links} />
                              </IconButton>
                            </Tooltip>
                          </ItemGrid>
                        </Grid>
                        {/* Tabla restituciones */}
                        <Grid container>
                          <Table xs={12} sm={12} md={12}
                            tableHead={[
                              { id: 'fh_OperacionPemex', numeric: false, disablePadding: false, label: 'Fecha operación' },
                              { id: 'nu_PermisoCRE', numeric: false, disablePadding: false, label: 'Permiso CRE' },
                              { id: 'nu_ComprobanteCargaPemex', numeric: false, disablePadding: false, label: 'Referencia' },
                              { id: 'fl_RemisionPemex', numeric: false, disablePadding: false, label: 'Remisión' },
                              { id: 'nb_ProductoPemex', numeric: false, disablePadding: false, label: 'Producto' },
                              { id: 'nu_VolumenComprobante', numeric: true, disablePadding: false, label: 'Volumen comprobante de carga (LTS)' },
                              { id: 'nu_VolumenTotalFactura', numeric: true, disablePadding: false, label: 'Volumen total de factura (LTS)' },
                              { id: 'nu_VolumenRestituir', numeric: true, disablePadding: false, label: 'Volumen a restituir (LTS)' },
                            ]}
                            tableData={this.state.tableDataRestituciones}
                          />
                        </Grid>
                      </MuiThemeProvider>
                    </div>
                  </div>
                )}
                expansionAction={(e)=>{this.setState({ expandedRestituciones : true });}}
                expationClose={(e)=>{this.setState({ expandedRestituciones : false });}}
              />
            </ItemGrid>
          ) : null}
        </Grid>

        <FormDialog
          title={(<div><InputAdornment position="start"><Assignment /><span style={{paddingLeft:'15px'}}>Facturas</span></InputAdornment></div>)}
          description= {this.state.descriptionFactura}
          content= {<div>
            <Grid container>
              <ItemGrid xs={12} sm={12} md={3}>
                <TextField
                  id="name"
                  label="Folio de Pedido"
                  value={this.state.fl_Pedido}
                  onChange={this.handleChangeValidate('fl_Pedido',{'maxLength':10})}
                  margin="normal"
                  type="number"
                  fullWidth
                />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={3}>
                <TextField
                  id="fh_Inicio"
                  label="Fecha Factura Inicio"
                  type="date"
                  value={this.state.fh_Inicio}
                  onChange={this.handleChange('fh_Inicio')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  fullWidth
                />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={3}>
                <TextField
                  id="fh_Fin"
                  label="Fin"
                  type="date"
                  value={this.state.fh_Fin}
                  onChange={this.handleChange('fh_Fin')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  fullWidth
                />
              </ItemGrid>
              <ItemGrid xs={1} sm={1} md={1}>
                <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                  <IconButton
                      color="primary"
                      aria-label="Agregar"
                      aria-haspopup="true"
                      onClick={this.handleClickSearch}>
                    <Search />
                  </IconButton>
                </Tooltip>
              </ItemGrid>
            </Grid>
            <Grid container>
              <Table xs={12} sm={12} md={12}
                tableHead={[
                  { id: 'fl_Pedido', numeric: true, disablePadding: false, label: 'Folio Pedido' },
                  { id: 'fl_FolioDocumento', numeric: false, disablePadding: false, label: 'Folio Factura' },
                  { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Fecha Factura' },
                  { id: 'nb_Producto', numeric: false, disablePadding: false, label: 'Producto' },
                  { id: 'nu_Cantidad', numeric: false, disablePadding: false, label: 'Litros' },
                  { id: 'im_Total', numeric: false, disablePadding: false, label: 'Importe' },
                  { id: 'im_SaldoFactura', numeric: true, disablePadding: false, label: 'Saldo' },
                  { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Factura' },
                  { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                ]}
                tableData={this.state.tableData}
              />
            </Grid>
          </div>}
          close={this.handleClickCloseFactura}
          open= {this.state.openDialogFactura}
        />
        <FormDialog
            title = {(<div><InputAdornment position="start"><AssignmentReturned /><span style={{paddingLeft:'15px'}}>Descargar Factura</span></InputAdornment></div>)}
            description= {this.state.descriptionFactura}
            content = {
              <div>
                <iframe title="Factura" style={{minHeight: '63VH', minWidth: '1100px'}} src={this.state.urlFactura}></iframe>
              </div>
            }
            btn= 'Descargar'
            action= {this.handleClickFactura}
            close={this.handleClickCloseFacturaView}
            open= {this.state.openDialogFacturaView}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.tr}
            onClose={() => this.setState({ tr: false })}
            ContentProps={{ 'className': classes.red }}
            message={this.state.message}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.tc}
            onClose={() => this.setState({ tc: false })}
            ContentProps={{ 'className': classes.green }}
            message={this.state.message}
          />
        <div className={this.state.classMask}>
          <Linear  />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
