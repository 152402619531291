import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    width: '100%',
  },
  rootmobile:{
    '@media (max-width: 768px)': {
      padding: '24px 0px',
    }
  },
  content:{
    minWidth: '700px',
    '@media (max-width: 768px)': {
      minWidth: '250px',
    }
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    margin: (theme.spacing.unit * 2)+'px 0px',
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class VerticalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({ activeStep: state.activeStep - 1, }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  onClick = () => {
    this.handleBack();
  }

  //dangerouslySetInnerHTML={{__html: prop.html}} 

  render() {
    const { classes, steps, finish, handleData, activeStepData} = this.props;
    let { activeStep } = this.state;
    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical" className={classes.rootmobile}>
          {steps.map((prop, index) => {
            return (
            <Step key={index}>
              <StepLabel>{'Paso '+(index+1)+' - '} {prop.label}</StepLabel>
              <StepContent className={classes.content}>
                <div>{prop.html}</div>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={()=>{
                        this.setState(state => ({ activeStep: state.activeStep - 1, }));
                        activeStepData(0);
                        if (handleData)
                          handleData();
                      }}
                      className={classes.button}
                    >
                      Atras
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={()=>{this.handleNext();activeStepData(activeStep);}}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Terminar' : 'Siguiente'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          )
        })}
        </Stepper>
        {activeStep === steps.length && finish && (
          finish
        )} {(activeStep === steps.length && finish)?(<Button
                      disabled={activeStep === 0}
                      onClick={()=>{this.handleBack();activeStepData(0);}}
                      className={classes.button}
                      style={{color:'red'}}
                    >
                      Regresar
                    </Button>):(<div></div>)}
      </div>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
  steps: PropTypes.array,
  finish: PropTypes.node,
  handleData: PropTypes.node,
  activeStepData: PropTypes.node,
};

export default withStyles(styles)(VerticalLinearStepper);