import React from "react";
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  CardActions
} from "@material-ui/core";
import { IconButton } from "components";
import PropTypes from "prop-types";
import cx from "classnames";
import Tooltip from '@material-ui/core/Tooltip';
import { AddCircle, Reply } from "@material-ui/icons";

import regularCardStyle from "assets/jss/material-dashboard-react/regularCardStyle";

function RegularCard({ ...props }) {
  const {
    classes,
    headerColor,
    plainCard,
    cardTitle,
    cardSubtitle,
    content,
    footer,
    add,
    edit,
    back,
    excel
  } = props;
  const plainCardClasses = cx({
    [" " + classes.cardPlain]: plainCard
  });
  const cardPlainHeaderClasses = cx({
    [" " + classes.cardPlainHeader]: plainCard
  });
  return (
    <Card className={classes.card + plainCardClasses}>
      <CardHeader
        classes={{
          root:
            classes.cardHeader +
            " " +
            classes[headerColor + "CardHeader"] +
            cardPlainHeaderClasses,
          title: classes.cardTitle,
          subheader: classes.cardSubtitle
        }}
        title={cardTitle}
        subheader={cardSubtitle}
        action= 
          {add !== undefined ? (
            <div><Tooltip
                  title={edit?'Agregar':'Regresar'}
                  placement={'bottom-start'}
                  enterDelay={100}
                >
              <IconButton
                  color="simpleWhite"
                  aria-label="Agregar"
                  aria-haspopup="true"
                  onClick={(edit)?add:back}
                  className={classes.buttonLink}>
                {edit ?<AddCircle className={classes.links} />:<Reply className={classes.links} />}
              </IconButton>
            </Tooltip>
            <div>
             {excel !== undefined ? (
               excel
            ) : null}
           </div></div>
          ) : null}
        />
      <CardContent>{content}</CardContent>
      {footer !== undefined ? (
        <CardActions className={classes.cardActions}>{footer}</CardActions>
      ) : null}
    </Card>
  );
}

RegularCard.defaultProps = {
  headerColor: "orange"
};

RegularCard.propTypes = {
  plainCard: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple"]),
  cardTitle: PropTypes.node,
  cardSubtitle: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node
};

export default withStyles(regularCardStyle)(RegularCard);
