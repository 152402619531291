import {cajaLoginStyle, domains, hostname} from "assets/jss/material-dashboard-react/cajaLoginStyle";
import React from "react";
import PropTypes from "prop-types";
import invoke from "services/invoke.jsx";
import { Lock, AccountCircle } from "@material-ui/icons";
import injectSheet from 'react-jss'
import { Grid, InputAdornment, Snackbar, TextField } from '@material-ui/core';
import { RegularCard, Button, ItemGrid, Linear } from "components";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tl: false,
      tc: false,
      tr: false,
      bl: false,
      bc: false,
      br: false,
      rfc: '',
      nu_cliente: '',
      message: '',
      requiredPass: false,
      requiredUser: false,
      classMask:  'maskProgress',
      image: null
    };
  }

  showNotification(place,message,time=5000) {
    this.setState({message:message});
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClick = (event, value) => {
    if(this.state.rfc === ''){
      this.mensajeLog('requiredRfc','El RFC es requerido.');
      return;
    }else if(this.state.nu_cliente === ''){
      this.mensajeLog('requiredCliente','Número de Cliente es requerido.');
      return;
    }
    this.setState({classMask:'maskProgress active'});
    invoke.loginRfc({de_rfc: this.state.rfc, id_cliente: this.state.nu_cliente})
      .then(res => {
        if(res.id_Cliente > 0){
          if(res.sn_Activo){
            invoke.setSesionCliente({cliente: res, opciones:'', estaciones:'', estacionObj:'', estacion:''});
            invoke.resetTime();
            const logInDate = new Date();
            invoke.setDateSession(logInDate);
            window.location.reload();
          } else {
            this.showNotification("tr",'Cliente Sin Acceso.')
          }
        }else{
          this.showNotification("tr",'RFC o Número de cliente incorrecto.')
        }
        setTimeout(function() {
          this.setState({classMask:'maskProgress desactive'});
          setTimeout(function() {
              this.setState({classMask:'maskProgress'});
          }.bind(this), 300);
        }.bind(this), 300);
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.containerLogin}>
          <div className={classes.cajaLogin}>
            <RegularCard
                cardTitle={
                  (domains[hostname])?domains[hostname].logo1:''
                }
                content={
                  <div>
                    <Grid container>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <TextField
                          label="RFC"
                          className={classes.textField}
                          id="rfc"
                          value={this.state.rfc}
                          onChange={this.handleChange('rfc')}
                          error={this.state.requiredRfc}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          margin="normal"
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <TextField
                          label="Número de Cliente Único"
                          className={classes.textField}
                          id="cliente"
                          value={this.state.nu_cliente}
                          onChange={this.handleChange('nu_cliente')}
                          error={this.state.requiredCliente}
                          onKeyPress={(ev) => {if (ev.key === 'Enter') {this.handleClick(); ev.preventDefault();}}}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            ),
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </ItemGrid>
                    </Grid>
                  </div>
                }
                footer={
                    <Button onClick={this.handleClick} color="primary" className={classes.simple}>Entrar</Button>
                }
            />
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.tr}
          onClose={() => this.setState({ tr: false })}
          ContentProps={{ 'className': classes.red }}
          message={this.state.message}
        />
        <div className={classes.backLogin}></div>
        <div className={classes.backLoginMascara}></div>
        <div className={this.state.classMask}>
          <Linear  />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectSheet(cajaLoginStyle)(Dashboard);
