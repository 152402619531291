// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  'Sign contract for "What are conference organizers afraid of?"',
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  "Create 4 Invisible User Experiences you Never Knew About"
];
var website = [
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];
var server = [
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];

var terminosCondiciones = [
    '<div style="color: #2E3433;font-size: 14px;padding: 25px 50px;line-height: 20px;font-family: Raleway, sans-serif;">'
      +'<p style="margin-block: 0px;text-align:left;"><strong><span style="font-weight:600;font-size:24px;margin-bottom: 10px;display: block;">Aviso de privacidad</span></strong></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>En Petroil, su privacidad y confianza son muy importantes; por ello, queremos asegurarnos de que nuestros clientes conozcan c&oacute;mo salvaguardamos la integridad, privacidad y protecci&oacute;n de sus datos personales conforme a lo previsto en la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares (la &quot;Ley&quot;).</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><strong><span style="font-weight:600;">Sobre la empresa</span></strong></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><strong><span style="font-weight:600;">ABASTECEDORA DE COMBUSTIBLES DEL PAC&Iacute;FICO, S.A. DE C.V.,</span></strong><span>&nbsp;con domicilio en Av. Puerto de Mazatl&aacute;n # 4, Parque Industrial Alfredo V. Bonfil, C.P. 82050, Mazatl&aacute;n, Sinaloa; a quien se le denomina comercialmente como&nbsp;</span><strong><span style="font-weight:600;">&ldquo;PETROIL&rdquo;</span></strong><span>&nbsp;declara:</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<ul style="padding: 0 15px;margin: 0;">'
      +'<li><span style="font-weight:600;">I.&nbsp;</span><strong><span style="font-weight:600;">CONTENIDO DE LOS DATOS PERSONALES</span></strong></li>'
      +'</ul>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>De acuerdo con la Ley, se entienden como &ldquo;Datos Personales&rdquo; cualquier informaci&oacute;n alusiva a una persona f&iacute;sica o moral identificada o identificable. Para efectos del presente Aviso de Privacidad, de manera enunciativa m&aacute;s no limitativa, se entender&aacute;n como &ldquo;Datos Personales&rdquo;:</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>a) Datos generales: Nombre de la Persona Moral, Nombre del apoderado o Representante legal, Nombre (s) y apellidos, fecha de nacimiento, sexo, estado civil, existencia o no de hijos, domicilio, tel&eacute;fono fijo (de casa u oficina), tel&eacute;fono celular, correo electr&oacute;nico, Registro Federal de Causantes (RFC), Clave &Uacute;nica de Registro Poblacional (CURP), r&eacute;gimen fiscal, especificaci&oacute;n sobre formas de pago (efectivo, vales, cheques, cuentas bancarias,dep&oacute;sitos y/o visas), n&uacute;mero de folio de Credencial de Elector (IFE), entre otros datos similares concernientes a una persona.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>b) Datos personales sensibles: Se consideran datos personales sensibles, informaci&oacute;n de: origen racial o &eacute;tnico, estado de salud presente y futura, informaci&oacute;n gen&eacute;tica, creencias religiosas, filos&oacute;ficas y morales, afiliaci&oacute;n sindical, opiniones pol&iacute;ticas, preferencia sexual.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Para cumplir con las finalidades previstas en este aviso, en caso de ser recabados datos personales de este tipo, estos ser&aacute;n tratados bajo medidas de seguridad, siempre garantizando su confidencialidad.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Si usted no manifiesta su oposici&oacute;n mediante la solicitud de revocaci&oacute;n para los derechos ARCO</span><span>, para que sus datos personales sensibles sean tratados, se entender&aacute; que ha otorgado su consentimiento para ello.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Consentimiento: &ldquo;Al recibir este aviso, manifiesto mi voluntad como titular de los datos, para que Petroil efect&uacute;e el tratamiento de los mismos, entendiendo como tratamiento la obtenci&oacute;n, uso, divulgaci&oacute;n o almacenamiento de datos personales, por cualquier medio. El uso abarca cualquier acci&oacute;n de acceso, manejo, aprovechamiento, transferencia o disposici&oacute;n de mis datos personales&quot;.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>c) Datos relacionados con solicitudes de empleo: Cuando se ingresen o proporcionen Datos</span><span>&nbsp;Personales en la bolsa de trabajo de Petroil, adem&aacute;s de los datos generales, se considerar&aacute;n Datos Personales la informaci&oacute;n sobre nivel educativo, experiencia laboral y cualquier informaci&oacute;n relacionada con la solicitud de empleo llenada o el curr&iacute;culo que sea entregado o enviado.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>d) Revistas y transacciones en l&iacute;nea: Cuando el usuario se suscriba para recibir alguno de los productos de Petroil tales como publicidad impresa (revistas, boletines, folletos, etc.), boletines electr&oacute;nicos, entre otros y cuando se realicen transacciones para la compra de productos y servicios en cualquiera de nuestras plataformas digitales, actuales o futuras, adem&aacute;s de los datos generales, se consideraran Datos Personales los relacionados con tarjetas bancarias tales como nombre del tarjetahabiente, n&uacute;mero de tarjeta, fecha de vencimiento de la tarjeta, tipo de tarjeta, c&oacute;digo de seguridad, adem&aacute;s del domicilio de entrega del producto y cualquier otra informaci&oacute;n y cualquier informaci&oacute;n necesaria para la suscripci&oacute;n o transacci&oacute;n en l&iacute;nea.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Los Datos Personales referidos en este punto &uacute;nicamente estar&aacute;n sujetos a las Finalidades del Tratamiento establecidas m&aacute;s adelante en el presente Aviso de Privacidad, por el tiempo que dure la transacci&oacute;n comercial; es decir, una vez que la transacci&oacute;n y sus efectos concluyan, los Datos Personales ser&aacute;n eliminados de nuestras bases de datos.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Los Datos personales mencionados en los incisos a, b y c arriba expuestos y correspondientes al presente Aviso de Privacidad, ser&aacute;n recabados mediante el llenado de los formatos y/o mediante la recopilaci&oacute;n de informaci&oacute;n o documentaci&oacute;n requerida por Petroil, ya sea de manera personal o v&iacute;a electr&oacute;nica en la siguiente direcci&oacute;n: https://clientes.petroil.com.mx/login</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>e) Redes Sociales: Las redes sociales (tales como Facebook, Twitter, Instagram, Foursquare, Linked In, entre otras), son una plataforma de comunicaci&oacute;n e interconexi&oacute;n dentro de plataformas digitales de los diferentes usuarios, son ajenas a Petroil y por lo tanto, no se encuentran bajo su responsabilidad.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>La informaci&oacute;n que se proporcione a las redes sociales en las que Petroil participa como usuario, no forma parte de los Datos Personales sujetos a la protecci&oacute;n de este Aviso de Privacidad, siendo responsabilidad de la empresa prestadora de esa plataforma y de qui&eacute;n lo publica.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>f) Cookies: Las Cookies son archivos de texto que se descargan autom&aacute;ticamente y se almacenan</span><span>&nbsp;en el disco duro del equipo de c&oacute;mputo del usuario al navegar en una p&aacute;gina o portal de Internet espec&iacute;fico, que permiten almacenar al servidor de Internet ciertos datos, entre ellos, p&aacute;ginas preferidas de visualizaci&oacute;n en internet, algunas claves y contrase&ntilde;as.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Petroil utilizar&aacute; cookies para determinar sus preferencias cuando se conecta a los servicios de nuestro sitio. Por lo anteriormente dicho, a partir de este momento, el usuario otorga su consentimiento a Petroil para mantener en sus registros tanto f&iacute;sicos como electr&oacute;nicos, toda la informaci&oacute;n que nos haya sido facilitada a trav&eacute;s de cualquier medio de los puestos a disposici&oacute;n por Petroil a fin de recabar sus Datos Personales.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>La confidencialidad y seguridad de los datos que los usuarios proporcionen al solicitar un</span><span>&nbsp;servicio o comprar un producto en l&iacute;nea, estar&aacute;n protegidos con un candado SSL (Secure Socket Layer) de VeriSign; de tal forma que los datos proporcionados se transmitir&aacute;n encriptados para asegurar su resguardo.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Sin embargo, el usuario y/o clientes de Petroil entienden y aceptan que los mecanismos de seguridad para la informaci&oacute;n que viaja por Internet podr&iacute;an tener errores y fallas que escapan del control de Petroil y que por ser el Internet un medio de libre acceso al p&uacute;blico en general, pudiera ser vulnerable de delitos inform&aacute;ticos por parte de terceros sin responsabilidad para Petroil.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><strong><span style="font-weight:600;">II. SOBRE LA PERSONA RESPONSABLE DE RECABAR DATOS PERSONALES</span></strong></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Se declara que sus datos personales estar&aacute;n a cargo del C</span><span>. MIRSAY SABRINA SHIMKEVICH RIVAS quien es el &uacute;nico responsable del tratamiento de los mismos, con correo electr&oacute;nico&nbsp;</span><a href="mailto:mshimkevich@petroil.com.mx"><u><span style="color:rgb(0,0,255);text-decoration:underline;">mshimkevich@petroil.com.mx</span></u></a><span style="color:rgb(0,0,10);">&nbsp;</span><span>y con domicilio en Av. Puerto de Mazatl&aacute;n # 4, Parque Industrial Alfredo V. Bonfil, C.P. 82050, Mazatl&aacute;n, Sinaloa.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><strong><span style="font-weight:600;">III. TRATAMIENTO DE DATOS PERSONALES</span></strong></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Se considera &ldquo;Tratamiento de Datos Personales&rdquo; la obtenci&oacute;n, uso, divulgaci&oacute;n o almacenamiento de Datos Personales por cualquier medio. El uso se extienda a cualquier actividad de acceso, manejo, aprovechamiento, transferencia o disposici&oacute;n de Datos Personales.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>El Tratamiento de Datos Personales por parte de Petroil se sujetar&aacute; a lo dispuesto en el siguiente punto.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><strong><span style="font-weight:600;">IV. FINALIDADES DEL TRATAMIENTO DE DATOS PERSONALES</span></strong></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>A trav&eacute;s del presente Aviso de Privacidad, Petroil declara que las finalidades del tratamiento de sus Datos Personales ser&aacute;n los siguientes (&ldquo;Finalidades del Tratamiento&rdquo;):</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>a) Fines comerciales: con el objeto de dar a conocer la informaci&oacute;n comercial de Petroil, entre la que se destaca la relacionada con trivias, promociones, concursos, informaci&oacute;n de nuevos productos o servicios, felicitaciones de cumplea&ntilde;os, revista electr&oacute;nica, cambios en nuestros productos o servicios, afiliar al cliente al programa de fidelidad de la empresa, dar cumplimiento a las obligaciones contra&iacute;das con nuestros clientes, solicitar evaluaci&oacute;n de la calidad en el servicio, realizar estudios internos sobre h&aacute;bitos de consumo y cualquier otro promocional o cuesti&oacute;n relacionada con los productos y/o servicios que ofrece Petroil.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>b) Investigaci&oacute;n y diversos estudios de mercado: cuestionarios y entrevistas, estudios socio-econ&oacute;micos y socio-demogr&aacute;ficos, procesos administrativos como devoluciones, facturaciones, aclaraciones, atenci&oacute;n a requerimiento de Autoridad.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>c) Pedidos, &oacute;rdenes de compra, suministros y Pagos en l&iacute;nea de transacciones: con el fin de brindar el servicio de compra de combustibles y otros derivados de los Hidrocarburos, Petroil pone a disposici&oacute;n de nuestro clientes y p&uacute;blico en general un portal para efectuar pedidos u ordenes de compra, en dicho portal los clientes tambi&eacute;n podr&aacute;n consultar consumos, revisar notas de compra, facturaci&oacute;n y efectuar pagos de sus compras a trav&eacute;s del uso de referencias bancarias.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>d) Entrega de productos y cumplimiento de obligaciones con nuestros clientes, en caso de que se realice una transacci&oacute;n electr&oacute;nica o sean acreedores a alg&uacute;n premio relacionado a promociones, trivias o sorteos de Petroil, se utilizaran los Datos Personales correspondientes para contactar o enviar los productos a quienes as&iacute; corresponda.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>e) Reclutamiento y selecci&oacute;n de personal, en caso de que el usuario aplique a alguna de las vacantes de la bolsa de trabajo de Petroil y reciba informaci&oacute;n sobre ofertas de empleo o vacantes que surjan al interior de la empresa</span><span>.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><span>En caso de que se pretenda tratar los Datos Personales de los usuarios para un fin distinto al expresado en el presente Aviso de Privacidad, Petroil deber&aacute; obtener el consentimiento del usuario para tal fin de nueva cuenta.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><strong><span style="font-weight:600;">V. ACEPTACI&Oacute;N DEL TRATAMIENTO DE DATOS PERSONALES</span></strong></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Los datos personales que usted (en lo sucesivo &ldquo;el Titular&rdquo;) ha proporcionado directamente o a trav&eacute;s de medios electr&oacute;nicos a Petroil (en lo sucesivo &ldquo;el Responsable&rdquo;), han sido recabados y ser&aacute;n tratados por Petroil bajo los principios de licitud, consentimiento, informaci&oacute;n, calidad, finalidad, lealtad y responsabilidad de conformidad con lo dispuesto por la Ley.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><strong><span style="font-weight:600;">VI. LIMITACIONES DE USO DE DATOS PERSONALES</span></strong></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>En caso de que el Titular desee limitar el uso de sus Datos Personales, en relaci&oacute;n con una o varias de las finalidades del Tratamiento de Datos Personales (por ejemplo, correos publicitarios, felicitaciones de cumplea&ntilde;os, investigaciones de mercado, entre otros), podr&aacute; enviar la solicitud correspondiente conforme al proceso estipulado en el punto VII del presente Aviso de Privacidad.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><strong><span style="font-weight:600;">VII. DERECHOS DE ACCESO, RECTIFICACI&Oacute;N, CANCELACI&Oacute;N U OPOSICI&Oacute;N. (DERECHOS ARCO)</span></strong></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>De acuerdo a lo establecido en la Ley, el Titular tiene derecho al acceso, rectificaci&oacute;n cancelaci&oacute;n u oposici&oacute;n al Tratamiento de sus Datos Personales para cual se deber&aacute; seguir el siguiente procedimiento:</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>a) Como Titular de Datos Personales, presentar o enviar una solicitud para el acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n al correo mshimkevich@petroil.com.mx dirigida al responsable del tratamiento de sus datos. Dicha solicitud la podr&aacute; descargar en la siguiente liga&nbsp;</span><u><span style="text-decoration:underline;">FORMATO DE SOLUCITUD DE DERECHOS ARCO</span></u><u><span style="text-decoration:underline;">.</span></u></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>b) Resoluci&oacute;n y comunicaci&oacute;n Petroil comunicar&aacute; al Titular de los Datos Personales la determinaci&oacute;n adoptada en un plazo no mayor a 15 d&iacute;as h&aacute;biles, a partir de la fecha en que se recibi&oacute; la solicitud. Este plazo podr&aacute; ser ampliado por Petroil en una sola ocasi&oacute;n por un periodo igual, siempre y cuando as&iacute; lo justifiquen las circunstancias del caso.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>De acuerdo a lo establecido en la Ley, Petroil informar&aacute; al Titular de los Datos Personales la resoluci&oacute;n tomada por el mismo medio por el que se llev&oacute; a cabo la solicitud acompa&ntilde;ando a la misma con las pruebas pertinentes en su caso.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><span>Cuando la resoluci&oacute;n sea procedente, se har&aacute; efectiva por Petroil en los siguientes 15 d&iacute;as a la comunicaci&oacute;n de la resoluci&oacute;n hecha.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>El titular podr&aacute; presentar ante el Instituto Nacional de Acceso a la Informaci&oacute;n (INAI) una solicitud de protecci&oacute;n de datos por la respuesta recibida o falta de respuesta de Petroil, dentro de los 15 d&iacute;as h&aacute;biles siguientes a la fecha en que se comunique la respuesta al titular por parte de Petroil y se sujetar&aacute; a lo previsto en la Ley.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><span>En caso de solicitudes de acceso a Datos Personales, ser&aacute; necesario que el solicitante o su representante legal acrediten previamente su identidad.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>La obligaci&oacute;n de acceso a la informaci&oacute;n se dar&aacute; por cumplida cuando Petroil ponga a disposici&oacute;n del titular los Datos Personales o mediante la expedici&oacute;n de copias simples o documentos electr&oacute;nicos.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>En el supuesto en que una persona solicite a Petroil el acceso a sus Datos Personales presumiendo que &eacute;ste es el responsable y resultara que no lo es, bastar&aacute; con que Petroil as&iacute; se lo indique al titular por los medios de establecidos en este apartado, para tener por desahogada la solicitud.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>La contestaci&oacute;n de la solicitud para acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n de los Datos Personales ser&aacute; gratuita. En caso de solicitar env&iacute;o de copias u otros formatos, los gastos derivados de los mismos deber&aacute;n ser cubiertos por el Titular, costos que en su momento, Petroil informar&aacute; oportunamente a quien corresponda.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>c) Negativa para acceder a Datos Personales: Petroil podr&aacute; negar el acceso total o parcial a los Datos Personales o a la realizaci&oacute;n de la rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n al tratamiento de los mismos, en los siguientes casos:</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>-Cuando el solicitante no sea el titular o el representante legal no est&eacute; acreditado para ello.</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>-Cuando en la Base de Datos de Petroil no se encuentren los Datos Personales del solicitante.</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>-Cuando se lesionen los derechos de un tercero.</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>-Cuando exista impedimento legal o resoluci&oacute;n de una autoridad.</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>-Cuando la rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n haya sido previamente realizada, de manera que la solicitud carezca de materia.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><strong><span style="font-weight:600;">VIII. TRANSFERENCIA DE DATOS</span></strong></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Petroil no realiza transferencias de sus datos a terceros nacionales o extranjeros. Se informa que sus datos personales, incluyendo sus datos sensibles, que actualmente y en un futuro formen parte de nuestros registros ser&aacute;n utilizados &uacute;nicamente por las empresas que conforman &ldquo;Petroil&rdquo;, y exclusivamente con fines promocionales y/o para tr&aacute;mites que lo requieran, as&iacute; como cuando alguna autoridad legal lo solicite</span><span>.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><strong><span style="font-weight:600;">IX. CAMBIOS EN EL AVISO DE PRIVACIDAD</span></strong></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Petroil se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad, para atenci&oacute;n de novedades legislativas o jurisprudenciales, pol&iacute;ticas internas, nuevos requerimientos para la prestaci&oacute;n u ofrecimiento de nuestros servicios o productos y pr&aacute;cticas del mercado.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Estas modificaciones estar&aacute;n disponibles al p&uacute;blico a trav&eacute;s nuestra p&aacute;gina de internet www.petroil.com.mx</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Es responsabilidad del Titular revisar el contenido actualizado del aviso de privacidad disponible en la p&aacute;gina de Internet www.petroil.com.mx</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Petroil asume que si el Titular no se opone a los cambios informando oportunamente a trav&eacute;s de alguno de los medios expuestos en el inciso a del punto VI de este documento, significa que ha le&iacute;do, entendido y consentido los t&eacute;rminos aqu&iacute; establecidos.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><span>Recomendamos que visite, de tiempo en tiempo, las p&aacute;ginas de Internet de Petroil para saber de cualquier modificaci&oacute;n a este Aviso de Privacidad.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><strong><span style="font-weight:600;">X. QUEJAS Y DENUNCIAS POR EL TRATAMIENTO INDEBIDO DE SUS DATOS PERSONALES</span></strong></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;text-align:justify;"><span>Si usted considera que su derecho de protecci&oacute;n de datos personales ha sido lesionado por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violaci&oacute;n a las disposiciones previstas en la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares, podr&aacute; interponer la queja o denuncia correspondiente ante el INAI, para mayor informaci&oacute;n visite www.inai.org.mx.</span></p>'
      +'<p style="margin-block: 0px;"><span>&nbsp;</span></p>'
      +'<p style="margin-block: 0px;"><span>El presente Aviso de Privacidad fue actualizado por &uacute;ltima ocasi&oacute;n el 18 de Noviembre de 2020</span><span>.</span></p>'
      +'</div>',
];

module.exports = {
  bugs,
  website,
  server,
  terminosCondiciones
};
