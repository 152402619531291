// ##############################
// // // Dashboard styles
// #############################

import { successColor,dangerColor,reverseColor } from "assets/jss/material-dashboard-react.jsx";
import {backgroundFiscales } from 'variables/domains/domains.jsx'

const dashboardStyle = {
  successText: {
    color: successColor
  },
  dangerText: {
    color: dangerColor
  },
  reverseText:{
    color:reverseColor
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  oilCardCategory: {
    width: 14,
    height: 14
  },
  imgBanco:{
    width: '100px',
    position: 'static',
    marginLeft: '50px',
    '@media (max-width: 768px)': {
      position: 'relative',
      left: '0px',
      right: '0px',
      //display: 'block'
    }
  },
  backFiscales: {
    'background-image' : `url(${backgroundFiscales})`,
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'block',
    'background-color': '#00315c',
    'background-repeat': 'no-repeat',
    position: 'absolute',
    'background-size': 'cover',
    'background-position': 'center center',
    'z-index': '-2'
  },
  backLoginMascara: {
    //'background' : "linear-gradient(60deg, rgba(0, 49, 92, 0.2), rgba(0, 49, 92, 0.7))",
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    top: '0',
    left: '0',
    width: '100%',
    height: '100VH',
    display: 'block',
    'background-repeat': 'no-repeat',
    position: 'absolute',
    'background-size': 'contain',
    'background-position': 'center center',
    'z-index': '-1'
  },
  estatus:{
    padding: '3px',
    backgroundColor: '#ff9800',
    color: '#fff',
    borderRadius: '3px',
    fontSize: '11px',
    width: 'calc(100% - 4px)',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '14px',
  },
  red: {
    backgroundColor: '#f44336'
  },
  green: {
    backgroundColor: '#4caf50'
  },
  info: {
    backgroundColor: '#29B6F6'
  },
  buttonTable:{
    height: '30px',
    width: '30px',
    '& svg': {
      height: '20px',
      width: '20px',
    },
  },
  redClass:{
    '.ct-point, .ct-line':{
      stroke: '#ffffff',
    }
  },
  buttonLink: {
    width: '40px',
    border: 'none',
    cursor: 'pointer',
    height: '40px',
    margin: 'auto',
    padding: '0',
    display: 'inline-block',
    overflow: 'hidden',
    position: 'relative',
    fontSize: '24px',
    minWidth: '40px',
    boxShadow: '0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)',
    transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    textAlign: 'center',
    fontWeight: '400',
    lineHeight: 'normal',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    willChange: 'box-shadow, transform',
    touchAction: 'manipulation',
    borderRadius: '50%',
    letterSpacing: '0',
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    backgroundImage: 'none',
    backgroundColor: 'rgb(76, 175, 80)',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'rgb(76, 175, 80)',
    },
  },
  customButton: {
    cursor: 'Pointer',
    fontWeight:'bold',
    display: 'inline-block',
    height: '24px',
    width: '30px',
      '&:hover': {
        background: "#dadada",
        borderRadius:'10px',
      },
  },
  footerFiscal: {
    width: '100%',
    textAlign: 'right'
  }
};

export default dashboardStyle;
