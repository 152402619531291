import React from "react";
import PropTypes from "prop-types";
import invoke from "services/invoke.jsx";
import ChartistGraph from "react-chartist";
import { History, Opacity, Search, AccessTime } from "@material-ui/icons";
import { withStyles, Grid, Snackbar, Divider, FormControlLabel, Checkbox, TextField, Tooltip, IconButton, } from "@material-ui/core";
import { ItemGrid, Table, Linear, SpecialCard, ChartCard } from "components";
import orange from '@material-ui/core/colors/orange';
import dashboardStyle from "assets/jss/material-dashboard-react/dashboardStyle";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { dailySalesChart } from "variables/charts";
var sesion = invoke.getSesion();
// var id_EmpresaVenta = (sesion && sesion.estacionObj && sesion.estacionObj[0].id_EmpresaVenta)?sesion.estacionObj[0].id_EmpresaVenta:1;

const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

class Dashboard extends React.Component {

  state = {
    usuario :  (sesion.usuario)? sesion.usuario: {},
    estacion :  (sesion.estacionObj)? sesion.estacionObj[0]: {},
    productos: [],
    precios: [],
    open:       false,
    tr:         false,
    tc:         false,
    message:    '',
    classMask:  'maskProgress',
    fh_Month: '',
    de_Referencia: '',
    de_Tar: '',
    de_Ubicacion: '',
  };

  componentWillMount(){ //es parecido a la funcion init
    if (this.state.estacion.id_Estacion) {
      invoke.resetTime();
    }

    if(sesion.estacionObj.length){
      invoke.post('precios','productos',{id_Cliente: sesion.estacionObj[0].id_Cliente, id_Tar: sesion.estacionObj[0].id_Tar}).then(res => {
        if(res.status===200 && res.data.data){
          var productosVAR = [];
          for(var i in res.data.data){
            var x = res.data.data[i];
            productosVAR.push({
              value: x.id_TipoProducto,
              label: x.nb_TipoProducto,
              color: x.nb_Color,
              id_TipoProducto: x.id_TipoProducto,
            });
            this.setState({
              ['checkedConfirmacion'+x.id_TipoProducto] : 'FALSE',
            });
          }
          this.setState({ productos: productosVAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });
    }
    let date = new Date();
    let monthVAR = ( ( ( parseInt( date.getMonth() ) + 1 ) < 10 )?'0':'') + (parseInt( date.getMonth() ) + 1)
    let fh_inicio = date.getFullYear() +'-'+ monthVAR;
    this.setState({ fh_Month: fh_inicio });

    this.buscar();

  }

  handleClickSearch = () => {
    this.buscar();
  };

  hideMaskProgress(){
    setTimeout(function() {
      this.setState({classMask:'maskProgress desactive'});
      setTimeout(function() {
          this.setState({classMask:'maskProgress'});
      }.bind(this), 1);
    }.bind(this), 1);
  }

  buscar(){
    if(sesion.estacionObj.length){
      this.setState({classMask:'maskProgress active'});
      let id_ProductosVAR = [];
      for(let i in this.state.productos){
        if(this.state['checkedConfirmacion'+this.state.productos[i].id_TipoProducto]==='TRUE'){
          id_ProductosVAR.push(this.state.productos[i].id_TipoProducto);
        }
      }

      let obj = {
        id_Cliente: sesion.estacionObj[0].id_Cliente, 
        id_Tar: sesion.estacionObj[0].id_Tar, 
        fh_Operacion: new Date(this.state.fh_Month),
        id_Productos: (id_ProductosVAR)?id_ProductosVAR.join():null
      }

      invoke.post('precios','historialPrecios', obj).then(res => {
        if(res.status===200 && res.data.data){
          let preciosVAR = [];
          let dias = [];
          let seriesVAR = [];
          for(let i in res.data.data){
            let x = res.data.data[i];
            if(i === '0' || i === 0){
              this.setState({ de_Referencia: x.nb_Refineria });
              this.setState({ de_Ubicacion: x.de_Ubicacion });
              this.setState({ de_Tar: x.de_Tar });
            }
            
            if(!dias.find(function(element) { return element === x.fh_Dia; })){
              dias.push(x.fh_Dia);
            }
            preciosVAR.push([
              (<div><Opacity style={{textAlign: 'left', color: (x.nb_Color)?x.nb_Color:'#000000', verticalAlign: 'sub'}} /> {x.nb_TipoProducto}</div>),
              (<div style={{textAlign: 'center'}}>{x.fh_PrecioConvert}</div>),
              (<div style={{textAlign: 'center'}}>${x.im_Precio}</div>),
            ]);
          }

          let productoVAR = [];
          for(let y in this.state.productos){
            productoVAR = [];
            for(let z in res.data.data){
              if(res.data.data[z].id_TipoProducto === this.state.productos[y].id_TipoProducto){
                productoVAR.push({value: res.data.data[z].im_Precio});
              }
            }
            seriesVAR.push({value: productoVAR.reverse(), className: 'ct-series-'+this.state.productos[y].id_TipoProducto, name: 'Precios', meta: 'Precio'});
          }

          dailySalesChart.data.labels = dias.reverse();
          dailySalesChart.data.series = seriesVAR;
          this.setState({ precios: preciosVAR });
        }else{
          this.showNotification("tr", res.message)
        }
        this.hideMaskProgress();
      });
    }
  }

  handleChange = name => event => {
    invoke.resetTime();
    let checkVar = false;
    if(this.state.productos.find(function(element) { return 'checkedConfirmacion'+element.id_TipoProducto === name; })){
      checkVar = true;
    }
    if( checkVar ){
      this.setState({
        [name]: (event.target.value==='FALSE')?'TRUE':'FALSE',
      }, () => {
        this.buscar();
      });
    }else{
      this.setState({
        [name]: (event.target)?event.target.value:event,
      }, () => {
        this.buscar();
      });
    }
  };

  handleChangeValidate = (name,validate) => event => {
    invoke.resetTime();
    if(validate.maxLength && (event.target.value).length <= validate.maxLength){
      this.setState({
        [name]: event.target.value,
      });
    }
    else if(validate.date){
      var VAR = new Date((validate.min).toISOString().slice(0,10));
      var VAL = new Date(event.target.value);
      if(VAR <= VAL){
        this.setState({
          [name]: event.target.value,
        });
      }
    }
  };

  showNotification(place,message,time=5000) {
    this.setState({message : message})
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  handleChangeIndex = index => {
    invoke.resetTime();
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Grid container>
            <ItemGrid xs={12} sm={12} md={12}>
              <SpecialCard
                icon={History}
                iconColor="orange"
                cardTitle="Historial de Precio"
                cardSubtitle='Comportamiento de los precios de los producto'
                content={(<div>
                  <Grid container>
                    <ItemGrid xs={12} sm={12} md={12}>
                      {this.state.productos.map((option,index) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state['checkedConfirmacion'+option.id_TipoProducto]==='TRUE')?true:false}
                              onChange={this.handleChange('checkedConfirmacion'+option.id_TipoProducto)}
                              value={this.state['checkedConfirmacion'+option.id_TipoProducto]}
                              style={{color: option.color}}
                            />
                          }
                          label={(<p><Opacity style={{color:option.color, verticalAlign: 'sub'}}/> {option.label}</p>)}
                        />
                      ))}
                    </ItemGrid>
                  </Grid>
                  <Divider />
                  <Grid container>
                    <ItemGrid xs={12} sm={2} md={2}>
                      <TextField
                        id="de_Referencia"
                        label="Refineria"
                        className={classes.textField}
                        value={this.state.de_Referencia}
                        onChange={this.handleChange('de_Referencia')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#ff9800'},
                        }}
                        InputProps={{
                          style: {color: '#000000'}
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2} md={2}>
                      <TextField
                        id="de_Tar"
                        label="Tar"
                        className={classes.textField}
                        value={this.state.de_Tar}
                        onChange={this.handleChange('de_Tar')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#ff9800'},
                        }}
                        InputProps={{
                          style: {color: '#000000'}
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2} md={2}>
                      <TextField
                        id="de_Ubicacion"
                        label="Ubicación"
                        className={classes.textField}
                        value={this.state.de_Ubicacion}
                        onChange={this.handleChange('de_Ubicacion')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#ff9800'},
                        }}
                        InputProps={{
                          style: {color: '#000000'}
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2} md={2}>
                      <TextField
                        id="fh_Month"
                        label="Fecha de Precios"
                        error={this.state.requiredMonth}
                        type="month"
                        value={this.state.fh_Month}
                        onChange={this.handleChange('fh_Month')}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                          style: {color: '#ff9800'},
                        }}
                        margin="normal"
                        fullWidth
                      />
                    </ItemGrid>
                    <ItemGrid xs={1} sm={1} md={1}>
                      <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                        <IconButton
                            color="primary"
                            style={{marginTop: '20px'}}
                            aria-label="Agregar"
                            aria-haspopup="true"
                            onClick={this.handleClickSearch}>
                          <Search />
                        </IconButton>
                      </Tooltip>
                    </ItemGrid>
                  </Grid>
                  <Grid container>
                    {(this.state.precios.length)?(
                      <ItemGrid xs={12} sm={5} md={5}>
                        <Table xs={12} sm={12} md={12}
                            tableHead={[
                              { disablePadding: false, label: 'Producto' },
                              { disablePadding: false, label: 'Fecha' },
                              { disablePadding: false, label: 'Precio' },
                            ]}
                            tableData={this.state.precios}
                            rowsPerPage={10}
                            rowsPerPageOptions={[10,50,100,1000]}
                        />
                      </ItemGrid>):null}
                    {(this.state.precios.length)?(<ItemGrid xs={12} sm={7} md={7}>
                      <ChartCard
                        chart={
                          <ChartistGraph
                            className="ct-chart ct-precios"
                            data={dailySalesChart.data}
                            type="Line"
                            options={dailySalesChart.options}
                            listener={dailySalesChart.animation}
                          />
                        }
                        chartColor="gray"
                        title={'Historial de Precio Mensual'}
                        text={'Precios Diarios'}
                        statIcon={AccessTime}
                        statText="Actualizado hoy"
                      />
                    </ItemGrid>):null}
                    </Grid>
                </div>)}
                />
            </ItemGrid>
          </Grid>
        </MuiThemeProvider>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.tr}
          onClose={() => this.setState({ tr: false })}
          ContentProps={{ 'className': classes.red }}
          message={this.state.message}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.tc}
          onClose={() => this.setState({ tc: false })}
          ContentProps={{ 'className': classes.green }}
          message={this.state.message}
        />
        <div className={this.state.classMask}>
          <Linear  />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
