//import {domains, hostname} from "assets/jss/material-dashboard-react/cajaLoginStyle";
import React from "react";
import PropTypes from "prop-types";
import invoke from "services/invoke.jsx";
//import { Info} from "@material-ui/icons";
import { withStyles,  Snackbar} from "@material-ui/core";
import { Linear } from "components";
import dashboardStyle from "assets/jss/material-dashboard-react/dashboardStyle";
//import TextField from '@material-ui/core/TextField';
import InformacionFiscal from '../../components/Customs/InformacionFiscal'



var sesion = invoke.getSesionCliente();
class Dashboard extends React.Component {
  state = {
    tr: false,
    tc: false,
    cliente :  (sesion.cliente)? sesion.cliente: {},
    info: {},
    classMask:  'maskProgress',
    fl_Pedido: '',
    fh_Inicio: '',
    fh_Fin: '',


  };

  componentWillMount(){ //es parecido a la funcion init

  }


  handleChangeValidate = (name,validate) => event => {
    invoke.resetTime();
    if(validate.maxLength && (event.target.value).length <= validate.maxLength){
      this.setState({
        [name]: event.target.value,
      });
    }
    else if(validate.date){
      var VAR = new Date((validate.min).toISOString().slice(0,10));
      var VAL = new Date(event.target.value);
      if(VAR <= VAL){
        this.setState({
          [name]: event.target.value,
        });
      }
    }
  };

  noOfDaysInMonth(){
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  getFirstDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      1,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  getLastDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      this.noOfDaysInMonth(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  showNotification(place,message,time=5000) {
    this.setState({message : message})
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  routeChange = (path) => event => {
    this.props.history.push(path);
  }

  handleChangeIndex = index => {
    invoke.resetTime();
    this.setState({ value: index });
  };
  handleCloseSesion = () => {
    invoke.logout2();
    window.location.reload();
  };


  render() {
    const { classes } = this.props;
    if (sesion) {
      return (
        <div>
          <InformacionFiscal />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.tc}
            onClose={() => this.setState({ tc: false })}
            ContentProps={{ 'className': classes.gree }}
            message={this.state.message}
          />
          <div className={classes.backFiscales}></div>
          <div className={classes.backLoginMascara}></div>
          <div className={this.state.classMask}>
            <Linear  />
          </div>
        </div>
      );
    } else {
      return null
    }
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
