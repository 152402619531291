import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          <Typography>{children}</Typography>
      )}
    </div>
  );
}


class TabsPortal extends React.Component {
  state = {
    activeTab: 0,
  };

  handleChange = (event, newValue) => {
    this.setState(state => ({
      activeTab: newValue
    }));
  };

  render() {
    const { classes, tabContent, getPrecioDiario } = this.props;
    let { activeTab } = this.state;
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs value={activeTab} onChange={this.handleChange} aria-label="simple tabs example" indicatorColor="primary" textColor="primary" variant="fullWidth">
          {tabContent.map(prop => (
            <Tab label= {prop.label} {...a11yProps(prop.index)} onClick={()=>{getPrecioDiario(prop.index)}} style={{fontWeight:'bold'}}/>
          ))}
          </Tabs>
        </AppBar>
        {tabContent.map(prop => (
          <TabPanel value={activeTab} index={prop.index}>
            <div>{prop.html}</div>
          </TabPanel>
        ))}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default withStyles(styles)(TabsPortal);
