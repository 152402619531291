import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
//import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    boxShadow: 'none'
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  head: {
    height: '48px'
  },
  cell: {
    width: '1%',
    color: '#333',
    boxSizing: 'border-box',
    whiteSpace: 'initial',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '10px',
    textAlign: 'center',
    lineHeight: '15px',
    fontWeight: 'bold',
  },
  cellBody: {
    overflow: 'hidden',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '5px',
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  center: {
    textAlign: 'center'
  },
  title: {
    padding: '20px',
  }
});

function CustomizedTable(props) {
  const { classes, textAlign, tableHead, tableData } = props;
  if (textAlign) {
    let ta = {textAlign: textAlign}
    classes.cellBody = {...ta}
  }
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        {(tableHead.length > 0)?(<TableHead>
          <TableRow className={classes.head}>
            {tableHead.map((column, key) => {
              return (
                <Tooltip
                    title={column.label?column.label:''}
                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={1000}
                    key={key}
                  >
                  <TableCell key={key} className={classes.cell}>
                    {column.label}
                  </TableCell>
                </Tooltip>
              );
            }, this)}
          </TableRow>
        </TableHead>):null}
        <TableBody>
          {tableData.map((prop, key) => {
              return (
                <TableRow
                    hover
                    key={key}
                  >
                  {prop.map((prop, key2) => {
                    return (
                      <Tooltip
                        title={prop?((prop.props)?'':prop):''}
                        placement={'bottom-start'}
                        enterDelay={300}
                        key={key2}
                      >
                        <TableCell key={key2} className={classes.cellBody}>
                          {prop}
                        </TableCell>
                      </Tooltip>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHead: PropTypes.arrayOf(PropTypes.object),
  tableData: PropTypes.arrayOf(PropTypes.array)
};

export default withStyles(styles)(CustomizedTable);