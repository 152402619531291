import React from "react";
import PropTypes from "prop-types";
import ReactExport from 'react-data-export';
import invoke from "services/invoke.jsx";
import ChartistGraph from "react-chartist";
import { AccessTime, Today, TrendingUp, AttachMoney, MoneyOff, Assignment, MonetizationOn, Opacity, Search, AssignmentReturned, LocalGasStation, Contacts, Info, Warning } from "@material-ui/icons";
import { withStyles, Grid, TextField, Tooltip, IconButton, Snackbar, InputAdornment, MenuItem  } from "@material-ui/core";
import { StatsCard, ChartCard, ItemGrid, Table, TableCustom, TablePrecios, FormDialog, Linear, SpecialCard, Tabs } from "components";
import { dailySalesChart } from "variables/charts";
import dashboardStyle from "assets/jss/material-dashboard-react/dashboardStyle";
import * as moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

var sesion = invoke.getSesion();
var id_EmpresaVenta = (sesion && sesion.estacionObj && sesion.estacionObj[0].id_EmpresaVenta)?sesion.estacionObj[0].id_EmpresaVenta:1;

class Dashboard extends React.Component {

  state = {
    usuario :  (sesion)? sesion.usuario: {},
    estacion :  (sesion)? sesion.estacionObj[0]: {},
    info: {},
    precios:[],
    multiDataSet: [],
    multiDataSetExcel: [],
    estaciones: [],
    descriptionFactura: '',
    tableData: [],
    tableDataInfo: [],
    estatuscobranza: [],
    descuentos: [],
    openDialogFactura: false,
    openDialogFacturaView: false,
    openDialogSimple: false,
    urlFactura: '',
    urlFacturaDownload: '',
    classMask:  'maskProgress',
    id_EstatusCobranza: '',
    nb_EstatusCobranza: 'Todos',
    id_Estacion: '',
    nb_Estacion: '',

    fl_Pedido: '',
    fh_Inicio: '',
    fh_Fin: '',
    preciosData: [],
    sn_CondicionesComercialesPortal: 0,
    fh_Precio: '',
    fh_PrecioMañana: '',
    // Modal para aviso de posible fraude
    openDialogNotificacion: false,
    de_DescripcionAvisoNotificacion: 'Estimado cliente, GRUPO PETROIL no envía correos notificando cambio de cuentas para pagos.'
  };

  componentWillMount(){ //es parecido a la funcion init
    if (this.state.estacion) {
      this.getInfoCliente();
      this.getHistorialPrecios();
      this.getPrecioDiario(0);
      this.openModalAviso();
      invoke.resetTime();
    }

    //Combo de Estatus Factura
    invoke.post('facturas','estatusId',{id_Inicial: 1511,id_Final: 1513}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value:0,label:'Todas'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Estatus,
            label: x.de_Estatus,
            obj: x
          });
        }
        this.setState({ estatuscobranza: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Estaciones
    invoke.post('estacion','combo',{id_Usuario: sesion.usuario.id_Usuario, id_Estacion: null}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value:0,label:'Todos'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Estacion,
            label: x.nb_Estacion,
            obj: x
          });
        }
        this.setState({ estaciones: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    if(sesion.estacionObj.length){
      // Descuentos
      invoke.post('descuentos','listado',{id_Cliente: sesion.estacionObj[0].id_Cliente, id_Estacion: sesion.estacionObj[0].id_Estacion, id_Empresa: id_EmpresaVenta}).then(res => {
        if(res.status===200 && res.data.data){
          let VAR = [];
          for(let i in res.data.data){
            const x = res.data.data[i];
            VAR.push([
              (<div><Opacity style={{color: (x.nb_Color)?x.nb_Color:'#000000', verticalAlign: 'sub'}} /> {x.nb_Producto}</div>),
              (<div style={{textAlign: 'center'}}>{x.im_Descuento}</div>),
              (<div style={{textAlign: 'center'}}>{x.im_Descuento}</div>)
            ]);
          }
          this.setState({ descuentos: VAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });
    }
    

    var fh_inicio = this.getFirstDayOfMonth();
    var fh_fin = this.getLastDayOfMonth();
    fh_inicio = fh_inicio.toISOString().slice(0,10);
    fh_fin = fh_fin.toISOString().slice(0,10);

    this.setState({ fh_Inicio: fh_inicio });
    this.setState({ fh_Fin: fh_fin });
  }

  downloadPdf = (url,urlDescarga) => event => {
    this.setState({ openDialogFacturaView: true });
    this.setState({ urlFactura: url });
    this.setState({ urlFacturaDownload: urlDescarga });
  };

  handleClickFactura = () => {
    window.open(this.state.urlFacturaDownload, "_blank")
    //window.location.href = this.state.urlFacturaDownload;
    this.setState({ openDialogFacturaView: false });
  };

  handleClickCloseFacturaView = () => {
    this.setState({ openDialogFacturaView: false });
  };

  handleClickSearch = () => {
    this.buscar();
  };

  buscar(){
    const { classes } = this.props;
    var obj = {
      id_Usuario: sesion.usuario.id_Usuario,
      id_Estacion: this.state.id_Estacion?this.state.id_Estacion:null,
      fl_Pedido: this.state.fl_Pedido,
      fh_Inicio: this.state.fh_Inicio,
      fh_Fin: this.state.fh_Fin,
      id_Estatus: this.state.id_EstatusCobranza,
      id_Cliente : sesion.usuario.id_Cliente,
    }
    for(var i in this.state.estatuscobranza){
      if(this.state.estatuscobranza[i].value===this.state.id_EstatusCobranza){
        this.setState({nb_EstatusCobranza : this.state.estatuscobranza[i].label})
      }
    }
    for(i in this.state.estaciones){
      if(this.state.estaciones[i].value===this.state.id_Estacion){
        this.setState({nb_Estacion : this.state.estaciones[i].label})
      }
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('facturas','listado',obj).then(res => {
      if(res.status===200 && res.data.data){
          var tableDataVAR = [];
          for(var i in res.data.data){
            var PD = res.data.data[i];
            tableDataVAR.push([
              PD.fl_Pedido,
              PD.fl_FolioDocumento,
              PD.fh_Documento,
              PD.fh_Vencimiento,
              PD.nb_Producto,
              PD.nu_Cantidad,
              PD.im_Total,
              PD.im_SaldoFactura,
              PD.nb_Moneda,
              PD.DPorVencer,
              PD.Dvencidos,
              (
                <Tooltip
                  title={PD.de_Estatus}
                  placement={'bottom-start'}
                  enterDelay={300}>
                  <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                </Tooltip>
              ),
              (
                <Tooltip
                  title={PD.de_EstatusCobranza}
                  placement={'bottom-start'}
                  enterDelay={300}>
                  <span className={classes.estatus} style={{background: PD.de_ColorCobranza}}>{PD.de_EstatusCobranza}</span>
                </Tooltip>
              ),
              (
                <div>
                  <Tooltip
                      title='Visualizar Factura PDF'
                      placement={'bottom-start'}
                      enterDelay={300}>
                    <IconButton style={{color:'#C62828'}} aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} className={classes.buttonTable}>
                      <AssignmentReturned className={classes.links} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title='Descargar Factura XML'
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <a href={PD.urlFacturaDownloadXML} download={PD.fl_FolioDocumento}>
                      <IconButton style={{color:'#388E3C'}} aria-label="DescargarXML" aria-haspopup="true" className={classes.buttonTable}>
                        <AssignmentReturned className={classes.links} />
                      </IconButton>
                    </a>
                  </Tooltip>
                </div>
              )
            ]);
          }

          var DataVAR = [];
          for(i in res.data.data){
            PD = res.data.data[i];
            DataVAR.push([
              {value: (PD.fl_Pedido)?PD.fl_Pedido:'', style: {type:'number', numFmt: "0",alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.fl_FolioDocumento)?PD.fl_FolioDocumento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.fh_Documento)?PD.fh_Documento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.fh_Vencimiento)?PD.fh_Vencimiento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.nb_Producto)?PD.nb_Producto:'', style: {alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' }}},
              {value: (PD.nu_CantidadSinFormato)?PD.nu_CantidadSinFormato:0, style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
              {value: (PD.im_TotalSinFormato)?PD.im_TotalSinFormato:0, style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
              {value: (PD.im_SaldoFacturaSinFormato)?PD.im_SaldoFacturaSinFormato:0, style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
              {value: (PD.nb_Moneda)?PD.nb_Moneda:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.DPorVencer)?PD.DPorVencer:0, style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.Dvencidos)?PD.Dvencidos:0, style: {type:'number', numFmt: "0", alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.de_Estatus)?PD.de_Estatus:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
              {value: (PD.de_EstatusCobranza)?PD.de_EstatusCobranza:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}}
            ]);
          }

          var multiDataSet = [
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: 'Listado de Facturas', style: { font: { sz:'14', bold: true } }},
                ],
              ]
            },
            {
              xSteps: 8,
              ySteps: -2,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: ((new Date()).toISOString().slice(0,10))+'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'14', bold: true } }},
                ],
              ]
            },
            {
              xSteps: 1,
              ySteps: 0,
              columns: [
                {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
              ],
              data: [
                [
                  {value: 'Estatus Cobranza: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.nb_EstatusCobranza+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Estacion(es): ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.nb_Estacion+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Inicio: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Inicio+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Fin: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Fin+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ]
              ]
            },
            {
              xSteps: 0,
              ySteps: 1,
              columns: [
                {value:'Folio Pedido',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Folio Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Fecha Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Fecha Vencimiento',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Producto',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Litros',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Importe',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Saldo',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Moneda',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Dias Por Vencer',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Dias Vencidos',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Estatus Factura',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Estatus Cobranza',style: { wpx: '85', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              ],
              data: DataVAR
            }
          ];
          this.setState({ multiDataSetExcel: multiDataSet });

          if(!tableDataVAR.length){
            this.showNotification("tr", 'No se encontraron Facturas')
          }
          this.setState({ tableData: tableDataVAR });
      }else{
        this.showNotification("tr", res.data.message)
      }

      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  handleClickOpenFactura = () => {
    this.setState({ openDialogFactura: true });
    this.buscar();
  };

  handleClickCloseFactura = () => {
    this.setState({ openDialogFactura: false });
  };

  handleChange = name => event => {
    invoke.resetTime();
    this.setState({
      [name]: event.target.value,
    }, () => {
      if( name === 'id_Producto' || name === 'fl_Pedido' || name === 'id_Estatus' || name === 'fh_Inicio' || name === 'fh_Fin'){
        //this.buscar();
      }
    });
  };

  handleChangeValidate = (name,validate) => event => {
    invoke.resetTime();
    if(validate.maxLength && (event.target.value).length <= validate.maxLength){
      this.setState({
        [name]: event.target.value,
      });
    }
    else if(validate.date){
      var VAR = new Date((validate.min).toISOString().slice(0,10));
      var VAL = new Date(event.target.value);
      if(VAR <= VAL){
        this.setState({
          [name]: event.target.value,
        });
      }
    }
  };

  noOfDaysInMonth(){
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  getFirstDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      1,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  getLastDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      this.noOfDaysInMonth(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  showNotification(place,message,time=5000) {
    this.setState({message : message})
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  getInfoCliente(){
    let filtros = {
      id_Usuario : this.state.usuario.id_Usuario,
      id_Estacion : sesion.estacion,
      id_Cliente : sesion.usuario.id_Cliente,
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('usuario','getinfoCliente',filtros)
      .then(res => {
        if(res.status===200 && res.data.data){
          this.setState({info : res.data.data[0]})
          this.setState({sn_CondicionesComercialesPortal: res.data.data[0].sn_CondicionesComercialesPortal})
          var tableDataVAR = [];
          tableDataVAR.push([
            (<TrendingUp />),
            'Linea de Credito',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_LineaDeCredito)? parseFloat(this.state.info.im_LineaDeCredito).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          tableDataVAR.push([
            (<Today />),
            'Plazo Autorizado',
            (<span style={{textAlign:'right',display:'block'}}>
              {((this.state.info && this.state.info.nu_DiasCredito)? parseFloat(this.state.info.nu_DiasCredito).toLocaleString("en-US")  : '0') + ' dias'}
            </span>)
          ]);
          tableDataVAR.push([
            (<MonetizationOn />),
            'Credito Disponible',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_CreditoDisponible)? parseFloat(this.state.info.im_CreditoDisponible).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          tableDataVAR.push([
            (<Assignment />),
            'Importe Facturado/Entregado',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_SaldoFacturadoEntregado)? parseFloat(this.state.info.im_SaldoFacturadoEntregado).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          tableDataVAR.push([
            (<MoneyOff />),
            'Saldo Vencido',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_SaldoVencido)? parseFloat(this.state.info.im_SaldoVencido).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          tableDataVAR.push([
            (<AttachMoney />),
            'Saldo Pedidos No Entregados',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_SaldoPedidosNoEntregados)? parseFloat(this.state.info.im_SaldoPedidosNoEntregados).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          tableDataVAR.push([
            (<AttachMoney />),
            'Saldo Pendiente Total',
            (<span style={{textAlign:'right',display:'block'}}>
              {"$"+ ((this.state.info && this.state.info.im_SaldoTotal)? parseFloat(this.state.info.im_SaldoTotal).toLocaleString("en-US")  : '0')}
            </span>)
          ]);
          this.setState({tableDataInfo : tableDataVAR})
          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 100);
          }.bind(this), 1);

        }
    })
  }

  // Aviso para notificación de prevención de fraude
  openModalAviso = () => {
    if(localStorage.getItem("showDialogNotificacion") === 'true'){
      this.setState({ openDialogNotificacion: true });
    }
  };
  
  handleClickCloseAviso = () => {
    this.setState({ openDialogNotificacion: false });
    localStorage.setItem('showDialogNotificacion', false) // seteamos a falso para solo mostrar el modal
  };

  getHistorialPrecios(){
    invoke.post('productos','getHistorialPrecios',{id_Tar: this.state.estacion.id_Tar})
      .then(res => {
        if(res.status===200 && res.data){
          let data = res.data.data;
          if(data.length){
            let preciosMagna = [];
            let preciosPremium = [];
            let preciosDiesel = [];
            let dias = [];
            let fechaArray = [];
            let fecha = new Date();

            fechaArray.push({dia : fecha.getDay(), fecha : fecha.getDate()});
            for(let i=0; i<=5; i++ ){
              fecha.setDate(fecha.getDate() - 1);
              fechaArray.push({dia : fecha.getDay(), fecha : fecha.getDate()});
            }

            fechaArray.forEach((itemVar) => {
              if(itemVar.dia === 1){
                dias.push('L');
              }else if(itemVar.dia === 2){
                dias.push('M');
              }else if(itemVar.dia === 3){
                dias.push('M');
              }else if(itemVar.dia === 4){
                dias.push('J');
              }else if(itemVar.dia === 5){
                dias.push('V');
              }else if(itemVar.dia === 6){
                dias.push('S');
              }else if(itemVar.dia === 0){
                dias.push('D');
              }
              data.forEach((item) => {
                var fechaDia = new Date(item.fh_PrecioConvert)
                if(itemVar.dia===item.dia && fechaDia.getDate() >= itemVar.fecha){
                  switch (item.id_TipoProducto){
                    case 1 :
                        preciosDiesel.push(item.value)
                      break;
                    case 11 :
                        preciosMagna.push(item.value)
                      break;
                    case 3:
                        preciosPremium.push(item.value)
                      break;
                    default:
                      break;
                  }
                }
              })
            })

            let precios = []
            precios.push(preciosDiesel.reverse())
            precios.push(preciosMagna.reverse())
            precios.push(preciosPremium.reverse())
            this.setState({precios:precios})
            dailySalesChart.data.labels = dias.reverse()
            dailySalesChart.data.series = this.state.precios;
          }
      }
    })
  }

  getPrecioDiario(idx){
    // Parámetro Tipo Consulta
    // 1 para consultar el día de hoy
    // 2 Para consultar el día de mañana
    const datos = {
      id_Cliente: sesion.estacionObj[0].id_Cliente, 
      id_Estacion: sesion.estacionObj[0].id_Estacion,
      id_TipoConsulta: (idx + 1),
    }
    invoke.post('productos','getPrecioDiario',datos).then(res => {
        if(res.status===200 && res.data){
          let data = res.data.data;
          const preciosData =[];
          if(data.length){
            for(var i in res.data.data){
              var obj = res.data.data[i];
              preciosData.push([
                obj.Concepto,
                obj.im_PrecioMagna,
                obj.im_PrecioPremium,
                obj.im_PrecioDiesel,
              ]);
            }
            
            this.setState({ fh_Precio: data[0].currentDate });
            this.setState({ fh_PrecioMañana: data[0].tomorrowDate });
          }
        this.setState({ preciosData: preciosData });
      }
    })
  }

  handleChangeIndex = index => {
    invoke.resetTime();
    this.setState({ value: index });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container>
          <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={Assignment}
              iconColor="blueSip"
              title="Importe Facturado / Entregado"
              description={"$"+ ((this.state.info && this.state.info.im_SaldoFacturadoEntregado_Estacion)? parseFloat(this.state.info.im_SaldoFacturadoEntregado_Estacion).toLocaleString("en-US")  : '0')}
              statIcon={LocalGasStation}
              statText={(<span style={{textTransform:'uppercase', verticalAlign: 'middle'}}>{(sesion.estacionObj[0])?sesion.estacionObj[0].nb_Estacion:''}</span>)}
              action={this.handleClickOpenFactura}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={MoneyOff}
              iconColor="red"
              title="Saldo Vencido"
              description={"$"+ ((this.state.info && this.state.info.im_SaldoVencido_Estacion)? parseFloat(this.state.info.im_SaldoVencido_Estacion).toLocaleString("en-US")  : '0')}
              statIcon={LocalGasStation}
              statText={(<span style={{textTransform:'uppercase', verticalAlign: 'middle'}}>{(sesion.estacionObj[0])?sesion.estacionObj[0].nb_Estacion:''}</span>)}
              action={this.handleClickOpenSimple}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={AttachMoney}
              iconColor="orange"
              title="Saldo Pedidos No Entregados"
              description={"$"+ ((this.state.info && this.state.info.im_SaldoPedidosNoEntregados_Estacion)? parseFloat(this.state.info.im_SaldoPedidosNoEntregados_Estacion).toLocaleString("en-US")  : '0')}
              statIcon={LocalGasStation}
              statText={(<span style={{textTransform:'uppercase', verticalAlign: 'middle'}}>{(sesion.estacionObj[0])?sesion.estacionObj[0].nb_Estacion:''}</span>)}
              />
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={AttachMoney}
              iconColor="green"
              title="Saldo a Favor"
              description={"$"+ ((this.state.info && this.state.info.im_SaldoPorAplicar)? parseFloat(this.state.info.im_SaldoPorAplicar).toLocaleString("en-US")  : '0')}
              statIcon={LocalGasStation}
              statText={(<span style={{textTransform:'uppercase', verticalAlign: 'middle'}}>{(sesion.estacionObj[0])?sesion.estacionObj[0].nb_Estacion:''}</span>)}
              />
          </ItemGrid>
        </Grid>
        <br/>
        <Grid container>
          <ItemGrid xs={12} sm={6} md={6}>
            <SpecialCard
              icon={Contacts}
              iconColor="green"
              cardTitle={(<div><span style={{textTransform: 'uppercase'}}>{sesion.usuario.de_RazonSocial}</span></div>)}
              cardSubtitle='Informacion general del Cliente'
              content={(<div>
                <TableCustom xs={12} sm={12} md={12}
                    tableHead={[]}
                    tableData={this.state.tableDataInfo}
                />
            </div>)}
            />
          </ItemGrid>
          {/* Condiciones Comerciales del cliente */}
          {(this.state.sn_CondicionesComercialesPortal === 1 || this.state.sn_CondicionesComercialesPortal === true)?(
          <ItemGrid xs={12} sm={6} md={6}>
            <SpecialCard
              icon={MonetizationOn}
              iconColor="blueSip"
              cardTitle={(<div><span style={{textTransform: 'uppercase'}}>Precios del día</span></div>)}
              cardSubtitle={(<span><LocalGasStation style={{fontSize:' 18px', verticalAlign: 'sub'}}/> <span style={{textTransform:'uppercase', verticalAlign: 'middle'}}>{(sesion.estacionObj[0])?sesion.estacionObj[0].nb_Estacion:''}</span></span>)}
              content={(<div>
              <Tabs 
                tabContent={[
                  {
                    label:'Hoy',
                    index: 0,
                    html:(<div style={{height:'288px'}}>
                      {(this.state.preciosData.length)?
                        <div style={{marginTop:'10px', display:'inline-block', width: '100%'}}>
                          <TablePrecios xs={12} sm={12} md={12}
                              tableHead={[
                                { id: 'Concepto', numeric: true, disablePadding: false, label: ''},
                                { id: 'im_PrecioMagna', numeric: false, disablePadding: false, label: '87 Octanos', color: 'green'},
                                { id: 'im_PrecioPremium', numeric: false, disablePadding: false, label: '91 Octanos', color: 'red' },
                                { id: 'im_PrecioDiesel', numeric: false, disablePadding: false, label: 'Diesel', color: 'black'},
                              ]}
                              tableData={this.state.preciosData}/>
                              <div><Today style={{fontSize:' 18px', verticalAlign: 'sub'}}/> <span style={{paddingTop:'10px', display:'inline-block'}}><i>Precios para la fecha {(this.state.fh_Precio)}</i></span></div>
                        </div>
                        : <div><Info style={{fontSize:' 18px', verticalAlign: 'sub'}}/> <span style={{paddingTop:'10px', display:'inline-block'}}><i>No se encontró precio para la fecha requerida</i></span></div>}
                      </div>),
                  },
                  {
                    label:'Mañana',
                    index: 1,
                    html:(<div style={{height:'288px'}}>
                      {(this.state.preciosData.length)?
                      <div style={{marginTop:'10px', display:'inline-block', width: '100%'}}>
                        <TablePrecios xs={12} sm={12} md={12} style={{marginTop:'5px;', display:'inline-block'}}
                            tableHead={[
                              { id: 'Concepto', numeric: true, disablePadding: false, label: '' },
                              { id: 'im_PrecioMagna', numeric: false, disablePadding: false, label: '87 Octanos', color: 'green' },
                              { id: 'im_PrecioPremium', numeric: false, disablePadding: false, label: '91 Octanos', color: 'red' },
                              { id: 'im_PrecioDiesel', numeric: false, disablePadding: false, label: 'Diesel', color: 'black' },
                            ]}
                            tableData={this.state.preciosData}/>  
                        <div><Today style={{fontSize:' 18px', verticalAlign: 'sub'}}/> <span style={{paddingTop:'10px', display:'inline-block'}}><i>Precios para la fecha {(this.state.fh_PrecioMañana)}</i></span></div>
                      </div> 
                      : <div> <Info style={{fontSize:' 18px', verticalAlign: 'sub'}}/> <span style={{paddingTop:'10px', display:'inline-block'}}><i>No se encontró precio para la fecha requerida</i></span></div>}
                      </div>),
                  }
                ]}
                getPrecioDiario={this.getPrecioDiario.bind(this)}
              />
            </div>)}
            />
          </ItemGrid>):null} 
          {(this.state.sn_CondicionesComercialesPortal === 1 || this.state.sn_CondicionesComercialesPortal === true)?(
            <ItemGrid xs={12} sm={12} md={6}></ItemGrid>
          ):null}
          {(this.state.descuentos.length && 1===2)?(
          <ItemGrid xs={12} sm={6} md={6}>
              <SpecialCard
                icon={MonetizationOn}
                iconColor="orange"
                cardTitle={(<div><span style={{textTransform: 'uppercase'}}>Condición Comercial</span></div>)}
                cardSubtitle={(<span><LocalGasStation style={{fontSize:' 18px', verticalAlign: 'sub'}}/> <span style={{textTransform:'uppercase', verticalAlign: 'middle'}}>{(sesion.estacionObj[0])?sesion.estacionObj[0].nb_Estacion:''}</span></span>)}
                content={(<div>
                  <TableCustom xs={12} sm={12} md={12}
                      tableHead={[
                        { id: 'id_Producto', numeric: true, disablePadding: false, label: 'Producto' },
                        { id: 'id_Producto', numeric: true, disablePadding: false, label: 'Descuento Por Litro (CREDITO)' },
                        { id: 'id_Producto', numeric: true, disablePadding: false, label: 'Descuento Por Litro (CONTADO)' },
                      ]}
                      tableData={this.state.descuentos}
                  />
              </div>)}
            />
          </ItemGrid>): null} 
          {(this.state.precios.length)?(<ItemGrid xs={12} sm={12} md={6}>
            <ChartCard
              chart={
                <ChartistGraph
                  className="ct-chart ct-precios"
                  data={dailySalesChart.data}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                />
              }
              chartColor="gray"
              title={'Precios del Dia '+((this.state.precios.length > 0 || this.state.precios[0].length || this.state.precios[0][0].length)? moment(this.state.precios[0][0].fh_PrecioConvert).format('DD/MM/YYYY') : '')+(sesion.estacionObj[0].nb_Tar?(' - TAR '+sesion.estacionObj[0].nb_Tar):'')}
              text={
                  <span>
                    <span className={this.props.classes.successText}>
                      <Opacity
                        className={this.props.classes.oilCardCategory}
                      />{" "}
                      ${(this.state.precios.length)? this.state.precios[1][this.state.precios[1].length-1] : 0}
                    </span>{" "}
                    {(this.state.precios.length)?' 87 Octanos' : 0}
                  <br/>
                  <span className={this.props.classes.dangerText}>
                    <Opacity
                      className={this.props.classes.oilCardCategory}
                    />{" "}
                    ${(this.state.precios.length)? this.state.precios[2][this.state.precios[1].length-1] : 0}
                  </span>{" "}
                  {(this.state.precios.length)? ' 91 Octanos' : 0}
                  <br/>
                  <span className={this.props.classes.reverseText}>
                    <Opacity
                      className={this.props.classes.oilCardCategory}
                    />{" "}
                    ${(this.state.precios.length)? this.state.precios[0][this.state.precios[1].length-1] : 0}
                  </span>{" "}
                  {(this.state.precios.length)?' Diesel' : 0}
                  <br></br>
                  <div><Info style={{fontSize:' 18px', verticalAlign: 'sub', color:'#000000'}}/> <span style={{color:'#000000'}}><i>Precios publicados por Pemex para la TAR, mostrados de manera informativa.</i></span></div>
                </span>
              }
              statIcon={AccessTime}
              statText="Actualizado hoy"
            />
          </ItemGrid>):null}
        </Grid>
              
        <FormDialog
          title={(<div><InputAdornment position="start"><Warning style={{color: '#ff3f3f'}}/><span style={{paddingLeft:'15px',maxWidth:'600px', color: '#ff3f3f', fontWeight: 'bold'}}>¡Aviso Importante!</span></InputAdornment></div>)}
          content= {
            <div style={{maxWidth: '600px'}}>
              <p align="justify">
                Estimado cliente, Grupo Petroil no está haciendo cambios en sus cuentas interbancarias, 
                por lo cual te pedimos denunciar cualquier irregularidad.
              </p>
              <p align="justify">
                Gracias por su amable atención.
                Ante la ola de fraudes digitales suscitados a nivel nacional, te invitamos de la manera más atenta, a verificar 
                la información oficial de la empresa con tu asesor comercial.
              </p>
              <p align="justify">
                Adicionalmente ponemos a tu disposición una línea segura para validar tu información. <b>800 EN ORDEN (800 366 7336)</b>
              </p>
            </div>
          }
          close={this.handleClickCloseAviso}
          open= {this.state.openDialogNotificacion}
        />

        <FormDialog
          title={(<div><InputAdornment position="start"><Assignment /><span style={{paddingLeft:'15px',minWidth:'1100px'}}>Facturas</span></InputAdornment></div>)}
          description= {this.state.descriptionFactura}
          content= {<div>
            <Grid container>
              <ItemGrid xs={12} sm={4} md={2}>
                <TextField
                  id="name"
                  label="Folio de Pedido"
                  value={this.state.fl_Pedido}
                  onChange={this.handleChangeValidate('fl_Pedido',{'maxLength':10})}
                  margin="normal"
                  type="number"
                  fullWidth
                />
              </ItemGrid>
              <ItemGrid xs={12} sm={4} md={2}>
                <TextField
                  id="fh_Inicio"
                  label="Fecha Factura Inicio"
                  type="date"
                  value={this.state.fh_Inicio}
                  onChange={this.handleChange('fh_Inicio')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  fullWidth
                />
              </ItemGrid>
              <ItemGrid xs={12} sm={4} md={2}>
                <TextField
                  id="fh_Fin"
                  label="Fin"
                  type="date"
                  value={this.state.fh_Fin}
                  onChange={this.handleChange('fh_Fin')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  fullWidth
                />
              </ItemGrid>
              <ItemGrid xs={12} sm={4} md={2}>
                <TextField
                    id="id_EstatusCobranza"
                    select
                    label="Estatus de Cobranza"
                    className={classes.textField}
                    value={this.state.id_EstatusCobranza}
                    onChange={this.handleChange('id_EstatusCobranza')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    fullWidth
                  >
                    {this.state.estatuscobranza.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
              </ItemGrid>
              <ItemGrid xs={12} sm={4} md={3}>
                <TextField
                    id="id_Estacion"
                    select
                    label="Estaciones"
                    className={classes.textField}
                    value={this.state.id_Estacion}
                    onChange={this.handleChange('id_Estacion')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    fullWidth
                  >
                    {this.state.estaciones.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
              </ItemGrid>
              <ItemGrid xs={1} sm={1} md={1}>
                <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                  <IconButton
                      color="primary"
                      style={{marginTop: '20px'}}
                      aria-label="Agregar"
                      aria-haspopup="true"
                      onClick={this.handleClickSearch}>
                    <Search />
                  </IconButton>
                </Tooltip>
              </ItemGrid>
            </Grid>
            <Grid container>
              <Table xs={12} sm={12} md={12}
                tableHead={[
                  { id: 'fl_Pedido', numeric: true, disablePadding: false, label: 'Folio Pedido' },
                  { id: 'fl_FolioDocumento', numeric: false, disablePadding: false, label: 'Folio Factura' },
                  { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Fecha Factura' },
                  { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Fecha Vencimiento' },
                  { id: 'nb_Producto', numeric: false, disablePadding: false, label: 'Producto' },
                  { id: 'nu_Cantidad', numeric: false, disablePadding: false, label: 'Litros' },
                  { id: 'im_Total', numeric: false, disablePadding: false, label: 'Importe' },
                  { id: 'im_SaldoFactura', numeric: true, disablePadding: false, label: 'Saldo' },
                  { id: 'nb_Moneda', numeric: true, disablePadding: false, label: 'Moneda' },
                  { id: 'DPorVencer', numeric: false, disablePadding: false, label: 'Dias por Vencer' },
                  { id: 'Dvencidos', numeric: false, disablePadding: false, label: 'Dias Vencido' },
                  { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Factura' },
                  { id: 'de_EstatusCobranza', numeric: false, disablePadding: false, label: 'Estatus Cobranza' },
                  { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                ]}
                tableData={this.state.tableData}
              />
            </Grid>
          </div>}
          close={this.handleClickCloseFactura}
          open= {this.state.openDialogFactura}
          excel = {<div><ExcelFile element={
              <span>Descargar Excel</span>
            }>
            <ExcelSheet dataSet={this.state.multiDataSetExcel} name="Listado de Facturas"/>
            </ExcelFile></div>}
        />
        <FormDialog
            title={(<div><InputAdornment position="start"><AssignmentReturned /><span style={{paddingLeft:'15px'}}>Descargar Factura</span></InputAdornment></div>)}
            description= {this.state.descriptionFactura}
            content= {
              <div>
                <iframe title="Facturas" style={{minHeight: '63VH', minWidth: '1100px'}} src={this.state.urlFactura}></iframe>
              </div>
            }
            btn= 'Descargar'
            action= {this.handleClickFactura}
            close={this.handleClickCloseFacturaView}
            open= {this.state.openDialogFacturaView}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.tr}
            onClose={() => this.setState({ tr: false })}
            ContentProps={{ 'className': classes.red }}
            message={this.state.message}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.tc}
            onClose={() => this.setState({ tc: false })}
            ContentProps={{ 'className': classes.green }}
            message={this.state.message}
          />
        <div className={this.state.classMask}>
          <Linear  />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
