import React from "react";
import { withStyles, CardContent, CardHeader, CardActions, Typography } from "@material-ui/core";
import { IconButton } from "components";
import PropTypes from "prop-types";
import cx from "classnames";
import Tooltip from '@material-ui/core/Tooltip';
import { AddCircle, Reply } from "@material-ui/icons";
import specialCardStyle from "assets/jss/material-dashboard-react/specialCardStyle";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function ExpansionCard({ ...props }) {
  const { classes, plainCard, cardTitle, cardSubtitle, content, footer, add, edit,
    back, action, iconColor, expansionAction, defaultExpanded, expanded, expationClose
  } = props;
  const plainCardClasses = cx({
    [" " + classes.cardPlain]: plainCard
  });
  return (
    <ExpansionPanel 
      className={classes.card + plainCardClasses} 
      defaultExpanded={defaultExpanded} 
      expanded={expanded} 
      onChange={ function(event, expanded){ if(expanded && expansionAction) { expansionAction() }else{ if(expationClose){ expationClose() } } } }
    >
      <ExpansionPanelSummary style={{'paddingLeft':'0px'}} expandIcon={<ExpandMoreIcon />}>
        <CardHeader
          classes={{
            root: classes.cardHeader + " " + classes[iconColor + "CardHeader"],
            avatar: classes.cardAvatar
          }}
          avatar={<props.icon className={classes.cardIcon}  />}
          style={{height:'60px'}}
          />
        <div>
          <Typography variant="title" className={classes.title} style={{padding:'0px'}}>
            {cardTitle}
          </Typography>
          <Typography component="p" className={classes.cardCategory}>
            {cardSubtitle}
          </Typography>
          <div>
            <div className={classes.iconos}>
              {add !== undefined ? (
                
                <Tooltip
                  title={edit?'Agregar':'Regresar'}
                  placement={'bottom-start'}
                  enterDelay={100}
                >
                  <IconButton
                      color="primary"
                      aria-label="Agregar"
                      aria-haspopup="true"
                      onClick={(edit)?add:back}
                      className={classes.buttonLink}>
                    {edit ?<AddCircle className={classes.links} />:<Reply className={classes.links} />}
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details} style={{padding:'8px 0px'}}>
        <CardContent style={{width:'calc(100% - 32px)', 'marginTop': '-40px'}} >{content}</CardContent>
        {footer !== undefined ? (
          <CardActions className={classes.cardActions}>{footer}</CardActions>
        ) : null}
      </ExpansionPanelDetails>
      {(action !== undefined ) ? (
        action
      ) : <div></div>}
    </ExpansionPanel>
  );
}

ExpansionCard.defaultProps = {
  headerColor: "orange"
};

ExpansionCard.propTypes = {
  defaultExpanded: PropTypes.bool,
  expanded: PropTypes.bool,
  plainCard: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple", "blueSip"]),
  cardTitle: PropTypes.node,
  cardSubtitle: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node
};

export default withStyles(specialCardStyle)(ExpansionCard);
