import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: orange
  },
});

const themeButton = createMuiTheme({
  palette: {
    primary: blue,
    secondary: red
  },
});

const styles = theme => ({
  root: {
    background:'#FFC107',
    padding: '15px 24px',
    '& h2': {
      color:'#000',
    },
  },
  rootTitle:{
    '@media (max-width: 768px)' : {
      padding:'0 15px 24px'
    }
  },
  DialogContentText: {
    paddingTop:'20px', 
    color:'#000'
  },
  dialog: {
    '& > div:nth-child(2)': {
      maxWidth: '700px',
      '@media (max-width: 768px)' : {
        margin:'4%',
        width:'100%',
        maxHeight:'calc(100% - 30px)'
      }
    }
  }
});

function Alerta( props) {
  const { classes, title, content, btn, action, excel, close, open, secondBtn, disabled } = props;
  return (
    <div>
        <Dialog
          open={open?true:false}
          aria-labelledby="form-dialog-title"
          className={classes.dialog}
        >
          <DialogTitle id="form-dialog-title" className={classes.root}>{title}</DialogTitle>
          <MuiThemeProvider theme={theme}>
            <DialogContent className={classes.rootTitle}>
              <DialogContentText className={classes.DialogContentText}>
                {content?content:null}
              </DialogContentText>
            </DialogContent>
          </MuiThemeProvider>
          <MuiThemeProvider theme={themeButton}>
          <DialogActions>
            <Button onClick={close} color="secondary">
              Cerrar
            </Button>
            {(btn==="Imprimir")?(action):(action?(<Button onClick={action} disabled={(disabled)? true : false} color="primary">{btn?btn:'Aceptar'}</Button>):null)}
            {excel?(<Button onClick={action} color="primary">{excel}</Button>):null}
            {secondBtn?(<Button onClick={secondBtn.action} variant="contained" color="primary">{secondBtn.text}</Button>):null}            
          </DialogActions>
          </MuiThemeProvider>
        </Dialog>
    </div>
  );
}

Alerta.propTypes = {
  title: PropTypes.node,
  content: PropTypes.node,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Alerta);