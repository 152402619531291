import DashboardPage from "views/Dashboard/Dashboard.jsx";
import InicioPage from "views/Inicio/Inicio.jsx";
import Calendar from "views/Calendar/Calendar.jsx";
import CalendarioClientes from "views/Calendar/CalendarioClientes.jsx";
import LoginPage from "views/Login/Login.jsx";
import LoginRfc from "views/LoginRfc/LoginRfc.jsx";
import Pedidos from "views/Pedidos/Pedidos.jsx";
import OrdenesDeSuministro from "views/Pedidos/OrdenesDeSuministro.jsx";
import Reportes from "views/Reportes/Reportes.jsx";
import ReportesClientes from "views/ReportesClientes/ReportesClientes.jsx";
import Referencias from "views/Referencias/Referencias.jsx";
// import Comprobantes from "views/Comprobantes/Comprobantes.jsx";
import invoke from "services/invoke.jsx";
import HistorialPrecios from "views/HistorialPrecios/HistorialPrecios.jsx";
import DatosFiscales from "views/DatosFiscales/DatosFiscales.jsx";

import {
  Dashboard,
  BubbleChart,
  Assignment,
  Assessment,
  DateRange,
  //Dvr,
  //Receipt,
  History,
  Payment,
} from "@material-ui/icons";

var sesion = invoke.getSesion();
var cliente = invoke.getSesionCliente();
var dashRouter = [];
var useLocation = window.location.pathname;
if(sesion){

    if(sesion.usuario.id_TipoUsuario===5){
      dashRouter =
      [
        {
          path: "/inicio",
          sidebarName: "Inicio",
          navbarName: "Petroil - Negocios Asociados",
          icon: Dashboard,
          component: DashboardPage,
          id_OpcionPortal: 1
        },
      ];

      for(var i in sesion.opciones){
        var view,icon, id_OpcionPortal;
        if(sesion.opciones[i].nb_Opcion === 'Pedidos'){
          view = Pedidos;
          icon = Assignment;
          id_OpcionPortal = 2
        }
        if(sesion.opciones[i].nb_Titulo === 'HistorialPrecios'){
          view = HistorialPrecios;
          icon = History;
          id_OpcionPortal = 6
        }
        if(sesion.opciones[i].nb_Opcion === 'Reportes'){
          view = Reportes;
          icon = Assessment;
          id_OpcionPortal = 5
        }
        var opcion = {
          path: "/"+sesion.opciones[i].nb_Titulo,
          sidebarName: sesion.opciones[i].nb_Opcion,
          navbarName: sesion.opciones[i].nb_Opcion,
          icon: icon,
          component: view,
          id_OpcionPortal: id_OpcionPortal
        }
        dashRouter.push(opcion);
        if(sesion.opciones[i].nb_Opcion === 'Pedidos'){
          dashRouter.push({
            path: "/ReferenciaBancaria",
            sidebarName: "Pagos",
            navbarName: "Pagos",
            icon: Payment,
            component: Referencias,
            id_OpcionPortal: 3
          });
          dashRouter.push({
            path: "/calendario",
            sidebarName: "Calendario de Pedidos",
            navbarName: "Calendario",
            icon: DateRange,
            component: Calendar,
            id_OpcionPortal: 4
          });
        }
      }
    }else if(sesion.usuario.id_TipoUsuario===6){
      dashRouter =
      [
        {
          path: "/inicio",
          sidebarName: "Inicio",
          navbarName: "Petroil - Negocios Asociados",
          icon: Dashboard,
          component: InicioPage,
          id_OpcionPortal: 1
        },
        {
          path: "/Pedidos",
          sidebarName: "Pedidos",
          navbarName: "Pedidos",
          icon: Assignment,
          component: OrdenesDeSuministro,
          id_OpcionPortal: 2
        },
        {
          path: "/ReferenciaBancaria",
          sidebarName: "Pagos",
          navbarName: "Pagos",
          icon: Payment,
          component: Referencias,
          id_OpcionPortal: 3
        },
        /*{
          path: "/ComprobantesBancarios",
          sidebarName: "Comprobantes",
          navbarName: "Comprobantes Bancarios",
          icon: Receipt,
          component: Comprobantes
        },*/
        {
          path: "/calendario",
          sidebarName: "Calendario de Pedidos",
          navbarName: "Calendario",
          icon: DateRange,
          component: CalendarioClientes,
          id_OpcionPortal: 4
        },
        {
          path: "/Reportes",
          sidebarName: "Reportes",
          navbarName: "Reportes",
          icon: Assessment,
          component: ReportesClientes,
          id_OpcionPortal: 5
        },
      ];
    }
    dashRouter.push({ redirect: true, path: "/", to: "/inicio", navbarName: "Redirect" });
} else if (cliente) {
  dashRouter = [
    {
      path: "/datosFiscales",
      sidebarName: "Inicio",
      navbarName: "Petroil - Negocios Asociados",
      icon: Dashboard,
      component: DatosFiscales
    }
  ]
} else{
  if (useLocation === '/signin') {
    dashRouter = [
      {
        path: "/signin",
        sidebarName: "Login",
        navbarName: "Petroil - Negocios Asociados",
        icon: BubbleChart,
        component
        : LoginRfc
      },
      {
        path: "/datosFiscales",
        sidebarName: "Inicio",
        navbarName: "Petroil - Negocios Asociados",
        icon: Dashboard,
        component: DatosFiscales
      },
      {
        path: "/.well-known/acme-challenge/glQ06wQuP0MvLMn-K-hV1JrOhud0jidJ_3hM2sftJ2c",
        to: "/.well-known/acme-challenge/glQ06wQuP0MvLMn-K-hV1JrOhud0jidJ_3hM2sftJ2c",
        navbarName: "Redirect"
      }
    ]
    dashRouter.push({ redirect: true, path: "/", to: "/signing", navbarName: "Redirect" });
  } else if (useLocation === '/datosFiscales') {
    dashRouter = [
      {
        path: "/signin",
        sidebarName: "Login",
        navbarName: "Petroil - Negocios Asociados",
        icon: BubbleChart,
        component: LoginRfc
      },
      {
        path: "/.well-known/acme-challenge/glQ06wQuP0MvLMn-K-hV1JrOhud0jidJ_3hM2sftJ2c",
        to: "/.well-known/acme-challenge/glQ06wQuP0MvLMn-K-hV1JrOhud0jidJ_3hM2sftJ2c",
        navbarName: "Redirect"
      }
    ]
    dashRouter.push({ redirect: false, path: "/", to: "/signing", navbarName: "Redirect" });
  } else {
    dashRouter = [
      {
        path: "/login",
        sidebarName: "Login",
        navbarName: "Petroil - Negocios Asociados",
        icon: BubbleChart,
        component: LoginPage
      },
      {
        path: "/.well-known/acme-challenge/glQ06wQuP0MvLMn-K-hV1JrOhud0jidJ_3hM2sftJ2c",
        to: "/.well-known/acme-challenge/glQ06wQuP0MvLMn-K-hV1JrOhud0jidJ_3hM2sftJ2c",
        navbarName: "Redirect"
      }
    ]
    dashRouter.push({ redirect: true, path: "/", to: "/login", navbarName: "Redirect" });
  }
}

export default dashRouter;
