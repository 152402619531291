import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Toolbar, Typography, Paper, IconButton, Tooltip } from '@material-ui/core';
import {Delete as DeleteIcon, FilterList as FilterListIcon} from '@material-ui/icons/Delete';

const headStyle = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    '@media (max-width: 768px)' : {
      minWidth: 250,
    }
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  head: {
    backgroundColor: '#0d4377'
  },
  cell: {
    width: '1%',
    color: '#fff',
    boxSizing: 'border-box',
    whiteSpace: 'initial',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '10px',
    textAlign: 'center',
    lineHeight: '15px',
  },
  title: {
    padding: '20px',
  },
  height:{
    height: '35px'
  }
});

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { classes, tableHead } = this.props;

    return (
      <TableHead>
        <TableRow className={classes.head}>
          {tableHead.map((column, key) => {
            return (
              <Tooltip
                  title={column.label?column.label:''}
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={1000}
                  key={key}
                >
                <TableCell key={key} className={classes.cell}>
                  {column.label}
                </TableCell>
              </Tooltip>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  tableHead: PropTypes.arrayOf(PropTypes.object)
};

EnhancedTableHead = withStyles(headStyle)(EnhancedTableHead);

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="title" id="tableTitle">
            Nutrition
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableTH:{
    width: '10%',
    padding: '0',
    textAlign: 'center'
  },
  cell: {
    overflow: 'hidden',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '1px',
    padding: '4px',
    textAlign: 'center',
    fontSize: '12px',
  },
  row: {
    height: '40px',
  },
  labelRowsPerPage: {
    '@media (max-width: 768px)': {
      display:'none'
    }
  }
});

class EnhancedTable extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: (props.rowsPerPage)?props.rowsPerPage:5,
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, tableHead, tableData, rowsPerPageOptions } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={tableData.length}
              tableHead={tableHead}
            />
            <TableBody>
              {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prop, key) => {
                  return (
                    <TableRow
                        hover
                        key={key}
                        className={classes.row}
                      >
                      {prop.map((prop, key2) => {
                        return (
                          <Tooltip
                            title={prop?((prop.props)?'':prop):''}
                            placement={'bottom-start'}
                            enterDelay={300}
                            key={key2}
                          >
                            <TableCell className={classes.cell} key={key2}>
                              {prop}
                            </TableCell>
                          </Tooltip>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Atras',
          }}
          nextIconButtonProps={{
            'aria-label': 'Siguiente',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage= {(<div className={classes.labelRowsPerPage}>Registros por Pagina:</div>)}
          rowsPerPageOptions= {(rowsPerPageOptions)?rowsPerPageOptions:[5, 10, 25]}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHead: PropTypes.arrayOf(PropTypes.object),
  tableData: PropTypes.arrayOf(PropTypes.array),
  rowsPerPage: PropTypes.node,
  rowsPerPageOptions: PropTypes.node
};

export default withStyles(styles)(EnhancedTable);