import React from "react";
import PropTypes from "prop-types";
import invoke from "services/invoke.jsx";
import { saveAs } from "file-saver";
import { Assignment, Search, AssignmentReturned, Payment , AddCircle, Visibility, Print, Mail, NotInterested, /*Info,*/ } from "@material-ui/icons";
import { withStyles, Grid, TextField, Tooltip, IconButton, Snackbar, InputAdornment, MenuItem, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import { ItemGrid, Table, TableSimple, FormDialog, Linear, TableSelected, VerticalStep, ExpansionCard } from "components";

import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import dashboardStyle from "assets/jss/material-dashboard-react/dashboardStyle";
import NumberFormat from 'react-number-format';
import { Page, Text, Document, StyleSheet, /*PDFDownloadLink,*/ View, pdf, } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: { backgroundColor: '#FFF', textAlign: 'center',},
  view: { flexDirection: 'row', },
  text0: { marginTop: '25pt', marginBottom: '5pt', fontSize: '16pt',},
  text1: { marginBottom: '25pt', fontSize: '16pt',},
  text2: { backgroundColor: '#cccccc', border: '1pt solid #000000', fontSize: '14pt', height: '28pt', width: '90%', marginLeft: '30pt', paddingTop: '5pt', fontWeight: 600},
  text3: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '14pt', marginBottom: '20pt', height: '28pt', width: '90%', marginLeft: '30pt', paddingTop: '5pt',},
  text4: { backgroundColor: '#ccc', border: '1pt solid #555', fontSize: '14pt', width: '45%', marginLeft: '30pt', height: '28pt', paddingTop: '5pt',},
  text5: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '14pt', width: '45%', marginLeft: '30pt', height: '28pt', paddingTop: '5pt',},
  text6: { backgroundColor: '#ccc', border: '1pt solid #555', fontSize: '14pt', width: '45%', height: '28pt', paddingTop: '5pt',},
  text7: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '14pt', width: '45%', height: '28pt', paddingTop: '5pt',},
  text8: { backgroundColor: '#ccc', border: '1pt solid #555', fontSize: '14pt', width: '45%', marginLeft: '30pt', height: '28pt', paddingTop: '5pt',},
  text9: { backgroundColor:' #fff',border: '1pt solid #555',fontSize: '14pt',width: '45%',marginLeft: '30pt',height: '28pt',paddingTop: '5pt',},
  text10: { backgroundColor: '#ccc',border: '1pt solid #555',fontSize: '14pt',width: '45%',height: '28pt',paddingTop: '5pt',},
  text11: { backgroundColor: '#fff',border: '1pt solid #555',fontSize: '14pt',width: '45%',height: '28pt',marginBottom: '20pt',paddingTop: '5pt',},
  text12: { backgroundColor: '#ccc', border: '1pt solid #555', fontSize: '14pt', height: '28pt', width: '90%', marginLeft: '30pt', paddingTop: '5pt',},
  text13: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '16pt', marginBottom: '20pt', padding: '5pt', height: '28pt', width: '90%', marginLeft: '30pt',},
  text14: { fontSize: '14pt', marginBottom: '10pt'},
  text15: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '16pt', padding: '5pt', height: '28pt', width: '90%', marginLeft: '30pt',},
  text16: { backgroundColor: '#fff', fontSize: '10pt', width: '45%', marginLeft: '30pt', height: '28pt', paddingTop: '7pt',},
  text17: { backgroundColor: '#fff', fontSize: '10pt', width: '45%', height: '28pt', paddingTop: '7pt',},
  text18: { fontSize: '12pt', marginLeft: '30pt', height: '28pt', paddingTop: '5pt', textAlign: 'left'},
  text19: { backgroundColor: '#fff', fontSize: '7pt', width: '89%', marginLeft: '30pt', height: '35pt', paddingTop: '10pt',textAlign: 'justify', fontWeight:'1000pt'},
  text20: { backgroundColor: '#fff', fontSize: '7pt', width: '89%', marginLeft: '30pt', height: '40pt', paddingTop: '1pt',textAlign: 'justify',},
  text21: { backgroundColor: '#fff', fontSize: '7pt', width: '89%', marginLeft: '30pt', height: '20pt', paddingTop: '1pt',textAlign: 'justify',},
  text22: { backgroundColor: '#fff', fontSize: '7pt', width: '89%', marginLeft: '30pt', height: '10pt', paddingTop: '1pt',textAlign: 'justify',},
  text23: { backgroundColor: '#fff', fontSize: '7pt', width: '89%', marginLeft: '30pt', height: '30pt', paddingTop: '5pt',textAlign: 'justify',},
  //TABLE
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    marginLeft:'30pt',
    marginRight:'30pt',
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol: { 
    width: "33.3%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
  }, 
  tableCell: { 
    margin: "auto", 
    marginTop: 5, 
    fontSize: '10pt',
    textAlign:'left',
  },
  /* ENCABEZADO*/
  tableHeader: {
    backgroundColor:'#CCCCCC',
  },
  tableCellHeader: { 
    margin: "auto", 
    marginTop: 5, 
    fontSize: '12pt',
    textAlign:'left',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: blue
  },
  labelRowsPerPage: {
    '@media (max-width: 768px)': {display:'none'}
  }
});

const themeGreen = createMuiTheme({
  palette: {
    primary: green,
    secondary: blue
  }
});

var sesion = invoke.getSesion();

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class Dashboard extends React.Component {

  state = {
    usuario :  (sesion) ? sesion.usuario: {},
    estacion :  (sesion) ? sesion.estacionObj[0]: {},
    info: {},
    precios:[],
    tableDataReferencias: [],
    estaciones: [],
    sucursales: [],
    descriptionFactura: '',
    tableData: [],
    PD_Array: [],
    pdf_HTML: (<div></div>),
    tableReset: (<div></div>),
    openDialogFactura: false,
    openDialogFacturaView: false,
    openDialogAgregarFactura: false,
    openDialogDetalle: false,
    openDialogMail: false,
    openDialogCancelar: false,
    openDialogCuentaBancaria: false,
    activeStepVAR: '',
    urlFactura: '',
    urlFacturaDownload: '',
    classMask:  'maskProgress',
    id_Estacion: sesion.estacion,
    id_Sucursal: '',
    nb_Estacion: '',
    nb_Sucursal: '',
    steps: [],
    facturasPendientes: [],
    facturasPendientesData: [],
    facturasData: [],
    comprobantesData: [],
    facturasPendientesTotal: 0,
    im_importeMinimo: 0,
    im_Importe: '',
    comboEmpresas: [],
    comboEstatus: [],
    comboBancos: [],
    comboCuentas: [],
    facturasPendientesDetalle: [],
    facturasPendientesDetalle_PDF: [],
    nb_Banco: '',
    id_Banco: '',
    nb_Cuenta: '',
    id_Cuenta: '',
    id_BancoSearch: '',
    id_Estatus_Filtro: '',
    fh_Vencimiento: '',
    id_CuentaBancaria: '',
    cuentasBancarias: [],
    urlBanco: 'https://storage.googleapis.com/sipp-attachments-dev/Documentos/Bancos/2/9BAE410C6A9F16CA3D69742C231DD88A16E4A96A.png',
    ckb_Vencimiento: 'FALSE',
    id_FormaPago: '',
    formaPagos: [],

    fl_Pedido: '',
    fh_Inicio: '',
    fh_Fin: '',
    fh_InicioSearch: '',
    fh_FinSearch: '',

    expandedComprobantes: true,
    expandedFacturas: false,
    required_id_Empresa: false,
    required_id_Sucursal: false,
    required_fh_Vencimiento: false,
    required_im_Importe: false,
    required_id_FormaPago: false,

    nb_EstatusCobranza: '',
    estatuscobranza: [],
    id_EstatusCobranza: '',

    nb_fileComprobante: '',
    fileData: [],
    empresas: [],
    monedas: [],
    id_Empresa: '',
    totalSinFormato: 0,

    expanded: null,
    fh_Comprobante: '',
    lbl_FacturasRelacionadas: 'Las facturas relacionadas a una referencia(vigente) se marcarán en Azul',
    lbl_FacturasAbonadas: 'Las facturas abonadas se marcarán en Naranja',
    PD_PDF: (<div></div>),
    // Validar Formas pago
    expresion_Regular: '',
    descripcion_Expresion : '',
    // Impresion Pdf
    table: null,
    rowDataPDF: [],
    sn_hideDescuento: false,
    sn_hideDescuento_LF: false,
    sn_hideDescuento_REF: false,

    // Filtro estaciones
    showFiltroEstacion : 'none',
    sn_HideEstacion: false,
    id_Estacion_Filtro : sesion.estacion
  };

  componentWillMount(){ //es parecido a la funcion init

    invoke.resetTime();
    localStorage.setItem('referenciaFacturas', '')
    invoke.post('facturas','estatusId',{id_Inicial: 1511,id_Final: 1513}).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [{value:0,label:'Todas'}];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.id_Estatus,
            label: x.de_Estatus,
            obj: x
          });
        }
        this.setState({ estatuscobranza: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    invoke.post('facturas','estatusId',{id_Inicial: 1531,id_Final: 1534}).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [{value:0,label:'Todas'}];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.id_Estatus,
            label: x.de_Estatus,
            obj: x
          });
        }
        this.setState({ comboEstatus: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de bancos
    invoke.post('bancos','listado').then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [{value:'',label:'Seleccionar'}];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.id_Banco,
            label: x.de_Banco,
            obj: x
          });
        }
        this.setState({ comboBancos: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //combo de Cuentas Bancarias
    invoke.post('bancos','listadoCuentas',{id_Cliente: (this.state.usuario) ? this.state.usuario.id_Cliente : null, id_Banco: this.state.id_Banco}).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [{value:'',label:'Seleccionar'}];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.nu_CuentaBancaria,
            label: x.nu_CuentaBancaria,
            obj: x
          });
        }
        this.setState({ comboCuentas: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Empresas
    invoke.post('empresas','listado').then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.id_Empresa,
            label: x.nb_Empresa,
            obj: x
          });
        }

        this.setState({ id_Empresa: res.data.data[0].id_Empresa });
        this.setState({ empresas: VAR });
        this.handleChangeEmpresa(res.data.data[0].id_Empresa);
      }else{
        this.showNotification("tr", res.message)
      }
    });

    // Formas de Pagos
    invoke.post('referencias','formaPago',{sn_Bancarizado: 1}).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.c_FormaPago,
            label: x.Descripcion,
            obj: x
          });
        }
        this.setState({ formaPagos: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });
    //Combo de Estaciones - Solo para clientes Gasolineros tipo 5
    if(sesion.usuario.id_TipoCliente === 5){
      invoke.post('estacion','combo',{id_Usuario: sesion.usuario.id_Usuario, id_Estacion: null, id_Cliente: sesion.usuario.id_Cliente}).then(res => {
        if(res.status===200 && res.data.data){
          var VAR = [{value:0,label:'Todos'}];
          for(var i in res.data.data){
            var x = res.data.data[i];
            VAR.push({
              value: x.id_Estacion,
              label: x.nb_Estacion,
              obj: x
            });
          }
          this.setState({ estaciones: VAR });
          if(this.state.estaciones.length > 1){
            this.setState({ showFiltroEstacion: 'inline' });
          }
        }else{
          this.showNotification("tr", res.message)
        }
      });
    }

    let fh_inicio = this.getFirstDayOfMonth();
    let fh_fin = this.getLastDayOfMonth();
    fh_inicio = fh_inicio.toISOString().slice(0,10);
    fh_fin = fh_fin.toISOString().slice(0,10);

    //this.setState({ fh_Inicio: fh_inicio });
    this.setState({ fh_Fin: fh_fin });
    this.setState({ fh_InicioSearch: fh_inicio });
    this.setState({ fh_FinSebuscarComprobantesarch: fh_fin }, () => {
      this.buscarReferencias();
      //this.buscarFactura();
    });
  }

  handleChangeEmpresa = (id_Empresa) => {
    //Combo de Cuentas Bancarias
    invoke.post('referencias','cuentasBancarias', {id_Empresa: id_Empresa}).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [];
        if(res.data.data.length){
          for(let i in res.data.data){
            let x = res.data.data[i];
            VAR.push({
              value: x.id_CuentaBancaria,
              label: x.de_CuentaBancaria,
              obj: x
            });
          }
          this.setState({ id_CuentaBancaria: res.data.data[0].id_CuentaBancaria });
          this.setState({ urlBanco: res.data.data[0].ar_ImagenLogo });
        }else{
          this.setState({ id_CuentaBancaria: '' });
          this.setState({ urlBanco: '' });
        }
        this.setState({ cuentasBancarias: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de sucursales
    invoke.post('estacion','sucursales',{id_Empresa: id_Empresa}).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.id_Sucursal,
            label: x.nb_Sucursal,
            obj: x
          });
        }
        this.setState({ sucursales: VAR });
        this.setState({ id_Sucursal: '' });
      }else{

        this.showNotification("tr", res.message)
      }
    });
  }

  handleChangeFormaPago = (c_FormaPago) => {
    for (let i = 0; i < this.state.formaPagos.length; i++) {
      if(this.state.id_FormaPago === this.state.formaPagos[i].obj.c_FormaPago){
        this.setState({ expresion_Regular: this.state.formaPagos[i].obj.Expresion_Regular });
        this.setState({ descripcion_Expresion: this.state.formaPagos[i].obj.Descripcion_Expresion});
        break;
      }
    }
  }
  
  resetTable = () => {
    if(this.state.tableData){
      localStorage.setItem('referenciaFacturas', 'null');
        this.setState({ tableReset: (<TableSelected
          titulo = {(<p><b>Facturas Pendientes</b> <br></br><label className="labelRowsPerPage" style={{color: '#2196F3', fontWeight: 'bold'}}>&bull;	{this.state.lbl_FacturasRelacionadas} </label><br></br> <label className="labelRowsPerPage" style={{color: '#ff8b00', fontWeight: 'bold'}}>&bull; {this.state.lbl_FacturasAbonadas} </label></p>)}
          tableHead={[
            { id: 'Estación', numeric: false, disablePadding: false, label: 'Estación', hidden: this.state.sn_HideEstacion },
            { id: 'fl_FolioDocumento', numeric: false, disablePadding: false, label: 'Folio Factura' },
            { id: 'fh_Documento', numeric: false, disablePadding: false, label: 'Fecha Factura' },
            { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Fecha Vencimiento' },
            { id: 'im_Total', numeric: false, disablePadding: false, label: 'Importe' },
            { id: 'im_SaldoFactura', numeric: true, disablePadding: false, label: 'Saldo' },
            { id: 'im_Descuento', numeric: false, disablePadding: false, label: 'Descuento Pronto Pago', hidden: this.state.sn_hideDescuento_LF },
            { id: 'im_Neto', numeric: false, disablePadding: false, label: 'Saldo al Momento' }
          ]}
          tableData={this.state.tableData}
          action = {
            <div>
              <Tooltip
                title={'Agregar a Referencia'}
                placement={'bottom-start'}
                enterDelay={100}
              >
                <IconButton
                    aria-label="Agregar a Referencia"
                    aria-haspopup="true"
                    onClick={this.handleClickOpenAgregarFactura(0)}>
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </div>
          }
          actionSelected = {
            <div>
              <Tooltip
                title={'Agregar a Referencia'}
                placement={'bottom-start'}
                enterDelay={100}
              >
                <IconButton
                    aria-label="Agregar a Referencia"
                    aria-haspopup="true"
                    onClick={this.handleClickOpenAgregarFactura(1)}>
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </div>
          }
        />)});
    }else{
      this.buscarFactura();
    }
  }

  generatePdf = async () => {
    if(this.state.facturasPendientesDetalle_PDF.length){
      var FacturasPendientes = this.state.facturasPendientesDetalle_PDF;
      var rowData = [['Folio Factura', 'Fecha Vencimiento','Importe Pago']];
      for (let index = 0; index < FacturasPendientes.length; index++) {
        rowData.push(FacturasPendientes[index])
      }
    }
    const Table = ({rowData, col, th}) => (
      <View style={styles.table}>
          {rowData.map((row, ind) =>
              <View key={ind} style={[styles.tableRow]}>
                  {row.map((cell, j) =>
                      <View key={j} style={[styles.tableCol, th && ind === 0 ? styles.tableHeader: {}]}>
                          {
                              typeof(cell) === 'string' || typeof(cell) === 'number' ? 
                              <Text style={ind === 0 ? styles.tableCellHeader: styles.tableCell}>{cell}</Text> : cell
                          }
                      </View>
                  )}
              </View>
          )}
      </View>
    );
    const blob = await pdf(
      <Document>
        <Page size="A4" style={styles.page}>
            <Text style={styles.text0}>Petroil - Distribución de Combustible</Text>
            <Text style={styles.text1}>Referencia Bancaria para Pago de adeudo</Text>

            <Text style={styles.text2}>NOMBRE DEL CLIENTE:</Text>
            <Text style={styles.text3}>{sesion.usuario.de_RazonSocial}</Text>

            <View style={styles.view}>
                <Text style={styles.text4}>CONCEPTO:</Text>
                <Text style={styles.text6}>FECHA DE VENCIMIENTO:</Text>
            </View>
            <View style={styles.view}>
                <Text style={styles.text5}>Pago Bancario</Text>
                <Text style={styles.text7}>{this.state.PD_Array.fh_Vencimiento}</Text>
            </View>
            <View style={styles.view}>
                <Text style={styles.text8}>IMPORTE A PAGAR:</Text>
                <Text style={styles.text10}></Text>
            </View>
            <View style={styles.view}>
                <Text style={styles.text9}>{'$'+this.state.PD_Array.im_Importe}</Text>
                <Text style={styles.text11}></Text>
            </View>

            <Text style={styles.text12}>PAGO A NOMBRE DE LA EMPRESA:</Text>
            <Text style={styles.text15}>{this.state.PD_Array.nb_Empresa}</Text>
            <View style={styles.view}>
                <Text style={styles.text4}>*CONVENIO:</Text>
                <Text style={styles.text6}>**CUENTA CLABE:</Text>
            </View>
            <View style={styles.view}>
                <Text style={styles.text5}>{this.state.PD_Array.nu_ConvenioH2H}</Text>
                <Text style={styles.text7}>{this.state.PD_Array.nu_ClabeInterbancariaH2H}</Text>
            </View>
            <View style={styles.view}>
                <Text style={styles.text16}>* Pagos via BBVA a BBVA</Text>
                <Text style={styles.text17}>** Pagos via SPEI desde cualquier banco</Text>
            </View>
            <Text style={styles.text12}>REFERENCIA BANCARIA:</Text>
            <Text style={styles.text13}>{this.state.PD_Array.de_Referencia}</Text>

            <Text style={styles.text14}>Ultimo dia para pagar antes de su expiracion: {this.state.PD_Array.fh_Vencimiento}</Text>
            {(this.state.facturasPendientesDetalle_PDF.length)?(
              <Text style={styles.text18}>Detalles de la referencia</Text>):<Text></Text>
            }
            {(this.state.facturasPendientesDetalle_PDF.length)?(
              <Table
                th
                col={['33.33%', '33.33%', '33.33%']}
                rowData={rowData}>
              </Table>):<Text></Text>
            }
            <Text style={styles.text19}>
              La aplicacion de pagos realizados a traves del referencias bancarias se reflejan una vez confirmado el pago por la institucion receptora(BBVA).
              La descarga de movimientos y pagos referenciados se realiza en horario de 7:00 a 20:00 hrs por intervalos de 30 minutos entre cada consulta.
              Los pagos se pueden realizar a traves de la banca de BBVA (mediante convenio), estos se reflejan en estado de cuenta de forma inmediata.
            </Text>
            <Text style={styles.text20}>
              Los pagos realizados a traves de transferencia SPEI (mediante cuenta CLABE) se reflejan en edo. de cuenta cuando se confirma la operacion
              tanto del banco emisor, como del banco receptor (BBVA), la confirmacion del pago/transferencia  esta sujeta a los horarios de operacion del servicio
              SPEI por parte de Banxico. Para realizar transferencias SPEI sin limite en los importes de transferencia son de Lunes a Viernes en horarios de de 6:00 - 17:59 hrs.
              Lunes a Viernes de 18:00 - 5:59 hrs para montos de hasta $8,000 MXN.
              Sábados, Domingos y días inhábiles 24 hrs por un monto hasta $8,000 MXN. 
            </Text>
            <Text style={styles.text22}> 
              Si los pagos se realizan en horarios fuera de rango, estos pueden reflejarse en edo. de cuenta pero no ser confirmados por el banco sino hasta el siguiente dia habil.
            </Text>
            <Text style={styles.text23}> 
              En caso de presentar algún error en su portal de banca electrónica, se recomienda borrar sus archivos temporales de internet, cache y cookies, e intentar hacer la {"\n"}
              operación nuevamente.
            </Text>
            <Text style={styles.text21}> 
              Para mayor informacion de los pagos mediante SPEI:{"\n"}
              https://www.banxico.org.mx/servicios/sistema-pagos-electronicos-in.html
            </Text>
        </Page>
      </Document>
    ).toBlob()
    saveAs(blob, "ReferenciaBancaria - "+ this.state.PD_Array.de_Referencia);
}


  downloadPdf = (url,urlDescarga) => event => {
    this.setState({ openDialogFacturaView: true });
    this.setState({ urlFactura: url });
    this.setState({ urlFacturaDownload: urlDescarga });
  };

  handleClickFactura = () => {
    window.location.href = this.state.urlFacturaDownload;
    this.setState({ openDialogFacturaView: false });
  };

  handleClickCloseFacturaView = () => {
    this.setState({ openDialogFacturaView: false });
  };

  handleClickCloseDetalle = () => {
    this.setState({ openDialogDetalle: false });
  };

  handleClickCloseMail = () => {
    this.setState({ openDialogMail: false });
  };

  handleClickOpenMail = (PD) => event => {
    this.setState({ openDialogMail: true });
    this.setState({ de_Referencia_Detalle: PD.de_Referencia });
    this.setState({ fh_Vencimiento_detalle: PD.fh_Vencimiento_Detalle });
    this.setState({ im_Importe_detalle: PD.im_Importe });

    let obj = {
      id_Empresa: PD.id_Empresa,
      id_Cliente: PD.id_Cliente,
      id_Referencia: PD.id_Referencia,
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('Referencias','detalle', obj).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [];
        let PDF = [];
        let contDescuento = 0;
        for(let i in res.data.data){
          let x = res.data.data[i];
          // para determinar si pintamos la columna de descuento o no
          if(x.im_DescuentoSinFormato > 0){
            contDescuento++;
          }
          VAR.push([
            x.fl_FolioDocumento,//Folio Factura
            x.im_Total,//Total de La factura
            x.im_Saldo,//saldo de la Factura
            x.im_Descuento,//descuento en la Factura
            x.im_Importe,
          ]);

          PDF.push([
            x.fl_FolioDocumento,//Folio Factura
            x.fh_Vencimiento,//Fecha Vencimiento
            x.im_Importe,// ImportePago
          ]);

          PD.id_Sucursal = x.id_Sucursal;
        }
        if(!contDescuento){
          this.setState({ sn_hideDescuento: true });
          for (let i = 0; i < VAR.length; i++) {
            VAR[i].splice(3, 1);
          }
        }
        this.setState({ facturasPendientesDetalle: VAR });
        this.setState({ facturasPendientesDetalle_PDF: PDF });

      }else{
        this.showNotification("tr", res.message)
      }
      this.setState({ PD_Array: PD });
      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 1);
      }.bind(this), 1);
    });
  }

  handleClickCloseCancelar = () => {
    this.setState({ openDialogCancelar: false });
  };

  handleClickOpenCancelar = (PD) => event => {
    this.setState({ openDialogCancelar: true });
    this.setState({ PD_Array: PD });
  }

  handleClickCancelar(){
    this.setState({classMask:'maskProgress active'});
    invoke.post('referencias','cancelar',{...this.state.PD_Array, id_Usuario: sesion.usuario.id_Usuario}).then(res => {
      if(res.status===200 && res.data.data){
        this.showNotification("tc", res.data.message);
      }else{
        this.showNotification("tr", res.data.message);
      }
      this.setState({ openDialogCancelar: false });
      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
          this.setState({classMask:'maskProgress'});
          this.buscarReferencias();
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  handleClickCloseCuentaBancaria = () => {
    this.setState({ openDialogCuentaBancaria: false });
  };

  handleClickOpenCuentaBancaria(){
    this.setState({ openDialogCuentaBancaria: true });
  }

  handleClickAgregarCuenta(){
    this.setState({classMask:'maskProgress active'});
    invoke.post('bancos','agregarCuenta',{id_Cliente: this.state.usuario.id_Cliente, id_Banco: this.state.id_Banco, nu_CuentaBancaria: this.state.nu_CuentaCliente}).then(res => {
      if(res.status===200 && res.data.data && res.data.data[0].sn_Continuar === 1){
        this.showNotification("tc", res.data.message);
        this.handleChangeCuenta(this.state.nu_CuentaCliente);
        this.setState({ openDialogCuentaBancaria: false });
      }else{
        this.showNotification("tr", res.data.message);
      }
      
      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
          this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  handleClickOpenDetalle = (PD) => event => {
    this.setState({ openDialogDetalle: true });
    this.setState({ PD_Array: PD });
    this.setState({ de_Referencia_Detalle: PD.de_Referencia });
    this.setState({ nb_CuentaClabe_Detalle: PD.nu_ClabeInterbancariaH2H });
    this.setState({ nb_Convenio_Detalle: PD.nu_ConvenioH2H });
    this.setState({ fh_Vencimiento_detalle: PD.fh_Vencimiento_Detalle });
    this.setState({ im_Importe_detalle: PD.im_Importe });
    this.setState({ sn_hideDescuento: false });

    let obj = {
      id_Empresa: PD.id_Empresa,
      id_Cliente: PD.id_Cliente,
      id_Referencia: PD.id_Referencia,
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('Referencias','detalle', obj).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [];
        let PDF = [];
        let contDescuento = 0;
        for(let i in res.data.data){
          let x = res.data.data[i];
          // para determinar si pintamos la columna de descuento o no
          if(x.im_DescuentoSinFormato > 0){
            contDescuento++;
          }
          VAR.push([
            x.fl_FolioDocumento,//Folio Factura
            x.im_Total,//Total de La factura
            x.im_Saldo,//saldo de la Factura
            x.im_Descuento,//descuento en la Factura
            x.im_Importe,
          ]);

          PDF.push([
            x.fl_FolioDocumento,//Folio Factura
            x.fh_Vencimiento,//Fecha Vencimiento
            x.im_Importe,// ImportePago
          ]);
        }
        // si el cliente no retorna descuentos, ocultamos la columna
        if(!contDescuento){
          this.setState({ sn_hideDescuento: true });
          for (let i = 0; i < VAR.length; i++) {
            VAR[i].splice(3, 1);
          }
        }
        this.setState({ facturasPendientesDetalle: VAR });
        this.setState({ facturasPendientesDetalle_PDF: PDF });
      }else{
        this.showNotification("tr", res.message)
      }
      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 1);
      }.bind(this), 1);
    });
  };

  getDetalleReferencia = (PD) => event => {
    this.setState({ PD_Array: PD });
    let obj = {
      id_Empresa: PD.id_Empresa,
      id_Cliente: PD.id_Cliente,
      id_Referencia: PD.id_Referencia,
    }
    invoke.post('Referencias','detalle', obj).then(res => {
      if(res.status===200 && res.data.data){
        let PDF = [];
        for(let i in res.data.data){
          let x = res.data.data[i];
          PDF.push([
            x.fl_FolioDocumento,//Folio Factura
            x.fh_Vencimiento,//Fecha Vencimiento
            x.im_Importe,// ImportePago
          ]);
        }
        this.setState({ facturasPendientesDetalle_PDF: PDF });
        // Generar el pdf
        setTimeout(function() {
          this.generatePdf();
        }.bind(this), 100);

      }else{
        this.showNotification("tr", res.message)
      }
    });
  };

  handleClickSearch = () => {
    this.getInfoCliente();
  };

  handleChangeCuenta = (nu_CuentaCliente) => {
    //combo de Cuentas Bancarias
    this.setState({ nu_CuentaCliente: nu_CuentaCliente });
    invoke.post('bancos','listadoCuentas',{id_Cliente: (this.state.usuario) ? this.state.usuario.id_Cliente : null, id_Banco: this.state.id_Banco}).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [{value:'',label:'Seleccionar'}];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.nu_CuentaBancaria,
            label: x.nu_CuentaBancaria,
            obj: x
          });
        }
        this.setState({ comboCuentas: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });
  };

  handleClickOpenAgregarFactura = () => event => {
    let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 10);
    this.setState({ openDialogAgregarFactura: true });
    this.setState({ im_Importe: '' })
    this.setState({ im_importeMinimo: 0 })
    this.setState({ fh_Vencimiento: localISOTime })
    this.setState({ id_FormaPago: '' })
    this.setState({ id_Banco: '' })
    this.setState({ nu_CuentaCliente: '' })
    this.setState({ sn_hideDescuento_REF: false });

    let res = localStorage.getItem('referenciaFacturas').split(",");
    let data = this.state.facturasPendientesData;
    let obj = [];
    let objData = [];
    let total = 0;
    let contDescuento = 0;
    for(let i in data){
      for(let x in res){
        if(i === res[x]){
          if(data[i].im_DescuentoSinFormato > 0){
            contDescuento++;
          }
          // eslint-disable-next-line no-loop-func
          localStorage.setItem('id_Factura'+data[i].fl_FolioDocumento, data[i].im_NetoSinFormato); // se cambia al neto (ya tiene descuento aplicado)
          obj.push([
            data[i].fl_FolioDocumento,//Folio Factura
            data[i].fh_Vencimiento,//fechavencimiento factura
            data[i].im_Total,//fechavencimiento factura
            data[i].im_Neto,//saldo de la Factura
            data[i].im_Descuento,//saldo de la Factura
            (
              <TextField
                error={this.state['error_id_Factura'+data[i].fl_FolioDocumento]}
                onChange={this.handleChange('id_Factura'+data[i].fl_FolioDocumento)}
                defaultValue={localStorage.getItem('id_Factura'+data[i].fl_FolioDocumento)}
                margin="none"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                  style: {textAlign:'right'}
                }}
              />
            ),
          ]);
          total += data[i].im_NetoSinFormato;
          objData.push([
            data[i]
          ]);
        }
      }
    }
    if(!contDescuento){
      this.setState({ sn_hideDescuento_REF: true });
      for (let i = 0; i < obj.length; i++) {
        obj[i].splice(4, 1);
      }
    }
    this.setState({ facturasData: objData });
    this.setState({ facturasPendientes: obj });
    this.setState({ totalSinFormato: total });
    this.setState({ facturasPendientesTotal: this.formatMoney(total) });
    if(total > 0){
      this.setState({ im_Importe: parseFloat(total).toFixed(2) })
    }

  }

  activeStepData (activeStep) {
    this.setState({ activeStepVAR: activeStep });
  }

  handleData () {

    let res = localStorage.getItem('referenciaFacturas').split(",");
    let data = this.state.facturasPendientesData;
    let obj = [];
    let contDescuento = 0;
    for(let i in data){
      for(let x in res){
        if(i === res[x]){
          // eslint-disable-next-line no-loop-func
          if(data[i].im_DescuentoSinFormato > 0){
            contDescuento++;
          }
          obj.push([
            data[i].fl_FolioDocumento,//Folio Factura
            data[i].fh_Vencimiento,//fechavencimiento factura
            data[i].im_Total,//fechavencimiento factura
            data[i].im_Neto,//saldo de la Factura
            data[i].im_Descuento,//Descuento pronto pago
            (
              <TextField
                error={this.state['error_id_Factura'+data[i].fl_FolioDocumento]}
                onChange={this.handleChange('id_Factura'+data[i].fl_FolioDocumento)}
                defaultValue={localStorage.getItem('id_Factura'+data[i].fl_FolioDocumento)}
                margin="none"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                  style: {textAlign:'right'}
                }}
              />
            ),
          ]);
        }
      }
    }
    if(!contDescuento){
      this.setState({ sn_hideDescuento_REF: true });
      for (let i = 0; i < obj.length; i++) {
        obj[i].splice(4, 1);
      }
    }
    this.setState({ facturasPendientes: obj });

  }

  getDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
          <Text style={styles.text0}>Petroil - Distribución de Combustible</Text>
          <Text style={styles.text1}>Referencia Bancaria para Pago de adeudo</Text>

          <Text style={styles.text2}>NOMBRE DEL CLIENTE:</Text>
          <Text style={styles.text3}>{sesion.usuario.de_RazonSocial}</Text>

          <View style={styles.view}>
              <Text style={styles.text4}>CONCEPTO:</Text>
              <Text style={styles.text6}>FECHA DE VENCIMIENTO:</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.text5}>Pago Bancario</Text>
              <Text style={styles.text7}>{this.state.PD_Array.fh_Vencimiento}</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.text8}>IMPORTE A PAGAR:</Text>
              <Text style={styles.text10}></Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.text9}>{'$'+this.state.PD_Array.im_Importe}</Text>
              <Text style={styles.text11}></Text>
          </View>

          <Text style={styles.text12}>PAGO A NOMBRE DE LA EMPRESA:</Text>
          <Text style={styles.text15}>{this.state.PD_Array.nb_Empresa}</Text>
          <View style={styles.view}>
              <Text style={styles.text4}>*CONVENIO:</Text>
              <Text style={styles.text6}>**CUENTA CLABE:</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.text5}>{this.state.PD_Array.nu_ConvenioH2H}</Text>
              <Text style={styles.text7}>{this.state.PD_Array.nu_ClabeInterbancariaH2H}</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.text16}>* Pagos via BBVA a BBVA</Text>
              <Text style={styles.text17}>** Pagos via SPEI desde cualquier banco</Text>
          </View>
          <Text style={styles.text12}>REFERENCIA BANCARIA:</Text>
          <Text style={styles.text13}>{this.state.PD_Array.de_Referencia}</Text>

          <Text style={styles.text14}>Ultimo dia para pagar antes de su expiracion: {this.state.PD_Array.fh_Vencimiento}</Text>
      </Page>
    </Document>
  );

  validar() {
    invoke.resetTime();

    if(this.state.facturasData.length === 0){
      if(this.state.id_Empresa === ''){
        this.mensajeLog("required_id_Empresa", 'La Empresa es requerida.')
        return false;
      }else if(!this.state.id_CuentaBancaria > 0){
        this.mensajeLog("required_id_Empresa", 'La Empresa seleccionada no tiene cuenta Bancaria, favor de seleccionar otra.')
        return false;
      }else if(this.state.id_Sucursal === ''){
        this.mensajeLog("required_id_Sucursal", 'La Sucursal es requerida.')
        return false;
      }
    }

    if(this.state.id_CuentaBancaria === ''){
      this.mensajeLog("required_id_CuentaBancaria", 'La Cuenta Bancaria es requerida.')
      return false;
    } else if(this.state.fh_Vencimiento === ''){
      this.mensajeLog("required_fh_Vencimiento", 'La Fecha de Vencimiento es necesaria.')
      return false;
    }else if(this.state.im_Importe === '' || this.state.im_Importe <= 0){
      this.mensajeLog("required_im_Importe", 'El Importe es requerido y no puede ir menor o igual a 0.')
      return false;
    }else if(this.state.id_FormaPago === ''){
      this.mensajeLog("required_id_FormaPago", 'La forma de pago es necesaria.')
      return false;
    }

    return true;
  }


  handleClickAgregarReferencia = () => {

    if(this.validar()){

      let documentos = [];
      let documentosSucursales = [];

      if(this.state.facturasData.length){
        let positionX = -1;
        for(let i in this.state.facturasData){
          documentos.push([{
            id_Empresa       : this.state.facturasData[i][0].id_Empresa,
            id_Sucursal      : this.state.facturasData[i][0].id_Sucursal,
            cl_TipoDocumento : this.state.facturasData[i][0].cl_TipoDocumento,
            id_Documento     : this.state.facturasData[i][0].id_Documento,
            im_Importe       : localStorage.getItem('id_Factura' + this.state.facturasData[i][0].fl_FolioDocumento),
            im_Descuento     : this.state.facturasData[i][0].im_DescuentoSinFormato
          }]);

          if(localStorage.getItem('id_Factura' + this.state.facturasData[i][0].fl_FolioDocumento) <= 0){
            this.mensajeLog("error_id_Factura" + this.state.facturasData[i][0].fl_FolioDocumento, 'Ninguna factura puede estar con valor menor o igual a 0.');
            return false;
          }

          if(documentosSucursales.length){
            positionX = -1;
            for(let x in documentosSucursales){
              if(
                documentosSucursales[x].id_Empresa===this.state.facturasData[i][0].id_Empresa &&
                documentosSucursales[x].id_Sucursal===this.state.facturasData[i][0].id_Sucursal
              ){
                positionX = x;
              }
            }

            if(positionX < 0){
              documentosSucursales.push([{
                id_Empresa  : this.state.facturasData[i][0].id_Empresa,
                id_Sucursal : this.state.facturasData[i][0].id_Sucursal,
                im_Importe  : parseFloat(localStorage.getItem('id_Factura' + this.state.facturasData[i][0].fl_FolioDocumento)),
              }]);
            }else{
              documentosSucursales[positionX].im_Importe += parseFloat(localStorage.getItem('id_Factura' + this.state.facturasData[i][0].fl_FolioDocumento));
            }

          }else{
            documentosSucursales.push([{
              id_Empresa  : this.state.facturasData[i][0].id_Empresa,
              id_Sucursal : this.state.facturasData[i][0].id_Sucursal,
              im_Importe  : parseFloat(localStorage.getItem('id_Factura' + this.state.facturasData[i][0].fl_FolioDocumento)),
            }]);
          }
        }
      } else {
        documentosSucursales.push([{
          id_Empresa  : this.state.id_Empresa,
          id_Sucursal : this.state.id_Sucursal,
          im_Importe  : this.state.im_Importe,
        }]);
        documentos = '';
      }

      // Validar el numero de cuenta según la la forma de pago
      // Se agrega que solo valide si trae la cuenta
      if(this.state.nu_CuentaCliente){
        const validar = RegExp(this.state.expresion_Regular);
        var resp = validar.test(this.state.nu_CuentaCliente);
        if(!resp){
          this.mensajeLog("required_id_FormaPago", 'Favor de revisar la cuenta de pago seleccionada. ' + this.state.descripcion_Expresion);
          return false;
        }
      }

      let obj = {
        id_Empresa:					      this.state.id_Empresa,
        id_Cliente:					      sesion.usuario.id_Cliente,
        id_Sucursal:              this.state.id_Sucursal,
        id_CuentaBancaria:			  this.state.id_CuentaBancaria,
        id_AlgoritmoReferencia:	  null,
        im_Movimiento:				    this.state.im_Importe,
        fh_Vencimiento:				    this.state.fh_Vencimiento,
        id_Usuario:					      sesion.usuario.id_Usuario,
        documentos:               JSON.stringify(documentos),
        documentosSucursales:     JSON.stringify(documentosSucursales),
        id_FormaPago:             this.state.id_FormaPago,
        id_Banco:                 this.state.id_Banco,
        nu_CuentaCliente:         this.state.nu_CuentaCliente,
      }

      this.setState({classMask:'maskProgress active'});
      invoke.post('referencias','agregar', obj)
        .then(res => {
          // le movi aqui
          if(res.status===200 && res.data.data.length){
            this.showNotification("tc", res.data.message);
            this.setState({ facturasPendientesData: [] });
            this.setState({ openDialogAgregarFactura: false }, () => {
              let PD = res.data.data[0];
              this.setState({ PD_Array: PD });
              this.buscarReferencias();
              this.setState({ openDialogDetalle: true }, () => {
                this.setState({ de_Referencia_Detalle: PD.de_Referencia });
                this.setState({ nb_CuentaClabe_Detalle: PD.nu_ClabeInterbancariaH2H });
                this.setState({ nb_Convenio_Detalle: PD.nu_ConvenioH2H });
                this.setState({ fh_Vencimiento_detalle: PD.fh_Vencimiento_Detalle });
                this.setState({ im_Importe_detalle: PD.im_Importe });

                let obj = {
                  id_Empresa: PD.id_Empresa,
                  id_Cliente: PD.id_Cliente,
                  id_Referencia: PD.id_Referencia,
                }
                this.setState({classMask:'maskProgress active'});
                invoke.post('Referencias','detalle', obj).then(res => {
                  if(res.status===200 && res.data.data){
                    let VAR = [];
                    let PDF = [];
                    let contDescuento = 0;
                    for(let i in res.data.data){
                      let x = res.data.data[i];
                      // para determinar si pintamos la columna de descuento o no
                      if(x.im_DescuentoSinFormato > 0){
                        contDescuento++;
                      }
                      VAR.push([
                        x.fl_FolioDocumento,//Folio Factura
                        x.im_Total,//Total de La factura
                        x.im_Saldo,//saldo de la Factura
                        x.im_Descuento,//descuento en la Factura
                        x.im_Importe,
                      ]);

                      PDF.push([
                        x.fl_FolioDocumento,//Folio Factura
                        x.fh_Vencimiento,//Fecha Vencimiento
                        x.im_Importe,// ImportePago
                      ]);
                    }
                    // si el cliente no retorna descuentos, ocultamos la columna
                    if(!contDescuento){
                      this.setState({ sn_hideDescuento: true });
                      for (let i = 0; i < VAR.length; i++) {
                        VAR[i].splice(3, 1);
                      }
                    }
                    this.setState({ facturasPendientesDetalle: VAR });
                    this.setState({ facturasPendientesDetalle_PDF: PDF });
                  }else{
                    this.showNotification("tr", res.message)
                  }
                  setTimeout(function() {
                    this.setState({classMask:'maskProgress desactive'});
                    setTimeout(function() {
                      this.setState({classMask:'maskProgress'});
                      localStorage.setItem('referenciaFacturas', 'null');
                      this.setState({ tableReset: (<div></div>)}, () => {
                        this.resetTable();
                      });
                    }.bind(this), 1);
                  }.bind(this), 1);
                });
              });
              this.buscarFactura();
            });
          }else if(res.status===200 && res.data.data===0){
            this.showNotification("tr", res.data.message)
          }else{
            this.showNotification("tr", res.data.message)
          }
          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 1);
          }.bind(this), 1);
      });
    }
  };

  handleClickCloseAgregarFactura = () => {
    this.setState({ openDialogAgregarFactura: false });
  };

  handleClickAgregarFactura = () => {
    this.setState({ openDialogAgregarFactura: false });
  };

  handleClickOpenFactura = () => {
    this.setState({ openDialogFactura: true });
    this.buscar();
  };

  handleClickCloseFactura = () => {
    this.setState({ openDialogFactura: false });
  };

  handleChange = name => event => {
    invoke.resetTime();
    if( name === 'ckb_Vencimiento'){
      this.setState({
        [name]: (event.target.value==='FALSE')?'TRUE':'FALSE',
      }, () => { this.buscarFactura() });
    }else{
      this.setState({
        [name]: event.target.value,
      }, () => {
        if(name==='id_Moneda' || name==='id_Empresa' || name==='fh_Inicio' || name==='fh_Fin' || name==='id_EstatusCobranza' || name==='id_Estacion_Filtro'){
          this.buscarFactura();
        }
        if(name==='id_Banco'){
          this.handleChangeCuenta();
        }
        if(name==='id_Empresa'){
          this.handleChangeEmpresa(event.target.value);
        }
        if(name==='id_FormaPago'){
          this.handleChangeFormaPago();
        }
        if(name.slice(0,10)==='id_Factura'){
          localStorage.setItem(name, event.target.value);
          let res = localStorage.getItem('referenciaFacturas').split(",");
          let data = this.state.facturasPendientesData;
          let total = 0.0;
          for(let i in data){
            for(let x in res){
              if(i === res[x]){
                if(localStorage.getItem(name) > data[i].im_NetoSinFormato && name === 'id_Factura'+data[i].fl_FolioDocumento){
                  localStorage.setItem(name, data[i].im_NetoSinFormato);
                  this.setState({ [name]: data[i].im_NetoSinFormato });
                  this.mensajeLog('error_id_Factura'+data[i].fl_FolioDocumento, 'La cantidad no puede ser mayor al saldo de la factura.')
                  this.setState({ facturasPendientes: [] }, () => {
                    this.handleData();
                  });
                }
                total += parseFloat(localStorage.getItem('id_Factura'+data[i].fl_FolioDocumento)) || 0;
              }
            }
          }
          this.setState({ totalSinFormato: total });
          this.setState({ facturasPendientesTotal: this.formatMoney(total) });
          if(total >= 0){
            this.setState({ im_Importe: parseFloat(total).toFixed(2) })
          }
        }
      });
    }
  };


  handleChangeValidate = (name,validate) => event => {
    invoke.resetTime();
    if(validate.maxLength && (event.target.value).length <= validate.maxLength){
      this.setState({
        [name]: event.target.value,
      });
    }
    else if(validate.date){
      let tzoffset = (validate.min).getTimezoneOffset() * 60000; //offset in milliseconds
      let localISOTime = (new Date(validate.min - tzoffset)).toISOString().slice(0, 10);
      let VAR = new Date(localISOTime);
      let VAL = new Date(event.target.value);
      if(VAR <= VAL){
        this.setState({
          [name]: event.target.value,
        });
      }
    }
  };

  noOfDaysInMonth(){
    let date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  };

  getFirstDayOfMonth(){
    let date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      1,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  getLastDayOfMonth(){
    let date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      this.noOfDaysInMonth(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  showNotification(place,message,time=5000) {
    this.setState({message : message})
    let val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    let val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  handleChangeIndex = index => {
    invoke.resetTime();
    this.setState({ value: index });
  };

  handleClickOpenDialogReferencia = () => {
    this.buscarReferencias();
  };

  handleClickSearchFacturas = () => {
    this.setState({ expandedComprobantes : false });
    this.setState({ expandedFacturas : true });
    this.buscarFactura();
  };

  handleClickSearchFacturasClose = () => {
    this.setState({ expandedComprobantes : true });
    this.setState({ expandedFacturas : false });
    this.buscarFactura();
  };

  handleClickSearchReferencias = () => {
    this.setState({ expandedComprobantes : true });
    this.setState({ expandedFacturas : false });
    this.buscarReferencias();
  };

  handleClickSearchReferenciasClose = () => {
    this.setState({ expandedComprobantes : false });
    this.setState({ expandedFacturas : true });
    this.buscarReferencias();
  };

  imageEncode = (arrayBuffer) => {
    // let u8 = new Uint8Array(arrayBuffer)
    let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer),function(p,c){return p+String.fromCharCode(c)},''))
    let mimetype="image/jpeg"
    return "data:"+mimetype+";base64,"+b64encoded
  }

  buscarFactura(){
    //this.resetTable();
    let obj = {
      id_Usuario: sesion.usuario.id_Usuario,
      id_Cliente: sesion.usuario.id_Cliente,
      fh_Inicio: this.state.fh_Inicio,
      fh_Fin: this.state.fh_Fin,
      id_Estatus: this.state.id_EstatusCobranza,
      id_Empresa: this.state.id_Empresa,
      sn_Asociados: sesion.estacion ? 1 : null,
      fl_Factura: this.state.fl_Factura || null,
      sn_FHVencimiento: (this.state.ckb_Vencimiento === 'TRUE') ? 1 : 0,
      id_Estacion: this.state.id_Estacion_Filtro || null,
    }
    for(let i in this.state.estatuscobranza){
      if(this.state.estatuscobranza[i].value===this.state.id_EstatusCobranza){
        this.setState({nb_EstatusCobranza : this.state.estatuscobranza[i].label})
      }
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('facturas','listadoPendientes',obj).then(res => {
      if(res.status===200 && res.data.data){
        let tableDataVAR = [];
        this.setState({ facturasPendientesData : res.data.data });
        localStorage.setItem('facturasPendientesData', res.data.data);
        let contDescuento = 0;
        let contEstaciones = 0;
        for(let i in res.data.data){
          let PD = res.data.data[i];
          if(PD.im_DescuentoSinFormato > 0){
            contDescuento++;
          }
          const id_Estacion = (PD.id_Estacion || 0);
          if(id_Estacion > 0){
            contEstaciones++;
          }
          if (PD.sn_Abonada || PD.sn_MReferencia) {
            tableDataVAR.push({id:i,data:[
              (<label style={{color: PD.de_RowColor,fontWeight: 'bold'}}>{PD.nb_Estacion}</label>),
              (<label style={{color: PD.de_RowColor,fontWeight: 'bold'}}>{PD.fl_FolioDocumento}</label>),
              (<label style={{color: PD.de_RowColor,fontWeight: 'bold'}}>{PD.fh_Documento}</label>),
              (<label style={{color: PD.de_RowColor,fontWeight: 'bold'}}>{PD.fh_Vencimiento}</label>),
              (<label style={{color: PD.de_RowColor,fontWeight: 'bold'}}>{PD.im_Total}</label>),
              (<label style={{color: PD.de_RowColor,fontWeight: 'bold'}}>{PD.im_SaldoFactura}</label>),
              (<label style={{color: PD.de_RowColor,fontWeight: 'bold'}}>{PD.im_Descuento}</label>),
              (<label style={{color: PD.de_RowColor,fontWeight: 'bold'}}>{PD.im_Neto}</label>),
            ]});
          } else {
            tableDataVAR.push({id:i,data:[
              PD.nb_Estacion, PD.fl_FolioDocumento, PD.fh_Documento, PD.fh_Vencimiento, PD.im_Total, PD.im_SaldoFactura, PD.im_Descuento, PD.im_Neto,
            ]});
          }
        }
        // Eliminar del arreglo de la tabla las columnas de descuento
        if(!contDescuento){
          this.setState({ sn_hideDescuento_LF: true });
          for (let i = 0; i < tableDataVAR.length; i++) {
            for (let x = 0; x < tableDataVAR[i].data.length; x++) {
              tableDataVAR[i].data.splice(6,1);
              break;
            }
          }
        } else{
          this.setState({ sn_hideDescuento_LF: false });
        }

       // Eliminar del arreglo de la tabla las columnas de estacion
        if(!contEstaciones){
          this.setState({ sn_HideEstacion: true });
          for (let i = 0; i < tableDataVAR.length; i++) {
            for (let x = 0; x < tableDataVAR[i].data.length; x++) {
              tableDataVAR[i].data.splice(0,1);
              break;
            }
          }
        } else{
          this.setState({ sn_HideEstacion: false });
        }

        if(!tableDataVAR.length){
          this.showNotification("tr", 'No se encontraron Facturas')
        }else{
          localStorage.setItem('referenciaFacturas', '')
        }

        this.setState({ tableData: tableDataVAR }, () => {
          this.resetTable();
        });
      }else{
        this.showNotification("tr", res.data.message)
      }

      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  buscarReferencias(){
    const { classes } = this.props;
    let obj = {
      id_Cliente: sesion.usuario.id_Cliente,
      id_Empresa: null,
      id_Estatus: this.state.id_Estatus_Filtro,
      fh_Inicio: this.state.fh_InicioSearch,
      fh_Fin: this.state.fh_FinSearch,
    }

    this.setState({classMask:'maskProgress active'});
    invoke.post('referencias','listado',obj).then(res => {
      if(res.status===200 && res.data.data){
        let tableDataVAR = [];
          this.setState({ comprobantesData : res.data.data });

          for(let i in res.data.data){
            let PD = res.data.data[i];
            tableDataVAR.push([
              PD.de_Referencia,
              '$'+PD.im_Importe,
              (
                <Tooltip
                  title={PD.fh_Vencimiento}
                  placement={'bottom-start'}
                  enterDelay={300}>
                  <span className={classes.estatus} style={{background: PD.fh_Color}}>{PD.fh_Vencimiento}</span>
                </Tooltip>
              ),
              PD.fh_Pago,
              (
                <Tooltip
                  title={PD.de_Estatus}
                  placement={'bottom-start'}
                  enterDelay={300}>
                  <span className={classes.estatus} style={{background: PD.nb_Color}}>{PD.de_Estatus}</span>
                </Tooltip>
              ),
              (
                <div>
                  <Tooltip
                      title='Visualizar Detalle de Referencia'
                      placement={'bottom-start'}
                      enterDelay={300}>
                    <IconButton aria-label="Detalle" aria-haspopup="true" onClick={this.handleClickOpenDetalle(PD)} className={classes.buttonTable}>
                      <Visibility className={classes.links} />
                    </IconButton>
                  </Tooltip>
                  {(PD.id_Estatus!==1532 && PD.id_Estatus!==1533 && PD.id_Estatus!==1534 && PD.id_Estatus!==1535)?(
                  <Tooltip
                      title='Imprimir Referencia'
                      placement={'bottom-start'}
                      enterDelay={300}>
                    <IconButton aria-label="Detalle" aria-haspopup="true" className={classes.buttonTable} onClick={this.getDetalleReferencia(PD)}>
                      <Print className={classes.links} />
                    </IconButton>
                  </Tooltip>):null}
                  {(PD.id_Estatus!==1532 && PD.id_Estatus!==1533 && PD.id_Estatus!==1534 && PD.id_Estatus!==1535)?(
                  <Tooltip
                      title='Enviar Mail'
                      placement={'bottom-start'}
                      enterDelay={300}>
                    <IconButton aria-label="Detalle" aria-haspopup="true" onClick={this.handleClickOpenMail(PD)} className={classes.buttonTable}>
                      <Mail className={classes.links} />
                    </IconButton>
                  </Tooltip>
                  ):null}
                  {((PD.id_Estatus===1533 || PD.id_Estatus===1534) && (PD.ar_PDF))?(
                    <Tooltip
                      title='Descargar Factura PDF'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.ar_PDF ? PD.ar_PDF:''),(PD.ar_PDF ? PD.ar_PDF:''))} style={{width:'23px',height:'23px',color:'#C62828'}} className={classes.buttonTable}>
                        <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>):null}
                  {((PD.id_Estatus===1533 || PD.id_Estatus===1534) && (PD.ar_XML))?(
                    <Tooltip
                      title='Descargar Factura XML'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <a href={PD.ar_XML} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="DescargarXML" aria-haspopup="true" style={{width:'23px',height:'23px',color:'#388E3C'}} className={classes.buttonTable}>
                          <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                        </IconButton>
                      </a>
                    </Tooltip>
                  ):null}
                  {((PD.id_Estatus===1533 || PD.id_Estatus===1534) && (PD.ar_PDF) && !(PD.ar_XML))?(
                    <Tooltip
                      title='Descargar Factura PDF'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Descargar" aria-haspopup="true" onClick={this.downloadPdf( (PD.ar_PDF ? PD.ar_PDF:''),(PD.ar_PDF ? PD.ar_PDF:''))} style={{width:'23px',height:'23px',color:'#C62828'}} className={classes.buttonTable}>
                        <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>):null}
                  {((PD.id_Estatus===1531))?(
                    <Tooltip
                      title='Cancelar'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Detalle" style={{color:'#F44336'}} aria-haspopup="true" onClick={this.handleClickOpenCancelar(PD)} className={classes.buttonTable}>
                        <NotInterested className={classes.links} />
                      </IconButton>
                    </Tooltip>
                  ):null}
                </div>
              )
            ]);
          }

          // if(!tableDataVAR.length){
          //   this.showNotification("tr", 'No se encontraron Referencias Bancarias.')
          // }

          this.setState({ tableDataReferencias: tableDataVAR });
      }else{
        this.showNotification("tr", res.data.message)
      }

      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  handleClickEnviarPDF(){
    this.setState({classMask:'maskProgress active'});
    invoke.post('referencias','enviarCorreo',{...this.state.PD_Array, id_Usuario: sesion.usuario.id_Usuario}).then(res => {
      if(res.status===200 && res.data.data){
        this.showNotification("tc", res.data.message);
      }else{
        this.showNotification("tr", 'No se pudo mandar el correo.')
      }
      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <ExpansionCard defaultExpanded={true}
              icon={Payment}
              iconColor="blueSip"
              cardTitle="Referencias Bancarias"
              cardSubtitle='Información de todas las comprobaciones que se han agregado'
              expanded={this.state.expandedComprobantes}
              content={(<div>
                          <MuiThemeProvider theme={theme}>
                            <Grid container>
                              <ItemGrid xs={12} sm={4} md={2}>
                                <TextField
                                  id="id_Estatus_Filtro"
                                  select
                                  label="Estatus"
                                  className={classes.textField}
                                  value={this.state.id_Estatus_Filtro}
                                  onChange={this.handleChange('id_Estatus_Filtro')}
                                  SelectProps={{
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  margin="normal"
                                  fullWidth
                                >
                                  {this.state.comboEstatus.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={4} md={2}>
                                <TextField
                                  id="fh_InicioSearch"
                                  label="Fecha Factura Inicio"
                                  type="date"
                                  value={this.state.fh_InicioSearch}
                                  onChange={this.handleChange('fh_InicioSearch')}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  margin="normal"
                                  fullWidth
                                />
                              </ItemGrid>
                              <ItemGrid xs={12} sm={4} md={2}>
                                <TextField
                                  id="fh_FinSearch"
                                  label="Fin"
                                  type="date"
                                  value={this.state.fh_FinSearch}
                                  onChange={this.handleChange('fh_FinSearch')}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  margin="normal"
                                  fullWidth
                                />
                              </ItemGrid>
                              <ItemGrid xs={12} sm={1} md={1}>
                                <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                                  <IconButton
                                      color="primary"
                                      style={{marginTop: '20px'}}
                                      aria-label="Agregar"
                                      aria-haspopup="true"
                                      onClick={this.handleClickSearchReferencias}>
                                    <Search />
                                  </IconButton>
                                </Tooltip>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={5} md={5}>
                                <MuiThemeProvider theme={themeGreen}>
                                  <Button
                                    variant="contained"
                                    style={{float: 'right', position: 'relative', top: '18px', color: '#fff', background: '#55ae59'}}
                                    className={classes.button}
                                    onClick={this.handleClickOpenAgregarFactura(0)}
                                  >
                                    Prepago <AddCircle className={classes.links} style={{paddingLeft: '5px'}} />
                                  </Button>
                                </MuiThemeProvider>
                                <MuiThemeProvider theme={themeGreen}>
                                  <Button
                                    variant="contained"
                                    style={{float: 'right', marginRight: '10px', position: 'relative', top: '18px', color: '#fff', background: 'linear-gradient(60deg, #26c6da, #00acc1)'}}
                                    className={classes.button}
                                    onClick={this.handleClickSearchFacturas}
                                  >
                                    Facturas <Assignment className={classes.links} style={{paddingLeft: '5px'}} />
                                  </Button>
                                </MuiThemeProvider>
                              </ItemGrid>
                            </Grid>
                          </MuiThemeProvider>
                          <div>
                            <Table
                              titulo = "Referencias Bancarias"
                              tableHead={[
                                { id: 'de_Referencia', numeric: false, disablePadding: false, label: 'Referencia del Banco' },
                                { id: 'im_Importe', numeric: false, disablePadding: false, label: 'Importe' },
                                { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Fecha Vencimiento' },
                                { id: 'fh_Pago', numeric: false, disablePadding: false, label: 'Fecha Pago' },
                                { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus' },
                                { id: '', numeric: false, disablePadding: false, label: 'Acciones' }
                              ]}
                              tableData={this.state.tableDataReferencias}
                            />
                          </div>
                      </div>)}
              expansionAction = {this.handleClickSearchReferencias}
              expationClose= {this.handleClickSearchReferenciasClose}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={12}>
            <ExpansionCard
              icon={Assignment}
              iconColor="blue"
              cardTitle="Facturas del cliente"
              cardSubtitle='Informacion de facturas del Cliente'
              expanded={this.state.expandedFacturas}
              content={(<div>
                          <MuiThemeProvider theme={theme}>
                            <Grid container>
                              <ItemGrid xs={12} sm={4} md={3}>
                                <TextField
                                  id="id_EmpresaSearch"
                                  select
                                  label="Empresa"
                                  className={classes.textField}
                                  value={this.state.id_Empresa}
                                  onChange={this.handleChange('id_Empresa')}
                                  SelectProps={{
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  margin="normal"
                                  fullWidth
                                >
                                  {this.state.empresas.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={12} md={2} style={{display:"none"}}>
                                <TextField
                                  id="name"
                                  label="Folio de Factura"
                                  className={classes.textField}
                                  value={this.state.fl_Factura}
                                  onChange={this.handleChange('fl_Factura')}
                                  margin="normal"
                                  type="text"
                                  disabled="true"
                                  fullWidth
                                />
                              </ItemGrid>
                              <ItemGrid xs={12} sm={4} md={3}>
                                <TextField
                                    id="id_EstatusCobranza"
                                    select
                                    label="Estatus Cobranza"
                                    className={classes.textField}
                                    value={this.state.id_EstatusCobranza}
                                    onChange={this.handleChange('id_EstatusCobranza')}
                                    SelectProps={{
                                      MenuProps: {
                                        className: classes.menu,
                                      },
                                    }}
                                    margin="normal"
                                    fullWidth
                                  >
                                    {this.state.estatuscobranza.map(option => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={4} md={3}>
                                <TextField
                                  id="fh_Inicio"
                                  label="Fecha Factura Inicio"
                                  type="date"
                                  value={this.state.fh_Inicio}
                                  onChange={this.handleChange('fh_Inicio')}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  margin="normal"
                                  fullWidth
                                />
                              </ItemGrid>
                              <ItemGrid xs={12} sm={4} md={2}>
                                <TextField
                                  id="fh_Fin"
                                  label="Fin"
                                  type="date"
                                  value={this.state.fh_Fin}
                                  onChange={this.handleChange('fh_Fin')}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  margin="normal"
                                  fullWidth
                                />
                              </ItemGrid>
                              <ItemGrid xs={6} sm={1} md={1}>
                                <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                                  <IconButton
                                      color="primary"
                                      style={{marginTop: '20px'}}
                                      aria-label="Agregar"
                                      aria-haspopup="true"
                                      onClick={this.handleClickSearchFacturas}>
                                    <Search />
                                  </IconButton>
                                </Tooltip>
                              </ItemGrid>
                              <ItemGrid xs={6} sm={3} md={3} style={{marginTop: '23px'}}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={(this.state.ckb_Vencimiento==='TRUE')?true:false}
                                      onChange={this.handleChange('ckb_Vencimiento')}
                                      value={this.state.ckb_Vencimiento}
                                      style={{color:'#ff9800'}}
                                    />
                                  }
                                  label={(<p>Fecha por Vencimiento</p>)}
                                />
                              </ItemGrid>
                              <ItemGrid xs={12} sm={12} md={3} style={{display: this.state.showFiltroEstacion}}>
                                <TextField
                                  id="id_Estacion_Filtro"
                                  select
                                  label="Estación"
                                  className={classes.textField}
                                  value={this.state.id_Estacion_Filtro}
                                  onChange={this.handleChange('id_Estacion_Filtro')}
                                  SelectProps={{
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  margin="normal"
                                  fullWidth
                                >
                                  {this.state.estaciones.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </ItemGrid>                              
                            </Grid>
                          </MuiThemeProvider>
                          <MuiThemeProvider theme={theme}>
                            {this.state.tableReset}
                          </MuiThemeProvider>
                      </div>)}
              expansionAction = {this.handleClickSearchFacturas}
              expationClose= {this.handleClickSearchFacturasClose}
            />
          </ItemGrid>
        </Grid>

        <FormDialog
          title = {(<div><InputAdornment position="start"><AssignmentReturned /><span style={{paddingLeft:'15px'}}>Descargar Factura</span></InputAdornment></div>)}
          description= {this.state.descriptionFactura}
          content = {
            <div>
              <iframe title="Factura" style={{minHeight: '63VH', minWidth: '1100px'}} src={this.state.urlFactura}></iframe>
            </div>
          }
          btn= 'Descargar'
          action= {this.handleClickFactura}
          close={this.handleClickCloseFacturaView}
          open= {this.state.openDialogFacturaView}
        />

        <FormDialog
          title = {(<div><InputAdornment position="start"><Payment /><span style={{paddingLeft:'15px'}}>Generar Referencia</span></InputAdornment></div>)}
          description = {<div>
            <label style={{color: '#0d4377', fontSize: '16px'}}>Pasos para generar una Referencia Bancaria, puede o no tener facturas referenciadas</label>
            <img alt="Banco" className={classes.imgBanco} src={this.state.urlBanco} />
          </div>}
          content = {
            <div>
              <VerticalStep
                steps={[
                  (this.state.facturasPendientes.length)?{
                    label:'Facturas Pendientes('+(this.state.facturasPendientes.length)+') - Total: $'+(this.state.facturasPendientesTotal),
                    handleChange : this.handleChange,
                    html:(<div>
                        <TableSimple xs={12} sm={12} md={12}
                          tableHead={[
                            { id: 'Folio Factura', numeric: true, disablePadding: false, label: 'Folio Factura', },
                            { id: 'fh_Vencimiento', numeric: false, disablePadding: false, label: 'Fecha Venc.' },
                            { id: 'Total Factura', numeric: false, disablePadding: false, label: 'Total Factura'},
                            { id: 'Saldo', numeric: true, disablePadding: false, label: 'Saldo' },
                            { id: 'Saldo', numeric: true, disablePadding: false, label: 'Descuento Pronto Pago', hidden: this.state.sn_hideDescuento_REF },
                            { id: 'Cantidad', numeric: false, disablePadding: false, label: 'Importe Pago' },
                          ]}
                          tableData={this.state.facturasPendientes}
                        />
                        <label style={{color:'#555', textAlign: 'right', display: 'block', fontSize: '15px', lineHeight: '35px'}}>Total: ${this.state.facturasPendientesTotal}</label>
                      </div>),
                  }:{
                    label:'Sin Facturas Adjuntas es un comprobante de Prepago',
                    html:(<div>
                        <p style={{color:'#F44336'}}>**Para Adjuntar facturas se necesitan seleccionar facturas del listado de facturas pendientes.<br />
                        En cualquier otro caso es un comprobante de Prepago.**</p>
                        <Grid container>
                          <ItemGrid xs={12} sm={12} md={12}>
                            <TextField
                              id="id_EmpresaStep"
                              select
                              error={this.state.required_id_Empresa}
                              label="Empresa"
                              className={classes.textField}
                              value={this.state.id_Empresa}
                              onChange={this.handleChange('id_Empresa')}
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu,
                                },
                              }}
                              margin="normal"
                              fullWidth
                            >
                              {this.state.empresas.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={12} md={12}>
                            <TextField
                              id="id_SucursalStep"
                              error={this.state.required_id_Sucursal}
                              select
                              label="Sucursal"
                              className={classes.textField}
                              value={this.state.id_Sucursal}
                              onChange={this.handleChange('id_Sucursal')}
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu,
                                },
                              }}
                              margin="normal"
                              fullWidth
                            >
                              {this.state.sucursales.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </ItemGrid>
                        </Grid>
                      </div>)
                  },
                  {
                    label:'Fecha de Vencimiento: '+(this.state.fh_Vencimiento),
                    html:(<div style={{width: '700px'}}>
                          <MuiThemeProvider theme={theme}>
                            <Grid container>
                              <ItemGrid xs={12} sm={6} md={6}>
                                <TextField
                                  id="fh_Vencimiento"
                                  label="Fecha Vencimiento"
                                  type="date"
                                  autoFocus={true}
                                  className={classes.textField}
                                  error={this.state.required_fh_Vencimiento}
                                  value={this.state.fh_Vencimiento}
                                  onChange={this.handleChangeValidate('fh_Vencimiento',{'date':true,'min': new Date()})}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  margin="normal"
                                  fullWidth
                                />
                              </ItemGrid>
                              <ItemGrid xs={12} sm={6} md={6}>
                                <TextField
                                  className={classes.formControl}
                                  error={this.state.required_im_Importe}
                                  label="Importe Referencia"
                                  value={this.state.im_Importe}
                                  onChange={this.handleChange('im_Importe')}
                                  id="importe"
                                  fullWidth
                                  margin="normal"
                                  disabled={(this.state.facturasPendientes.length)?true:false}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                                    style: {textAlign:'right'}
                                  }}
                                />
                              </ItemGrid>
                            </Grid>
                            <Grid container>
                              <ItemGrid xs={12} sm={12} md={12}>
                                <TextField
                                  id="id_CuentaBancaria"
                                  select
                                  label="Cuenta Bancaria"
                                  error={this.state.required_id_CuentaBancaria}
                                  className={classes.textField}
                                  value={this.state.id_CuentaBancaria}
                                  onChange={this.handleChange('id_CuentaBancaria')}
                                  SelectProps={{
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  margin="normal"
                                  fullWidth
                                >
                                  {this.state.cuentasBancarias.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </ItemGrid>
                            </Grid>
                          </MuiThemeProvider>
                        </div>),
                },
                {
                  label:'Información CFDI Pago '+((this.state.nu_CuentaCliente)? '- No. Cuenta:'+this.state.nu_CuentaCliente : ''),
                  html:(<div style={{maxWidth: '700px'}}>
                        <MuiThemeProvider theme={theme}>
                          <Grid container>
                            <ItemGrid xs={12} sm={12} md={12}>
                              <TextField
                                id="id_FormaPago"
                                select
                                label="Forma de Pago"
                                error={this.state.required_id_FormaPago}
                                className={classes.textField}
                                value={this.state.id_FormaPago}
                                onChange={this.handleChange('id_FormaPago')}
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                                autoFocus={true}
                                margin="none"
                                fullWidth
                              >
                                {this.state.formaPagos.map(option => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={6} md={6}>
                              <TextField
                                id="id_Banco"
                                select
                                label="Banco Preferente(opcional)"
                                className={classes.textField}
                                value={this.state.id_Banco}
                                onChange={this.handleChange('id_Banco')}
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                                helperText="Banco del cliente que aparecera en cfdi"
                                margin="normal"
                                fullWidth
                              >
                                {this.state.comboBancos.map(option => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </ItemGrid>
                            <ItemGrid xs={10} sm={5} md={5}>
                              <TextField
                                id="nu_CuentaCliente"
                                select
                                error={this.state.required_nu_CuentaCliente}
                                label="Numero de Cuenta(opcional)"
                                className={classes.textField}
                                value={this.state.nu_CuentaCliente}
                                onChange={this.handleChange('nu_CuentaCliente')}
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                                helperText={((this.state.descripcion_Expresion)? this.state.descripcion_Expresion : 'Seleccione una forma de pago')}
                                margin="normal"
                                fullWidth
                              >
                                {this.state.comboCuentas.map(option => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </ItemGrid>
                            <ItemGrid xs={2} sm={1} md={1} style={{marginTop: '15px'}}>
                              <Tooltip title={'Agregar Numero de cuenta'} placement={'bottom-start'} enterDelay={100} >
                                <IconButton
                                    color="primary"
                                    style={{marginTop: '20px'}}
                                    aria-label="Agregar"
                                    aria-haspopup="true"
                                    onClick={this.handleClickOpenCuentaBancaria.bind(this)}
                                    >
                                  <AddCircle />
                                </IconButton>
                              </Tooltip>
                            </ItemGrid>
                          </Grid>
                        </MuiThemeProvider>
                      </div>),
                }]}
                finish={(<div>
                  <InputAdornment position="start">
                    <Payment />
                    <span style={{paddingLeft:'15px'}}>Para teminar el proceso de click en "Generar Referencia", una vez generada <br />la referencia no podra cancelarce.</span>
                  </InputAdornment>
                </div>)}
                handleData={this.handleData.bind(this)}
                activeStepData={this.activeStepData.bind(this)}
              />
            </div>
          }
          disabled={(this.state.activeStepVAR===2)? false : true}
          btn = 'Generar Referencia'
          action = {this.handleClickAgregarReferencia}
          close = {this.handleClickCloseAgregarFactura}
          open = {this.state.openDialogAgregarFactura}
        />

        <FormDialog
          title = {(<div><InputAdornment position="start"><Payment /><span style={{paddingLeft:'15px'}}>Detalle de la Referencia</span></InputAdornment></div>)}
          content = {<div>
              <Grid container>
                <ItemGrid xs={12} sm={12} md={6}>
                  <TextField
                    className={classes.formControl}
                    label="Importe Referencia"
                    value={this.state.im_Importe_detalle}
                    onChange={this.handleChange('im_Importe_detalle')}
                    margin="normal"
                    fullWidth
                    disabled={true}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                      style: {textAlign:'right'}
                    }}
                  />
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={6}>
                  <TextField
                    label="Fecha Vencimiento"
                    type="date"
                    autoFocus={true}
                    disabled={true}
                    className={classes.textField}
                    value={this.state.fh_Vencimiento_detalle}
                    onChange={this.handleChange('fh_Vencimiento_detalle')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    fullWidth
                  />
                </ItemGrid>
                <ItemGrid xs={12} sm={4} md={4}>
                  <TextField
                    className={classes.formControl}
                    label="Referencia Bancaria"
                    value={this.state.de_Referencia_Detalle}
                    onChange={this.handleChange('de_Referencia_Detalle')}
                    margin="normal"
                    disabled={true}
                    fullWidth
                  />
                </ItemGrid>
                <ItemGrid xs={12} sm={4} md={4}>
                  <TextField
                    className={classes.formControl}
                    label="Cuenta Clabe"
                    value={this.state.nb_CuentaClabe_Detalle}
                    onChange={this.handleChange('nb_CuentaClabe_Detalle')}
                    margin="normal"
                    disabled={true}
                    fullWidth
                  />
                </ItemGrid>
                <ItemGrid xs={12} sm={4} md={4}>
                  <TextField
                    className={classes.formControl}
                    label="Convenio"
                    value={this.state.nb_Convenio_Detalle}
                    onChange={this.handleChange('nb_Convenio_Detalle')}
                    margin="normal"
                    disabled={true}
                    fullWidth
                  />
                </ItemGrid>
                {(this.state.facturasPendientesDetalle.length)?(
                <TableSimple xs={12} sm={12} md={12}
                  tableHead={[
                    { id: 'Folio Factura', numeric: true, disablePadding: false, label: 'Folio Factura',},
                    { id: 'Total', numeric: false, disablePadding: false, label: 'Total' },
                    { id: 'im_SaldoFactura', numeric: true, disablePadding: false, label: 'Saldo Actual' },
                    { id: 'im_Descuento', numeric: true, disablePadding: false, label: 'Descuento Pronto Pago',hidden:this.state.sn_hideDescuento},
                    { id: 'im_Importe', numeric: true, disablePadding: false, label: 'Importe Aplicado' },
                  ]}
                  tableData={this.state.facturasPendientesDetalle}
                />):<div></div>}
              </Grid>
          </div>}
          btn = 'Imprimir'
          action = {(this.state.PD_Array && this.state.PD_Array.id_Estatus!==1535)?(<Button style={{color: '#fff', background: '#ff9800'}} className={classes.button} onClick={this.generatePdf.bind(this)}><Print /> Imprimir</Button>):null}
          close = {this.handleClickCloseDetalle}
          open = {this.state.openDialogDetalle}
        />

        <FormDialog
          title = {(<div><InputAdornment position="start"><AddCircle /><span style={{paddingLeft:'15px'}}>Agregar Numero de Cuenta</span></InputAdornment></div>)}
          description = ""
          content = {<div>
              <Grid container>
                <ItemGrid xs={12} sm={12} md={12}>
                  <TextField
                    id="id_Banco"
                    select
                    label="Banco Preferente(opcional)"
                    className={classes.textField}
                    value={this.state.id_Banco}
                    onChange={this.handleChange('id_Banco')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Banco del cliente que aparecera en cfdi"
                    margin="normal"
                    fullWidth
                  >
                    {this.state.comboBancos.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={12}>
                  <TextField
                    className={classes.formControl}
                    error={this.state.required_nu_CuentaCliente}
                    label="Numero de Cuenta"
                    value={this.state.nu_CuentaCliente}
                    onChange={this.handleChangeValidate('nu_CuentaCliente',{'maxLength':18})}
                    id="nu_CuentaCliente"
                    fullWidth
                    type="number"
                    margin="normal"
                    helperText="Solo se permiten valores NUMÉRICOS. Límite de (10, 16, 18) caracteres."
                  />
                </ItemGrid>
              </Grid>
          </div>}
          btn = 'Agregar'
          action = {this.handleClickAgregarCuenta.bind(this)}
          close = {this.handleClickCloseCuentaBancaria}
          open = {this.state.openDialogCuentaBancaria}
        />

        <FormDialog
          title = {(<div><InputAdornment position="start"><NotInterested /><span style={{paddingLeft:'15px'}}>Cancelar Referencia</span></InputAdornment></div>)}
          description = ""
          content = {<div>
              <Grid container>
                <ItemGrid xs={12} sm={12} md={12}>
                  <p>¿Realmente desea cancelar la referencia de pago seleccionada?</p>
                </ItemGrid>
              </Grid>
          </div>}
          btn = 'Aceptar'
          action = {this.handleClickCancelar.bind(this)}
          close = {this.handleClickCloseCancelar}
          open = {this.state.openDialogCancelar}
        />

        <FormDialog
          title = {(<div><InputAdornment position="start"><Mail /><span style={{paddingLeft:'15px'}}>Enviar Correo</span></InputAdornment></div>)}
          description = ""
          content = {<div>
              <Grid container>
                <ItemGrid xs={12} sm={12} md={6}>
                  <TextField
                    className={classes.formControl}
                    label="Importe Referencia"
                    value={this.state.im_Importe_detalle}
                    onChange={this.handleChange('im_Importe_detalle')}
                    margin="normal"
                    fullWidth
                    disabled={true}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                      style: {textAlign:'right'}
                    }}
                  />
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={6}>
                  <TextField
                    label="Fecha Vencimiento"
                    type="date"
                    autoFocus={true}
                    disabled={true}
                    className={classes.textField}
                    value={this.state.fh_Vencimiento_detalle}
                    onChange={this.handleChange('fh_Vencimiento_detalle')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    fullWidth
                  />
                </ItemGrid>
                <ItemGrid xs={12} sm={4} md={4}>
                  <TextField
                    className={classes.formControl}
                    label="Referencia Bancaria"
                    value={this.state.de_Referencia_Detalle}
                    onChange={this.handleChange('de_Referencia_Detalle')}
                    margin="normal"
                    disabled={true}
                    fullWidth
                  />
                </ItemGrid>
                <ItemGrid xs={12} sm={4} md={4}>
                  <TextField
                    className={classes.formControl}
                    label="Cuenta Clabe"
                    value={this.state.nb_CuentaClabe_Detalle}
                    onChange={this.handleChange('nb_CuentaClabe_Detalle')}
                    margin="normal"
                    disabled={true}
                    fullWidth
                  />
                </ItemGrid>
                <ItemGrid xs={12} sm={4} md={4}>
                  <TextField
                    className={classes.formControl}
                    label="Convenio"
                    value={this.state.nb_Convenio_Detalle}
                    onChange={this.handleChange('nb_Convenio_Detalle')}
                    margin="normal"
                    disabled={true}
                    fullWidth
                  />
                </ItemGrid>
                {(this.state.facturasPendientesDetalle.length)?(<TableSimple xs={12} sm={12} md={12}
                  tableHead={[
                    { id: 'Folio Factura', numeric: true, disablePadding: false, label: 'Folio Factura' },
                    { id: 'Total', numeric: false, disablePadding: false, label: 'Total' },
                    { id: 'im_SaldoFactura', numeric: true, disablePadding: false, label: 'Saldo Actual' },
                    { id: 'im_Descuento', numeric: true, disablePadding: false, label: 'Descuento Pronto Pago', hidden:this.state.sn_hideDescuento },
                    { id: 'im_Importe', numeric: true, disablePadding: false, label: 'Importe Aplicado' },
                  ]}
                  tableData={this.state.facturasPendientesDetalle}
                />):<div></div>}

              </Grid>
          </div>}
          btn = 'Enviar'
          action = {this.handleClickEnviarPDF.bind(this)}
          close = {this.handleClickCloseMail}
          open = {this.state.openDialogMail}
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.tr}
          onClose={() => this.setState({ tr: false })}
          ContentProps={{ 'className': classes.red }}
          message={this.state.message}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.tc}
          onClose={() => this.setState({ tc: false })}
          ContentProps={{ 'className': classes.green }}
          message={this.state.message}
        />
        <div className={this.state.classMask}>
          <Linear  />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
