import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
//import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const headStyle = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  head: {
    backgroundColor: '#0d4377'
  },
  cell: {
    width: '1%',
    color: '#555',
    boxSizing: 'border-box',
    whiteSpace: 'initial',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '10px',
    textAlign: 'center',
    lineHeight: '15px',
  },
  check:{
    width: '1%',
    textAlign: 'center',
    padding: '0px'
  },
  title: {
    padding: '20px',
  },
  height:{
    height: '35px'
  }
});

class EnhancedTableHead extends React.Component {

  render() {
    const { onSelectAllClick, numSelected, rowCount, tableHead, classes } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" className={classes.check}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {tableHead.map(
            row => (
              (row.hidden ? null :
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                className={classes.cell}
              >
                {row.label}
              </TableCell>
            )),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  tableHead: PropTypes.arrayOf(PropTypes.object)
};

EnhancedTableHead = withStyles(headStyle)(EnhancedTableHead);

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
    width: 'calc(100% - 48px)'
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, titulo, action, actionSelected  } = props;

  return (
    <div>
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit">
              {numSelected} Seleccionados
            </Typography>
          ) : (
            <Typography id="tableTitle">
              {titulo}
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {numSelected > 0 ? (
            <div>{actionSelected}</div>
          ) : (
            <div>{action}</div>
          )}
        </div>
      </Toolbar>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  titulo: PropTypes.node,
  action: PropTypes.node,
  actionSelected: PropTypes.node
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableTH:{
    width: '10%',
    padding: '0',
    textAlign: 'center'
  },
  cell: {
    overflow: 'hidden',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '1px',
    padding: '4px',
    textAlign: 'center',
    fontSize: '12px',
  },
  row: {
    height: '35px',
  },
  labelRowsPerPage: {
    '@media (max-width: 768px)': {
      display:'none'
    }
  }
});

class EnhancedTable extends React.Component {

  state = {
    selected: [],
    data: this.props.tableData,
    page: 0,
    rowsPerPage: 10,
  };

  handleSelectAllClick = event => {
    const { tableData } = this.props;
    if (event.target.checked) {
      this.setState(state => ({ selected: tableData.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id, data) => {
    // console.log(data);
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, tableData, tableHead, titulo, action, actionSelected } = this.props;
    const { selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);
    if (localStorage.getItem('referenciaFacturas') === 'null') {
      localStorage.setItem('referenciaFacturas', '');
    } else {
      localStorage.setItem('referenciaFacturas', selected);
    }
    
    
    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar 
          numSelected={selected.length} 
          titulo={titulo} 
          action={action} 
          actionSelected={actionSelected}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={this.handleSelectAllClick}
              rowCount={tableData.length}
              tableHead={tableHead}
              action={action}
            />
            <TableBody>
              {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prop, key) => {
                  const isSelected = this.isSelected(prop.id);
                  return (
                    <TableRow
                        hover
                        key={prop.id}
                        onClick={event => this.handleClick(event, prop.id, prop.data)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        selected={isSelected}
                        className={classes.row}
                      >
                        <TableCell className={classes.cell} padding="checkbox">
                          <Checkbox className={classes.row} checked={isSelected} />
                        </TableCell>
                      {(prop.data).map((prop, key2) => {
                        return (
                          <Tooltip
                            title={prop?((prop.props)?'':prop):''}
                            placement={'bottom-start'}
                            enterDelay={300}
                            key={key2}
                          >
                            <TableCell className={classes.cell} key={key2}>
                              {prop}
                            </TableCell>
                          </Tooltip>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage= {(<div className={classes.labelRowsPerPage}>Registros por Pagina:</div>)}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  titulo: PropTypes.node,
  action: PropTypes.node,
  actionSelected: PropTypes.node,
  tableHead: PropTypes.arrayOf(PropTypes.object),
  tableData: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(styles)(EnhancedTable);