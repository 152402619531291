import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    '@media (max-width: 768px)' : {
      minWidth: 250,
    }
  },
  row2: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  head: {
    backgroundColor: '#0d4377',
    height: '45px',
  },
  cell: {
    width: '1%',
    color: '#fff',
    boxSizing: 'border-box',
    whiteSpace: 'initial',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '10px',
    textAlign: 'center',
    lineHeight: '15px',
  },
  cellBody: {
    overflow: 'hidden',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '1px',
    padding: '5px',
    textAlign: 'center'
  },
  row: {
    height: '40px',
  }
});

function CustomizedTable(props) {
  const { classes, title, tableHead, tableData } = props;
  return (
    <Paper className={classes.root}>
      <div className={classes.title}>
        <Typography variant="title" id="tableTitle">
          {title}
        </Typography>
      </div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.head}>
            {tableHead.map((column, key) => {
              return (
                (column.hidden ? null :
                <Tooltip
                    title={column.label?column.label:''}
                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={1000}
                    key={key}
                  >
                  <TableCell key={key} className={classes.cell}>
                    {column.label}
                  </TableCell>
                </Tooltip>)
              );
            }, this)}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((prop, key) => {
              return (
                <TableRow
                    hover
                    key={key}
                    className={classes.row}
                  >
                  {prop.map((prop, key2) => {
                    return (
                      <Tooltip
                        title={prop?((prop.props)?'':prop):''}
                        placement={'bottom-start'}
                        enterDelay={300}
                        key={key2}
                      >
                        <TableCell key={key2} className={classes.cellBody}>
                          {prop}
                        </TableCell>
                      </Tooltip>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  tableHead: PropTypes.arrayOf(PropTypes.object),
  tableData: PropTypes.arrayOf(PropTypes.array)
};

export default withStyles(styles)(CustomizedTable);