import React from "react";
import invoke from "services/invoke.jsx";
import { Info } from "@material-ui/icons";
import { withStyles,  MenuItem, Grid, Checkbox, Snackbar } from "@material-ui/core";
import { ItemGrid, RegularCard,  Button, Linear  } from "components";
import TextField from '@material-ui/core/TextField';
import dashboardStyle from "assets/jss/material-dashboard-react/dashboardStyle";
import {domains, hostname} from "assets/jss/material-dashboard-react/cajaLoginStyle";


var sesion = invoke.getSesionCliente();
class InformacionFiscal extends React.Component {
  state = {
    open: false,
    open2: false,
    tr: false,
    tc: false,
    classMask:  'maskProgress',
    // Información Fiscal
    regimenFiscal: [],
    regimenSocietario: [],
    direccionesFiscales: [],
    de_Rfc: '',
    requiredRfc: false,
    de_RazonSocial: '',
    requirednbRazonSocial: false,
    id_RegimenFiscal: '',
    requiredRegimenFiscal: false,
    id_RegimenSocietario:'',
    requiredRegimenSocietario: false,
    id_ClienteDireccionFiscal: '',
    requiredDireccionFiscal: false,
    id_TipoPersona: '',
    de_AvisoInformacionFiscal: 'Estimado cliente, favor de excluir el régimen societario de su razón social',
    sn_InformacionFiscalCorrecta: 'FALSE',
    requiredConfirmacion: false,
    disabledsave: false
  };

  componentWillMount(){//es parecido a la funcion init

    this.combosInformacionFiscal();
    this.getInformacionFiscal();
  }

  showNotification(place,message,time=5000) {
    this.setState({message : message})
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };


  handleChangeRegimenFiscal = name => event => {
    let option
    let i
    for(i in this.state.regimenFiscal) {
      option = this.state.regimenFiscal[i]
      if(option.obj.id_RegimenFiscal === event.target.value){
        this.setState({ id_RegimenFiscal: option.obj.id_RegimenFiscal })
        this.setState({ [name]: event.target.value })
      }
    }
  };

  handleChangeRegimenSocietario = name => event => {
    let option
    let i
    for(i in this.state.regimenSocietario) {
      option = this.state.regimenSocietario[i]
      if(option.obj.id_RegimenSocietario === event.target.value){
        this.setState({ id_RegimenSocietario: option.obj.id_RegimenSocietario })
        this.setState({ [name]: event.target.value })
      }
    }
  };

  handleChangeDireccionFiscal = name => event => {
    let option
    let i
    for(i in this.state.direccionesFiscales) {
      option = this.state.direccionesFiscales[i]
      if(option.obj.id_ClienteDireccionFiscal === event.target.value){
        this.setState({ id_ClienteDireccionFiscal: option.obj.id_ClienteDireccionFiscal })
        this.setState({ [name]: event.target.value })
      }
    }
  };



  handleChangeValidate = (name,validate) => event => {
    invoke.resetTime();
    if(validate.maxLength && (event.target.value).length <= validate.maxLength){
      this.setState({
        [name]: event.target.value,
      });
    }
  };


  // INFORMACION FISCAL DEL CLIENTE

  combosInformacionFiscal = async () => {

    // Combo Regimen fiscal
    await invoke.post('Clientes','comboRegimenFiscal',{}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_RegimenFiscal,
            label: x.nb_RegimenFiscal,
            obj: x
          });
        }
        this.setState({ regimenFiscal: VAR });
      }else{
        this.setState({ required: true });
        this.showNotification("tr", res.message)
      }
    });

    // Combo Regimen Societario
    await invoke.post('Clientes','comboRegimenSocietario',{}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_RegimenSocietario,
            label: x.nb_RegimenSocietario,
            obj: x
          });
        }
        this.setState({ regimenSocietario: VAR });
      }else{
        this.setState({ required: true });
        this.showNotification("tr", res.message)
      }
    });
    // Combo Direcciones Fiscales
    await invoke.post('Clientes','comboDireccionesFiscales',{ id_Cliente: sesion.cliente.id_Cliente }).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_ClienteDireccionFiscal,
            label: x.de_Direccion,
            obj: x
          });
        }
        this.setState({ direccionesFiscales: VAR });
      }else{
        this.setState({ required: true });
        this.showNotification("tr", res.message)
      }
    });
  }

  guardarInformacionFiscal = () =>{


    if(this.state.de_RazonSocial === '' || !this.state.de_RazonSocial){
      this.mensajeLog('requirednbRazonSocial','Es requerido capturar la Razón Social.');
      return;
    }
    if(this.state.de_Rfc === '' || !this.state.de_Rfc){
      this.mensajeLog('requiredRfc','Es requerido capturar el RFC.');
      return;
    }

    // eslint-disable-next-line no-useless-escape
    let rfc = '^(([A-Z]|[a-z]|\s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
    if(this.state.de_Rfc.length === 12){
      rfc = '^(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
    }

    var valida = new RegExp(rfc);
    if(!valida.test(this.state.de_Rfc)){
        this.mensajeLog('requiredRfc','La estructura del RFC no es válida, favor de revisar.');
        return false;
    }

    if(this.state.id_RegimenFiscal === '' || !this.state.id_RegimenFiscal){
      this.mensajeLog('requiredRegimenFiscal','Es requerido capturar el Régimen Fiscal.');
      return;
    }

    // régimen societario solo es requerido para las personas morales
    if (this.state.id_TipoPersona === 2) {
      if(this.state.id_RegimenSocietario === '' || !this.state.id_RegimenSocietario){
        this.mensajeLog('requiredRegimenSocietario','Es requerido capturar el Régimen Societario.');
        return;
      }
    }

    if(this.state.id_ClienteDireccionFiscal === '' || !this.state.id_ClienteDireccionFiscal){
      this.mensajeLog('requiredDireccionFiscal','Es requerido capturar la Dirección Fiscal.');
      return;
    }

    if(this.state.sn_InformacionFiscalCorrecta === 'FALSE' || !this.state.sn_InformacionFiscalCorrecta){
      this.mensajeLog('required','Es requerido marcar la casilla de confirmación información fiscal.');
      return;
    }

    const datos = {
      id_Cliente: sesion.cliente.id_Cliente,
      de_RazonSocial: this.state.de_RazonSocial,
      de_Rfc: this.state.de_Rfc,
      id_RegimenFiscal: this.state.id_RegimenFiscal,
      id_RegimenSocietario: this.state.id_RegimenSocietario,
      id_ClienteDireccionFiscal: this.state.id_ClienteDireccionFiscal,
      sn_InformacionFiscalCorrecta: this.state.sn_InformacionFiscalCorrecta === 'TRUE' ? 1 : 0,
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('clientes','guardarInformacionFiscal',datos).then(res => {
      if(res.status===200 && res.data.message==='OK'){
        this.showNotification("tc", 'Información editada correctamente.')
        sesion.cliente.de_RazonSocial = this.state.de_RazonSocial;
        invoke.setSesionCliente(sesion);
        // window.location.reload();
        this.getInformacionFiscal();
      }else{
        this.setState({ requiredRfc: true });
        this.showNotification("tr", res.data.message);
      }
      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 300);
      }.bind(this), 300);
    });

  }

  handleClickOpenInfoFiscal = () => {
    this.getInformacionFiscal();
    this.setState({ openModalInfoFiscal: true });
    this.setState({ open: false, open2: false });
  };

  handleChangeConfirmacion = name => event => {
    this.setState({
      [name]: (event.target.value==='FALSE')?'TRUE':'FALSE',
    });
  };

  getInformacionFiscal = () => {
    invoke.post('Clientes','getInformacionFiscal',{ id_Cliente: sesion.cliente.id_Cliente }).then(res => {
      if(res.status===200 && res.data.data){
        let informacionFiscal = res.data.data[0];

        this.setState({ de_RazonSocial: informacionFiscal.de_RazonSocial });
        this.setState({ de_Rfc: informacionFiscal.de_Rfc });
        this.setState({ id_TipoPersona: informacionFiscal.id_TipoPersona });
        this.setState({ id_RegimenSocietario: informacionFiscal.id_RegimenSocietario });
        this.setState({ id_RegimenFiscal: informacionFiscal.id_RegimenFiscal });
        this.setState({ id_ClienteDireccionFiscal: informacionFiscal.id_ClienteDireccionFiscal });
        this.setState({ sn_InformacionFiscalCorrecta: informacionFiscal.sn_InformacionFiscalCorrecta ? 'TRUE' : 'FALSE' });

      }else{
        this.setState({ required: true });
        this.showNotification("tr", res.message)
      }
    });
  }

  handleCloseSesion = () => {
    invoke.logout2();
    window.location.reload();
  };


  render() {
    const { classes } = this.props;
      return (
        <div>
          <Grid container>
            <ItemGrid item xs={2}></ItemGrid>
            <ItemGrid item xs={8}>
              <div className={classes.containerLogin}>
                <div className={classes.cajaLogin}>
                  <RegularCard
                      cardTitle={
                        (domains[hostname])?domains[hostname].logo1:''
                      }
                      content={

                        <div>
                          {(this.state.id_TipoPersona === 2 )?(
                            <p style={{color:'#C62828', display: 'block', textAlign:'justify', fontSize:'1.0em', fontWeight:'bold'}}><Info style={{fontSize:'14px'}}/> {this.state.de_AvisoInformacionFiscal}</p>
                          )
                            : (<label></label>)
                          }
                          <Grid container>
                            <ItemGrid item xs={9}>
                              <TextField
                                id="de_RazonSocial"
                                label="Razón Social"
                                className={classes.textField}
                                error={this.state.requirednbRazonSocial}
                                value={this.state.de_RazonSocial}
                                onChange={this.handleChangeValidate('de_RazonSocial', {'maxLength':250})}
                                margin="normal"
                                type="text"
                                fullWidth
                              />
                            </ItemGrid>
                            <ItemGrid item xs={3}>
                              <TextField
                                id="de_Rfc"
                                label="RFC"
                                className={classes.textField}
                                error={this.state.requiredRfc}
                                value={this.state.de_Rfc}
                                onChange={this.handleChangeValidate('de_Rfc', {'maxLength':13})}
                                margin="normal"
                                type="text"
                                fullWidth
                              />
                            </ItemGrid>
                          </Grid>
                          <div>
                            <TextField
                              id="id_RegimenFiscal"
                              select
                              label="Régimen Fiscal"
                              className={classes.textField}
                              error={this.state.requiredRegimenFiscal}
                              value={this.state.id_RegimenFiscal}
                              onChange={this.handleChangeRegimenFiscal('id_RegimenFiscal')}
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                  },
                                  getContentAnchorEl: null,
                                  PaperProps:{
                                    style:{ maxHeight: 350, maxWidth: 1000 }
                                  },
                                },
                                value: this.state.id_RegimenFiscal
                              }}
                              margin="normal"
                              fullWidth
                            >
                              {this.state.regimenFiscal.map(option => (
                                <MenuItem key={option.obj.id_RegimenFiscal} value={option.obj.id_RegimenFiscal}>
                                  {option.obj.nb_RegimenFiscal}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          {(this.state.id_TipoPersona === 2 )?(
                            <TextField
                                id="id_RegimenSocietario"
                                select
                                label="Régimen Societario"
                                className={classes.textField}
                                error={this.state.requiredRegimenSocietario}
                                value={this.state.id_RegimenSocietario}
                                onChange={this.handleChangeRegimenSocietario('id_RegimenSocietario')}
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps:{
                                      style:{ maxHeight: 350, }
                                    }
                                  },
                                  value: this.state.id_RegimenSocietario
                                }}
                                margin="normal"
                                fullWidth
                              >
                                {this.state.regimenSocietario.map(option => (
                                  <MenuItem key={option.obj.id_RegimenSocietario} value={option.obj.id_RegimenSocietario}>
                                    {option.obj.nb_RegimenSocietario}
                                  </MenuItem>
                                ))}
                            </TextField>
                          )
                            : (<label></label>)
                          }
                          <div>
                            <TextField
                                id="id_ClienteDireccionFiscal"
                                select
                                label="Domicilio Fiscal"
                                className={classes.textField}
                                error={this.state.requiredDireccionFiscal}
                                value={this.state.id_ClienteDireccionFiscal}
                                onChange={this.handleChangeDireccionFiscal('id_ClienteDireccionFiscal')}
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps:{
                                      style:{ maxHeight: 350 }
                                    },
                                  },
                                  value: this.state.id_ClienteDireccionFiscal
                                }}
                                margin="normal"
                                fullWidth
                              >
                                {this.state.direccionesFiscales.map(option => (
                                  <MenuItem key={option.obj.id_ClienteDireccionFiscal} value={option.obj.id_ClienteDireccionFiscal}>
                                    {option.obj.de_Direccion}
                                  </MenuItem>
                                ))}
                              </TextField>
                          </div>
                          <ItemGrid xs={12} sm={12} md={12} style={{marginLeft: '-20px'}}>
                            <Checkbox
                              checked={((this.state.sn_InformacionFiscalCorrecta==='TRUE')?true:false)}
                              onChange={this.handleChangeConfirmacion('sn_InformacionFiscalCorrecta')}
                              value={this.state.sn_InformacionFiscalCorrecta}
                              label="Confirmo que mi información fiscal es correcta"
                              style={{color:'#C62828'}}
                            />
                            <label style={{color:'#C62828', fontWeight:'bold'}}>Confirmo que mi información fiscal es correcta</label>
                          </ItemGrid>
                        </div>
                      }
                      footer={
                          <div className={classes.footerFiscal}>
                            <><Button onClick={this.guardarInformacionFiscal} color="primary" disabled={this.state.disabledsave} className={classes.simple}>Guardar</Button><Button onClick={this.handleCloseSesion}  className={classes.simple}>Cerrar</Button></>
                          </div>
                      }
                  />
              </div>
              </div>
            </ItemGrid>
            <ItemGrid item xs={2}></ItemGrid>
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.tc}
            onClose={() => this.setState({ tc: false })}
            ContentProps={{ 'className': classes.green }}
            message={this.state.message}
          />
          <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.tr}
              onClose={() => this.setState({ tr: false })}
              ContentProps={{ 'className': classes.red }}
              message={this.state.message}
            />
          <div className={this.state.classMask}>
            <Linear  />
          </div>
        </div>

      );
  }
}


export default withStyles(dashboardStyle)(InformacionFiscal);
