import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { withStyles } from "@material-ui/core";
import { Header, Footer, Sidebar, SimpleDialog } from "components";
import dashboardRoutes from "routes/dashboard.jsx";
import appStyle from "assets/jss/material-dashboard-react/appStyle.jsx";
import {cajaLoginStyle, logoBlanco, background} from "assets/jss/material-dashboard-react/cajaLoginStyle.jsx";
import { terminosCondiciones } from "variables/general";
import invoke from "services/invoke.jsx";

const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);
var sesion = invoke.getSesion();
var cliente = invoke.getSesionCliente();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      main: false,
      openDialogSimple: false,
    };
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== "/maps";
  }

  handleClickOpenSimple = () => {
    this.setState({ openDialogSimple: true });
  };

  handleClickCloseSimple = () => {
    this.setState({ openDialogSimple: false });
  };

  componentWillMount(){
    if(localStorage.getItem("sesion")){
      if(this.props.location.pathname === "/login"){
        this.props.history.push('/inicio');
      }
      this.setState({ main: true });
    } else if (localStorage.getItem("cliente")) {
      if(this.props.location.pathname === "/signin"){
        this.props.history.push('/datosFiscales');
      }
      this.setState({ main: true });
    }else{
      if(this.props.location.pathname !== "/login" && this.props.location.pathname !== "/signin"){
        if(this.props.location.pathname === "/.well-known/acme-challenge/glQ06wQuP0MvLMn-K-hV1JrOhud0jidJ_3hM2sftJ2c"){
          return;
        }else{
          this.props.location.pathname === '/datosFiscales' ? this.props.history.push('/signin') : this.props.history.push('/login');
        }
      }
      this.setState({ main: true });
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    if(this.props.location.pathname !== "/login" && this.props.location.pathname !== "/signin"){
      if (sesion) {
        return (
          <div>
            {this.state.main ? (
              <div className={classes.wrapper}>
                <Sidebar
                  routes={dashboardRoutes}
                  logoText={""}
                  logo={logoBlanco}
                  image={background}
                  handleDrawerToggle={this.handleDrawerToggle}
                  open={this.state.mobileOpen}
                  color="orange"
                  {...rest}
                />
                <div className={classes.mainPanel} ref="mainPanel">
                  <Header
                    routes={dashboardRoutes}
                    handleDrawerToggle={this.handleDrawerToggle}
                    {...rest}
                  />
                  {this.getRoute() ? (
                    <div className={classes.content}>
                      <div>{switchRoutes}</div>
                    </div>
                  ) : (
                    <div className={classes.map}>{switchRoutes}</div>
                  )}
                  {this.getRoute() ? <Footer mobile={this.state.mobileOpen} simpleDialog={this.handleClickOpenSimple}/> : null}
                  <SimpleDialog
                    content={(<div dangerouslySetInnerHTML={{__html: terminosCondiciones}}></div>)}
                    close={this.handleClickCloseSimple}
                    open= {this.state.openDialogSimple}
                    />
                </div>
              </div>
              ) : null
            }
          </div>
        );
      } else if (cliente) {
        return (
          <div>
            {this.state.main ? (
              <div className={classes.wrapper}>
                <div className={classes.mainPanelLogin} ref="mainPanel">
                  <Header
                    routes={dashboardRoutes}
                    handleDrawerToggle={this.handleDrawerToggle}
                    {...rest}
                  />
                  {this.getRoute() ? (
                    <div className={classes.contentLogin}>
                      <div className={classes.container}>{switchRoutes}</div>
                    </div>
                  ) : (
                    <div className={classes.map}>{switchRoutes}</div>
                  )}
                  {this.getRoute() ? <Footer mobile={this.state.mobileOpen} simpleDialog={this.handleClickOpenSimple}/> : null}
                  <SimpleDialog
                    content={(<div dangerouslySetInnerHTML={{__html: terminosCondiciones}}></div>)}
                    close={this.handleClickCloseSimple}
                    open= {this.state.openDialogSimple}
                    />
                </div>
              </div>
              ) : null
            }
          </div>
        );
      } else if (this.props.location.pathname === "/datosFiscales") {
        return (
          <div>
              {this.state.main ? (
                <div className={classes.wrapper}>
                  <div className={classes.mainPanelLogin} ref="mainPanel">
                      <Header
                        routes={dashboardRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                      />
                      <div className={classes.contentLogin}>
                        <div className={classes.container}>{switchRoutes}</div>
                      </div>
                      <div className={classes.footerLogin}>
                        <Footer mobile={this.state.mobileOpen} simpleDialog={this.handleClickOpenSimple}/>
                        <SimpleDialog
                          content={(<div dangerouslySetInnerHTML={{__html: terminosCondiciones}}></div>)}
                          close={this.handleClickCloseSimple}
                          open= {this.state.openDialogSimple}
                          />
                      </div>
                  </div>
                </div>
                ) : null
              }
          </div>
        )
      } else{
        return (
          <div>
              {this.state.main ? (
                <div className={classes.wrapper}>
                  <div className={classes.mainPanelLogin} ref="mainPanel">
                      <Header
                        routes={dashboardRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                      />
                      <div className={classes.contentLogin}>
                        <div className={classes.container}>{switchRoutes}</div>
                      </div>
                      <div className={classes.footerLogin}>
                        <Footer mobile={this.state.mobileOpen} simpleDialog={this.handleClickOpenSimple}/>
                        <SimpleDialog
                          content={(<div dangerouslySetInnerHTML={{__html: terminosCondiciones}}></div>)}
                          close={this.handleClickCloseSimple}
                          open= {this.state.openDialogSimple}
                          />
                      </div>
                  </div>
                </div>
                ) : null
              }
          </div>
        )
      }
    }else{
      return (
        <div>
            {this.state.main ? (
              <div className={classes.wrapper}>
                <div className={classes.mainPanelLogin} ref="mainPanel">
                    <Header
                      routes={dashboardRoutes}
                      handleDrawerToggle={this.handleDrawerToggle}
                      {...rest}
                    />
                    <div className={classes.contentLogin}>
                      <div className={classes.container}>{switchRoutes}</div>
                    </div>
                    <div className={classes.footerLogin}>
                      <Footer mobile={this.state.mobileOpen} simpleDialog={this.handleClickOpenSimple}/>
                      <SimpleDialog
                        content={(<div dangerouslySetInnerHTML={{__html: terminosCondiciones}}></div>)}
                        close={this.handleClickCloseSimple}
                        open= {this.state.openDialogSimple}
                        />
                    </div>
                </div>
              </div>
              ) : null
            }
        </div>
      );
    }
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle,cajaLoginStyle)(App);
