/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import ReactExport from 'react-data-export';
import invoke from "services/invoke.jsx";
import { Grid, Tooltip, TextField, MenuItem, IconButton,
Snackbar, List, ListItem, ListItemText, Avatar, InputAdornment, FormControlLabel, Checkbox, Hidden/*, ListItemIcon*/ } from "@material-ui/core";
import orange from '@material-ui/core/colors/orange';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SpecialCard, Table, TableCustom, TableMovile, ItemGrid, Linear, FormDialog, Alerta, Button, ExpansionPanelCustomized } from "components";
import { Search, /*Cancel,*/ AssignmentReturned, Assessment, AddCircle,
Assignment, FormatListBulleted, Computer, ScreenShare, LocalShipping, AssignmentTurnedIn, Edit, Print, Description } from "@material-ui/icons";
import dashboardStyle from "assets/jss/material-dashboard-react/dashboardStyle";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { saveAs } from "file-saver";
import { Page, Text, Document, StyleSheet, View,  pdf, } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: { backgroundColor: '#FFF', textAlign: 'center', marginTop:'30pt'},
  view: { flexDirection: 'row'},
  headerColspan: { backgroundColor: '#cccccc', border: '1pt solid #000000', fontSize: '12pt', height: '25pt', width: '90%', marginLeft: '30pt', paddingTop: '5pt', fontWeight: 600},
  header: { backgroundColor: '#cccccc', border: '1pt solid #000000', fontSize: '12pt', height: '25pt', width: '45%', marginLeft: '30pt', paddingTop: '5pt'},
  header2: { backgroundColor: '#cccccc', border: '1pt solid #000000', fontSize: '12pt', height: '25pt', width: '45%', paddingTop: '5pt'},
  cellColspan: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '12pt', height: '25pt', width: '90%', marginLeft: '30pt', paddingTop: '5pt',fontWeight: 600, display:'flex'},
  cellColspanItalic: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '12pt', height: '25pt', width: '90%', marginLeft: '30pt', paddingTop: '5pt',fontWeight: 600, display:'flex', fontFamily:'Times-Italic',},
  cell: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '12pt', height: '25pt', width: '45%', marginLeft: '30pt', paddingTop: '5pt'},
  cell2: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '12pt', height: '25pt', width: '45%', paddingTop: '5pt'},
  text: {fontSize: 12, textAlign: 'justify', border: '1pt solid #555', width: '90%', marginLeft: '30pt', paddingTop: '5pt', paddingLeft:'5pt' }
});

// Estilos para PDF
const headerStyle = { backgroundColor: '#CCCCCC', textAlign: 'center', border: ' 1px solid rgb(150, 150, 150)' };
const headerStyle50 = { backgroundColor: '#CCCCCC', textAlign: 'center', border: ' 1px solid rgb(150, 150, 150)', width: '50%'};
const tdStyle ={ textAlign: 'center', border: ' 1px solid rgb(150, 150, 150)' };
const tdStyle50 ={ textAlign: 'center', border: ' 1px solid rgb(150, 150, 150)',  width: '50%'};
const tdStyleJustify = { textAlign: 'justify', textJustify: 'inter-word',  wordWrap: 'break-word', border: ' 1px solid rgb(150, 150, 150)' };

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',
  padding: '0px'
});

const colourStyles = (titulo = '"Proveedor"') =>({
  control: styles => ({ ...styles,
    backgroundColor: 'white',
    marginTop: '0px',
    border: 'none',
    borderBottom: '1px solid #777',
    borderRadius: 0,
    borderColor:' #777 !important',
    boxShadow: 'none !important',
    minHeight: '32px'
  }),
  indicatorSeparator: style => ({ ...style,
    display: 'none'
  }),
  container: style => ({ ...style,
    position: 'relative',
    boxSizing: 'border-box',
    paddingTop: '16px',
    marginTop: '16px',
    ':before': {
      content: titulo,
      display: 'block',
      position: 'absolute',
      left: 0,
      top: '1px',
      zIndex: 2,
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '12px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: 1,
    },
  }),
  indicatorsContainer: style => ({ ...style,
    height: '32px'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = '#ddd';
    return {
      ...styles,
      backgroundColor:
        isDisabled ? null
        : isSelected ? '#ddd'
        : isFocused ? '#eee'
        : null,
      color:
        isDisabled ? '#777'
        : isSelected ? 'black'
        : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? color : '#ddd'),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({
    ...styles,
    position: 'relative',
    left: '-10px',
    top: '13px',
    ...dot(data.color)
  }),
  menu: style => ({ ...style,
    animationName: 'selectAnimation',
    animationDuration: '0.267s',
    transition: 'opacity 267ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 178ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transformOrigin: '128px 0px',
    position:'absolute',
    zIndex: 3
  }),
});

var sesion = invoke.getSesion();

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={6}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class Pedidos extends React.Component {
  //Inicializar estados
  state = {
  //estatus
    open:       false,
    tr:         false,
    tc:         false,
    message:    '',
    classMask:  'maskProgress',
    SearchView: true,
    openDialog: false,
    openDialogCancel: false,
    openDialogFactura: false,
    openDialogSeguimiento: false,
    openDialogConfirmacion: false,
    openDialogFacturasRelacionadas: false,
    openDialogAcuse: false,
    openDialogAcuseAccordion: false,
    al_title: '',
    al_content: '',
    al_open: false,

    checkedConfirmacion: 'FALSE',
    nu_CantidadConfirmacion: '',
    urlFactura: '',
    descriptionFactura: '',
    sn_PuedeCancelar: '',
    sn_RemisionTransito: '',
    id_FormaEntregaCancel: '',
    descriptionCancelar: 'De click en el boton de Aceptar para confirmar la cancelación o de click en cerrar y salir de la opción.',
    descriptionConfirmacion: '',
    principalDesciption: 'Busqueda de pedidos',
    descriptionAcuseCre: 'Descarga de Acuse CRE',
    seguimiento: '',
    id_PedidoMD5: '',
    de_DescripcionListadoFacturas: '',

  //tablas
    productos:  [],
    productosAgregar:  [],
    estaciones: [],
    estatus:    [],
    tableData:  [],
    tableDataMovile:  [],
    tableDataFacturas:  [],
    entregaPemex: [],
    entregaPetroil: [],
    clienteRetira: [],

  //combos
    formaEntregas:   [],
    centroEntregas:  [],
    formaCompras:    [],
    cantidades: [],
    proveedores: [],
    operadores: [],
    unidadTrasportes: [],
    direccionEntregas: [],
    plazas: [],
    turnos: [],
    multiDataSet: [],
    destinos: [],
    firmasVale: [],
    tanques: [],

  //campos Busqueda
    id_Producto: '',
    fl_Pedido: '',
    id_Estatus: '',
    fh_Inicio: '',
    fh_Fin: '',
    id_TipoPortal: '',

  //campos Detalle Cliente
    nb_Cliente: '',
    nb_RFC: '',
    nu_EstacionServicio: '',
    nu_ClaveSIIC: '',

  //campos Agregar
    id_Proveedor_chosen: { value: 0, label: 'Seleccione' },
    id_CentroEntrega: '',
    id_FormaCompra: '',
    id_ProductoGeneral: '',
    productoAgregar: {},
    cantidadAgregar: {},
    id_Cantidad: '',
    nu_Cantidad: '',
    id_Proveedor: '',
    id_Operador: '',
    id_Unidad: '',
    nb_ProductoAnterior: '',
    id_Domicilio: '',
    fh_Hora: '',
    id_Turno: '',
    fh_Entrega: '',
    nb_mensajeCancelar: '',
    id_Cancelacion: '',
    id_EmpresaCancela: '',
    fh_Cancelacion: '',
    fh_view: false,
    id_Plaza: '',
    id_Tanque: '',
    de_Comentarios: '',
    id_PedidoNG: '',

    required_id_FormaEntrega: false,
    required_fh_Entrega: false,
    required_fh_Hora: false,
    required_id_FormaCompra: false,
    required_id_Plaza: false,
    required_id_Tanque: false,
    required_id_Domicilio: false,
    required_id_ProductoGeneral: false,
    required_nu_Cantidad: false,
    required_id_Proveedor: false,
    required_id_Operador: false,
    required_id_UnidadTransporte: false,
    required_nb_ProductoAnterior: false,
    required_de_Comentarios: false,
    
    // Nuevos Filtros
    domiciliosEntrega: [],
    plazasFiltro: [],
    id_DomicilioFiltro: '',
    id_PlazaFiltro: '',
    div_FacturasRelacionadas: (<div></div>),

    // Acuse Cre
    htmlAcuseCre: [],
    infoAcuse: {},
  };

  componentWillMount(){//es parecido a la funcion init
    if(!localStorage.getItem("sesion")){
      localStorage.removeItem("sesion");
      window.location.reload();
    }

    if(sesion.usuario){
      //Combo de Productos
      invoke.post('pedidos','permisosClienteProductos',{id_Cliente: sesion.usuario.id_Cliente}).then(res => {
        if(res.status===200 && res.data.data){
          var productosVAR = [{value:0,label:'Todos'}];
          for(var i in res.data.data){
            var x = res.data.data[i];
            productosVAR.push({
              value: x.id_ProductoGeneral,
              label: x.nb_ProductoGeneral
            });
          }
          this.setState({ productos: productosVAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });
      //combo de estatus
      invoke.post('ordenesDeSuministro','listarFormaEntrega',{sn_Activo: 1, id_Cliente: sesion.usuario.id_Cliente}).then(res => {
        if(res.status===200 && res.data.data){
          var formaEntregasVAR = [];
          for(var i in res.data.data){
            var x = res.data.data[i];
            formaEntregasVAR.push({
              value: x.id_FormaDeEntrega,
              label: x.nb_FormaDeEntrega
            });
            this.setState({ id_FormaEntrega: (x.Sn_Distribuidora) ? x.id_FormaDeEntrega : '' });
          }
          this.setState({ formaEntregas: formaEntregasVAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });
      //Combo de Plazas
      invoke.post('pedidos','permisosPlazas',{id_Cliente: sesion.usuario.id_Cliente, id_Usuario: sesion.usuario.id_Usuario}).then(res => {
        if(res.status===200 && res.data.data){
          var VAR = [];
          for(var i in res.data.data){
            var x = res.data.data[i];
            VAR.push({
              value: x.id_Plaza,
              label: x.nb_Plaza
            });
          }
          this.setState({ plazas: VAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });

      //Combo de Productos
      invoke.post('pedidos','permisosProductos',{id_Cliente: sesion.usuario.id_Cliente, id_Usuario: sesion.usuario.id_Usuario}).then(res => {
        if(res.status===200 && res.data.data){
          var VAR = [];
          for(var i in res.data.data){
            var x = res.data.data[i];
            VAR.push({
              value: x.id_ProductoGeneral,
              label: x.nb_ProductoGeneral,
              id_Empresa: x.id_Empresa
            });
          }
          this.setState({ productosAgregar: VAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });
    }

    //combo de estatus
    invoke.post('estatus','rango',{id_Inicial: 801, id_Final: 810}).then(res => {
      if(res.status===200 && res.data.data){
        var estatusVAR = [{value:0,label:'Todos'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          estatusVAR.push({
            value: x.id_Estatus,
            label: x.de_Estatus
          });
        }
        this.setState({ estatus: estatusVAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Forma de Compra
    invoke.post('pedidos','listarFormaCompra',{sn_Distribuidora: 1}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_FormaPago,
            label: x.nb_FormaPago
          });
        }
        this.setState({ formaCompras: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Proveedores
    invoke.post('pedidos','listarProveedores',{}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Proveedor,
            label: x.nb_Proveedor
          });
        }
        this.setState({ proveedores: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Domicilios de Entrega
    invoke.post('pedidos','listarDireccionEntregas',{id_Cliente: sesion.usuario.id_Cliente, id_Usuario: sesion.usuario.id_Usuario}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value: 0, label: 'Seleccionar'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Domicilio,
            label: x.nb_Direccion
          });
        }
        this.setState({ direccionEntregas: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    invoke.post('reportes','domiciliosClientes',{id_Cliente: sesion.usuario.id_Cliente}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value:0,label:'Todos'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Domicilio,
            label: x.de_Domicilio,
            obj: x
          });
        }
        this.setState({ domiciliosEntrega: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Plazas
    invoke.post('pedidos','permisosPlazas',{id_Cliente: sesion.usuario.id_Cliente, id_Usuario: sesion.usuario.id_Usuario}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value:0,label:'Todos'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Plaza,
            label: x.nb_Plaza
          });
        }
        this.setState({ plazasFiltro: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });


    var fh_inicio = this.getFirstDayOfMonth();
    var fh_fin = this.getLastDayOfMonth();
    fh_inicio = fh_inicio.toISOString().slice(0,10);
    fh_fin = fh_fin.toISOString().slice(0,10);
    this.setState({ fh_Inicio: fh_inicio });
    this.setState({ fh_Fin: fh_fin });
    setTimeout(function() {
      this.buscar();
      invoke.resetTime();
    }.bind(this), 100);
  }

  printDocument = id => event => {
    const input = document.getElementById('htmlPDF')
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save(id+"download.pdf");
      })
    ;
  }

  showNotification(place,message,time=5000) {
    this.setState({ message: message });
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  alerta(title, content) {
    this.setState({ al_title: title})
    this.setState({ al_content: content})
    this.setState({ al_open: true})
  };

  handleClickCloseAlerta = () => {
    this.setState({ al_open: false });
  };

  tanques(){
    //Combo de Tanques de consignacion
    invoke.post('ordenesDeSuministro','tanquesConsignacion',{id_Cliente:sesion.usuario.id_Cliente}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value: 0, label: 'Seleccionar', nb_Direccion: '--Seleccionar'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_TanqueAlmacenamiento,
            label: x.nb_TanqueAlmacenamiento,
            nb_Direccion: x.nb_Direccion
          });
        }
        this.setState({ tanques: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });
  }

  tanqueChange(){
    let direccionesVAR = this.state.direccionEntregas;
    direccionesVAR[0].label = 'Seleccionar';
    this.setState({ id_Domicilio: '' });
    if(this.state.id_Tanque){
      for(let i in this.state.tanques){
        let item = this.state.tanques[i];
        if(item.value === this.state.id_Tanque){
          direccionesVAR[0].label = item.nb_Direccion;
          this.setState({ direccionEntregas: direccionesVAR });
          this.setState({ id_Domicilio: 0 });
        }
      }
    }
  }

  buscar() {
    const { classes } = this.props;
    var sesion = invoke.getSesion();
    if(!localStorage.getItem("sesion")){
      window.location.reload();
    }else{
      this.setState({classMask:'maskProgress active'});
      let obj = {
        id_Cliente: sesion.usuario.id_Cliente,
        id_Producto: this.state.id_Producto,
        fl_Pedido: this.state.fl_Pedido,
        fh_Inicio: this.state.fh_Inicio,
        fh_Fin: this.state.fh_Fin,
        id_Estatus: this.state.id_Estatus,
        id_TipoPortal: this.state.id_TipoPortal,
        id_Usuario: sesion.usuario.id_Usuario,
        id_Domicilio: this.state.id_DomicilioFiltro || null,
        id_Plaza: this.state.id_PlazaFiltro || null,
      }
      invoke.post('ordenesDeSuministro','listado',obj)
        .then(res => {
          if(res.status===200 && res.data.data){
            let tableDataVAR = [];
            let tableDataVARMovile = [];
            for(let i in res.data.data){
              let PD = res.data.data[i];
              tableDataVAR.push([
                PD.nb_Plaza,
                PD.fl_PedidoNG,
                PD.fh_Entrega,
                PD.fh_EntregaPetroil,
                PD.nb_FormaDeEntrega,
                PD.nb_FormaPago,
                PD.nb_ProductoGeneral,
                PD.nu_Cantidad,
                PD.de_DomicilioEntrega,
                // (
                //   <Checkbox
                //       checked={(PD.sn_Pedido)?true:false}
                //       value={PD.sn_Pedido+''}
                //       style={{color:'#ff9800'}}
                //   />
                // ),
                (
                  <Tooltip
                    title={PD.de_Estatus}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                  </Tooltip>
                ),
                // (<
                //   <Tooltip
                //     title={PD.de_EstatusSeguimiento}
                //     placement={'bottom-start'}
                //     enterDelay={300}>
                //     <span className={classes.estatus} style={{background: (PD.de_ColorSeguimiento)?PD.de_ColorSeguimiento:PD.de_Color}}>{PD.de_EstatusSeguimiento}</span>
                //   </Tooltip>
                // ),
                (PD.id_EstatusSeguimiento===2105)?(
                  <div>
                    <Tooltip
                      title={(PD.id_EstatusSeguimiento===2105)?'Editar y Enviar':'Editar'}
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Editar" aria-haspopup="true" onClick={this.handleClickAdd(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Edit style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip
                      title='Cancelar'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Cancelar" aria-haspopup="true" onClick={this.openDialogCancel(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Cancel style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip
                      title='Descarga de acuse CRE'
                      placement={'bottom-start'}
                      enterDelay={300}>
                        <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                    </Tooltip> 
                  </div>
                ):(PD.id_EstatusSeguimiento!==2120 && PD.id_EstatusSeguimiento!==2119 && PD.id_EstatusSeguimiento!==2116 && PD.id_EstatusSeguimiento!==2115)?(
                  <div>
                    <Tooltip
                      title={'Editar'}
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Editar" aria-haspopup="true" onClick={this.handleClickAdd(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Edit style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Seguimiento de Pedido'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip
                      title='Cancelar'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Cancelar" aria-haspopup="true" onClick={this.openDialogCancel(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Cancel style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip
                      title='Descarga de acuse CRE'
                      placement={'bottom-start'}
                      enterDelay={300}>
                        <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                    </Tooltip> 
                  </div>
                ):/* (PD.urlFactura && (PD.nu_remisiones === 1 || PD.nu_remisiones === 0))?(<div>
                    <Tooltip
                      title='Descargar Factura PDF'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Descargar" disabled={(PD.urlFactura)?false:true} aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} style={{width:'23px',height:'23px',color:'#C62828'}} className={classes.buttonTable}>
                        <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descargar Factura XML'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <a href={PD.urlFacturaDownloadXML} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="DescargarXML" disabled={(PD.urlFacturaXML)?false:true} aria-haspopup="true" style={{width:'23px',height:'23px',color:'#388E3C'}} className={classes.buttonTable}>
                          <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                        </IconButton>
                      </a>
                    </Tooltip>
                    <Tooltip
                      title='Seguimiento de Pedido'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    </div>
                ): */(PD.urlFactura && PD.nu_remisiones >= 0)?(<div>
                  <Tooltip
                    title='Documentos Relacionados'
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <IconButton aria-label="Documentos Relacionados" aria-haspopup="true" onClick={this.handleClickOpenFacturasRelacionadas(PD)} style={{width:'23px',height:'23px',color:'#1565c0'}} className={classes.buttonTable}>
                      <AssignmentTurnedIn style={{width:'18px',height:'18px'}} className={classes.links} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title='Seguimiento de Pedido'
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                      <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title='Descarga de acuse CRE'
                    placement={'bottom-start'}
                    enterDelay={300}>
                      <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                  </Tooltip> 
                  </div>
              ):(<div>
                    <Tooltip
                      title='Seguimiento de Pedido'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descarga de acuse CRE'
                      placement={'bottom-start'}
                      enterDelay={300}>
                        <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                    </Tooltip> 
                  </div>)
              ]);
              tableDataVARMovile.push([(<div>
                <table style={{display:'block',width:'100%'}}>
                  <tbody style={{display:'block',width:'100%'}}>
                    <tr style={{display:'block',width:'100%'}}>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Plaza:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.nb_Plaza}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Folio:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.fl_PedidoNG}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Fecha Requerida:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.fh_Entrega}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Fecha Estimada:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.fh_EntregaPetroil}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Forma de Entrega:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.nb_FormaDeEntrega}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Forma de Pago:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.nb_FormaPago}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Producto:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.nb_ProductoGeneral}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Cantidad:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.nu_Cantidad}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div style={{whiteSpace: 'initial',textAlign: 'left'}}><b>Centro de Entrega: </b>{PD.de_DomicilioEntrega}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Estatus:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>
                          <Tooltip
                            title={PD.de_Estatus}
                            placement={'bottom-start'}
                            enterDelay={300}>
                            <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                          </Tooltip>
                        </div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Acciones:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>
                        {(PD.id_EstatusSeguimiento===2105)?(
                          <div>
                            <Tooltip
                              title={(PD.id_EstatusSeguimiento===2105)?'Editar y Enviar':'Editar'}
                              placement={'bottom-start'}
                              enterDelay={300}>
                              <IconButton aria-label="Editar" aria-haspopup="true" onClick={this.handleClickAdd(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                                <Edit style={{width:'18px',height:'18px'}} className={classes.links} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ):(PD.id_EstatusSeguimiento!==2120 && PD.id_EstatusSeguimiento!==2119 && PD.id_EstatusSeguimiento!==2116 && PD.id_EstatusSeguimiento!==2115)?(
                          <div>
                            <Tooltip
                              title={'Editar'}
                              placement={'bottom-start'}
                              enterDelay={300}>
                              <IconButton aria-label="Editar" aria-haspopup="true" onClick={this.handleClickAdd(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                                <Edit style={{width:'18px',height:'18px'}} className={classes.links} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title='Seguimiento de Pedido'
                              placement={'bottom-start'}
                              enterDelay={300}>
                              <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                                <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title='Descarga de acuse CRE'
                              placement={'bottom-start'}
                              enterDelay={300}>
                                <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                            </Tooltip> 
                          </div>
                        ):/* (PD.urlFactura && (PD.nu_remisiones === 1 || PD.nu_remisiones === 0))?(<div>
                            <Tooltip
                              title='Descargar Factura PDF'
                              placement={'bottom-start'}
                              enterDelay={300}>
                              <IconButton aria-label="Descargar" disabled={(PD.urlFactura)?false:true} aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} style={{width:'23px',height:'23px',color:'#C62828'}} className={classes.buttonTable}>
                                <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title='Descargar Factura XML'
                              placement={'bottom-start'}
                              enterDelay={300}>
                              <a href={PD.urlFacturaDownloadXML} target="_blank" rel="noopener noreferrer">
                                <IconButton aria-label="DescargarXML" disabled={(PD.urlFacturaXML)?false:true} aria-haspopup="true" style={{width:'23px',height:'23px',color:'#388E3C'}} className={classes.buttonTable}>
                                  <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                                </IconButton>
                              </a>
                            </Tooltip>
                            <Tooltip
                              title='Seguimiento de Pedido'
                              placement={'bottom-start'}
                              enterDelay={300}>
                              <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                                <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                              </IconButton>
                            </Tooltip>
                            </div>
                        ): */(PD.urlFactura && PD.nu_remisiones >= 0)?(<div>
                          <Tooltip
                            title='Documentos Relacionados'
                            placement={'bottom-start'}
                            enterDelay={300}>
                            <IconButton aria-label="Documentos Relacionados" aria-haspopup="true" onClick={this.handleClickOpenFacturasRelacionadas(PD)} style={{width:'23px',height:'23px',color:'#1565c0'}} className={classes.buttonTable}>
                              <AssignmentTurnedIn style={{width:'18px',height:'18px'}} className={classes.links} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title='Seguimiento de Pedido'
                            placement={'bottom-start'}
                            enterDelay={300}>
                            <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                              <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title='Descarga de acuse CRE'
                            placement={'bottom-start'}
                            enterDelay={300}>
                              <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                          </Tooltip> 
                        </div>
                      ):(<div>
                            <Tooltip
                              title='Seguimiento de Pedido'
                              placement={'bottom-start'}
                              enterDelay={300}>
                              <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                                <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title='Descarga de acuse CRE'
                              placement={'bottom-start'}
                              enterDelay={300}>
                                <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                            </Tooltip> 
                          </div>)}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>)]);
            }
            this.setState({ tableData: tableDataVAR });
            this.setState({ tableDataMovile: tableDataVARMovile });

            let DataVAR = [];
            for(let i in res.data.data){
              let PD = res.data.data[i];
              DataVAR.push([
                {value: (PD.nb_Plaza)?PD.nb_Plaza:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_PedidoNG)?PD.fl_PedidoNG:'', style: {type:'number', numFmt: "0",alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_Entrega)?PD.fh_Entrega:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_EntregaPetroil)?PD.fh_EntregaPetroil:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_FormaDeEntrega)?PD.nb_FormaDeEntrega:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_FormaPago)?PD.nb_FormaPago:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_ProductoGeneral)?PD.nb_ProductoGeneral:'', style: {alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' }}},
                {value: (PD.nu_Cantidad)?PD.nu_Cantidad:'', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.nu_CantidadEntregada)?PD.nu_CantidadEntregada:'', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.de_DomicilioEntrega)?PD.de_DomicilioEntrega:'', style: {alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' }}},
                {value: (PD.de_Estatus)?PD.de_Estatus:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fl_FolioDocumento)?PD.fl_FolioDocumento:'', style: {alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' }}},
              ]);
            }

            let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 10);

            let multiDataSet = [
            {
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: 'Reporte de Pedidos', style: { font: { sz:'14', bold: true } }},
                ],
              ]
            },
            {
              xSteps: 8,
              ySteps: -2,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: (localISOTime)+'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'14', bold: true } }},
                ],
              ]
            },
            {
              columns: [
                {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
              ],
              data: [
                [
                  {value: 'Fecha Inicio: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Inicio+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Fin: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Fin+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ]
              ]
            },
            {
              xSteps: 0,
              ySteps: 1,
              columns: [
                {value:'Plaza',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Folio Pedido',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Fecha Entrega Requerida',style: { wpx: '120', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Fecha Entrega Estimada',style: { wpx: '120', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Forma de Entrega',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Forma de Pago',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Producto',style: { wpx: '200', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Cantidad Solicitada',style: { wpx: '105', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Cantidad Entregada',style: { wpx: '105', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Centro de Entrega del Pedido',style: { wpx: '160', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Estatus Pedido',style: { wpx: '105', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Facturas Relacionadas',style: { wpx: '150', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
              ],
              data: DataVAR
            }
          ];
          this.setState({ multiDataSet: multiDataSet });
          }else{
            this.showNotification("tr", res.data.message)
          }

          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 1);
          }.bind(this), 1);
      });
    }
    invoke.resetTime();
  }

  validar() {
    invoke.resetTime();
    if(this.state.id_FormaEntrega > 0){
      if(!this.state.fh_Entrega){
        this.mensajeLog("required_fh_Entrega", 'La fecha es requerida.')
        return false;
      }else if(!this.state.fh_Hora){
        this.mensajeLog("required_fh_Hora", 'La Hora es requerida.')
        return false;
      }else if(this.state.id_Plaza === ''){
        this.mensajeLog("required_id_Plaza", 'La Plaza es requerida.')
        return false;
      }else if(this.state.id_FormaCompra === ''){
        this.mensajeLog("required_id_FormaCompra", 'La Forma de Compra es requerida.')
        return false;
      }else if((this.state.id_Tanque === '' || this.state.id_Tanque === 0) && this.state.id_FormaCompra === 4){
        this.mensajeLog("required_id_Tanque", 'El tanque es requerido.')
        return false;
      }else if( ((!this.state.id_Domicilio && !this.state.id_Tanque) || (this.state.id_Domicilio > 0 && this.state.id_Tanque > 0)) ){
        this.mensajeLog("required_id_Domicilio", 'La dirección de Entrega es requerida.')
        return false;
      }else if(this.state.id_ProductoGeneral === ''){
        this.mensajeLog("required_id_ProductoGeneral", 'El Producto es requerido.')
        return false;
      }else if(!this.state.nu_Cantidad){
        this.mensajeLog("required_nu_Cantidad", 'La Cantidad es requerida y tiene que se mayor a 0.')
        return false;
      }else if(!this.state.id_Proveedor && this.state.id_FormaEntrega === 3){
        this.mensajeLog("required_id_Proveedor", 'El Proveedor es requerido.')
        return false;
      }else if(!this.state.id_Operador && this.state.id_FormaEntrega === 3){
        this.mensajeLog("required_id_Operador", 'El Operador es requerido.')
        return false;
      }else if(!this.state.id_UnidadDeTransporte && this.state.id_FormaEntrega === 3){
        this.mensajeLog("required_id_UnidadTransporte", 'La unidad de Transporte es requerida.')
        return false;
      }else if(!this.state.nb_ProductoAnterior && this.state.id_FormaEntrega === 3){
        this.mensajeLog("required_nb_ProductoAnterior", 'El Producto anterior es requerido.')
        return false;
      }else if(this.state.de_Comentarios === ''){
        this.mensajeLog("required_de_Comentarios", 'Los comentarios son requeridos.')
        return false;
      }
    }else{
      this.mensajeLog("requiredFormaEntrega", 'La forma de Entrega es requerida.')
      return false;
    }
    return true;
  }

  handleClickAceptar  = () => {
    invoke.resetTime();
  };

  openDates = fh => event => {
    this.setState({ fh_view: true });
    this.setState({ fh_Entrega: fh });
    this.setState({ SearchView: false });
    setTimeout(function() {
      this.handleClickAceptar();
    }.bind(this), 100);
    invoke.resetTime();
  };

  handleClickBack = () => {
    this.setState({ SearchView: true });
    this.setState({ principalDesciption: 'Busqueda de pedidos' });
    this.buscar();
    invoke.resetTime();
  };

  handleClickAdd = (PD) => event => {
    let date = new Date();
    let fh_EntregaVar = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate());
    fh_EntregaVar = fh_EntregaVar.toISOString().slice(0,10);
    this.setState({ fh_Entrega: fh_EntregaVar });
    if(PD){
      this.setState({
        id_OrdenDeSuministro: PD.id_OrdenDeSuministro,
        id_FormaEntrega: PD.id_FormaDeEntrega,
        id_FormaCompra: PD.id_FormaPago,
        id_ProductoGeneral: PD.id_ProductoGeneral,
        nu_Cantidad: PD.nu_Cantidad,
        id_Proveedor: PD.id_ProveedorTransportista,
        id_Operador: PD.id_OperadorTransportista,
        id_UnidadDeTransporte: PD.id_UnidadDeTransporte,
        nb_ProductoAnterior: PD.nb_ProductoTransportoanterior,
        id_Domicilio: PD.id_DomicilioEntrega,
        fh_Hora: PD.fh_Hora.slice(0,5),
        fh_Entrega: PD.fh_Pedido.slice(0,10),
        id_Plaza: PD.id_Plaza,
        de_Comentarios: PD.de_Comentarios,
        id_Tanque: PD.id_TanqueAlmacenamiento,
        id_PedidoNG: PD.id_PedidoNG,
        id_EstatusSeguimiento: PD.id_EstatusSeguimiento,
      }, () => {
        if (PD.id_TanqueAlmacenamiento){
          this.tanques();
          this.tanqueChange();
        }
        this.proveedoresChange(PD.id_ProveedorTransportista);
        for(let i in this.state.proveedores){
          let x = this.state.proveedores[i];
          if(this.state.proveedores[i].value === PD.id_ProveedorTransportista){
            this.setState({ id_Proveedor: { value: x.value, label: x.label } });
          }
        }
      });
    }else{
      this.setState({
        id_OrdenDeSuministro: '',
        id_Plaza: '',
        id_FormaEntrega: 2,
        id_FormaCompra: '',
        id_ProductoGeneral: '',
        nu_Cantidad: '',
        id_Proveedor: '',
        id_Operador: '',
        id_UnidadDeTransporte: '',
        nb_ProductoAnterior: '',
        id_Domicilio: '',
        fh_Hora: '',
        de_Comentarios: '',
        id_Tanque: '',
        id_PedidoNG: '',
      });
    }
    this.setState({ openDialog: true });
    invoke.resetTime();
  };

  openDialogSeguimiento = (row) => event => {
    var obj = {
      id_Empresa: row.id_Empresa,
      id_OrdenSuministro: row.id_OrdenDeSuministro,
      id_PedidoNG: row.id_PedidoNG,
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('ordenesDeSuministro','seguimientoPedidos',obj).then(res => {
      if(res.status===200 && res.data.data){
        var x =  res.data.data;
        var seguimientoVAR = (<div></div>)
        if(!res.data.data.length){
          seguimientoVAR = (<div><label style={{color: '#9E9E9E'}}>** Borrador **</label></div>)
        } else if(x[0].de_Comentarios !== ''){
          seguimientoVAR = (<div><label style={{color: 'red'}}>** {x[0].de_Comentarios} **</label></div>)
        } else{
          seguimientoVAR = x.map(option =>
            <ListItem>
              <Tooltip  title={option.de_Comentarios} placement={'bottom-start'} enterDelay={(option.de_Comentarios)?100:900*9*9} >
                <Avatar style={{backgroundColor: '#43A047'}}>
                  {(option.id_Seguimiento===1)?<Assignment />:<span></span>}
                  {(option.id_Seguimiento===2)?<Computer />:<span></span>}
                  {(option.id_Seguimiento===3)?<ScreenShare />:<span></span>}
                  {(option.id_Seguimiento===4)?<AssignmentReturned />:<span></span>}
                  {(option.id_Seguimiento===5)?<LocalShipping />:<span></span>}
                  {(option.id_Seguimiento===6)?<AssignmentTurnedIn />:<span></span>}
                  {(option.id_Seguimiento>6)?<Assignment />:<span></span>}
                </Avatar>
              </Tooltip>
              <ListItemText primary={option.nb_estatus} secondary={option.fh_Registro} />
            </ListItem>
          );
          if(x[x.length-1].id_Seguimiento===1){
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><Computer /></Avatar><ListItemText primary="AUTORIZADO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><ScreenShare /></Avatar><ListItemText primary="PRODUCTO CARGADO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentReturned /></Avatar><ListItemText primary="FACTURADO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><LocalShipping /></Avatar><ListItemText primary="EN TRANSITO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentTurnedIn /></Avatar><ListItemText primary="ENTREGADO" secondary="" /></ListItem></div>)
          }else if(x[x.length-1].id_Seguimiento===2){
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><ScreenShare /></Avatar><ListItemText primary="PRODUCTO CARGADO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentReturned /></Avatar><ListItemText primary="FACTURADO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><LocalShipping /></Avatar><ListItemText primary="EN TRANSITO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentTurnedIn /></Avatar><ListItemText primary="ENTREGADO" secondary="" /></ListItem></div>)
          }else if(x[x.length-1].id_Seguimiento===3){
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentReturned /></Avatar><ListItemText primary="FACTURADO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><LocalShipping /></Avatar><ListItemText primary="EN TRANSITO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentTurnedIn /></Avatar><ListItemText primary="ENTREGADO" secondary="" /></ListItem></div>)
          }
          else if(x[x.length-1].id_Seguimiento===4){
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><LocalShipping /></Avatar><ListItemText primary="EN TRANSITO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentTurnedIn /></Avatar><ListItemText primary="ENTREGADO" secondary="" /></ListItem></div>)
          }
          else if(x[x.length-1].id_Seguimiento===5){
            //if(x[4].id_Seguimiento===5){
              seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentTurnedIn /></Avatar><ListItemText primary="ENTREGADO" secondary="" /></ListItem></div>)
            //}
          }
        }

        seguimientoVAR = (<div><List>{seguimientoVAR}</List></div>)
        this.setState({ seguimiento: seguimientoVAR });
        this.setState({ openDialogSeguimiento: true });
      }else{
        this.showNotification("tr", res.data.message)
      }

      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
    invoke.resetTime();
  };

  handleClickCloseSeguimiento = () => {
    this.setState({ openDialogSeguimiento: false });
  };

  handleClickDelete = () => {
    if(this.state.nb_mensajeCancelar === ''){
      this.mensajeLog("requiredMensajeCancelar", 'Necesita escribir comentarios de Cancelación.')
      return false;
    }
    if(this.state.sn_PuedeCancelar===0 && (this.state.id_FormaEntregaCancel===2 || this.state.id_FormaEntregaCancel===3)){
      this.showNotification("tr", 'No se puede cancelar el pedido dias despues o durante la fecha de Entrega.',)
      return false;
    }
    if(this.state.sn_RemisionTransito===1){
      this.showNotification("tr", 'No se puede cancelar el pedido porque el pedido ya esta en transito.',)
      return false;
    }
    let obj = {
      id_Empresa: this.state.id_EmpresaCancela,
      id_PedidoNG: this.state.id_Cancelacion,
      id_usuario: sesion.usuario.id_Usuario,
      de_Cancelacion: this.state.nb_mensajeCancelar,
      fh_Entrega: this.state.fh_Cancelacion,
    }
    this.setState({classMask:'maskProgress active'});
    invoke.post('ordenesDeSuministro','cancelar',obj)
      .then(res => {
        if(res.status===200){
          if(res.data.data){
            this.showNotification("tc", res.data.message)
          }else{
            this.showNotification("tr", res.data.message)
          }
          this.buscar();
          this.setState({ openDialogCancel: false });
        }else{
          this.showNotification("tr", res.data.message)
        }
        setTimeout(function() {
          this.setState({classMask:'maskProgress desactive'});
          setTimeout(function() {
            this.setState({classMask:'maskProgress'});
          }.bind(this), 1);
        }.bind(this), 1);
    });
    invoke.resetTime();
  };

  openDialogCancel = (row) => event => {
    let id = row.id_PedidoNG;
    let id_EmpresaCancela = row.id_Empresa;
    let sn_PuedeCancelar = row.sn_PuedeCancelar;
    let sn_RemisionTransito = row.sn_RemisionTransito;
    let id_FormaEntregaCancel = row.id_FormaDeEntrega;
    let fh_Cancelacion = (row.fh_Pedido.slice(0,10)) + 'T' + (row.fh_Hora.slice(0,5)) + ':00';
    this.setState({ openDialogCancel: true });
    this.setState({ id_EmpresaCancela: id_EmpresaCancela });
    this.setState({ id_Cancelacion: id });
    this.setState({ id_FormaEntregaCancel: id_FormaEntregaCancel });
    this.setState({ sn_PuedeCancelar: sn_PuedeCancelar });
    this.setState({ sn_RemisionTransito: sn_RemisionTransito });
    this.setState({ fh_Cancelacion: fh_Cancelacion });
    this.setState({ nb_mensajeCancelar: '' });
    this.setState({ descriptionCancelar: (<p style={{ minWidth:'35VW'}}><b>Folio:</b> {row.fl_PedidoNG}<br/><b>Producto:</b> {row.nb_ProductoGeneral}<br/><b>Cantidad:</b> {row.nu_Cantidad}<br/><b>Fecha:</b> {row.fh_Entrega}</p>) });
    invoke.resetTime();
  };

  handleClickCloseCancel = () => {
    this.setState({ openDialogCancel: false });
  };

  handleClickCloseFacturasRelacionadas = () => {
    this.setState({ openDialogFacturasRelacionadas: false });
  };

  handleClickOpenFacturasRelacionadas = (data) => event => {
    this.setState({ openDialogFacturasRelacionadas: true });
    this.setState({ de_DescripcionListadoFacturas: (<div style={{fontSize:'15px',color:'#000'}}>
      <p style={{ minWidth:'35VW'}}><b>Fecha:</b> {data.fh_Entrega}<br/><b>Folio:</b> {data.fl_PedidoNG}<br/><b>Producto:</b> {data.nb_ProductoGeneral}<br/><b>Litros:</b> {data.nu_Cantidad}</p>
    </div>) });

    this.setState({classMask:'maskProgress active'});
    let obj = {
      id_PedidoNG: data.id_PedidoNG,
    }
    invoke.post('ordenesDeSuministro','listadoFacturasPedido',obj)
      .then(res => {
        if(res.status===200 && res.data.data){
          let tableDataVAR = [];
          for(let i in res.data.data){
            let PD = res.data.data[i];
            tableDataVAR.push([
              PD.fh_Documento,
              PD.fl_FolioDocumento,
              (<div>
                <Tooltip
                  title='Descargar Factura PDF'
                  placement={'bottom-start'}
                  enterDelay={300}>
                  <IconButton aria-label="Descargar" disabled={(PD.urlFactura)?false:true} aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} style={{width:'23px',height:'23px',color:'#C62828'}}>
                    <AssignmentReturned style={{width:'18px',height:'18px'}} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title='Descargar Factura XML'
                  placement={'bottom-start'}
                  enterDelay={300}>
                  <a href={PD.urlFacturaDownloadXML} target="_blank" rel="noopener noreferrer">
                    <IconButton aria-label="DescargarXML" disabled={(PD.urlFacturaXML)?false:true} aria-haspopup="true" style={{width:'23px',height:'23px',color:'#388E3C'}}>
                      <AssignmentReturned style={{width:'18px',height:'18px'}} />
                    </IconButton>
                  </a>
                </Tooltip>
              </div>)
            ]);
          }
          this.setState({ tableDataFacturas: tableDataVAR}, () =>{
            this.setState({ div_FacturasRelacionadas: (<div><Grid container>
              <TableCustom xs={12} sm={12} md={12}
                tableHead={[
                  { id: 'fh_Factura', textAlign: 'center', label: 'Fecha Expedición' },
                  { id: 'fl_Factura', textAlign: 'center', label: 'Folio Factura' },
                  { id: 'Acciones', textAlign: 'center', label: 'Acciones' }
                ]}
                tableData={this.state.tableDataFacturas}
              />
            </Grid></div>) });
          });
        } else {
          this.showNotification("tr", res.data.message)
        }

        setTimeout(function() {
          this.setState({classMask:'maskProgress desactive'});
          setTimeout(function() {
              this.setState({classMask:'maskProgress'});
          }.bind(this), 1);
        }.bind(this), 1);
    });
  };

  handleClickCloseConfirmacion = () => {
    this.setState({ openDialogConfirmacion: false });
  };

  downloadPdf = (url,urlDescarga) => event => {
    this.setState({ openDialogFactura: true });
    this.setState({ urlFactura: url });
    this.setState({ urlFacturaDownload: urlDescarga });
  };

  handleClickFactura = () => {
    //window.location.href = this.state.urlFacturaDownload;
    window.open(this.state.urlFacturaDownload, "_blank")
    this.setState({ openDialogFactura: false });
  };

  handleClickFacturaXML = (download) => event => {
    console.log(download);
    //window.location.href = download;
  };

  handleClickCloseFactura = () => {
    this.setState({ openDialogFactura: false });
  };

  handleClickAddDialog = (enviar) => event => {
    invoke.resetTime();
    if(this.validar()){
      let domicilioVAR = '';
      let productoVAR = '';
      let id_EmpresaVAR = '';
      let item = {};
      for(let i in this.state.productosAgregar){
        item = this.state.productosAgregar[i];
        if(item.value === this.state.id_ProductoGeneral){
          productoVAR = item.label;
          id_EmpresaVAR = item.id_Empresa;
        }
      }
      for(let i in this.state.direccionEntregas){
        item = this.state.direccionEntregas[i];
        if(item.value === this.state.id_Domicilio){
          domicilioVAR = item.label;
        }
      }

      let obj = {
        id_Cliente:                     sesion.usuario.id_Cliente,
        id_Empresa:                     id_EmpresaVAR,
        id_Plaza:                       this.state.id_Plaza,
        id_FormaDeEntrega:              this.state.id_FormaEntrega,
        id_FormaPago:                   this.state.id_FormaCompra,
        fh_EntregaPedido:               (this.state.fh_Entrega) + 'T' + (this.state.fh_Hora) + ':00',
        id_ProductoGeneral:             this.state.id_ProductoGeneral,
        nb_Producto:                    productoVAR,
        nu_Cantidad:                    this.state.nu_Cantidad || null,
        id_ProveedorTransportista:      null,
        id_OperadorTransportista:       null,
        id_UnidadDeTransporte:          null,
        nb_ProductoTransportoanterior:  null,
        id_Usuario:                     sesion.usuario.id_Usuario,
        id_DireccionDeEntrega:          this.state.id_Domicilio,
        de_DireccionDeEntrega:          domicilioVAR,
        de_Comentarios:                 this.state.de_Comentarios,
        id_TanqueAlmacenamiento:        this.state.id_Tanque || null,
        sn_Consignacion:                (this.state.id_Tanque) ? 1 : 0,
        sn_Envio:                       enviar,
        id_PedidoNG:                    this.state.id_PedidoNG || null,
        id_OrdenesDeSuministro:         this.state.id_OrdenDeSuministro || null,
      }
      if(this.state.id_FormaEntrega === 3){
        obj.id_ProveedorTransportista     = this.state.id_Proveedor.value;
        obj.id_OperadorTransportista      = this.state.id_Operador.value;
        obj.id_UnidadDeTransporte         = this.state.id_UnidadDeTransporte.value;
        obj.nb_ProductoTransportoanterior = this.state.nb_ProductoAnterior;
      }
      this.setState({classMask:'maskProgress active'});
      invoke.post('ordenesDeSuministro','agregar',obj)
        .then(res => {
          if(res.status===200 && res.data.data===1){
            this.showNotification("tc", res.data.message)
            this.buscar();
            this.setState({ openDialog: false });
          }else if(res.status===200 && res.data.data===2){
            // this.showNotification("tr", res.data.message)
            this.alerta('¡Aviso!', res.data.message);
            this.buscar();
            this.setState({ openDialog: false });
          }else{
            this.showNotification("tr", res.data.message)
          }
          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 1);
          }.bind(this), 1);
      });
    }
    invoke.resetTime();
  };
  handleClickClose = () => {
    invoke.resetTime();
    this.setState({ openDialog: false });
  };
  handleClickSearch = () => {
    invoke.resetTime();
    this.buscar();
  };

  handleChange = name => event => {
    invoke.resetTime();
    if( name === 'checkedConfirmacion'){
        this.setState({
          [name]: (event.target.value==='FALSE')?'TRUE':'FALSE',
        });
    }else{
      this.setState({
        [name]: (event.target)?event.target.value:event,
      }, () => {
        if( name === 'id_Producto' || name === 'fl_Pedido' || name === 'id_Estatus' || name === 'fh_Inicio' || name === 'fh_Fin'){
          this.buscar();
        }
        if( name === 'id_Plaza' ){
          this.tanques();
        }
        if( name === 'id_Tanque' ){
          this.tanqueChange();
        }
        if( name === 'id_FormaCompra' ){
          this.setState({ id_Tanque: '' });
          this.setState({ id_Domicilio: '' });
        }
      });
    }
  };

  handleChangeValidate = (name,validate) => event => {
    invoke.resetTime();
    if(validate.maxLength && (event.target.value).length <= validate.maxLength){
      this.setState({
        [name]: event.target.value,
      });
    }
    else if(validate.date){
      let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 10);
      var VAR = localISOTime;
      var VAL = new Date(event.target.value).toISOString().slice(0, 10);
      if(VAR <= VAL){
        this.setState({
          [name]: event.target.value,
        });
      }
    }
  };

  handleChangeText = (name,text) => event => {
    invoke.resetTime();
    this.setState({
      [name]: event.target.value,
    });
    this.setState({
      [text]: this.state.productosAgregar[event.target.value].obj,
    });
    this.setState({ [text]: this.state.productosAgregar[event.target.value].obj });
  };

  handleChangeCantidad = (name,text) => event => {
    invoke.resetTime();
    this.setState({
      [name]: event.target.value,
    });
    this.setState({ [name]: event.target.value });
    this.setState({
      [text]: this.state.cantidades[event.target.value].obj,
    });
    this.setState({ [text]: this.state.cantidades[event.target.value].obj });
  };

  handleChangeDireccion = (name,text) => event => {
    invoke.resetTime();
    this.setState({
      [name]: event.target.value,
    });
    this.setState({ [name]: event.target.value });
    this.setState({
      [text]: this.state.direccionEntregas[event.target.value].obj,
    });
    this.setState({ [text]: this.state.direccionEntregas[event.target.value].obj });
  };

  handleChangeProveedor = name => event => {
    invoke.resetTime();
    this.setState({
      [name]: (event.target)?event.target.value:event,
    }, () => {

      this.setState({ id_Operador : '' });
      this.setState({ id_Unidad : '' });

      //Combo de Operadores -------------------------------------------------------------------------
      invoke.post('pedidos','listarOperadores',{id_Proveedore: event.value, sn_Activo: 1}).then(res => {
        if(res.status===200 && res.data.data){
          var VAR = [];
          for(var i in res.data.data){
            var x = res.data.data[i];
            VAR.push({
              value: x.id_Operador,
              label: x.nb_Operador
            });
          }

          this.setState({ operadores: VAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });

      //Combo de Unidades de Trasporte  -------------------------------------------------------------
      invoke.post('pedidos','listarUnidadTransportes',{id_Proveedore: event.value, sn_Activo: 1}).then(res => {
        if(res.status===200 && res.data.data){
          var VAR = [];
          for(var i in res.data.data){
            var x = res.data.data[i];
            VAR.push({
              value: x.id_UnidadDeTransporte,
              label: x.nb_Unidad
            });
          }
          this.setState({ unidadTrasportes: VAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });
    });

  };

  proveedoresChange(id_Proveedor){
    //Combo de Operadores -------------------------------------------------------------------------
    invoke.post('pedidos','listarOperadores',{id_Proveedore: id_Proveedor, sn_Activo: 1}).then(res => {
      if(res.status===200 && res.data.data){
        let VAR = [];
        for(let i in res.data.data){
          let x = res.data.data[i];
          VAR.push({
            value: x.id_Operador,
            label: x.nb_Operador
          });
          if(this.state.id_Operador === x.id_Operador){
            this.setState({ id_Operador: { value: x.id_Operador, label: x.nb_Operador } });
          }
        }
        this.setState({ operadores: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Unidades de Trasporte  -------------------------------------------------------------
    invoke.post('pedidos','listarUnidadTransportes',{id_Proveedore: id_Proveedor, sn_Activo: 1}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_UnidadDeTransporte,
            label: x.nb_Unidad
          });
          if(this.state.id_UnidadDeTransporte === x.id_UnidadDeTransporte){
            this.setState({ id_UnidadDeTransporte: { value: x.id_UnidadDeTransporte, label: x.nb_Unidad } });
          }
        }
        this.setState({ unidadTrasportes: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });
  }

  /*PDF ACUSE CRE */
  openDialogAcuse = (row) => event => {
    var obj = {
      id_Pedido: null,
      id_PedidoNG: row.id_PedidoNG
    }

    this.setState({classMask:'maskProgress active'});
    invoke.post('pedidos','getInfoPdfAcuse',obj).then(res => {
      if(res.status===200 && res.data.data){
        var infoPDF =  res.data.data;
        var html = '';
        var registrosAcuse = [];
        let documento;
        
        if(!infoPDF.length){
          this.showNotification("tr", 'No se encontró información para generar el PDF acuse de la CRE.',);
          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 100);
          }.bind(this), 1);
          return false;
        }

        // Generación de PDF
        // Si el resultado es mas de una remisión, hacemos acordeones por los Acuses, de lo contraro solo mostramos el PDF
        if(infoPDF.length > 1 ){
          infoPDF.forEach((data,key)=>{
            documento = (<IconButton style={{color:'#0d4377'}} aria-label="Descargar" title={'Descargar Acuse'} aria-haspopup="true" onClick={this.generatePDFAcuse.bind(this, data)}>
                          <AssignmentReturned />
                        </IconButton>)
    
            registrosAcuse.push({
              title:(<div><Description style={{color: 'rgb(255 152 0 / 80%)', marginRight: '0px', verticalAlign: 'middle' }}/> {data.fl_Acuse}</div>),
              description: (<div>
                <table style={{ maxWidth: '900px', tableLayout: 'auto', marginLeft: 'auto', marginRight: 'auto', color: '#000000', border: ' 1px solid rgb(150, 150, 150)', fontSize: '12px'}}>
                  <thead>
                    <tr>
                      <th colSpan={2} style={{ backgroundColor: '#CCCCCC', height: '35px', textAlign: 'center', border: ' 1px solid rgb(150, 150, 150)' }}>
                        <b>COMISION REGULADORA DE ENERGIA</b>
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr><td colSpan={2} style={headerStyle}><b>Folio</b></td></tr>
                    <tr><td colSpan={2} style={tdStyle}>{infoPDF[key].fl_Acuse}</td></tr>
                    <tr>
                      <td style={headerStyle50}><b>Fecha de Firma</b></td>
                      <td style={headerStyle50}><b>Fecha de Recepción</b></td>
                    </tr>
                    <tr>
                      <td style={tdStyle50}>{infoPDF[key].fh_Firma}</td>
                      <td style={tdStyle50}>{infoPDF[key].fh_Recepcion}</td>
                    </tr>
                    <tr>
                      <td style={headerStyle50}><b>Representante Legal</b></td>
                      <td style={headerStyle50}> <b>RFC</b></td>
                    </tr>
                    <tr>
                      <td style={tdStyle50}>{infoPDF[key].nb_RepresentanteLegal} </td>
                      <td style={tdStyle50}>{infoPDF[key].de_RFC}</td>
                    </tr>
                    <tr><td colSpan={2} style={headerStyle}><b>Persona Representada</b></td></tr>
                    <tr><td colSpan={2} style={tdStyle}>{infoPDF[key].nb_PersonaRepresentada}</td></tr>
                    <tr><td colSpan={2} style={headerStyle}><b>Asunto</b></td></tr>
                    <tr><td colSpan={2} style={tdStyleJustify}>{infoPDF[key].de_Asunto}</td></tr>
                    <tr><td colSpan={2} style={headerStyle}><b>Tema o Permiso</b></td></tr>
                    <tr><td colSpan={2} style={tdStyle}>{infoPDF[key].nu_PermisoCRE}</td></tr>
                    {(infoPDF[key].sn_LeyendaCovid)?(
                      <tr><td colSpan={2} style={headerStyle}><b>¡Aviso Importante!</b></td></tr>
                    ):null}
                    {(infoPDF[key].sn_LeyendaCovid)?(
                      <tr>
                        <td colSpan={2} style={tdStyleJustify}>
                          En términos del punto Quinto del <strong>ACUERDO Número A/001/2021</strong> por el que se establece que la suspensión de plazos 
                          y términos legales en la Comisión Reguladora de Energía, como medida de prevención y combate de la propagación del coronavirus COVID-19, 
                          <strong> Publicado en el Diario Oficial de la Federación el 18 de Enero de 2021</strong>, se hace de su conocimiento que los plazos y 
                          terminos legales comenzarán a computarse hasta que la autoridad sanitaria del gobierno federal o autoridades de la Ciudad de México 
                          determinen que no existe un riesgo epidemiológico relacionado con la apertura de las actividades relacionadas con la Administración Pública Federal.
                        </td>
                      </tr>
                    ):null}
                    <tr><td colSpan={2} style={headerStyle}><b>Información Adicional</b></td></tr>
                    <tr><td colSpan={2} style={headerStyle}><b>Cliente</b></td></tr>
                    <tr><td colSpan={2} style={tdStyle}>{infoPDF[key].nb_Cliente}</td></tr>
                    <tr>
                      <td style={headerStyle50}><b>Producto</b></td>
                      <td style={headerStyle50}><b>Litros</b></td>
                    </tr>
                    <tr>
                      <td style={tdStyle50}>{infoPDF[key].nb_ProductoGeneral}</td>
                      <td style={tdStyle50}>{infoPDF[key].nu_Cantidad}</td>
                    </tr>
                    <tr><td colSpan={2} style={tdStyle}><i>{infoPDF[key].de_LeyendaFinal}</i></td></tr>
                  </tbody>
                </table>
              </div>),
              urlDocumento: (<div style={{ position: 'absolute', right: '5px', top: '5px'}}>{documento}</div>)
            });
          })
          
          this.setState({registrosAcuse: registrosAcuse})
          this.setState({ openDialogAcuseAccordion: true });
        } else {
          
          html = (<div>
            <table style={{ maxWidth: '900px', tableLayout: 'auto', marginLeft: 'auto', marginRight: 'auto', color: '#000000', border: ' 1px solid rgb(150, 150, 150)', fontSize: '12px'}}>
              <thead>
                <tr>
                  <th colSpan={2} style={{ backgroundColor: '#CCCCCC', height: '35px', textAlign: 'center', border: ' 1px solid rgb(150, 150, 150)' }}>
                    <b>COMISION REGULADORA DE ENERGIA</b>
                    </th>
                </tr>
              </thead>
              <tbody>
                <tr><td colSpan={2} style={headerStyle}><b>Folio</b></td></tr>
                <tr><td colSpan={2} style={tdStyle}>{infoPDF[0].fl_Acuse}</td></tr>
                <tr>
                  <td style={headerStyle50}><b>Fecha de Firma</b></td>
                  <td style={headerStyle50}><b>Fecha de Recepción</b></td>
                </tr>
                <tr>
                  <td style={tdStyle50}>{infoPDF[0].fh_Firma}</td>
                  <td style={tdStyle50}>{infoPDF[0].fh_Recepcion}</td>
                </tr>
                <tr>
                  <td style={headerStyle50}><b>Representante Legal</b></td>
                  <td style={headerStyle50}> <b>RFC</b></td>
                </tr>
                <tr>
                  <td style={tdStyle50}>{infoPDF[0].nb_RepresentanteLegal} </td>
                  <td style={tdStyle50}>{infoPDF[0].de_RFC}</td>
                </tr>
                <tr><td colSpan={2} style={headerStyle}><b>Persona Representada</b></td></tr>
                <tr><td colSpan={2} style={tdStyle}>{infoPDF[0].nb_PersonaRepresentada}</td></tr>
                <tr><td colSpan={2} style={headerStyle}><b>Asunto</b></td></tr>
                <tr><td colSpan={2} style={tdStyleJustify}>{infoPDF[0].de_Asunto}</td></tr>
                <tr><td colSpan={2} style={headerStyle}><b>Tema o Permiso</b></td></tr>
                <tr><td colSpan={2} style={tdStyle}>{infoPDF[0].nu_PermisoCRE}</td></tr>
                {(infoPDF[0].sn_LeyendaCovid)?(
                  <tr><td colSpan={2} style={headerStyle}><b>¡Aviso Importante!</b></td></tr>
                ):null}
                {(infoPDF[0].sn_LeyendaCovid)?(
                  <tr>
                    <td colSpan={2} style={tdStyleJustify}>
                      En términos del punto Quinto del <strong>ACUERDO Número A/001/2021</strong> por el que se establece que la suspensión de plazos 
                      y términos legales en la Comisión Reguladora de Energía, como medida de prevención y combate de la propagación del coronavirus COVID-19, 
                      <strong> Publicado en el Diario Oficial de la Federación el 18 de Enero de 2021</strong>, se hace de su conocimiento que los plazos y 
                      terminos legales comenzarán a computarse hasta que la autoridad sanitaria del gobierno federal o autoridades de la Ciudad de México 
                      determinen que no existe un riesgo epidemiológico relacionado con la apertura de las actividades relacionadas con la Administración Pública Federal.
                    </td>
                  </tr>
                ):null}
                <tr><td colSpan={2} style={headerStyle}><b>Información Adicional</b></td></tr>
                <tr><td colSpan={2} style={headerStyle}><b>Cliente</b></td></tr>
                <tr><td colSpan={2} style={tdStyle}>{infoPDF[0].nb_Cliente}</td></tr>
                <tr>
                  <td style={headerStyle50}><b>Producto</b></td>
                  <td style={headerStyle50}><b>Litros</b></td>
                </tr>
                <tr>
                  <td style={tdStyle50}>{infoPDF[0].nb_ProductoGeneral}</td>
                  <td style={tdStyle50}>{infoPDF[0].nu_Cantidad}</td>
                </tr>
                <tr><td colSpan={2} style={tdStyle}><i>{infoPDF[0].de_LeyendaFinal}</i></td></tr>
              </tbody>
            </table>
          </div>);
          this.setState({htmlAcuseCre: html})
          this.setState({infoAcuse: infoPDF[0]})
          this.setState({ openDialogAcuse: true });
        }
      }else{
        this.showNotification("tr", res.data.message)
      }
      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
    invoke.resetTime();
  }

  handleClickCloseAcuse = () => {
    this.setState({ openDialogAcuse: false });
  };

  handleClickCloseAcuseAccordion = () => {
    this.setState({ openDialogAcuseAccordion: false });
  };

  generatePDFAcuse = async (data) => {
    const blob = await pdf(
      <Document>
        <Page size="A4" style={styles.page}>
          <Text style={styles.headerColspan}>COMISION REGULADORA DE ENERGIA</Text>
          <Text style={styles.headerColspan}>Folio</Text>
          <Text style={styles.cellColspan}>{data.fl_Acuse}</Text>
          <View style={styles.view}>
              <Text style={styles.header}>Fecha de Firma</Text>
              <Text style={styles.header2}>Fecha de Recepción</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.cell}>{data.fh_Firma}</Text>
              <Text style={styles.cell2}>{data.fh_Recepcion}</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.header}>Representante Legal</Text>
              <Text style={styles.header2}>RFC</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.cell}>{data.nb_RepresentanteLegal}</Text>
              <Text style={styles.cell2}>{data.de_RFC}</Text>
          </View>
          <Text style={styles.headerColspan}>Persona Representada</Text>
          <Text style={styles.cellColspan}>{data.nb_PersonaRepresentada}</Text>
          <Text style={styles.headerColspan}>Asunto</Text>
          <Text style={styles.text}>
            {data.de_Asunto}
          </Text>
          <Text style={styles.headerColspan}>Tema o Permiso</Text>
          <Text style={styles.cellColspan}>{data.nu_PermisoCRE}</Text>
          {(data.sn_LeyendaCovid)?(
              <Text style={styles.headerColspan}>¡Aviso Importante!</Text>
            ):<Text></Text>
          }
          {(data.sn_LeyendaCovid)?(
            <Text style={styles.text}>
              En términos del punto Quinto del ACUERDO Número A/001/2021 por el que se establece que la suspensión de plazos 
              y términos legales en la Comisión Reguladora de Energía, como medida de prevención y combate de la propagación del coronavirus COVID-19, 
              Publicado en el Diario Oficial de la Federación el 18 de Enero de 2021, se hace de su conocimiento que los plazos y 
              terminos legales comenzarán a computarse hasta que la autoridad sanitaria del gobierno federal o autoridades de la Ciudad de México 
              determinen que no existe un riesgo epidemiológico relacionado con la apertura de las actividades relacionadas con la Administración Pública Federal.
            </Text>
            ):<Text></Text>
          }
          <Text style={styles.headerColspan}>Información Adicional</Text>
          <Text style={styles.headerColspan}>Cliente</Text>
          <Text style={styles.cellColspan}>{data.nb_Cliente}</Text>
          <View style={styles.view}>
              <Text style={styles.header}>Producto</Text>
              <Text style={styles.header2}>Litros</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.cell}>{data.nb_ProductoGeneral}</Text>
              <Text style={styles.cell2}>{data.nu_Cantidad}</Text>
          </View>
          <Text style={styles.cellColspanItalic}>{data.de_LeyendaFinal}</Text>
        </Page>
      </Document>
    ).toBlob()
    saveAs(blob, "AcuseCRE  - " + data.fl_Acuse);
  };

  noOfDaysInMonth(){
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  getFirstDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      1,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  getLastDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      this.noOfDaysInMonth(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  onError = () => e => {
    console.log(e);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <ItemGrid xs={12} sm={12} md={12}>
          <SpecialCard
            icon={Assignment}
            iconColor="orange"
            cardTitle="Pedidos de Combustible"
            cardSubtitle={this.state.principalDesciption}
            content={(<div>
              <div className={classes.backForm}>
                <MuiThemeProvider theme={theme}>
                  <Grid container>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="id_Producto"
                        select
                        label="Producto"
                        className={classes.textField}
                        value={this.state.id_Producto}
                        onChange={this.handleChange('id_Producto')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.productos.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="name"
                        label="Folio de Pedido"
                        className={classes.textField}
                        value={this.state.fl_Pedido}
                        onChange={this.handleChangeValidate('fl_Pedido',{'maxLength':10})}
                        margin="normal"
                        fullWidth
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={3}>
                    <TextField
                      id="id_Plaza"
                      select
                      label="Plaza"
                      className={classes.textField}
                      value={this.state.id_PlazaFiltro}
                      onChange={this.handleChange('id_PlazaFiltro')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth>
                      {this.state.plazasFiltro.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="id_Estatus"
                        select
                        label="Estatus Pedido"
                        className={classes.textField}
                        value={this.state.id_Estatus}
                        onChange={this.handleChange('id_Estatus')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.estatus.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                  </Grid>
                  <Grid container>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="fh_Inicio"
                        label="Fecha Entrega Inicio"
                        type="date"
                        className={classes.textField}
                        value={this.state.fh_Inicio}
                        onChange={this.handleChange('fh_Inicio')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        fullWidth
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="fh_Fin"
                        label="Fin"
                        type="date"
                        className={classes.textField}
                        value={this.state.fh_Fin}
                        onChange={this.handleChange('fh_Fin')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        fullWidth
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={5}>
                        <TextField
                          id="id_Domicilio"
                          select
                          label="Domicilio de Entrega"
                          className={classes.textField}
                          value={this.state.id_DomicilioFiltro}
                          onChange={this.handleChange('id_DomicilioFiltro')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.domiciliosEntrega.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                    <ItemGrid xs={1} sm={1} md={1}>
                      <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                        <IconButton
                            color="primary"
                            aria-label="Buscar"
                            aria-haspopup="true"
                            onClick={this.handleClickSearch}>
                          <Search className={classes.links} />
                        </IconButton>
                      </Tooltip>
                    </ItemGrid>
                  </Grid>

                      
                </MuiThemeProvider>
              </div>
              <Grid container>
                <Hidden smDown>
                  <Table xs={12} sm={12} md={12}
                    tableHead={[
                      { id: 'nb_Plaza', numeric: true, disablePadding: false, label: 'Plaza' },
                      { id: 'fl_Pedido', numeric: true, disablePadding: false, label: 'Folio Pedido' },
                      { id: 'fh_Entrega', numeric: false, disablePadding: false, label: 'Fecha Entrega Requerida' },
                      { id: 'fh_Entrega', numeric: false, disablePadding: false, label: 'Fecha Entrega Estimada' },
                      { id: 'nb_FormaDeEntrega', numeric: false, disablePadding: false, label: 'Forma de Entrega' },
                      { id: 'nb_FormaPago', numeric: false, disablePadding: false, label: 'Forma de Pago' },
                      { id: 'nb_Producto', numeric: false, disablePadding: false, label: 'Producto' },
                      { id: 'nu_Cantidad', numeric: true, disablePadding: false, label: 'Cantidad' },
                      { id: 'nb_direccion', numeric: false, disablePadding: false, label: 'Centro de Entrega' },
                      { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Pedido' },
                      { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                    ]}
                    tableData={this.state.tableData}
                  />
                </Hidden>
                <Hidden mdUp>
                  <TableMovile xs={12} sm={12} md={12}
                    tableHead={[
                      { id: 'listado', numeric: true, disablePadding: false, label: 'Listado de Pedidos' },
                    ]}
                    tableData={this.state.tableDataMovile}
                  />
                </Hidden>
              </Grid>
              <label style={{color:'red', display: 'block', marginTop: '30px'}}>Para realizar pedidos urgentes o cancelaciones de pedidos, favor de comunicarse con su contacto de atención a clientes Petroil.</label>
              </div>)
            }
            add = {this.handleClickAdd(0)}
            excel = {<div><ExcelFile element={
              <div style={{top:'0',right:'50px',position:'absolute'}}>
                <Tooltip
                  title={'Descargar Excel'}
                  placement={'bottom-start'}
                  enterDelay={100}
                >
                  <IconButton
                      aria-label="Excel"
                      aria-haspopup="true"
                      className={classes.buttonLink}>
                    <Assessment className={classes.links} />
                  </IconButton>
                </Tooltip>
              </div>
            }>
              <ExcelSheet dataSet={this.state.multiDataSet} name="Pedidos"/>
            </ExcelFile></div>}
            back = {this.handleClickBack}
            edit = {this.state.SearchView}
          />
          <div className={this.state.classMask}>
            <Linear  />
          </div>
          <FormDialog
            title={(this.state.id_PedidoNG > 0)? (<div><Edit style={{fontSize:'22px',verticalAlign:'text-top'}}/> Editar Solicitud</div>):<div><AddCircle style={{fontSize:'22px',verticalAlign:'text-top'}}/> Agregar Solicitud</div>}
            description = {(<div><Grid container>
              <ItemGrid xs={12} sm={12} md={7} style={{paddingRight: '10px'}}>
                <label style={{color:'#0d4377',fontSize:'1em'}}>Una vez enviada la solicitud ya no se podrá editar.</label>
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={2}>
                <TextField
                  id="fh_Entrega"
                  label="Fecha de Entrega"
                  error={this.state.required_fh_Entrega}
                  type="date"
                  disabled={this.state.fh_view}
                  className={classes.textField}
                  value={this.state.fh_Entrega}
                  onChange={this.handleChangeValidate('fh_Entrega',{'date':true,'min': new Date()})}
                  InputLabelProps={{
                    style: {color: '#ff9800'},
                    shrink: true,
                  }}
                  InputProps={{
                    style: {color: 'rgb(13, 67, 119)'}
                  }}
                  FormHelperTextProps={{
                    style: {color: '#F44336'}
                  }}
                  margin="dense"
                  helperText="Horario sujeto a disponibilidad"
                  fullWidth
                />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={2}>
                <TextField
                  id="fh_Hora"
                  label="Hora Entrega"
                  error={this.state.required_fh_Hora}
                  type="time"
                  value={this.state.fh_Hora}
                  onChange={this.handleChange('fh_Hora')}
                  className={classes.textField}
                  InputLabelProps={{
                    style: {color: '#ff9800'},
                    shrink: true,
                  }}
                  InputProps={{
                    style: {color: 'rgb(13, 67, 119)'}
                  }}
                  margin="dense"
                  fullWidth
                />
              </ItemGrid></Grid></div>)}
            content= {
              <div>
                <Grid container>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                      id="id_Plaza"
                      select
                      error={this.state.required_id_Plaza}
                      label="Plaza"
                      disabled={ (this.state.id_PedidoNG > 0) ? true : false }
                      className={classes.textField}
                      value={this.state.id_Plaza}
                      onChange={this.handleChange('id_Plaza')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.plazas.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}></ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                      id="id_FormaEntrega"
                      select
                      error={this.state.required_id_FormaEntrega}
                      label="Forma de Entrega"
                      className={classes.textField}
                      value={this.state.id_FormaEntrega}
                      onChange={this.handleChange('id_FormaEntrega')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        },
                        value: this.state.id_FormaEntrega
                      }}
                      margin="normal"
                      fullWidth
                      autoFocus
                    >
                    {this.state.formaEntregas.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                      id="id_FormaCompra"
                      select
                      error={this.state.required_id_FormaCompra}
                      label="Forma de Compra"
                      className={classes.textField}
                      value={this.state.id_FormaCompra}
                      onChange={this.handleChange('id_FormaCompra')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.formaCompras.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ItemGrid>
                  {(this.state.id_FormaCompra===4)?(
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                      id="id_Tanque"
                      select
                      error={this.state.required_id_Tanque}
                      label="Tanque de Almacenamiento"
                      className={classes.textField}
                      value={this.state.id_Tanque}
                      onChange={this.handleChange('id_Tanque')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.tanques.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ItemGrid>
                  ):null}
                  <ItemGrid xs={12} sm={12} md={(this.state.id_FormaCompra===4)?6:12}>
                    <TextField
                      id="id_Domicilio"
                      select
                      error={this.state.required_id_Domicilio}
                      label="Direccion de Entrega"
                      className={classes.textField}
                      value={this.state.id_Domicilio}
                      disabled={(this.state.id_Tanque)?true:false}
                      onChange={this.handleChange('id_Domicilio')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.direccionEntregas.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                      id="id_ProductoGeneral"
                      select
                      error={this.state.required_id_ProductoGeneral}
                      label="Productos"
                      className={classes.textField}
                      value={this.state.id_ProductoGeneral}
                      onChange={this.handleChange('id_ProductoGeneral')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.productosAgregar.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                      className={classes.formControl}
                      error={this.state.required_nu_Cantidad}
                      label="Cantidad"
                      value={this.state.nu_Cantidad}
                      onChange={this.handleChange('nu_Cantidad')}
                      id="Cantidad"
                      margin="normal"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        style: {textAlign:'right'}
                      }}
                    />
                  </ItemGrid>
                  {(this.state.id_FormaEntrega===3)?(
                    <ItemGrid xs={12} sm={12} md={12}>
                      <Select
                        value={this.state.id_Proveedor}
                        error={this.state.required_id_Proveedor}
                        onChange={this.handleChangeProveedor('id_Proveedor')}
                        options={this.state.proveedores}
                        styles={colourStyles('"Proveedor Transportista"')}
                        placeholder={"Seleccionar"}
                      />
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega===3)?(
                    <ItemGrid xs={12} sm={12} md={6}>
                      <Select
                        value={this.state.id_Operador}
                        error={this.state.required_id_Operador}
                        onChange={this.handleChange('id_Operador')}
                        options={this.state.operadores}
                        styles={colourStyles('"Operador Transportista"')}
                        placeholder={"Seleccionar"}
                      />
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega===3)?(
                    <ItemGrid xs={12} sm={12} md={6}>
                      <Select
                        value={this.state.id_UnidadDeTransporte}
                        error={this.state.required_id_UnidadTransporte}
                        onChange={this.handleChange('id_UnidadDeTransporte')}
                        options={this.state.unidadTrasportes}
                        styles={colourStyles('"Unidad de Transporte"')}
                        placeholder={"Seleccionar"}
                      />
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega===3)?(
                    <ItemGrid xs={12} sm={12} md={12}>
                      <TextField
                        id="nb_ProductoAnterior"
                        error={this.state.required_nb_ProductoAnterior}
                        label="Producto que transportó en el viaje anterior"
                        className={classes.textField}
                        value={this.state.nb_ProductoAnterior}
                        onChange={this.handleChangeValidate('nb_ProductoAnterior',{maxLength:50})}
                        margin="normal"
                        fullWidth
                      />
                    </ItemGrid>
                  ):null}
                  <ItemGrid xs={12} sm={12} md={12}>
                    <TextField
                      id="de_Comentarios"
                      multiline
                      rows="2"
                      error={this.state.required_de_Comentarios}
                      label="Comentarios"
                      className={classes.textField}
                      value={this.state.de_Comentarios}
                      onChange={this.handleChangeValidate('de_Comentarios',{maxLength:300})}
                      margin="normal"
                      fullWidth
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={5} style={{paddingRight: '10px'}}>
                    <label style={{color:'#F44336',fontSize:'1em'}}>** El pedido de Combustible se podra registrar a mas tardar 3 horas antes de la hora requerida por el cliente.</label>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={2} style={{paddingRight: '10px'}}></ItemGrid>
                  {(this.state.id_PedidoNG > 0 && this.state.id_EstatusSeguimiento !== 2105)?(
                    <ItemGrid xs={12} sm={12} md={5} style={{paddingRight: '10px'}}>
                    <label style={{color:'#F44336',fontSize:'1em'}}>** Una vez enviado el pedido, el usuario solo cuenta con 1 hora para realizar modificaciones.</label>
                  </ItemGrid>
                  ):null}
                </Grid>
              </div>
            }
            btn= {(this.state.id_PedidoNG > 0) ? (<div><Edit style={{fontSize: '16px', verticalAlign: 'sub', paddingRight: '5px'}}/><label style={{color: '#2979ff'}}>Editar</label></div>) : (<label style={{color: '#2979ff'}}>Guardar</label>)}
            action= {this.handleClickAddDialog((this.state.id_PedidoNG > 0) ? 2 : 0)}
            close={this.handleClickClose}
            open= {this.state.openDialog}
            secondBtn = {(this.state.id_PedidoNG > 0) ? ((this.state.id_EstatusSeguimiento===2105) ? {action:this.handleClickAddDialog(1), text:'Editar y Enviar'} : null) : {action:this.handleClickAddDialog(1), text:'Guardar y Enviar'}}
          />
          <FormDialog
            title="Documentos Relacionados"
            description={this.state.de_DescripcionListadoFacturas}
            content= {this.state.div_FacturasRelacionadas}
            close={this.handleClickCloseFacturasRelacionadas}
            open= {this.state.openDialogFacturasRelacionadas}
          />
          <FormDialog
            title="Cancelar Solicitud"
            description= {this.state.descriptionCancelar}
            content= {
              <div>
                <TextField
                  id="nb_mensajeCancelar"
                  label="Comentarios"
                  error={this.state.requiredMensajeCancelar}
                  multiline
                  rowsMax="3"
                  value={this.state.nb_mensajeCancelar}
                  onChange={this.handleChange('nb_mensajeCancelar')}
                  className={classes.textField}
                  margin="normal"
                  fullWidth
                  autoFocus
                />
              </div>
            }
            btn= 'Aceptar'
            action= {this.handleClickDelete}
            close={this.handleClickCloseCancel}
            open= {this.state.openDialogCancel}
          />
          <FormDialog
            title={(<div><InputAdornment position="start"><AssignmentTurnedIn /><span style={{paddingLeft:'15px'}}>Confirmación de Pedido</span></InputAdornment></div>)}
            description= {this.state.descriptionConfirmacion}
            content= {
              <div style={{maxWidth:'550px'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(this.state.checkedConfirmacion==='TRUE')?true:false}
                      onChange={this.handleChange('checkedConfirmacion')}
                      value={this.state.checkedConfirmacion}
                      style={{color:'#ff9800'}}
                    />
                  }
                  label={(<p>Recibí a mi entera satisfacción <b> {this.state.nu_CantidadConfirmacion} litros</b>, por lo que me reservo de cualquier acción legal alguna.</p>)}
                />
              </div>
            }
            btn= 'Aceptar'
            action= {this.handleClickConfirmacion}
            close={this.handleClickCloseConfirmacion}
            open= {this.state.openDialogConfirmacion}
          />

          <FormDialog
            title={(<div><InputAdornment position="start"><AssignmentReturned /><span style={{paddingLeft:'15px'}}>Descargar Factura</span></InputAdornment></div>)}
            description= {this.state.descriptionFactura}
            content= {
              <div>
                <iframe title="Factura" style={{minHeight: '63VH', minWidth: '1100px'}} src={this.state.urlFactura}></iframe>
              </div>
            }
            btn= 'Descargar'
            action= {this.handleClickFactura}
            close={this.handleClickCloseFactura}
            open= {this.state.openDialogFactura}
          />
          <FormDialog
            title={(<div><InputAdornment position="start"><FormatListBulleted /><span style={{paddingLeft:'15px'}}>Seguimiento de Pedidos</span></InputAdornment></div>)}
            content={this.state.seguimiento}
            close={this.handleClickCloseSeguimiento}
            open= {this.state.openDialogSeguimiento}
          />
          <FormDialog
            title={(<div><InputAdornment position="start"><Description /><span style={{paddingLeft:'15px'}}>Visor Acuse CRE</span></InputAdornment></div>)}
            content={this.state.htmlAcuseCre}
            btn= 'Imprimir'
            action={<Button style={{color: '#fff', background: '#ff9800', padding: '5px 10px'}} className={classes.button} onClick={this.generatePDFAcuse.bind(this, this.state.infoAcuse)}><Print /> Imprimir</Button>}
            close={this.handleClickCloseAcuse}
            open= {this.state.openDialogAcuse}
          />
          {/* Cuando trae mas de un registro de acuse */}
          <FormDialog
            title = {(<div><InputAdornment position="start"><Description /><span style={{paddingLeft:'15px'}}>Visor Acuse CRE</span></InputAdornment></div>)}
            description = {(<div style={{paddingBottom: '5px', fontWeight: '700'}}></div>)}
            content = {
              <div style={{ minWidth: '850px'}}>
                <ExpansionPanelCustomized
                  panels={ this.state.registrosAcuse }
                />
              </div>
            }
            close={this.handleClickCloseAcuseAccordion}
            open= {this.state.openDialogAcuseAccordion}
          />
          <Alerta
            title={this.state.al_title}
            content={this.state.al_content}
            close={this.handleClickCloseAlerta}
            open= {this.state.al_open}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.tr}
            onClose={() => this.setState({ tr: false })}
            ContentProps={{ 'className': classes.red }}
            message={this.state.message}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.tc}
            onClose={() => this.setState({ tc: false })}
            ContentProps={{ 'className': classes.green }}
            message={this.state.message}
          />
        </ItemGrid>
      </Grid>
    );
}
}

Pedidos.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Pedidos);
