import React from "react";
import PropTypes from "prop-types";
import invoke from "services/invoke.jsx";
import { Menu, Language, Contacts, Description } from "@material-ui/icons";
import { withStyles, AppBar, Toolbar, IconButton, Hidden, Button, Typography } from "@material-ui/core";
import cx from "classnames";
//import { NavLink } from "react-router-dom";
import headerStyle from "assets/jss/material-dashboard-react/headerStyle.jsx";
import {domains, hostname } from 'variables/domains/domains.jsx'
import HeaderLinks from "./HeaderLinks";

var sesion = invoke.getSesion();
var cliente = invoke.getSesionCliente();

function Header({ ...props }) {
  /*function makeBrand() {
    var name;
    props.routes.map((prop, key) => {
      if (prop.path === props.location.pathname) {
        name = prop.navbarName;
      }
      return null;
    });
    return name;
  }*/
  const { classes, color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });

  if (props.location.pathname !== '/login' && props.location.pathname !== '/signin')
  {
    if (sesion) {
      return (
        <AppBar className={classes.appBar + appBarClasses}>
          <Toolbar className={classes.container}>
            <div className={classes.flex}>
              {/* Here we create navbar brand, based on route name */}
              <Typography variant="subheading" gutterBottom>
                <Contacts style={{ color:'#555',verticalAlign: 'sub', marginRight: '10px'}}/>{(sesion)?sesion.usuario.de_RazonSocial:'Petroil'}
              </Typography>
            </div>
            <Hidden smDown implementation="css">
              <HeaderLinks location={props.location} history={props.history}/>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                className={classes.appResponsive}
                color="inherit"
                aria-label="open drawer"
                onClick={props.handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Hidden>
          </Toolbar>
          {/* Permiso del Cliente cuando es Distribuidora / Leyenda del sitio */}
          {(sesion) ? (
            (sesion.usuario.id_TipoDeNegocio === 1 && sesion.usuario.nu_PermisoCRE) ? (
              <Toolbar className={classes.container} style={{ marginTop: '-15px' }}>
                <div className={classes.flex}>
                  {/* Here we create navbar brand, based on route name */}
                  <Typography variant="subheading" gutterBottom>
                    <Description style={{ color: '#555', verticalAlign: 'sub', marginRight: '10px' }} />{(sesion.usuario.nu_PermisoCRE) ? sesion.usuario.nu_PermisoCRE : 'N/A'}
                  </Typography>
                </div>
              </Toolbar>
            ) : (<label></label>)
          ) : (<label></label>)}
        </AppBar>
      );
    } else if (cliente) {
      return (
        <AppBar className={classes.appBarLogin + appBarClasses}>
          <Toolbar className={classes.container}>
            <div className={classes.flex}>

            </div>
            <Hidden mdUp>
              <IconButton
                className={classes.appResponsive}
                color="inherit"
                aria-label="open drawer"
                onClick={props.handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>

      );
    } else {
      return (
        <AppBar className={classes.appBarLogin + appBarClasses}>
          <Toolbar className={classes.container}>
            <div className={classes.flex}>
                {(domains[hostname])?domains[hostname].logo3:''}
            </div>
            <Hidden smDown implementation="css">
              <a href="http://petroil.com.mx" target="_blank" rel="noopener noreferrer" className={classes.botonLogin}>
                <Button className={classes.botonLogin}>
                  <Language className={classes.leftIcon} style={{marginRight: '5px'}}/> Web Site Petroil
                </Button>
              </a>
              {(domains[hostname])?domains[hostname].logo4:''}
            </Hidden>
          </Toolbar>
        </AppBar>
      );
    }
  }else{
    return (
      <AppBar className={classes.appBarLogin + appBarClasses}>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
              {(domains[hostname])?domains[hostname].logo3:''}
          </div>
          <Hidden smDown implementation="css">
            <a href="http://petroil.com.mx" target="_blank" rel="noopener noreferrer" className={classes.botonLogin}>
              <Button className={classes.botonLogin}>
                <Language className={classes.leftIcon} style={{marginRight: '5px'}}/> Web Site Petroil
              </Button>
            </a>
            {(domains[hostname])?domains[hostname].logo4:''}
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(headerStyle)(Header);
